import * as React from "react";
import { CloudaccountMobx } from "../../mst/kinds/cloudaccount";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../mobxDataModels/stringModel";
import { notification } from "antd";
import { BrowserServiceModal } from "../identity/create/browserServiceModal";
import { BrowserServiceMobx, BrowserServiceModel } from "../../mst/kinds/browser";
import { FormButtons } from "../../components/forms/formButtons";
import { nameOfCloudaccountProvider } from "../../services/cpln";
import { useDetailContext } from "../../components/detail/detailContext";
import { NGLabel } from "../../newcomponents/text/label";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  cloudaccount: CloudaccountMobx;
}
const InfoRaw: React.FC<Props> = ({ cloudaccount }) => {
  const { fetchItem } = useDetailContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isBrowsing, setIsBrowsing] = React.useState(false);
  const browserServiceRef = React.useRef<BrowserServiceMobx>(null as any);
  const browserService = browserServiceRef.current;
  const descriptionRef = React.useRef(
    StringModel.create({ label: "Description", initialValue: cloudaccount.description }),
  );

  React.useEffect(() => {
    if (cloudaccount.provider !== "ngs") {
      browserServiceRef.current = BrowserServiceModel.create({ cloudaccountLink: cloudaccount.selfLink });
    }
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(descriptionRef.current.isDirty);
  }, [descriptionRef.current.isDirty]);

  useCleanPrompt();

  function reset() {
    descriptionRef.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      const description = descriptionRef.current.value || null;
      await cloudaccount.patch({ description });
      descriptionRef.current.confirm();
      if (!descriptionRef.current.value) {
        descriptionRef.current.setInitialValue(cloudaccount.name);
      }
      notification.success({
        message: "Success",
        description: "Updated cloud account",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  function onBrowse() {
    setIsBrowsing(true);
  }

  const showSubId = cloudaccount.provider === "azure" && !!cloudaccount.data?.subscriptionId;

  return (
    <>
      <NGFormElement
        name="description"
        label={descriptionRef.current.label}
        value={descriptionRef.current.value}
        onChange={descriptionRef.current.setValue}
      />
      <div className="flex items-center mb-4">
        <div className="w-4/12">
          <NGLabel>Provider</NGLabel>
          <div>{nameOfCloudaccountProvider(cloudaccount.provider)}</div>
        </div>
        {cloudaccount.provider === "aws" ? (
          <div className="w-4/12">
            <NGLabel>Role Arn</NGLabel>
            <div>{cloudaccount.data?.roleArn}</div>
          </div>
        ) : null}
        {cloudaccount.provider === "gcp" ? (
          <div className="w-4/12">
            <NGLabel>Project Id</NGLabel>
            <div>{cloudaccount.data?.projectId}</div>
          </div>
        ) : null}
        {cloudaccount.provider === "ngs" ? (
          <div className="w-4/12">
            <NGLabel>Secret</NGLabel>
            <div>{cloudaccount.data?.secretLink?.split("/")[4] || "Not found"}</div>
          </div>
        ) : null}
        {showSubId ? (
          <div className="w-4/12">
            <NGLabel>Subscription Id</NGLabel>
            <div>{cloudaccount.data?.subscriptionId}</div>
          </div>
        ) : null}
      </div>
      {cloudaccount.provider === "ngs" ? null : (
        <div className="flex flex-col mb-4">
          <NGLabel>Browser Service</NGLabel>
          <div className="flex items-center">
            <div>Check the resources with this cloud account to see if it is correctly configured.</div>
            <NGButton className="ml-2" size={"small"} variant={"primary"} onClick={onBrowse}>
              Browse
            </NGButton>
          </div>
        </div>
      )}
      <TagLinksTable
        tableId="cloudaccount-info-tag-links"
        tags={Object.entries(cloudaccount.tags || {})
          .map(([tagKey, tagValue]) => ({ key: tagKey, value: (tagValue as string) || "" }))
          .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
        styles={{ header: { marginBottom: 0 } }}
      />
      <FormButtons
        onSave={save}
        onReset={reset}
        loading={isLoading}
        resetDisabled={isLoading || !descriptionRef.current.isDirty}
        saveDisabled={isLoading || !descriptionRef.current.isDirty || !descriptionRef.current.isValid}
      />
      {isBrowsing ? (
        <BrowserServiceModal
          title={"Browse Resources"}
          onClose={() => {
            setIsBrowsing(false);
          }}
          selectionType={"none"}
          browserService={browserService}
        />
      ) : null}
    </>
  );
};

export const Info = observer(InfoRaw);
