import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { DryRunAlert } from "../../dryRunAlert";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { mk8sMobx } from "./../../../../mst/kinds/mk8s/mk8s";
import { useNGFormContext } from "../../../../reactContexts/ngFormContext";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderEphemeralRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const e = mk8sDraft.provider_ephemeral!;
  const formData = useNGFormContext();

  return (
    <>
      <div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"ephemeral.location"}
            as={"select"}
            label={e.location.label}
            options={e.location.options}
            value={e.location.value}
            onChange={e.location.setValue}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={e.location.setValue}
              path={"spec.provider.ephemeral.location"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const ProviderEphemeral = observer(ProviderEphemeralRaw);
