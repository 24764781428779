import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, useLocation } from "react-router-dom";
import { DetailLayout } from "../../components/detail/layout";
import { Events } from "../../components/detail/events";
import { AccessReport } from "../../components/detail/accessReport";
import { TagsNew } from "../../components/detail/tagsNew";
import { AuditTrail } from "../../components/detail/auditTrail";
import { Info } from "./info";
import { mk8sMobx } from "../../mst/kinds/mk8s/mk8s";
import { Provider } from "./provider/provider";
import { AddonsRoute } from "./addons/route";
import { Mk8sDraftMobx } from "../../mst/stores/mk8s.draft";
import { mk8sDryRun } from "../../mobxStores/dryRun/mk8s";
import { KubeConfig } from "./kubeConfig";
import { IS_DEPLOYMENT_ENV_TEST, METRICS_TOKEN_UPDATE_INTERVAL_MS } from "../../envVariables";
import { HiddenSectionsMobx } from "../../mobxStores/hiddenSections";
import { Firewall } from "./firewall";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { InnerNavLink } from "../../components/detail/ngNav";
import { AlertCircle, Plus } from "react-feather";
import { FormButtons } from "../../components/forms/formButtons";
import { Tooltip as AntTooltip, notification } from "antd";
import { useDetailContext } from "../../components/detail/detailContext";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useSetPromptShouldBlock } from "../../reactHooks/useSetPromptShouldBlock";
import { useNGFormContext } from "../../reactContexts/ngFormContext";
import { Discovery } from "../../mobxStores/discovery/discovery";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { getLocalToken } from "../../services/cpln";
import { getMetricsUrl } from "../../services/utils";
import { Mk8sLogs } from "./logs";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";

// add button
// checkbox on list of items
// scrape annotated checkbox

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
  startDraft: (w: mk8sMobx) => Promise<void>;
}
const Mk8sDetailRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const basePath = useBasePath("/mk8s/:mk8s/*");
  const formData = useNGFormContext();
  const { pathname } = useLocation();

  const eventlogHook = useEventlogs(mk8s.selfLink);

  const { fetchItem } = useDetailContext();

  const [isLoading, setIsLoading] = React.useState(false);

  const [metricsTokenFromLocal, setMetricsTokenFromLocal] = React.useState("");
  React.useEffect(() => {
    getLocalToken().then((res) => {
      if (res.accessToken !== "pass") {
        setMetricsTokenFromLocal(res.accessToken);
      }
    });
    const localTokenInterval = setInterval(() => {
      getLocalToken().then((res) => {
        if (res.accessToken !== "pass") {
          setMetricsTokenFromLocal(res.accessToken);
        }
      });
    }, METRICS_TOKEN_UPDATE_INTERVAL_MS);
    return () => {
      clearInterval(localTokenInterval);
    };
  }, []);

  React.useEffect(() => {
    mk8sDryRun.reset(true);

    PromptContext.setOnDiscard(() => mk8sDraft.reset());
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(mk8sDraft.isDirty);
  }, [mk8sDraft.isDirty]);

  useSetPromptShouldBlock((nextLocation, currentLocation) => {
    const allowedPaths = [
      //
      "-info",
      "-provider",
      "-addons",
      "-firewall",
      "-tags",
    ];

    let shouldBlock = true;
    for (const allowedPath of allowedPaths) {
      if (nextLocation.pathname.includes(allowedPath)) {
        shouldBlock = false;
        break;
      }
    }
    return shouldBlock;
  });

  useCleanPrompt();

  React.useEffect(() => {
    mk8sDryRun.run(mk8sDraft.asObject);
  }, [mk8sDraft.hash]);

  const [previousPathname, setPreviousPathname] = React.useState("");
  const [visitedPages, setVisitedPages] = React.useState<string[]>([]);
  React.useEffect(() => {
    if (!visitedPages.includes(previousPathname)) {
      setVisitedPages((_visitedPages) => [..._visitedPages, previousPathname]);
    }
    setPreviousPathname(location.pathname);
  }, [location.pathname]);
  function visited(pathSection: string) {
    return visitedPages.some((visitedPath) => visitedPath.includes(pathSection));
  }

  function reset() {
    mk8sDraft.reset();
  }

  const ngLinks: InnerNavLink[] = [
    { label: "Info", url: "-info", isDirty: mk8sDraft.description.isDirty },
    {
      label: mk8sDraft.provider.options.find((opt) => opt.value === mk8sDraft.provider.value)!.label,
      url: `-provider/${mk8sDraft.provider.value}`,
      visible: !!mk8sDraft.provider.value,
      disableFold: true,
      renderIcon:
        mk8sDraft.provider.value === "generic"
          ? !mk8sDraft.provider_generic.location.value &&
            (visited("/-provider/generic") || formData.get("generic.location").touched)
            ? () => (
                <AntTooltip title={"Location is required"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "aws"
          ? !mk8sDraft.provider_aws.isValid &&
            // TODO check touched any from the list of items
            visited("/-provider/aws")
            ? () => (
                <AntTooltip title={"AWS is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "ephemeral"
          ? !mk8sDraft.provider_ephemeral.isValid &&
            // TODO check touched any from the list of items
            visited("/-provider/ephemeral")
            ? () => (
                <AntTooltip title={"Ephemeral is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "hetzner"
          ? !mk8sDraft.provider_hetzner.isValid &&
            // TODO check touched any from the list of items
            visited("/-provider/hetzner")
            ? () => (
                <AntTooltip title={"Hetzner is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "oblivus"
          ? !mk8sDraft.provider_oblivus.isValid &&
            // TODO check touched any from the list of items
            visited("/-provider/oblivus")
            ? () => (
                <AntTooltip title={"Oblivus is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "lambdalabs"
          ? !mk8sDraft.provider_lambdalabs.isValid &&
            // TODO check touched any from the list of items
            visited("/-provider/lambdalabs")
            ? () => (
                <AntTooltip title={"Lambda Labs is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "linode"
          ? !mk8sDraft.provider_linode.isValid &&
            // TODO check touched any from the list of items
            visited("/-provider/linode")
            ? () => (
                <AntTooltip title={"Linode is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : undefined,
    },
  ];
  if (mk8sDraft.provider.value === "generic") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/generic/-node-pools",
      disableFold: true,
      renderIcon:
        mk8sDraft.provider_generic.nodePools.some((np) => !np.isValid) && visited("/-provider/generic/-node-pools")
          ? () => (
              <AntTooltip title={"Some node pools are not configured."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
      links: [
        ...mk8sDraft.provider_generic.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            // TODO get an error function here
            (!np.isValid || !mk8sDraft.provider_generic.isNodePoolNameValid(np.index)) &&
            (visited(`/-provider/generic/-node-pools/${npIndex}`) ||
              formData.get(`generic.nodePools[${npIndex}].name`).touched)
              ? () => (
                  <AntTooltip title={"Node Pool is not valid."}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/generic/-advanced",
      renderIcon:
        !mk8sDraft.provider_generic.networking.isValid && visited(`/-provider/generic/-advanced`)
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  if (mk8sDraft.provider.value === "aws") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/aws/-node-pools",
      disableFold: true,
      renderIcon:
        mk8sDraft.provider_aws.nodePools.some((np) => !np.isValid) && visited("/-provider/aws/-node-pools")
          ? () => (
              <AntTooltip title={"Some node pools are not configured."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
      links: [
        ...mk8sDraft.provider_aws.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            (!np.isValid || !mk8sDraft.provider_aws.isNodePoolNameValid(np.index)) &&
            (visited(`/-provider/aws/-node-pools/${npIndex}`) || formData.get(`aws.nodePools[${npIndex}].name`).touched)
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/aws/-advanced",
      renderIcon:
        (!mk8sDraft.provider_aws.networking.isValid || !mk8sDraft.provider_aws.autoscaler.isValid) &&
        visited(`/-provider/aws/-advanced`)
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }
  if (mk8sDraft.provider.value === "ephemeral") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/ephemeral/-node-pools",
      disableFold: true,
      renderIcon:
        mk8sDraft.provider_ephemeral.nodePools.some((np) => !np.isValid) && visited("/-provider/ephemeral/-node-pools")
          ? () => (
              <AntTooltip title={"Some node pools are not configured."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
      links: [
        ...mk8sDraft.provider_ephemeral.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            (!np.isValid || !mk8sDraft.provider_ephemeral.isNodePoolNameValid(np.index)) &&
            (visited(`/-provider/ephemeral/-node-pools/${npIndex}`) ||
              formData.get(`ephemeral.nodePools[${npIndex}].name`).touched)
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
  }

  if (mk8sDraft.provider.value === "hetzner") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/hetzner/-node-pools",
      disableFold: true,
      renderIcon:
        mk8sDraft.provider_hetzner.nodePools.some((np) => !np.isValid) && visited("/-provider/hetzner/-node-pools")
          ? () => (
              <AntTooltip title={"Some node pools are not configured."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
      links: [
        ...mk8sDraft.provider_hetzner.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            (!np.isValid || !mk8sDraft.provider_hetzner.isNodePoolNameValid(np.index)) &&
            visited(`/-provider/hetzner/-node-pools/${npIndex}`)
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Dedicated Server Node Pools",
      url: "-provider/hetzner/-dedicated-server-node-pools",
      disableFold: true,
      renderIcon:
        mk8sDraft.provider_hetzner.dedicatedServerNodePools.some((np) => !np.isValid) &&
        visited("/-provider/hetzner/-dedicated-server-node-pools")
          ? () => (
              <AntTooltip title={"Some dedicated server node pools are not configured."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
      links: [
        ...mk8sDraft.provider_hetzner.dedicatedServerNodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            (!np.isValid || !mk8sDraft.provider_hetzner.isDedicatedServerNodePoolNameValid(np.index)) &&
            visited(`/-provider/hetzner/-dedicated-server-node-pools/${npIndex}`)
              ? () => (
                  <AntTooltip title={"Dedicated Server Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/hetzner/-advanced",
      renderIcon:
        (!mk8sDraft.provider_hetzner.networking.isValid || !mk8sDraft.provider_hetzner.autoscaler.isValid) &&
        visited(`/-provider/hetzner/-advanced`)
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  if (mk8sDraft.provider.value === "lambdalabs") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/lambdalabs/-node-pools",
      disableFold: true,
      renderIcon:
        mk8sDraft.provider_lambdalabs.nodePools.some((np) => !np.isValid) &&
        visited("/-provider/lambdalabs/-node-pools")
          ? () => (
              <AntTooltip title={"Some node pools are not configured."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
      links: [
        ...mk8sDraft.provider_lambdalabs.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            (!np.isValid || !mk8sDraft.provider_lambdalabs.isNodePoolNameValid(np.index)) &&
            visited(`/-provider/lambdalabs/-node-pools/${npIndex}`)
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Unmanaged Node Pools",
      url: "-provider/lambdalabs/-unmanaged-node-pools",
      disableFold: true,
      renderIcon:
        mk8sDraft.provider_lambdalabs.unmanagedNodePools.some((np) => !np.isValid) &&
        visited("/-provider/lambdalabs/-unmanaged-node-pools")
          ? () => (
              <AntTooltip title={"Some unmanaged node pools are not configured."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
      links: [
        ...mk8sDraft.provider_lambdalabs.unmanagedNodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            (!np.isValid || !mk8sDraft.provider_lambdalabs.isUnmanagedNodePoolNameValid(np.index)) &&
            visited(`/-provider/lambdalabs/-unmanaged-node-pools/${npIndex}`)
              ? () => (
                  <AntTooltip title={"Unmanaged Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/lambdalabs/-advanced",
      renderIcon:
        !mk8sDraft.provider_lambdalabs.autoscaler.isValid && visited(`/-provider/lambdalabs/-advanced`)
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  if (mk8sDraft.provider.value === "oblivus") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/oblivus/-node-pools",
      disableFold: true,
      renderIcon:
        mk8sDraft.provider_oblivus.nodePools.some((np) => !np.isValid) && visited("/-provider/oblivus/-node-pools")
          ? () => (
              <AntTooltip title={"Some node pools are not configured."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
      links: [
        ...mk8sDraft.provider_oblivus.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            (!np.isValid || !mk8sDraft.provider_oblivus.isNodePoolNameValid(np.index)) &&
            visited(`/-provider/oblivus/-node-pools/${npIndex}`)
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Unmanaged Node Pools",
      url: "-provider/oblivus/-unmanaged-node-pools",
      disableFold: true,
      renderIcon:
        mk8sDraft.provider_oblivus.unmanagedNodePools.some((np) => !np.isValid) &&
        visited("/-provider/oblivus/-unmanaged-node-pools")
          ? () => (
              <AntTooltip title={"Some unmanaged node pools are not configured."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
      links: [
        ...mk8sDraft.provider_oblivus.unmanagedNodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            (!np.isValid || !mk8sDraft.provider_oblivus.isUnmanagedNodePoolNameValid(np.index)) &&
            visited(`/-provider/oblivus/-unmanaged-node-pools/${npIndex}`)
              ? () => (
                  <AntTooltip title={"Unmanaged Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/oblivus/-advanced",
      renderIcon:
        !mk8sDraft.provider_oblivus.autoscaler.isValid && visited(`/-provider/oblivus/-advanced`)
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  if (mk8sDraft.provider.value === "linode") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/linode/-node-pools",
      disableFold: true,
      renderIcon:
        mk8sDraft.provider_linode.nodePools.some((np) => !np.isValid) && visited("/-provider/linode/-node-pools")
          ? () => (
              <AntTooltip title={"Some node pools are not configured."}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
      links: [
        ...mk8sDraft.provider_linode.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            (!np.isValid || !mk8sDraft.provider_linode.isNodePoolNameValid(np.index)) &&
            visited(`/-provider/linode/-node-pools/${npIndex}`)
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/linode/-advanced",
      renderIcon:
        !mk8sDraft.provider_linode.autoscaler.isValid && visited(`/-provider/linode/-advanced`)
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  ngLinks.push({
    label: "Add-ons",
    url: "-addons",
    links: [
      mk8sDraft.addon_awsEcr.isEnabled
        ? {
            label: "AWS ECR",
            url: "-aws-ecr",
            renderIcon: !mk8sDraft.addon_awsEcr.isValid
              ? //  && (visited(`/-addons/-aws-ecr`) || formData.get(`awsEcr`).touched)
                () => (
                  <AntTooltip title={"Addon is not valid."}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.provider.value === "aws" && mk8sDraft.addon_awsEfs.isEnabled
        ? {
            label: "AWS EFS",
            url: "-aws-efs",
            renderIcon: !mk8sDraft.addon_awsEfs.isValid
              ? //  && (visited(`/-aws-efs`) || formData.get(`awsEfs`).touched)
                () => (
                  <AntTooltip title={"Addon is not valid."}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_awsElb.isEnabled
        ? {
            label: "AWS ELB",
            url: "-aws-elb",
            renderIcon: !mk8sDraft.addon_awsElb.isValid
              ? //  && (visited(`/-aws-elb`) || formData.get(`awsElb`).touched)
                () => (
                  <AntTooltip title={"Addon is not valid."}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_azureAcr.isEnabled
        ? {
            label: "Azure Container Registry",
            url: "-azure-acr",
            renderIcon: !mk8sDraft.addon_azureAcr.isValid
              ? // && (visited(`/-addons/-azure-acr`) || formData.get(`azureAcr`).touched)
                () => (
                  <AntTooltip title={"Addon is not valid."}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_azureWorkloadIdentity.isEnabled
        ? {
            label: "Azure Workload Identity",
            url: "-azure-workload-identity",
            renderIcon: !mk8sDraft.addon_azureWorkloadIdentity.isValid
              ? // && (visited(`/-addons/-azure-workload-identity`) || formData.get(`azureTenantId`).touched)
                () => (
                  <AntTooltip title={"Addon is not valid."}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_logs.isEnabled
        ? {
            label: "Logs",
            url: "-logs",
            renderIcon: !mk8sDraft.addon_logs.isValid
              ? // && (visited(`/-addons/-logs`) ||
                //   formData.get(`logs.retentionDays`).touched ||
                //   formData.get(`logs.includeNamespaces`).touched ||
                //   formData.get(`logs.excludeNamespaces`).touched)
                () => (
                  <AntTooltip title={"Addon is not valid."}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_metrics.isEnabled
        ? {
            label: "Metrics",
            url: "-metrics",
            renderIcon: !mk8sDraft.addon_metrics.isValid
              ? // TODO ability to check any item under metrics is touched
                // && (visited(`/-addons/-metrics`) ||
                //   formData.get(`metrics.retentionDays`).touched ||
                //   formData.get(`metrics.intervalSeconds`).touched ||
                //   formData.get(`metrics.includeNamespaces`).touched ||
                //   formData.get(`metrics.excludeNamespaces`).touched ||
                //   formData.get(`metrics.retainLabels`).touched)
                () => (
                  <AntTooltip title={"Addon is not valid."}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_nvidia.isEnabled
        ? {
            label: "NVIDIA",
            url: "-nvidia",
          }
        : { label: "filterOut", url: "filterOut" },
    ].filter((l) => l.label !== "filterOut"),
  });
  ngLinks.push({
    label: "Firewall",
    url: "-firewall",
    renderIcon: !mk8sDraft.firewall.isValid
      ? // && visited(`/-firewall`)
        () => (
          <AntTooltip title={"Firewall is not valid."}>
            <AlertCircle className="ml-1 h-4 color-danger" />
          </AntTooltip>
        )
      : undefined,
  });
  ngLinks.push({
    label: "Tags",
    url: "-tags",
    renderIcon: !mk8sDraft.tags.isValid
      ? // && visited(`/-tags`)
        () => (
          <AntTooltip title={"Tags are not valid."}>
            <AlertCircle className="ml-1 h-4 color-danger" />
          </AntTooltip>
        )
      : undefined,
  });
  ngLinks.push({ type: "separator", label: "", url: "" });
  ngLinks.push({ label: "kubeconfig", url: "-kubeconfig" });
  ngLinks.push({ label: "Logs", url: "-cluster-logs" });
  ngLinks.push({
    label: "Metrics",
    url: "-mk8s-metrics",
    type: "externalLink",
    externalLink: {
      action: `${Discovery.endpoints.grafana.replace("{org}", ConsoleContext.org!)}/auth/process`,
      inputs: [
        { name: "redirect", value: getMetricsUrl("mk8s", { clusterName: mk8s.name, clusterAlias: mk8s.alias }) },
        { name: "idToken", value: metricsTokenFromLocal },
      ],
      method: "POST",
    },
  });
  ngLinks.push({ label: "Events", url: "-events" });
  ngLinks.push({ label: "Audit Trail", url: `-audittrail` });
  ngLinks.push({ label: "Access Report", url: "-accessreport" });

  async function save() {
    try {
      setIsLoading(true);
      await mk8s.patch(mk8sDraft.asBodyObject);
      mk8sDraft.confirm();
      notification.success({
        message: "Success",
        description: "Updated Kubernetes",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        reset();
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <div style={{ maxWidth: pathname.includes("-cluster-logs") ? undefined : 1600 }}>
      <BasePathContext.Provider value={basePath}>
        <DetailLayout
          links={[]}
          ngLinks={ngLinks}
          useNewNav
          renderButtons={() => {
            if (pathname.includes("-tags")) {
              return <></>;
            }
            return (
              <FormButtons
                onSave={save}
                onReset={() => reset()}
                resetDisabled={isLoading || !mk8sDraft.isDirty}
                saveDisabled={isLoading || !mk8sDraft.isDirty || !mk8sDraft.isValid}
                loading={isLoading}
              />
            );
          }}
          hideButtons={["-kubeconfig", "-events", "-cluster-logs", "-audittrail", "accessreport"]}
        >
          <Route path={`-info`} element={<Info mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
          <Route path={`-provider/*`} element={<Provider mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
          <Route path={`-addons/*`} element={<AddonsRoute mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
          <Route path={`-firewall`} element={<Firewall mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
          <Route path={`-kubeconfig`} element={<KubeConfig mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
          <Route
            path={`-tags`}
            element={<TagsNew tagsMobx={mk8sDraft.tags} item={mk8s} kindPlural={"mk8s"} hideButtons />}
          />
          {/* <Route path={`-tags`} element={<Tags link={mk8s.selfLink} tags={mk8s.tags} hideButtonsForMk8s />} /> */}
          <Route path={`-cluster-logs`} element={<Mk8sLogs mk8s={mk8s} />} />
          <Route
            path={`-events`}
            element={
              <Events
                kind={"mk8s"}
                eventlogs={eventlogHook.eventlogs}
                isLoading={eventlogHook.isLoading}
                fetchEventlogs={eventlogHook.fetchEventlogs}
              />
            }
          />
          <Route path={`-audittrail`} element={<AuditTrail kind="mk8s" resourceId={mk8s.id} />} />
          <Route path={`-accessreport`} element={<AccessReport itemLink={mk8s.selfLink} />} />
        </DetailLayout>
      </BasePathContext.Provider>
      {IS_DEPLOYMENT_ENV_TEST && HiddenSectionsMobx.isRevealed("debug") ? (
        <div>
          <div>Invalid Reason</div>
          <div>{mk8sDraft.invalidReason || "none"}</div>
          <div>Dry run error</div>
          <div>Has error?: {String(mk8sDryRun.hasError)}</div>
          {mk8sDryRun.hasError ? <pre className="text-xs">{JSON.stringify(mk8sDryRun.response, null, 2)}</pre> : null}
        </div>
      ) : null}
    </div>
  );
};

export const Mk8sDetail = observer(Mk8sDetailRaw);
