import { types } from "mobx-state-tree";
import { TaintModel } from "./common";
import { AutoscalerModel } from "./autoscaler";

const PaperSpaceProviderNodePoolModel = types.model("PaperSpace Provider Node Pool", {
  name: types.string,
  labels: types.frozen(),
  taints: types.array(TaintModel),
  minSize: types.number,
  maxSize: types.number,
  machineType: types.string,
});

const PaperSpaceProviderUnmanagedNodePoolModel = types.model("PaperSpace Provider Unmanaged Node Pool", {
  name: types.string,
  labels: types.frozen(),
  taints: types.array(TaintModel),
});

export const PaperSpaceProviderModel = types.model("LambdaLabs Provider", {
  region: types.optional(types.string, ""), // us-tx-1
  tokenSecretLink: types.string,
  sharedDrives: types.array(types.string),
  nodePools: types.array(PaperSpaceProviderNodePoolModel),
  autoscaler: types.optional(AutoscalerModel, () => AutoscalerModel.create()),
  unmanagedNodePools: types.array(PaperSpaceProviderUnmanagedNodePoolModel),
});
