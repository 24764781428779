import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { DIRECT_LB_IPSET_ENABLED } from "../../../envVariables";
import { NGInputListMst } from "../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../newcomponents/input/input";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { inputTransforms } from "../../../mobxDataModels/transforms";
import { InfoTooltip } from "../../../components/InfoTooltip";

interface Props {
  workloadDraft: WorkloadDraftMobx;
}
const WorkloadCreateLoadBalancerRaw: React.FC<Props> = ({ workloadDraft }) => {
  const lb = workloadDraft.loadBalancer;
  const d = lb.direct;

  React.useEffect(() => {
    const lastItem = d.ports.items[d.ports.items.length - 1];
    if (!lastItem) {
      return;
    }
    if (!!lastItem.secondValue) {
      return;
    }
    lastItem.setSecondValue("TCP");
  }, [d.ports.items.length]);

  return (
    <>
      <div className="mb-4 flex items-center">
        <NGSwitch value={d.enabled} onChange={d.setEnabled}>
          <NGLabelText>Enable Direct Load Balancer</NGLabelText>
        </NGSwitch>
        <InfoTooltip
          title={[
            `Load Balancer charges will be accrued for each location.`,
            `The standard workload endpoints will route traffic to the Direct Workload Load Balancer.`,
            `Any required certificates must be provided by the workload at runtime.`,
          ]}
        />
      </div>
      {d.enabled ? (
        <>
          {DIRECT_LB_IPSET_ENABLED ? (
            <NGFormElement label={"Ip Set"} name={"ipSet"} value={d.ipSet} onChange={d.setIpSet} />
          ) : null}
          <NGInputListMst
            label="Ports"
            data={d.ports}
            firstInput={(i) => (
              <NGInput
                value={i.firstValue}
                // TODO improve transforms and validations
                onChange={(e) => i.setFirstValue(inputTransforms.number[0](e.target.value))}
                placeholder="External Port"
                required
                ignoreTouched
                invalid={
                  !i.firstValue ||
                  Number.isNaN(Number(i.firstValue)) ||
                  Number(i.firstValue) < 22 ||
                  Number(i.firstValue) > 32768
                }
              />
            )}
            secondInput={(i) => (
              <NGSelect
                value={i.secondValue}
                onChange={i.setSecondValue}
                placeholder="Protocol"
                options={[
                  { label: "TCP", value: "TCP" },
                  { label: "UDP", value: "UDP" },
                ]}
                ignoreTouched
              />
            )}
            thirdInput={(i) => (
              <NGInput
                value={i.thirdValue}
                onChange={(e) => i.setThirdValue(inputTransforms.number[0](e.target.value))}
                placeholder="Container Port"
                ignoreTouched
                invalid={
                  !!i.thirdValue &&
                  (Number.isNaN(Number(i.thirdValue)) ||
                    Number(i.thirdValue) < 80 ||
                    Number(i.thirdValue) > 65535 ||
                    [8012, 8022, 9090, 9091, 15000, 15001, 15006, 15020, 15021, 15090, 41000].includes(
                      Number(i.thirdValue),
                    ))
                }
              />
            )}
            invalid={!d.isPortsValid}
            error={d.portsError}
          />
        </>
      ) : null}
    </>
  );
};

export const WorkloadCreateLoadBalancer = observer(WorkloadCreateLoadBalancerRaw);
