import { observer } from "mobx-react-lite";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { mk8sMobx } from "../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { Addons } from "./addons";
import { AddonsAWSWorkloadIdentity } from "./awsWorkloadIdentity";
import { AddonsAzureWorkloadIdentity } from "./azureWorkloadIdentity";
import { AddonsDashboard } from "./dashboard";
import { AddonsInternalDeveloperPlatform } from "./internalDeveloperPlatform";
import { AddonsLocalPathStorage } from "./localPathStorage";
import { AddonsLogs } from "./logs";
import { AddonsMetrics } from "./metrics";
import { AddonsNvidia } from "./nvidia";
import { AddonsAWSEFS } from "./awsEfs";
import { AddonsAWSECR } from "./awsEcr";
import { AddonsAzureACR } from "./azureAcr";
import { AddonsAWSELB } from "./awsElb";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const AddonsRouteRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <Routes>
      <Route index element={<Addons mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-internal-developer-platform`}
        element={<AddonsInternalDeveloperPlatform mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-dashboard`} element={<AddonsDashboard mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route
        path={`-aws-workload-identity`}
        element={<AddonsAWSWorkloadIdentity mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route
        path={`-azure-workload-identity`}
        element={<AddonsAzureWorkloadIdentity mk8s={mk8s} mk8sDraft={mk8sDraft} />}
      />
      <Route path={`-local-path-storage`} element={<AddonsLocalPathStorage mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-metrics`} element={<AddonsMetrics mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-logs`} element={<AddonsLogs mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-nvidia`} element={<AddonsNvidia mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-aws-efs`} element={<AddonsAWSEFS mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-aws-ecr`} element={<AddonsAWSECR mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-aws-elb`} element={<AddonsAWSELB mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      <Route path={`-azure-acr`} element={<AddonsAzureACR mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
    </Routes>
  );
};

export const AddonsRoute = observer(AddonsRouteRaw);
