import * as React from "react";
import { observer } from "mobx-react-lite";
import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../services/cpln";
import { DryRunAlert } from "../../dryRunAlert";
import { MoreHorizontal, PlusCircle } from "react-feather";
import { NGKindSelect } from "../../../../newcomponents/select/ngkindselect";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGFormLabel } from "../../../../newcomponents/text/formLabel";
import NGAlert from "../../../../newcomponents/alert";
import { NGProviderCreateOpaqueSecret } from "../../ngProviderCreateOpaqueSecret";
import { NGProviderBrowser } from "../../ngProviderBrowser";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderLambdalabsRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const l = mk8sDraft.provider_lambdalabs!;

  const [browserKey, setBrowserKey] = React.useState("");

  return (
    <>
      <div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"lambdalabs.region"}
            label={l.region.label}
            value={l.region.value}
            onChange={l.region.setValue}
            required={l.region.isRequired}
            error={l.region.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("region"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={l.region.setValue}
              path={"spec.provider.lambdalabs.region"}
            />
          </div>
        </div>

        <div className="mb-4">
          <NGFormLabel required invalid={!l.tokenSecretName}>
            Lambda Labs Token Secret
          </NGFormLabel>
          <div className="mb-2 flex items-start gap-2">
            <NGKindSelect
              style={{ width: 450 }}
              value={l.tokenSecretName}
              invalid={!l.tokenSecretName}
              onChange={(value) => l.setTokenSecretName(value)}
              kind={"secret"}
              queries={[{ property: "type", value: "opaque" }]}
              buttons={[
                {
                  title: "Create",
                  render: () => <PlusCircle className="h-4" />,
                  onClick: () => setBrowserKey("createSecret"),
                },
              ]}
            />
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              path={"spec.provider.lambdalabs.tokenSecretLink"}
            />
          </div>
          <NGAlert
            style={{ width: 450 }}
            render={() => (
              <div className="flex flex-col text-sm">
                <span>An opaque secret that contains your Lambda Labs token.</span>
                <span>It will be used to manage servers in your Lambda Labs account.</span>
              </div>
            )}
          />
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"lambdalabs.sshKey"}
            label={l.sshKey.label}
            value={l.sshKey.value}
            onChange={l.sshKey.setValue}
            required={l.sshKey.isRequired}
            error={l.sshKey.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("ssh-keys"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={l.sshKey.setValue}
              path={"spec.provider.lambdalabs.sshKey"}
            />
          </div>
        </div>
      </div>

      {browserKey === "createSecret" ? (
        <NGProviderCreateOpaqueSecret
          onOk={(secretName: string) => l.setTokenSecretName(secretName)}
          onClose={() => setBrowserKey("")}
          title={"Create Lambda Labs Token Secret"}
          dataLabel={"Lambda Labs Token"}
        />
      ) : null}
      {browserKey === "region" ? (
        <NGProviderBrowser
          title="Browse Lambda Labs Regions"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "regions",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          onOk={(value) => l.region.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Description"]}
          getData={(item: any) => [item.name, item.name, item.description]}
        />
      ) : null}
      {browserKey === "ssh-keys" ? (
        <NGProviderBrowser
          key={l.tokenSecretName}
          title="Browse Lambda Labs SSH Keys"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ssh-keys",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          onOk={(value) => l.sshKey.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name"]}
          getData={(item: any) => [String(item.name), item.name]}
        />
      ) : null}
    </>
  );
};

export const ProviderLambdalabs = observer(ProviderLambdalabsRaw);
