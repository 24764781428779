import React, { useState } from "react";
import styles from "./style.module.scss";
import { Theme } from "../../../mobxStores/uiData/theme";
import { observer } from "mobx-react-lite";
import { Check, Copy } from "react-feather";
import { notification } from "antd";

interface Props {
  code: string;
  hideCopy?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const CodeSnippet: React.FC<Props> = observer(({ code, hideCopy = false, className, style }) => {
  const { theme } = Theme;
  const [isCopied, setIsCopied] = useState(false);

  React.useEffect(() => {
    let intervalId: any;
    if (isCopied) {
      intervalId = setInterval(() => {
        setIsCopied(false);
      }, 1500);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isCopied]);

  function handleCopyToClipboard() {
    setIsCopied(true);
    navigator.clipboard.writeText(code);
    notification.success({
      message: "Copied to clipboard",
    });
  }

  return (
    <div
      className={`relative mt-2 p-4 flex justify-between rounded
      ${theme === "dark" ? styles["dark-wrapper"] : styles["wrapper"]} ${className}`}
      style={style}
    >
      <div className="mr-8">{code}</div>
      {hideCopy ? null : (
        <div className="absolute top-2 right-2">
          <button onClick={handleCopyToClipboard} className={`p-2 focus rounded btn-blue ${styles["copy-button"]}`}>
            {!isCopied ? <Copy size={18} /> : <Check size={18} />}
          </button>
        </div>
      )}
    </div>
  );
});
