import moment from "moment-timezone";
import { RangePickerPresets } from "../../components/antd/RangePicker";

export type TimeStep = "hour" | "day" | "week" | "month";
export type PageView = "metric" | "cost";
export type GroupBy =
  | "account"
  | "org"
  | "gvc"
  | "location"
  | "metric"
  | "feature"
  // TODO update type to support any arbitrary value from features
  // rest is from chargeable items
  | "workloads"
  | "volume-sets"
  | "custom-ingress"
  | "observability";

export function GroupByToLabel(groupBy: GroupBy): string {
  switch (groupBy) {
    case "account":
      return "Account";
    case "org":
      return "Org";
    case "gvc":
      return "GVC";
    case "location":
      return "Location";
    case "metric":
      return "Metric";
    case "feature":
      return "Feature";
    case "workloads":
      return "Workload";
    case "volume-sets":
      return "Volume Set";
    case "custom-ingress":
      return "Custom Ingress";
    case "observability":
      return "Observability";
    default:
      return groupBy;
  }
}

export type NumberMap = { [_: string]: number };

export interface ChargeableItem {
  id: string;
  name: string;
  description: string;
  consumptionTags: { [_: string]: string };
  unit: string; // "cores * seconds"
}

export interface PeriodConsumption {
  total: number;
  projectedTotal?: number;
  value: number;
  projectedValue?: number;
  currency: "USD" | string;
  ratePlans: string[];
  tags: { [_: string]: string };
}

export interface PeriodGroup {
  key: { [_: string]: string };
  consumptions: PeriodConsumption[];
}

export interface Period {
  startTime: string;
  endTime: string;
  totalSeconds: number;
  elapsedSeconds?: number;
  consumptionCount: number;
  groups: PeriodGroup[];
}

export interface GraphData {
  periodTimes: string;
  totalMap: NumberMap;
  projectedTotalMap: NumberMap;
  valueMap: NumberMap;
  projectedValueMap: NumberMap;
}

export interface ChartUnit {
  metric?: string;
  dataKey: string;
  label: string;
  totalSum: number;
  projectedTotalSum: number;
}
export interface ChartData {
  units: ChartUnit[];
  graphData: GraphData[];
}

interface FormatXLabelProps {
  value: string;
  timeStep: TimeStep;
}

export function formatXLabel({ value, timeStep }: FormatXLabelProps): string {
  try {
    const startTime = moment(new Date(value.split(" ")[0]));
    const endTime = moment(new Date(value.split(" ")[1]));
    if (timeStep === "hour") {
      return startTime.toISOString().substring(11, 16);
    }
    if (timeStep === "day") {
      return startTime.format("MMM-DD");
    }
    if (timeStep === "week") {
      return `${startTime.format("MMM-DD")} - ${endTime.format("MMM-DD")}`;
    }
    if (timeStep === "month") {
      return startTime.format("MMM-YY").replace("-", " '");
    }
    return value;
  } catch (e) {
    return value;
  }
}

export function formatPrice(value: number) {
  return Number(value.toFixed(2));
}

export const featureLabelMap: { [_: string]: string } = {
  workloads: "Workloads",
  "custom-ingress": "Custom Ingress",
  "volume-sets": "Volume Sets",
  observability: "Observability",
};

export const chargeableItemLabelMap = {
  "ecbb3f5f-0b22-4c83-ba8e-1a36f899aeac": "Workloads - CPU",
  "6b611577-4bd8-44fa-9097-909a95242de1": "Workloads - Memory",
  "9f85f07d-e7cf-4931-a3c9-15cef006127a": "Workloads - Egress",
  "c9cf95aa-003f-4157-a214-5e5203f1fe0f": "Custom Ingress - Load Balancer",
  "3abd25b0-a886-43d8-808d-4e7499d4414f": "Volume Sets - Capacity - High Throughput SSD",
  "d7429d7a-e0dd-4334-a224-ade15c476f64": "Volume Sets - Capacity - General Purpose SSD",
  "8a049309-c074-4343-8e93-7e8dfde9d3cc": "Workloads - Replicas",
};

export type chargeableItemUnitProcessor = (
  value: number,
  elapsedSeconds: number,
  totalSeconds: number,
  isMiB: boolean,
) => number;

export type TChargeableItemUnitsAsMap = {
  cores: any; //
  bytes: any;
  seconds: any;
  "Load Balancers": any;
  GB: any;
  Replicas: any;
  Seconds: any;
}; //

export type TChargeableItemUnit =  //
  | "cores" //
  | "bytes"
  | "seconds"
  | "Load Balancers"
  | "GB"
  | "Replicas"
  | "Seconds";

export const chargeableItemUnits: TChargeableItemUnit[] = [
  //
  "cores", //
  "bytes",
  "seconds",
  "Load Balancers",
  "GB",
  "Replicas",
  "Seconds",
];

export function ChargeableItemMetricToLabel(metric: string): string {
  switch (metric) {
    case "cores * seconds":
      return "Core";
    case "bytes * seconds":
      return "GiB";
    case "bytes":
      return "GB";
    case "Load Balancers * Seconds":
      return "Load Balancer";
    case "GB * Seconds":
      return "GB";
    case "Replicas * Seconds":
      return "Replica";
    default:
      return metric;
  }
}

export type TChargeableItemUnitProcessorMap = {
  [Property in keyof TChargeableItemUnitsAsMap]: chargeableItemUnitProcessor[];
};

export const chargeableItemUnitProcessorMap: TChargeableItemUnitProcessorMap = {
  cores: [],
  bytes: [
    (value, e, t, isMiB) => {
      const gibDivider = 1_073_741_824;
      const mibDivider = gibDivider / 1_024;
      const divider = isMiB ? mibDivider : gibDivider;
      const result = value / divider;
      return result;
    },
  ],
  seconds: [
    (value, elapsedSeconds, totalSeconds, isMiB) => {
      const result = value / (elapsedSeconds || totalSeconds);
      return result;
    },
  ],
  "Load Balancers": [],
  GB: [
    (value, elapsedSeconds, totalSeconds, isMiB) => {
      let result = 0;
      if (!isMiB) {
        result = value;
      } else {
        result = value * 1_024;
      }
      return result;
    },
  ],
  Replicas: [],
  Seconds: [
    (value, elapsedSeconds, totalSeconds, isMiB) => {
      const result = value / (elapsedSeconds || totalSeconds);
      return result;
    },
  ],
};

export const getBody = (
  startTime: string,
  endTime: string,
  timeStep: TimeStep,
  groupBy: GroupBy,
  pageView: PageView,
  chargeableItems: ChargeableItem[],
  features: string[],
) => {
  const aggregateByMetric = groupBy !== "metric";

  if (features.includes(groupBy)) {
    groupBy = "name" as any;
  }

  return {
    detailed: false,
    startTime: startTime,
    endTime: endTime,
    aggregateByTimeStep: true,
    timeStep: timeStep,
    groupBy: pageView === "metric" ? ["metric", groupBy] : [groupBy],
    consumptionQueries: chargeableItems.map((_c) => {
      const c: ChargeableItem = JSON.parse(JSON.stringify(_c));
      const aggregateBy: string[] = [groupBy];
      if (aggregateByMetric) {
        aggregateBy.push("metric");
      }
      for (const consumptionTagKey of Object.keys(c.consumptionTags)) {
        if (consumptionTagKey === "metric") {
          continue;
        }
        aggregateBy.push(consumptionTagKey);
      }

      return {
        id: c.id,
        filterBy: c.consumptionTags,
        aggregateBy: aggregateBy,
      };
    }),
  };
};

export function getUsageHourPresets(): RangePickerPresets {
  const n = () => moment();

  return [
    {
      label: "Last 3 Hours",
      value: [n().add(1, "hour").startOf("hour").subtract(3, "hour"), n().add(1, "hour").startOf("hour")],
    },
    {
      label: "Last 6 Hours",
      value: [n().add(1, "hour").startOf("hour").subtract(6, "hour"), n().add(1, "hour").startOf("hour")],
    },
    {
      label: "Last 12 Hours",
      value: [n().add(1, "hour").startOf("hour").subtract(12, "hour"), n().add(1, "hour").startOf("hour")],
    },
    {
      label: "Last 24 Hours",
      value: [n().add(1, "hour").startOf("hour").subtract(24, "hour"), n().add(1, "hour").startOf("hour")],
    },
    {
      label: "Today",
      value: [n().startOf("day"), n().add(1, "day").startOf("day")],
    },
    {
      label: "Yesterday",
      value: [n().startOf("day").subtract(1, "day"), n().startOf("day")],
    },
  ];
}

export function getUsageDayPreset(): RangePickerPresets {
  const n = () => moment();

  return [
    { label: "Last 7 Days", value: [n().startOf("day").subtract(6, "day"), n().add(1, "day").startOf("day")] },
    { label: "Last 15 Days", value: [n().startOf("day").subtract(14, "day"), n().add(1, "day").startOf("day")] },
    { label: "Last 30 Days", value: [n().startOf("day").subtract(29, "day"), n().add(1, "day").startOf("day")] },
    { label: "This Month", value: [n().startOf("month"), n().add(1, "month").startOf("month")] },
    { label: "Last Month", value: [n().subtract(1, "month").startOf("month"), n().startOf("month")] },
  ];
}

export function getUsageWeekPreset(): RangePickerPresets {
  const n = () => moment();

  return [
    {
      label: "Last 6 Weeks",
      value: [n().startOf("week").subtract(5, "week").add(1, "day"), n().startOf("week").add(1, "week").add(1, "day")],
    },
    { label: "This Month", value: [n().startOf("month"), n().add(1, "month").startOf("month")] },
    { label: "Last Month", value: [n().subtract(1, "month").startOf("month"), n().startOf("month")] },
  ];
}

export function getUsageMonthPreset(): RangePickerPresets {
  const n = () => moment();

  return [
    {
      label: "Last 3 Months",
      value: [n().subtract(2, "month").startOf("month"), n().add(1, "month").startOf("month")],
    },
    {
      label: "Last 6 Months",
      value: [n().subtract(5, "month").startOf("month"), n().add(1, "month").startOf("month")],
    },
    {
      label: "Last 12 Months",
      value: [n().subtract(11, "month").startOf("month"), n().add(1, "month").startOf("month")],
    },
    {
      label: "Last 24 Months",
      value: [n().subtract(23, "month").startOf("month"), n().add(1, "month").startOf("month")],
    },
    { label: "This Year", value: [n().startOf("year"), n().add(1, "year").startOf("year")] },
    { label: "Last Year", value: [n().subtract(1, "year").startOf("year"), n().startOf("year")] },
  ];
}
