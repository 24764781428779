import * as React from "react";

export const VolumesTableLabel: React.FC = () => {
  return (
    <div className="px-4 py-2 flex items-center w-full table-labels">
      <div className="w-4/12">Index</div>
      <div className="w-2/12">Lifecycle</div>
      <div className="w-2/12">Bound to Replica</div>
      <div className="w-3/12">Volume Size</div>
      <div className="w-1/12"></div>
    </div>
  );
};
