import { observer } from "mobx-react-lite";
import * as React from "react";
import { EnvVarsMobx } from "../../../mobxDataModels/envVarsModel";
import { NGInput } from "../../../newcomponents/input/input";
import { InputGroup } from "../../../newcomponents/inputGroup/inputGroup";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";
import clsx from "clsx";
import { NGCombobox } from "../../../newcomponents/select/ngcombobox";
import { NGButton } from "../../../newcomponents/button/Button";
import { MinusCircle } from "react-feather";
import NGAlert from "../../../newcomponents/alert";
import { EnvVarInherit } from "./environmentVariables";
import { notification } from "antd";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { linksOf, request } from "../../../services/cpln";
import { useDebounce } from "../../../components/table/useDebounce";
import { EnvVarMobx } from "../../../mobxDataModels/envVarModel";
import { envCplnReferenceOptions } from "../../../mst/kinds/workload";
import { Theme } from "../../../mobxStores/uiData/theme";
import { Secret } from "../../../schema/types/secret";

interface Props {
  env: EnvVarsMobx;
  envVar: EnvVarMobx;
  inherit: EnvVarInherit;
}
const EnvironmentVariableRaw: React.FC<Props> = ({ env, envVar, inherit }) => {
  const [debouncedValue, setDebouncedValue] = useDebounce(envVar.value, 400);
  const [secret, setSecret] = React.useState<Secret | undefined>();
  const [suggestedProperties, setSuggestedProperties] = React.useState(false);
  const [flattenedDictionary, setFlattenedDictionary] = React.useState(false);

  React.useEffect(() => {
    setMetadata(envVar, secret);
    setSuggestedProperties(false);
    setFlattenedDictionary(false);
  }, [debouncedValue]);

  async function setMetadata(envVar: EnvVarMobx, secret?: Secret, reveal?: boolean) {
    if (!secret) {
      envVar.setMetadata({});
      return;
    }

    const secretTypeActionMap = {
      opaque: { shouldReveal: false, supportsProperties: false, properties: [] },
      tls: { shouldReveal: false, supportsProperties: true, properties: ["key", "cert", "chain"] },
      gcp: { shouldReveal: false, supportsProperties: false, properties: [] },
      aws: {
        shouldReveal: false,
        supportsProperties: true,
        properties: ["accessKey", "secretKey", "roleArn", "externalId"],
      },
      ecr: {
        shouldReveal: false,
        supportsProperties: true,
        properties: ["accessKey", "secretKey", "roleArn", "externalId"],
      },
      userpass: { shouldReveal: false, supportsProperties: true, properties: ["username", "password"] },
      keypair: { shouldReveal: false, supportsProperties: true, properties: ["secretKey", "publicKey", "passphrase"] },
      "azure-sdk": { shouldReveal: false, supportsProperties: false, properties: [] },
      "azure-connector": { shouldReveal: false, supportsProperties: true, properties: ["url", "code"] },
      docker: { shouldReveal: false, supportsProperties: false, properties: [] },
      dictionary: { shouldReveal: true, supportsProperties: true, properties: [] },
      "nats-account": { shouldReveal: false, supportsProperties: true, properties: ["accountId", "privateKey"] },
    };

    const actionItem = secretTypeActionMap[secret.type];
    if (!actionItem.shouldReveal) {
      envVar.setMetadata({
        ...secret,
        supportsProperties: actionItem.supportsProperties,
        properties: actionItem.properties,
      });
      return;
    }

    if (!reveal) {
      envVar.setMetadata({ ...secret, supportsProperties: actionItem.supportsProperties });
      return;
    }

    try {
      const revealLink = linksOf(secret).reveal!;
      const { data: revealedSecret } = await request({ url: revealLink });
      if (typeof revealedSecret.data === "object") {
        let props: string[] = Object.keys(revealedSecret.data);
        envVar.setMetadata({ ...secret, supportsProperties: actionItem.supportsProperties, properties: props });
      } else {
        envVar.setMetadata({ supportsProperties: actionItem.supportsProperties });
      }
      setSuggestedProperties(true);
    } catch (e) {
      envVar.setMetadata({ supportsProperties: actionItem.supportsProperties });
    }
  }

  async function flattenDictionary() {
    try {
      const secretName = envVar.metadata.name;
      const secretLink = `/org/${ConsoleContext.org}/secret/${secretName}`;
      const { data: revealedSecretItem } = await request({ url: secretLink + "/-reveal" });
      for (const key of Object.keys(revealedSecretItem.data)) {
        const index = env.add();
        const varItem = env.editVars.find((v) => v.index === index);
        varItem!.setName(`${secretName}.${key}`);
        varItem!.setValuePrefix(`cpln://secret/`);
        varItem!.setValueBody(secretName);
        varItem!.setValueSuffix(key);
      }
      setFlattenedDictionary(true);
    } catch (e) {
      notification.warning({
        message: "Failed to flatten dictionary to environment variables",
        description: e.message,
      });
    }
  }

  return (
    <React.Fragment>
      <div className="flex gap-2">
        <NGInput
          style={{ width: "calc((100% - 90px) / 3)" }}
          value={envVar.name}
          onChange={(e) => envVar.setName(e.target.value)}
        />
        <InputGroup style={{ width: "calc((100% - 90px) / 3 * 2)" }} key={envVar.valuePrefix} colSpans={[3, 6, 3]}>
          <NGSelect
            value={envVar.valuePrefix}
            onChange={envVar.setValuePrefix}
            renderOption={({ getOptionValue, getOptionLabel, option, isHovered, isSelected, props }) => {
              return (
                <li
                  key={getOptionValue(option)}
                  {...props}
                  className={clsx(`flex flex-col`, {
                    "option-hover": isHovered,
                    "option-selected": isSelected,
                  })}
                >
                  <span>{getOptionLabel(option)}</span>
                  {getOptionValue(option) !== "text" ? (
                    <span
                      className="text-sm"
                      style={{
                        display: "inline-block",
                        padding: "1px 4px",
                        borderRadius: 2,
                        color: Theme.theme === "dark" ? `var(--color-gray-50)` : `var(--color-gray-600)`,
                        backgroundColor: Theme.theme === "dark" ? `var(--color-gray-600)` : `var(--color-gray-50)`,
                      }}
                    >
                      {getOptionValue(option)}
                    </span>
                  ) : null}
                </li>
              );
            }}
            options={[
              { label: "Literal Value", value: "text" },
              { label: "Secret", value: "cpln://secret/" },
              { label: "Built-in", value: "cpln://reference/" },
            ]}
          />
          {envVar.valuePrefix === "cpln://secret/" ? (
            <NGKindSelect
              value={envVar.valueBody}
              onChange={(value, item) => {
                envVar.setValueBody(value);
                setDebouncedValue(value);
                setSecret(item);
              }}
              kind="secret"
              placeholder={"Secret"}
              renderOption={({ getOptionValue, getOptionLabel, props, option, isHovered, isSelected }) => (
                <li
                  key={getOptionValue(option)}
                  {...props}
                  className={clsx(`flex items-center justify-between`, {
                    "option-hover": isHovered,
                    "option-selected": isSelected,
                  })}
                >
                  <span className="truncate">{getOptionLabel(option)}</span>
                  <span className="truncate">{(option as any).type}</span>
                </li>
              )}
            />
          ) : envVar.valuePrefix === "cpln://reference/" ? (
            <NGSelect
              value={envVar.valueBody}
              onChange={envVar.setValueBody}
              options={envCplnReferenceOptions.map((v: string) => ({ label: v, value: v }))}
              placeholder="Built-in Reference"
            />
          ) : (
            <NGInput
              placeholder={"Value"}
              value={envVar.valueBody}
              onChange={(e) => envVar.setValueBody(e.target.value)}
            />
          )}
          {/* && envVar.metadata.supportsProperties */}
          {/* Not using this because it requires to fetch secrets initially to see their types */}
          {envVar.valuePrefix === "cpln://secret/" ? (
            <NGCombobox
              value={envVar.valueSuffix}
              onChange={envVar.setValueSuffix}
              options={(envVar.metadata.properties || []).map((v: string) => ({ label: v, value: v }))}
              placeholder="Property"
              showNoItems={false}
            />
          ) : null}
        </InputGroup>
        <NGButton
          onClick={() => env.remove(envVar.index)}
          variant="danger"
          text
          renderIcon={(_, props) => <MinusCircle {...props} />}
        />
      </div>
      {envVar.nameConflicts ? (
        <NGAlert message={`Variable name "${envVar.name}" is already being used.`} />
      ) : envVar.valuePrefix.includes("secret") &&
        (envVar.valueBody.includes(" ") || envVar.valueSuffix.includes(" ")) ? (
        <NGAlert message={`Variable value cannot have empty character when referencing a secret.`} />
      ) : null}
      {envVar.metadata?.type === "dictionary" && !suggestedProperties ? (
        <div className="flex items-center justify-end gap-2">
          <NGButton
            onClick={() => setMetadata(envVar, secret, true)}
            variant={"secondary"}
            size="small"
            style={{ width: 250 }}
          >
            Reveal & Suggest Properties
          </NGButton>
          <span style={{ width: 74 }} />
        </div>
      ) : null}
      {envVar.metadata?.type === "dictionary" && !flattenedDictionary ? (
        <div className="flex items-center justify-end gap-2">
          <NGButton onClick={() => flattenDictionary()} variant={"secondary"} size="small" style={{ width: 250 }}>
            Reveal & Flatten Dictionary
          </NGButton>
          <span style={{ width: 74 }} />
        </div>
      ) : null}
      {inherit.isActive && inherit.value && inherit.env.map((e) => e.name).includes(envVar.name) ? (
        <div className="mb-2 flex items-center">
          <NGAlert
            renderIcon={() => null as any}
            render={() => {
              return (
                <div className="text-sm">
                  <div>Overrides Inherited Env Variable</div>
                </div>
              );
            }}
            size={"small"}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export const EnvironmentVariable = observer(EnvironmentVariableRaw);
