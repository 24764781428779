import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button as AntButton } from "antd";
import { Mk8sDraftMobx } from "./../../../../../mst/stores/mk8s.draft";
import { NodePoolLabel } from "./../../../nodePoolLabel";
import { NodePoolTaint } from "./../../../nodePoolTaint";
import { Link, useNavigate } from "react-router-dom";
import { NGLabel } from "../../../../../newcomponents/text/label";
import { NGButton } from "../../../../../newcomponents/button/Button";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderHetznerNodePoolsRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const navigate = useNavigate();
  const h = mk8sDraft.provider_hetzner!;

  return (
    <>
      <NGLabel>Node Pools</NGLabel>
      {h.nodePools.length > 0 ? (
        <div
          className="flex items-center table-labels px-2 py-1"
          style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
        >
          <div className="w-2/12">Name</div>
          <div className="w-2/12">Labels</div>
          <div className="w-2/12">Taints</div>
          <div className="w-2/12">Server Type</div>
          <div className="w-2/12">Min/Max Size</div>
          <div className="w-2/12"></div>
        </div>
      ) : (
        <div
          className="border py-8 flex flex-col gap-4 mt-4 items-center justify-center"
          style={{ borderRadius: 6, width: 450 }}
        >
          <div className="text-lg">No node pools are added.</div>
          <NGButton variant={"action"} onClick={() => navigate("-add")}>
            Add a Node Pool
          </NGButton>
        </div>
      )}
      {h.nodePools.length > 0 ? (
        <div className="border border-t-0" style={{ borderBottomRightRadius: 6, borderBottomLeftRadius: 6 }}>
          {h.nodePools.map((np, npIndex) => {
            const lastNodePool = npIndex === h.nodePools.length - 1;

            return (
              <>
                <div key={np.index} className={`flex p-2 ${lastNodePool ? "" : "border-b"}`}>
                  <div className="w-2/12">
                    <Link to={`${np.index}`}>{np.name.value}</Link>
                  </div>
                  <div className="w-2/12">
                    {np.labels.items.map((l) => (
                      <NodePoolLabel label={{ key: l.firstValue, value: l.secondValue }} />
                    ))}
                  </div>
                  <div className="w-2/12">
                    {np.taints.items.map((l) => (
                      <NodePoolTaint taint={{ key: l.firstValue, value: l.secondValue, effect: l.thirdValue }} />
                    ))}
                  </div>
                  <div className="w-2/12">{np.serverType.value}</div>
                  <div className="w-2/12">
                    {np.minSize.value} - {np.maxSize.value}
                  </div>
                  <div className="w-2/12 flex justify-end gap-2">
                    <AntButton size={"small"} type={"primary"} danger onClick={() => h.removeNodePoolAt(np.index)}>
                      Remove
                    </AntButton>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export const Mk8sCreateProviderHetznerNodePools = observer(Mk8sCreateProviderHetznerNodePoolsRaw);
