import * as React from "react";
import { WorkloadNavLink } from "./createNavLink";
import { WorkloadNavSubLink } from "./createNavSubLink";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { PlusCircle } from "react-feather";
import { BasePathContext } from "../../../reactContexts/basePathContext";
import { DIRECT_LB_ENABLED } from "../../../envVariables";

interface LinksRenderProps {
  workloadDraft: WorkloadDraftMobx;
  addContainer: () => void;
  removeContainer: () => void;
  enableAuthFilter: boolean;
  isAuthValid: boolean;
}
const LinksRenderRaw: React.FC<LinksRenderProps> = ({
  workloadDraft,
  addContainer,
  removeContainer,
  enableAuthFilter,
  isAuthValid,
}) => {
  const basePath = React.useContext(BasePathContext);

  const workloadNameIsValid = workloadDraft.name.isValid;
  const container1IsValid = workloadDraft.container1.isValid;
  const container2IsValid = workloadDraft.container2?.isValid || false;
  const container3IsValid = workloadDraft.container3?.isValid || false;
  const container4IsValid = workloadDraft.container4?.isValid || false;
  const container5IsValid = workloadDraft.container5?.isValid || false;
  const container6IsValid = workloadDraft.container6?.isValid || false;
  const container7IsValid = workloadDraft.container7?.isValid || false;
  const container8IsValid = workloadDraft.container8?.isValid || false;

  function isIdentityActive() {
    if (!workloadNameIsValid) return false;
    if (!container1IsValid) return false;
    if (workloadDraft.container2) {
      if (!container2IsValid) return false;
    }
    if (workloadDraft.container3) {
      if (!container3IsValid) return false;
    }
    if (workloadDraft.container4) {
      if (!container4IsValid) return false;
    }
    if (workloadDraft.container5) {
      if (!container5IsValid) return false;
    }
    if (workloadDraft.container6) {
      if (!container6IsValid) return false;
    }
    if (workloadDraft.container7) {
      if (!container7IsValid) return false;
    }
    if (workloadDraft.container8) {
      if (!container8IsValid) return false;
    }
    return true;
  }

  function isOptionsActive() {
    return isIdentityActive();
  }

  function isRolloutActive() {
    if (!isOptionsActive()) return false;
    if (!workloadDraft.defaultOptions.isValid) return false;
    return true;
  }

  function isSecurityActive() {
    if (!isRolloutActive()) return false;
    if (!workloadDraft.rolloutOptions.isValid) return false;
    return true;
  }

  function isFirewallActive() {
    if (!isOptionsActive()) return false;
    if (!workloadDraft.defaultOptions.isValid) return false;
    if (!workloadDraft.isLocalOptionsValid) return false;
    if (!workloadDraft.rolloutOptions.isValid) return false;
    if (!workloadDraft.securityOptions.isValid) return false;
    return true;
  }

  function isLoadBalancerActive() {
    if (!isFirewallActive()) return false;
    if (!workloadDraft.defaultOptions.isValid) return false;
    if (!workloadDraft.isLocalOptionsValid) return false;
    if (!workloadDraft.rolloutOptions.isValid) return false;
    if (!workloadDraft.securityOptions.isValid) return false;
    //
    if (!workloadDraft.firewall.isValid) return false;
    return true;
  }

  function isAuthActive() {
    if (!isLoadBalancerActive()) return false;
    if (!workloadDraft.defaultOptions.isValid) return false;
    if (!workloadDraft.isLocalOptionsValid) return false;
    if (!workloadDraft.rolloutOptions.isValid) return false;
    if (!workloadDraft.securityOptions.isValid) return false;
    //
    if (!workloadDraft.firewall.isValid) return false;
    if (!workloadDraft.loadBalancer.isValid) return false;
    return true;
  }

  function isJobActive() {
    if (!workloadDraft.isCron) {
      return true;
    }
    if (!isOptionsActive()) return false;
    if (!isLoadBalancerActive()) return false;
    if (enableAuthFilter && !isAuthValid) return false;
    return true;
  }

  function isTagsActive() {
    if (!isJobActive()) return false;
    if (workloadDraft.type.value === "cron" && !workloadDraft.job.isValid) {
      return false;
    }
    if (!isLoadBalancerActive()) return false;
    if (enableAuthFilter && !isAuthValid) return false;
    return true;
  }

  function isAddContainerActive() {
    if (!workloadNameIsValid) return false;
    if (!container1IsValid) return false;
    if (workloadDraft.container2) {
      if (!container2IsValid) return false;
    }
    if (workloadDraft.container3) {
      if (!container3IsValid) return false;
    }
    if (workloadDraft.container4) {
      if (!container4IsValid) return false;
    }
    if (workloadDraft.container5) {
      if (!container5IsValid) return false;
    }
    if (workloadDraft.container6) {
      if (!container6IsValid) return false;
    }
    if (workloadDraft.container7) {
      if (!container7IsValid) return false;
    }
    if (workloadDraft.container8) {
      if (!container8IsValid) return false;
    }
    return true;
  }

  const showRollout = workloadDraft.isStateful || workloadDraft.isStandard;
  const showCron = workloadDraft.isCron;

  let linkCounterPart1 = 0; // for first part
  let linkCounterPart2 = 0; // for after load balancer
  let linkCounterPart3 = 0; // for tags

  if (showRollout) {
    linkCounterPart1 += 1;
    linkCounterPart2 += 1;
    linkCounterPart3 += 1;
  }
  if (DIRECT_LB_ENABLED) {
    linkCounterPart2 += 1;
    linkCounterPart3 += 1;
  }
  if (showCron) {
    linkCounterPart3 += 1;
  }

  return (
    <>
      <WorkloadNavLink isActive={true} number={1} title={"General"} url={`${basePath}/-general`} />
      <WorkloadNavLink isActive={workloadNameIsValid} number={2} title={"Containers"} url={`${basePath}/-containers`} />

      <WorkloadNavSubLink
        isActive={workloadNameIsValid}
        onRemove={() => {}}
        showRemove={false}
        url={`${basePath}/-containers/1`}
        title={workloadDraft.container1.name.value || "Container 1"}
      />
      {workloadDraft.container2 ? (
        <WorkloadNavSubLink
          isActive={workloadNameIsValid && workloadDraft.container1.isValid}
          onRemove={removeContainer}
          showRemove={!workloadDraft.container3}
          url={`${basePath}/-containers/2`}
          title={workloadDraft.container2.name.value || "Container 2"}
        />
      ) : null}
      {workloadDraft.container3 ? (
        <WorkloadNavSubLink
          isActive={workloadNameIsValid && container1IsValid && container2IsValid}
          onRemove={removeContainer}
          showRemove={!workloadDraft.container4}
          url={`${basePath}/-containers/3`}
          title={workloadDraft.container3.name.value || "Container 3"}
        />
      ) : null}
      {workloadDraft.container4 ? (
        <WorkloadNavSubLink
          isActive={workloadNameIsValid && container1IsValid && container2IsValid && container3IsValid}
          onRemove={removeContainer}
          showRemove={!workloadDraft.container5}
          url={`${basePath}/-containers/4`}
          title={workloadDraft.container4.name.value || "Container 4"}
        />
      ) : null}
      {workloadDraft.container5 ? (
        <WorkloadNavSubLink
          isActive={
            workloadNameIsValid && container1IsValid && container2IsValid && container3IsValid && container4IsValid
          }
          onRemove={removeContainer}
          showRemove={!workloadDraft.container6}
          url={`${basePath}/-containers/5`}
          title={workloadDraft.container5.name.value || "Container 5"}
        />
      ) : null}
      {workloadDraft.container6 ? (
        <WorkloadNavSubLink
          isActive={
            workloadNameIsValid &&
            container1IsValid &&
            container2IsValid &&
            container3IsValid &&
            container4IsValid &&
            container5IsValid
          }
          onRemove={removeContainer}
          showRemove={!workloadDraft.container7}
          url={`${basePath}/-containers/6`}
          title={workloadDraft.container6.name.value || "Container 6"}
        />
      ) : null}
      {workloadDraft.container7 ? (
        <WorkloadNavSubLink
          isActive={
            workloadNameIsValid &&
            container1IsValid &&
            container2IsValid &&
            container3IsValid &&
            container4IsValid &&
            container5IsValid &&
            container6IsValid
          }
          onRemove={removeContainer}
          showRemove={!workloadDraft.container8}
          url={`${basePath}/-containers/7`}
          title={workloadDraft.container7.name.value || "Container 7"}
        />
      ) : null}
      {workloadDraft.container8 ? (
        <WorkloadNavSubLink
          isActive={
            workloadNameIsValid &&
            container1IsValid &&
            container2IsValid &&
            container3IsValid &&
            container4IsValid &&
            container5IsValid &&
            container6IsValid &&
            container7IsValid
          }
          onRemove={removeContainer}
          showRemove={true}
          url={`${basePath}/-containers/8`}
          title={workloadDraft.container8.name.value || "Container 8"}
        />
      ) : null}

      {!workloadDraft.container8 ? (
        <button
          data-testid="add-container"
          className={`block focus ${isAddContainerActive() ? "color-action" : "cursor-not-allowed"}`}
          onClick={isAddContainerActive() ? () => addContainer() : () => {}}
          disabled={!isAddContainerActive()}
          style={{
            opacity: 0.7,
            paddingLeft: 43,
            marginTop: 10,
          }}
        >
          Add <PlusCircle className="feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
        </button>
      ) : null}

      <WorkloadNavLink title={"Identity"} url={`${basePath}/-identity`} isActive={isIdentityActive()} number={3} />
      <WorkloadNavLink title={"Options"} url={`${basePath}/-options`} isActive={isOptionsActive()} number={4} />
      {showRollout ? (
        <WorkloadNavLink
          title={"Rollout Options"}
          url={`${basePath}/-rollout`}
          isActive={isRolloutActive()}
          number={5}
        />
      ) : null}
      <WorkloadNavLink
        title={"Security"}
        url={`${basePath}/-security`}
        isActive={isSecurityActive()}
        number={5 + linkCounterPart1}
      />
      <WorkloadNavLink
        title={"Firewall"}
        url={`${basePath}/-firewall`}
        isActive={isFirewallActive()}
        number={6 + linkCounterPart1}
      />
      {DIRECT_LB_ENABLED ? (
        <WorkloadNavLink
          title={"Load Balancer"}
          url={`${basePath}/-loadbalancer`}
          isActive={isLoadBalancerActive()}
          number={7 + linkCounterPart1}
        />
      ) : null}
      <WorkloadNavLink
        title={"Auth"}
        url={`${basePath}/-auth`}
        isActive={isAuthActive()}
        number={7 + linkCounterPart2}
      />
      {showCron ? (
        <WorkloadNavLink
          title={"Job"}
          url={`${basePath}/-job`}
          isActive={isJobActive()}
          number={8 + linkCounterPart2}
        />
      ) : null}
      <WorkloadNavLink
        title={"Tags"}
        url={`${basePath}/-tags`}
        isActive={isTagsActive()}
        number={8 + linkCounterPart3}
      />
    </>
  );
};

export const LinksRender = observer(LinksRenderRaw);
