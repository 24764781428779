import * as React from "react";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
import { updateLastDeploymentTimeOnHubspot } from "../../services/utils";
import { WorkloadDraftMobx } from "../../mst/stores/workload.draft";
import { useDetailContext } from "../../components/detail/detailContext";
import { WorkloadDraftSecurityOptionsMobx } from "../../mst/stores/workload.draft.securityOptions";
import { NGSwitch } from "../../newcomponents/switch";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGLabelText } from "../../newcomponents/text/labelText";

interface Props {
  draft: WorkloadDraftMobx;
  securityOptions: WorkloadDraftSecurityOptionsMobx;
  patch: (body: any) => Promise<void>;
}
const SecurityOptionsRaw: React.FC<Props> = ({ draft, securityOptions: options, patch }) => {
  const { fetchItem } = useDetailContext();

  const [isLoading, setIsLoading] = React.useState(false);
  const [promptWhen, setPromptWhen] = React.useState(false);

  React.useEffect(() => {
    options.reset();
    return () => {
      options.reset();
    };
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(promptWhen);
  }, [promptWhen]);

  useCleanPrompt();

  React.useEffect(() => {
    setPromptWhen(options.isDirty);
  }, [options.isDirty]);

  async function save() {
    try {
      setIsLoading(true);
      await patch(draft.asPatch);
      options.confirm();
      notification.success({
        message: "Success",
        description: "Workload is updated",
      });
      updateLastDeploymentTimeOnHubspot();
      setPromptWhen(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
      setPromptWhen(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div>
        <div className="mb-4 flex items-center">
          <NGSwitch value={draft.securityOptions.configure} onChange={draft.securityOptions.setConfigure}>
            <NGLabelText>Configure Security Options</NGLabelText>
          </NGSwitch>
        </div>
        {draft.securityOptions.configure ? (
          <NGInputAdapter
            style={{ width: 450 }}
            data={options.filesystemGroupId}
            infoContents={["The group id assigned to any mounted volume."]}
          />
        ) : null}
        <div className="mt-8 flex items-center">
          <NGButton
            disabled={!options.isDirty || isLoading}
            style={{ width: 215, marginRight: 10 }}
            onClick={options.reset}
            variant={"danger"}
            outlined
          >
            Reset
          </NGButton>
          <NGButton
            disabled={!options.isDirty || !options.isValid || isLoading}
            loading={isLoading}
            style={{ width: 215, marginLeft: 10 }}
            onClick={save}
            variant={"primary"}
          >
            Save
          </NGButton>
        </div>
      </div>
    </>
  );
};

export const SecurityOptions = observer(SecurityOptionsRaw);
