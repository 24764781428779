import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftContainerMobx } from "../../../mst/stores/workload.draft.container";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGTextArea } from "../../../newcomponents/textarea";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { NGInputListMst } from "../../../newcomponents/inputList/inputListMst";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";

interface Props {
  containerDraft: WorkloadDraftContainerMobx;
}
const CommandRaw: React.FC<Props> = ({ containerDraft }) => {
  return (
    <div>
      <div className="flex items-center mb-2">
        <NGSwitch
          data-testid={`switch-override-workingdir`}
          value={containerDraft.overrideWorkingDir}
          onChange={(value) => containerDraft.setOverrideWorkingDir(value)}
        >
          <NGLabelText>Override Working Directory</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Optionally override the working directory.", "Must be an absolute path."]} />
      </div>
      {containerDraft.overrideWorkingDir ? (
        <NGFormElement
          name={"workingDir"}
          label={containerDraft.workingDir.label}
          value={containerDraft.workingDir.value}
          onChange={containerDraft.workingDir.setValue}
        />
      ) : null}
      <div className="flex items-center mb-2">
        <NGSwitch
          data-testid={`switch-override-command`}
          value={containerDraft.overrideCommand}
          onChange={(value) => containerDraft.setOverrideCommand(value)}
        >
          <NGLabelText>Overide Command</NGLabelText>
        </NGSwitch>
        <InfoTooltip title="Optionally override the entrypoint." />
      </div>
      {containerDraft.overrideCommand ? (
        <>
          <NGLabel>Command</NGLabel>
          <NGTextArea
            className="mb-4"
            style={{ width: 450 }}
            value={containerDraft.command}
            onChange={(e) => containerDraft.setCommand(e.target.value)}
          />
        </>
      ) : null}

      {/* <ReachTooltip
          contents={[
            "Command line arguments passed to the container at runtime.",
            "Replaces the CMD arguments of the running container. ",
            "It is an ordered list.",
          ]}
          info
        /> */}
      <NGInputListMst
        label="Arg List"
        data={containerDraft.args}
        draggable
        styles={{ container: { width: 450 } }}
        firstInput={(item) => (
          <NGTextArea
            value={item.firstValue}
            placeholder="Multiline Input"
            onChange={(e) => item.setFirstValue(e.target.value)}
            invalid={!item.firstValue}
            ignoreTouched
          />
        )}
      />
    </div>
  );
};

export const Command = observer(CommandRaw);
