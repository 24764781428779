import { types, Instance } from "mobx-state-tree";

import { StringModel } from "../../mobxDataModels/stringModel";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { BooleanModel } from "../../mobxDataModels/booleanModel";
import { hasDuplicatedValue } from "../../services/utils";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

export function validateProviderName(name: string | undefined) {
  if (!name) return "";

  let restrictedName: string;
  if (name?.startsWith("cpln_")) {
    restrictedName = name.substring("cpln_".length);
  } else {
    restrictedName = `cpln_${name}`;
  }

  return restrictedName;
}

function extractDomain(url: string): string | null {
  const protocolRegex = /^(?:https?:\/\/)?/;
  if (!protocolRegex.test(url)) {
    return null;
  }

  const domainRegex = /^(?:https?:\/\/)?([^\/]+)/;
  const matches = url.match(domainRegex);
  if (matches && matches.length >= 2) {
    return matches[1];
  } else {
    return null;
  }
}

export const CplnJwtDraftModel = types
  .model({
    _cluster_type: types.optional(types.string, "STRICT_DNS"),
    _transport_socket: types.optional(types.string, "envoy.transport_sockets.tls"),
    _http_name: types.optional(types.string, "envoy.filters.http.jwt_authn"),
    _typed_config_type: types.optional(
      types.string,
      "type.googleapis.com/envoy.extensions.filters.http.jwt_authn.v3.JwtAuthentication"
    ),
    _port_value: types.optional(types.number, 443),
    _priority: types.optional(types.number, 50),

    excludedWorkloads: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    enableAuthFilter: types.optional(BooleanModel, () => BooleanModel.create()),
    name: types.optional(StringModel, () => StringModel.create()),
    issuer: types.optional(StringModel, () => StringModel.create()),
    audiences: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    claim_to_headers: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    remote_jwks_http_uri: types.optional(StringModel, () => StringModel.create()),
    remote_jwks_http_uri_timeout: types.optional(StringModel, () => StringModel.create()),
    rules: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    rulesHeaders: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
  })
  .actions((self) => ({
    reset() {
      if (self.name.initialValue?.startsWith("cpln_")) {
        self.name.setValue(self.name.initialValue?.substring("cpln_".length));
      } else {
        self.name.reset();
      }
      self.enableAuthFilter.reset();
      self.issuer.reset();
      self.audiences.reset();
      self.excludedWorkloads.reset();
      self.claim_to_headers.reset();
      self.remote_jwks_http_uri.reset();
      self.remote_jwks_http_uri_timeout.reset();
      self.rules.reset();
      self.rulesHeaders.reset();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self.enableAuthFilter.confirm();
      if (self.enableAuthFilter.value) {
        self.name.confirm();
        self.issuer.confirm();
        self.audiences.confirm();
        self.excludedWorkloads.confirm();
        self.claim_to_headers.confirm();
        self.remote_jwks_http_uri.confirm();
        self.remote_jwks_http_uri_timeout.confirm();
        self.rules.confirm();
        self.rulesHeaders.confirm();
      } else {
        self.name.setInitialValue("");
        self.issuer.setInitialValue("");
        self.audiences.setInitialItems([]);
        self.excludedWorkloads.setInitialItems([]);
        self.claim_to_headers.setInitialItems([]);
        self.remote_jwks_http_uri.setInitialValue("");
        self.remote_jwks_http_uri_timeout.setInitialValue("");
        self.rules.setInitialItems([
          {
            firstValue: "/metric",
            secondValue: "true",
          },
          {
            firstValue: "/",
            secondValue: "false",
          },
        ]);
        self.rulesHeaders.setInitialItems([]);
      }
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.enableAuthFilter.isDirty) res = true;
      if (self.enableAuthFilter.value) {
        if (self.name.isDirty) res = true;
        if (self.issuer.isDirty) res = true;
        if (self.audiences.isDirty) res = true;
        if (self.excludedWorkloads.isDirty) res = true;
        if (self.claim_to_headers.isDirty) res = true;
        if (self.remote_jwks_http_uri.isDirty) res = true;
        if (self.remote_jwks_http_uri_timeout.isDirty) res = true;
        if (self.rules.isDirty) res = true;
        if (self.rulesHeaders.isDirty) res = true;
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (self.enableAuthFilter.value) {
        if (!self.name.isValid) res = false;
        if (!self.issuer.isValid) res = false;
        if (hasDuplicatedValue(self.excludedWorkloads.items.map((i) => i.firstValue).filter(Boolean))) res = false;
        if (hasDuplicatedValue(self.audiences.items.map((i) => i.firstValue).filter(Boolean))) res = false;
        if (hasDuplicatedValue(self.claim_to_headers.items.map((i) => i.firstValue).filter(Boolean))) res = false;
        if (!self.remote_jwks_http_uri.isValid) res = false;
        if (!self.remote_jwks_http_uri_timeout.isValid) res = false;
      }
      return res;
    },
    get asObject() {
      let res;
      if (self.enableAuthFilter.value) {
        res = {
          clusters: [
            {
              name: validateProviderName(self.name.value),
              type: self._cluster_type,
              load_assignment: {
                cluster_name: validateProviderName(self.name.value),
                endpoints: [
                  {
                    lb_endpoints: [
                      {
                        endpoint: {
                          address: {
                            socket_address: {
                              address: extractDomain(self.issuer.value),
                              port_value: self._port_value,
                            },
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              transport_socket: {
                name: self._transport_socket,
              },
            },
          ],
          http: [
            {
              name: self._http_name,
              priority: self._priority,
              ...(self.excludedWorkloads.items.length > 0
                ? {
                    excludedWorkloads: self.excludedWorkloads.items
                      .filter(({ firstValue }) => !!firstValue)
                      .map(
                        ({ firstValue }) =>
                          ngParseLink(firstValue, { gvc: ConsoleContext.gvc!, kind: "workload" }).absolute
                      ),
                  }
                : {}),
              typed_config: {
                "@type": self._typed_config_type,
                providers: {
                  [validateProviderName(self.name.value)]: {
                    audiences: self.audiences.items.map((i) => i.firstValue).filter(Boolean),
                    claim_to_headers: self.claim_to_headers.items
                      .filter((i) => i.firstValue !== "" && i.secondValue !== "")
                      .map((i) => ({
                        header_name: i.firstValue,
                        claim_name: i.secondValue,
                      })),
                    issuer: self.issuer.value,
                    remote_jwks: {
                      cache_duration: `${self.remote_jwks_http_uri_timeout.value}s`,
                      http_uri: {
                        cluster: validateProviderName(self.name.value),
                        timeout: `${self.remote_jwks_http_uri_timeout.value}s`,
                        uri: self.remote_jwks_http_uri.value,
                      },
                    },
                  },
                },
                rules: self.rules?.items
                  ?.filter((i) => i.firstValue !== "")
                  .map((rule) => {
                    return {
                      match: {
                        prefix: rule.firstValue,
                        headers: self.rulesHeaders.items
                          .filter((h) => h.thirdValue === rule.id)
                          .map((h) => ({
                            name: h.firstValue,
                            string_match: {
                              exact: h.secondValue,
                            },
                          })),
                      },
                      ...(rule.secondValue === "true"
                        ? {}
                        : {
                            requires: {
                              provider_name: validateProviderName(self.name.value),
                            },
                          }),
                    };
                  }),
              },
            },
          ],
        };
      }

      return res;
    },
  }));

export interface CplnJwtDraftMobx extends Instance<typeof CplnJwtDraftModel> {}
