import { observer } from "mobx-react-lite";
import * as React from "react";
import { mk8sMobx } from "../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { NGLabel } from "../../../newcomponents/text/label";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const AddonsInternalDeveloperPlatformRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <div>
      <NGLabel>Internal Developer Platform</NGLabel>
    </div>
  );
};

export const AddonsInternalDeveloperPlatform = observer(AddonsInternalDeveloperPlatformRaw);
