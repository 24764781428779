import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../../providerAutoscaler";
import { ProviderPreInstallScript } from "../../../providerPreInstallScript";
import { ProviderNetworking } from "../../../providerNetworking";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../../newcomponents/input/input";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderHetznerAdvancedRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const h = mk8sDraft.provider_hetzner!;

  return (
    //
    <div>
      <NGInputListMst
        label="Hetzner Labels"
        className="mb-8"
        data={h.hetznerLabels}
        firstInput={(item) => (
          <NGInput
            required
            // TODO make regex and length check
            invalid={!item.firstValue}
            ignoreTouched
            placeholder="Key"
            value={item.firstValue}
            onChange={(e) => item.setFirstValue(e.target.value)}
          />
        )}
        secondInput={(item) => (
          <NGInput
            required
            // TODO make regex and length check for invalidation
            ignoreTouched
            placeholder="Value"
            value={item.secondValue}
            onChange={(e) => item.setSecondValue(e.target.value)}
          />
        )}
        invalid={!h.isHetznerLabelsValid}
        error={h.hetznerLabelsErrorMessage}
      />
      <NGInputListMst
        label="Floating IP Selector"
        className="mb-8"
        data={h.floatingIPSelector}
        firstInput={(item) => (
          <NGInput
            required
            invalid={!item.firstValue}
            ignoreTouched
            placeholder="Key"
            value={item.firstValue}
            onChange={(e) => item.setFirstValue(e.target.value)}
          />
        )}
        secondInput={(item) => (
          <NGInput
            ignoreTouched
            placeholder="Value"
            value={item.secondValue}
            onChange={(e) => item.setSecondValue(e.target.value)}
          />
        )}
        invalid={!h.isFloatingIPSelectorValid}
        error={h.floatingIPSelectorErrorMessage}
      />
      <div className="mb-4">
        <ProviderPreInstallScript provider="hetzner" preInstallScript={h.preInstallScript} />
      </div>
      <div className="mb-4">
        <ProviderNetworking clusterDraft={mk8sDraft} networking={h.networking} />
      </div>
      <div>
        <ProviderAutoscaler autoscaler={h.autoscaler} />
      </div>
    </div>
  );
};

export const Mk8sCreateProviderHetznerAdvanced = observer(Mk8sCreateProviderHetznerAdvancedRaw);
