import * as React from "react";
import { observer } from "mobx-react-lite";
import "./index.css";
import { Copy } from "react-feather";

interface DefaultEnvironmentVariableProps {
  name: string;
  value: string;
  onClick: () => void;
}
const DefaultEnvironmentVariableRaw: React.FC<DefaultEnvironmentVariableProps> = ({ name, value, onClick }) => {
  return (
    <div className="relative flex items-center h-8 border-b">
      <div className="relative w-1/2">{name}</div>
      <div className="w-1/2 truncate">
        <span className="cursor-pointer color-link-hover flex items-center gap-2" onClick={onClick}>
          <span className="truncate">{String(value)}</span>
          <Copy className="h-4" />
        </span>
      </div>
    </div>
  );
};

export const DefaultEnvironmentVariable = observer(DefaultEnvironmentVariableRaw);
