import { observer } from "mobx-react-lite";
import * as React from "react";
import { mk8sMobx } from "../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const AddonsLogsRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <div>
      <div className="mb-2">Logs</div>
      <NGFormElement
        name={`logs.includeNamespaces`}
        label={mk8sDraft.addon_logs.includeNamespaces.label}
        placeholder="Regex"
        value={mk8sDraft.addon_logs.includeNamespaces.value}
        required={mk8sDraft.addon_logs.includeNamespaces.isRequired}
        error={mk8sDraft.addon_logs.includeNamespaces.error}
        onChange={mk8sDraft.addon_logs.includeNamespaces.setValue}
        inputProps={{ ignoreTouched: true }}
      />
      <NGFormElement
        name={`logs.excludeNamespaces`}
        label={mk8sDraft.addon_logs.excludeNamespaces.label}
        placeholder="Regex"
        value={mk8sDraft.addon_logs.excludeNamespaces.value}
        required={mk8sDraft.addon_logs.excludeNamespaces.isRequired}
        error={mk8sDraft.addon_logs.excludeNamespaces.error}
        onChange={mk8sDraft.addon_logs.excludeNamespaces.setValue}
        inputProps={{ ignoreTouched: true }}
      />
      <div className="flex items-center">
        <NGSwitch
          value={mk8sDraft.addon_logs.auditEnabled}
          onChange={(checked) => mk8sDraft.addon_logs.setAuditEnabled(checked)}
        >
          <NGLabelText>Enable Audit</NGLabelText>
        </NGSwitch>
        <InfoTooltip title="Cluster will be restarted when this value is changed." />
      </div>
    </div>
  );
};

export const AddonsLogs = observer(AddonsLogsRaw);
