import * as React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { PolicyDetail } from "./detail";
import { PolicyMobx, PolicyModel } from "../../mst/kinds/policy";
import { request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";

type RouteParams = "policy";
const PolicyDetailRouteRaw: React.FC = () => {
  const { policy: policyName } = useParams<RouteParams>();
  const [policy, setPolicy] = React.useState<PolicyMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
  }, [policyName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("policy", policyName) });
      const policyInstance = PolicyModel.create(data);
      setPolicy(policyInstance);
      setIsLoading(false);
    } catch (e) {
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching policy to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"policy"} canCreate={true} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {policy.name} - Policy - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: policy, fetchItem }}>
        <PolicyDetail policy={policy} />
      </DetailContext.Provider>
    </>
  );
};

export const PolicyDetailRoute = observer(PolicyDetailRouteRaw);
