import * as React from "react";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ParsedLocation } from "../../../../mobxStores/parsedLocation/parsedLocation";
import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { BillingContext } from "../../../../mobxStores/billingContext/billingContext";
import { UserData } from "../../../../mobxStores/userData/userData";
import { ChevronDown } from "react-feather";
import { NGButton } from "../../../../newcomponents/button/Button";
import { ItemType, MenuItemType } from "antd/es/menu/hooks/useItems";

const GenericCreateRaw: React.FC = () => {
  const navigate = useNavigate();
  const { org, gvc } = ConsoleContext;
  const { AccountUUID, canCreateOrg } = BillingContext;

  function handleMenuClick(e: any) {
    switch (true) {
      case e.key === "billingOrg":
        if (AccountUUID) {
          navigate(`/billing/account/${AccountUUID}/-tryorgcreate`);
        } else {
          navigate(`/billing/account/-select?action=tryCreateOrg`);
        }
        break;
      case e.key === "consoleOrg":
        navigate(`/billing/account/-select?action=tryCreateOrg`);
        break;
      case e.key === "app":
        navigate(`/console/org/${org}/marketplace?redirect=template`);
        break;
      case e.key === "gvc":
        navigate(`/console/org/${org}/gvc/-create`);
        break;
      case e.key === "workload":
        navigate(`/console/org/${org}/gvc/${gvc}/workload/-create`);
        break;
      case e.key === "identity":
        navigate(`/console/org/${org}/gvc/${gvc}/identity/-create`);
        break;
      case e.key === "volumeset":
        navigate(`/console/org/${org}/gvc/${gvc}/volumeset/-create`);
        break;
      case e.key === "location":
        navigate(`/console/org/${org}/location/-create`);
        break;
      case e.key === "domain":
        navigate(`/console/org/${org}/domain/-create`);
        break;
      case e.key === "ipset":
        navigate(`/console/org/${org}/ipset/-create`);
        break;
      case e.key === "agent":
        navigate(`/console/org/${org}/agent/-create`);
        break;
      case e.key === "secret":
        navigate(`/console/org/${org}/secret/-create`);
        break;
      case e.key === "cloudaccount":
        navigate(`/console/org/${org}/cloudaccount/-create`);
        break;
      case e.key === "mk8s":
        navigate(`/console/org/${org}/mk8s/-create`);
        break;
      case e.key === "group":
        navigate(`/console/org/${org}/group/-create`);
        break;
      case e.key === "serviceaccount":
        navigate(`/console/org/${org}/serviceaccount/-create`);
        break;
      case e.key === "policy":
        navigate(`/console/org/${org}/policy/-create`);
        break;
      case e.key === "auditctx":
        navigate(`/console/org/${org}/auditctx/-create`);
        break;
      default:
        break;
    }
  }

  if (ParsedLocation.dashboard === "billing" && !canCreateOrg) {
    return null;
  }

  const items: ItemType<MenuItemType>[] = [];
  if (ParsedLocation.dashboard === "billing" && canCreateOrg) {
    items.push({ key: "billingOrg", label: "Org" });
  }
  if (ParsedLocation.dashboard === "console") {
    items.push(
      //
      { key: "app", label: "App" },
      { type: "divider" },
      { key: "consoleOrg", label: "Org" },
      { type: "divider" },
      { key: "gvc", label: "GVC" },
    );
    if (UserData.hasGVC && ConsoleContext.gvc) {
      items.push(
        //
        { key: "workload", label: "Workload" },
        { key: "identity", label: "Identity" },
        { key: "volumeset", label: "Volume Set" },
        { type: "divider" },
      );
    }
    items.push(
      //
      { key: "location", label: "Location" },
      { key: "domain", label: "Domain" },
      { key: "ipset", label: "IP Set" },
      { key: "agent", label: "Agent" },
      { key: "secret", label: "Secret" },
      { key: "cloudaccount", label: "Cloud Account" },
      { key: "mk8s", label: "Kubernetes" },
      { type: "divider" },
      { key: "group", label: "Group" },
      { key: "serviceaccount", label: "Service Account" },
      { key: "policy", label: "Policy" },
      { key: "auditctx", label: "Audit Context" },
    );
  }

  return (
    <Dropdown
      trigger={["click"]}
      menu={{ onClick: handleMenuClick, className: "menu", items: items }}
      getPopupContainer={() => document.getElementById("genericCreate") as any}
    >
      <NGButton
        data-testid="generic-create"
        variant="action"
        size={"normal"}
        renderIcon={() => <ChevronDown color="var(--color-white)" size={16} />}
      >
        Create
      </NGButton>
    </Dropdown>
  );
};

export const GenericCreate = observer(GenericCreateRaw);
