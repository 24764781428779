import * as React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { Modal, notification } from "antd";
import { TabsLayout } from "../../../components/tabsLayout/tabsLayout";
import { HealthCheck } from "./healthCheck";
import { Command } from "./command";
import { EnvironmentVariables } from "./environmentVariables";
import { Resource } from "./resource";
import { General } from "./general";
import { WorkloadDraftContainerMobx } from "../../../mst/stores/workload.draft.container";
import { WorkloadMobx } from "../../../mst/kinds/workload";
import { updateLastDeploymentTimeOnHubspot } from "../../../services/utils";
import { ContainerDeleteModal } from "./deleteModal";
import { Volumes } from "./volumes";
import { Metrics } from "./metrics";
import { LifecycleHooks } from "./lifecycleHooks";
import { BasePathContext } from "../../../reactContexts/basePathContext";
import { NGButton } from "../../../newcomponents/button/Button";
import { useCleanPrompt } from "../../../reactHooks/useCleanPrompt";
import { useSetPromptShouldBlock } from "../../../reactHooks/useSetPromptShouldBlock";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { useSetPromptOnDiscard } from "../../../reactHooks/useSetPromptOnDiscard";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  containerDraft: WorkloadDraftContainerMobx;
  isNew: boolean;
  workloadMobx: WorkloadMobx;
  deleteContainer?: (containerName: string) => Promise<void>;
  startDraft: (w: WorkloadMobx) => Promise<void>;
  gvcEnv: { name: string; value: string }[];
  fetchItem?: any;
}

const ContainerDetailRaw: React.FC<Props> = ({
  workloadDraft,
  containerDraft,
  isNew,
  workloadMobx,
  deleteContainer,
  startDraft,
  gvcEnv,
  fetchItem,
}) => {
  const basePath = React.useContext(BasePathContext);
  const containersPath = `${basePath}/container`;
  const containerPath = `${containersPath}/${isNew ? "-new" : containerDraft.name.initialValue}`;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [promptWhen, setPromptWhen] = React.useState(workloadDraft.isDirty);
  const links = [
    { url: `${containerPath}/-general`, label: "General" },
    { url: `${containerPath}/-resource`, label: "Resources" },
    { url: `${containerPath}/-command`, label: "Command" },
    { url: `${containerPath}/-env`, label: "Env Variables" },
    { url: `${containerPath}/-lifecycle`, label: "Lifecycle" },
    { url: `${containerPath}/-volumes`, label: "Volumes" },
    { url: `${containerPath}/-metrics`, label: "Metrics" },
    workloadDraft.isCron ? undefined : { url: `${containerPath}/-readiness`, label: "Readiness" },
    workloadDraft.isCron ? undefined : { url: `${containerPath}/-liveness`, label: "Liveness" },
  ].filter(Boolean);

  const [showDelete, setShowDelete] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(promptWhen);
  }, [promptWhen]);

  useCleanPrompt();
  useSetPromptShouldBlock(shouldBlockNavigation);
  useSetPromptOnDiscard(() => workloadDraft.reset());

  React.useEffect(() => {
    let res = workloadDraft.isDirty;
    if (isNew) {
      res = containerDraft.isDirty;
    }
    setPromptWhen(res);
  }, [workloadDraft, containerDraft, isNew, workloadDraft.isDirty, containerDraft.isDirty]);

  React.useEffect(() => {
    const _showDelete = !isNew && !!workloadDraft.container2;
    setShowDelete(_showDelete);
  }, [isNew, workloadDraft.container2]);

  async function onConfirmTry() {
    const hasVolumesetChange = [...workloadDraft.allContainers, workloadDraft.newContainer].some(
      (c) => c.volumes.hasVolumesetChange
    );
    if (hasVolumesetChange) {
      const m = Modal.confirm({
        title: "Attention!",
        type: "warning",
        content: (
          <div>
            <div>You are changing a volumeset in this workload.</div>
            <div>
              Be aware that this will cause a <span className="font-bold">downtime</span> for this workload.
            </div>
          </div>
        ),
        footer: (
          <div className="modal-actions">
            <NGButton variant="secondary" onClick={() => m.destroy()}>
              Close
            </NGButton>
            <NGButton
              variant="primary"
              onClick={() => {
                m.destroy();
                onConfirm();
              }}
            >
              Confirm
            </NGButton>
          </div>
        ),
      });
    } else {
      onConfirm();
    }
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      const hasContainerWithNameDirty = workloadDraft.hasContainerWithNameDirty;
      const containerName = containerDraft.name.value;
      await workloadMobx.patch(workloadDraft.asPatch);
      workloadDraft.confirm();
      notification.success({
        message: "Success",
        description: isNew ? "Created container" : "Updated container",
      });
      updateLastDeploymentTimeOnHubspot();
      setPromptWhen(false);
      if (isNew) {
        await startDraft(workloadMobx);
        setTimeout(() => {
          navigate(`${containersPath}/${containerName}`);
        }, 0);
        return;
      } else if (hasContainerWithNameDirty) {
        await startDraft(workloadMobx);
        navigate(`${containersPath}/${containerName}`);
        return;
      }
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      console.trace(errorMessage);
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  function cancelNew() {
    workloadDraft.reset();
    setTimeout(() => navigate(containersPath), 50);
  }

  async function onDelete(containerName: string) {
    if (deleteContainer) {
      await deleteContainer(containerName);
    }
    await startDraft(workloadMobx);
    navigate(containersPath);
  }

  if (!isNew && !deleteContainer) {
    const message = "You need to give deleteContainer callback when it is not a new container";
    console.error(message);
    return null;
  }

  function shouldBlockNavigation(nextLocation: { pathname: string }, currentLocation: { pathname: string }) {
    if (nextLocation.pathname.includes("-delete")) return false;
    if (currentLocation.pathname.includes("-delete")) return false;
    if (isNew) {
      return !nextLocation.pathname.includes(`/container/-new/`);
    }
    return !nextLocation.pathname.includes(`/container/${containerDraft.name.initialValue}`);
  }

  return (
    <>
      <TabsLayout links={links as any}>
        <Routes>
          <Route index element={<Navigate to={`${containerPath}/-general`} replace />} />
          <Route
            path={`-general`}
            element={<General workloadDraft={workloadDraft} containerDraft={containerDraft} />}
          />
          <Route
            path={`-resource`}
            element={<Resource workloadDraft={workloadDraft} containerDraft={containerDraft} />}
          />
          <Route path={`-command`} element={<Command containerDraft={containerDraft} />} />
          <Route
            path={`-env`}
            element={
              <EnvironmentVariables
                env={containerDraft.env}
                hasDefaultEnv={true}
                filename={`${workloadDraft.name.value}-${containerDraft.name.value}-env`}
                env_gvc={containerDraft.envCPLN_GVC}
                env_gvcAlias={containerDraft.envCPLN_GVC_ALIAS}
                env_image={containerDraft.image.value}
                env_org={containerDraft.envCPLN_ORG}
                env_version={workloadDraft._version}
                env_workload={workloadMobx.selfLink}
                inherit={{
                  isActive: true,
                  env: gvcEnv,
                  value: containerDraft.inheritEnv,
                  setValue: containerDraft.setInheritEnv,
                }}
              />
            }
          />
          <Route
            path={`-lifecycle`}
            element={<LifecycleHooks workloadDraft={workloadDraft} containerDraft={containerDraft} />}
          />
          <Route
            path={`-volumes`}
            element={<Volumes containerDraft={containerDraft} workloadDraft={workloadDraft} />}
          />
          <Route
            path={`-metrics`}
            element={<Metrics containerDraft={containerDraft} workloadDraft={workloadDraft} />}
          />
          {workloadDraft.isCron ? null : (
            <Route
              path={`-readiness`}
              element={
                <HealthCheck
                  type={"readiness"}
                  container={containerDraft}
                  probe={containerDraft.readinessProbe}
                  useProbe={containerDraft.useReadinessProbe}
                  setUseProbe={containerDraft.setUseReadinessProbe}
                />
              }
            />
          )}
          {workloadDraft.isCron ? null : (
            <Route
              path={`-liveness`}
              element={
                <HealthCheck
                  type={"liveness"}
                  container={containerDraft}
                  probe={containerDraft.livenessProbe}
                  useProbe={containerDraft.useLivenessProbe}
                  setUseProbe={containerDraft.setUseLivenessProbe}
                />
              }
            />
          )}
        </Routes>
        <div className="mt-8 flex items-center">
          {showDelete ? (
            <NGButton
              disabled={isLoading}
              style={{ width: 220, marginRight: 10 }}
              variant={"danger"}
              outlined
              onClick={() => setShowDeleteModal(true)}
            >
              Delete Container
            </NGButton>
          ) : null}
          <NGButton
            disabled={isNew ? false : !containerDraft.isDirty || isLoading}
            style={{ width: 220, marginRight: 10 }}
            variant={"danger"}
            outlined
            data-testid="form-cancel"
            onClick={isNew ? cancelNew : workloadDraft.reset}
          >
            {isNew ? "Cancel" : "Reset"}
          </NGButton>
          <NGButton
            disabled={!containerDraft.isDirty || isLoading || !workloadDraft.isValid}
            loading={isLoading}
            style={{ width: 220 }}
            variant={"primary"}
            data-testid="form-create"
            onClick={onConfirmTry}
          >
            {isNew ? "Create" : "Save"}
          </NGButton>
        </div>
      </TabsLayout>
      {showDeleteModal ? (
        <ContainerDeleteModal
          containerName={containerDraft.name.initialValue}
          onClose={() => setShowDeleteModal(false)}
          deleteContainer={(containerName) => onDelete(containerName)}
          fetchItem={fetchItem}
        />
      ) : null}
    </>
  );
};

export const ContainerDetail = observer(ContainerDetailRaw);
