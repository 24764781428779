import { Instance, getParent, types } from "mobx-state-tree";

export const EnvVarModel = types
  .model({
    index: types.number,
    name: types.optional(types.string, ""),
    valuePrefix: types.optional(types.string, "text"),
    valueBody: types.optional(types.string, ""),
    valueSuffix: types.optional(types.string, ""),
    status: types.optional(types.enumeration(["default", "added", "changed"]), "default"),
    metadata: types.optional(types.frozen(), {}),
  })
  .views((self) => ({
    get nameConflicts(): boolean {
      // Used when is under EnvVarsModel
      const parent: EnvVarMobx[] = getParent(self); // .editTags
      if (!parent || !Array.isArray(parent)) {
        return false;
      }

      for (let envVar of parent) {
        if (envVar.index === self.index) {
          continue;
        }
        if (envVar.name === self.name) {
          return self.index < envVar.index;
        }
      }
      return false;
    },
  }))
  .views((self) => ({
    get isValid() {
      if (self.nameConflicts) {
        return false;
      }
      if (self.name.length < 1) {
        return false;
      }
      return true;
    },
    get value() {
      let result = "";
      if (self.valuePrefix !== "text") {
        result += self.valuePrefix;
      }
      result += self.valueBody;
      if (self.valuePrefix !== "text" && self.valueSuffix) {
        result += `.${self.valueSuffix}`;
      }
      return result;
    },
  }))
  .actions((self) => ({
    setStatus(value: "default" | "added" | "changed") {
      self.status = value;
    },
  }))
  .actions((self) => ({
    setMetadata(obj: any) {
      self.metadata = obj;
    },
    setName(value: string) {
      self.name = value;
      if (self.status === "added") {
        return;
      }
      self.setStatus("changed");
    },
    setValuePrefix(value: string) {
      self.valuePrefix = value;
      self.valueBody = "";
      self.valueSuffix = "";
      if (self.status === "added") {
        return;
      }
      self.setStatus("changed");
    },
    setValueBody(value: string) {
      self.valueBody = value;
      self.valueSuffix = "";
      if (self.status === "added") {
        return;
      }
      self.setStatus("changed");
    },
    setValueSuffix(value: string) {
      self.valueSuffix = value;
      if (self.status === "added") {
        return;
      }
      self.setStatus("changed");
    },
  }))
  .actions((self) => ({
    clearValue() {
      self.setValuePrefix("");
      if (self.status === "added") {
        return;
      }
      self.setStatus("changed");
    },
  }));
export interface EnvVarMobx extends Instance<typeof EnvVarModel> {}
