import * as React from "react";
import { observer } from "mobx-react-lite";
import { MarketplaceTemplateCard } from "./templateCard";
import { NGLabel } from "../../../newcomponents/text/label";
import "./templateList.css";
import { sortBy } from "lodash";
import { TemplateItem } from "../types/template";
import { request } from "../../../services/cpln";
import { Loader } from "../../../components/layout/loader";
import NGAlert from "../../../newcomponents/alert";

const MarketplaceTemplateListRaw: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [templates, setTemplates] = React.useState<TemplateItem[]>([]);

  React.useEffect(() => {
    fetchTemplates();
  }, []);

  async function fetchTemplates() {
    try {
      setIsLoading(true);
      setError("");
      const { data } = await request({ service: "marketplace", url: "/template" });
      setTemplates(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      setError(errorMessage);
    }
  }

  // const placeholderEffect = usePlaceholderEffect();

  return (
    <div className="template-list relative">
      <div className="mb-4 flex items-center justify-between gap-8">
        <NGLabel className="color-card-drop" size={2}>
          App Templates
        </NGLabel>
        {/* {isLoading || error ? null : (
          <NGInput
            onFocus={() => placeholderEffect.setIsFilterFocused(true)}
            onBlur={() => placeholderEffect.setIsFilterFocused(false)}
            style={{ width: 364 }}
            placeholder={`${placeholderEffect.placeholder}${placeholderEffect.index % 2 === 0 ? "|" : ""}`}
          />
        )} */}
      </div>
      {isLoading ? (
        <Loader
          reason={"fetching templates"}
          style={{
            width: 100,
            height: 100,
            margin: 0,
            left: `calc(50% - 50px)`,
            right: "unset",
            top: 150,
            bottom: "unset",
          }}
        />
      ) : error ? (
        <NGAlert type="error" title="Error" message={error} />
      ) : null}
      <div className="template-list-grid">
        {sortBy(templates, "folderName").map((template, index) => {
          return <MarketplaceTemplateCard key={index} template={template} />;
        })}
      </div>
    </div>
  );
};

export const MarketplaceTemplateList = observer(MarketplaceTemplateListRaw);
