import * as React from "react";
import { ReleaseInstance } from "../types/release";
import { NGLabel } from "../../../newcomponents/text/label";
import { Slash } from "react-feather";
import { Tooltip } from "../../../components/Tooltip";
import { Table } from "../../../newcomponents/table/table";
import { KindColumn } from "../../../newcomponents/table/columns/wellKnown/kindColumn";
import { CreatedColumn } from "../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { TagsColumn } from "../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { GvcColumn } from "../../../newcomponents/table/columns/wellKnown/gvcColumn";
import { ReleaseNameDescriptionColumn } from "../../../newcomponents/table/marketplace/releaseNameDescriptionColumn";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { useDeploymentsReducer } from "../../../components/detail/deploymentsReducer";
import { DEPLOYMENT_UPDATE_INTERVAL_MS } from "../../../envVariables";
import { WorkloadHealthObject, workloadHelpers } from "../../../mst/kinds/workload.helpers";
import { Kind, Links, Tags } from "../../../schema/types/base";
import { WorkloadReadinessColumn } from "../../../newcomponents/table/columns/workload/readinessColumn";

interface ReleaseTableItem {
  name: string;
  description: string;
  kind: Kind;
  notFound: boolean;
  links: Links;
  tags: Tags;
  created: string;
  lastModified: string;
  health: WorkloadHealthObject;
  readiness: string;
  fetchedReadiness: boolean;
}

interface Props {
  release: ReleaseInstance;
  usedGvcs: string[];
  items: any[];
  itemsNotFound: any[];
}
export const ReleaseInfo: React.FC<Props> = ({ release, usedGvcs, items, itemsNotFound }) => {
  const { state, fetchDeployments } = useDeploymentsReducer();
  React.useEffect(() => {
    const unsubscribe = fetchDeployments(
      items.map((i) => i.name),
      DEPLOYMENT_UPDATE_INTERVAL_MS,
    );
    return () => {
      unsubscribe();
    };
  }, [items.length]);

  const totalItemCount = items.length + itemsNotFound.length;

  const releaseTableItems = [...items, ...itemsNotFound].map((item) => {
    let health = workloadHelpers.getHealth([], item);
    let fetchedReadiness = false;
    if (item.kind === "workload") {
      workloadHelpers.getHealth(state.deploymentsMap[item.name], item);
      if (state.requestedWorkloadNames.includes(item.name)) {
        fetchedReadiness = true;
      }
    }

    return {
      ...item,
      health: health,
      readiness: health.text,
      fetchedReadiness: fetchedReadiness,
    };
  });

  return (
    //
    <>
      <div className="release-detail">
        <div className="mb-4 flex items-center">
          <div className="w-1/4">
            <div className="flex items-center justify-center" style={{ width: 75 }}>
              <img className="object-fit" src={`/resources/marketplace/template/${release.templateId}/icon.png`} />
            </div>
          </div>
          <div className="w-1/4">
            <NGLabel>GVC{usedGvcs.length > 1 ? "s" : ""}</NGLabel>
            <div>
              {usedGvcs.length > 0 ? (
                usedGvcs.join(", ")
              ) : (
                <div className="flex items-center gap-1">
                  <Slash className="feather-icon color-danger" />
                  <span>None</span>
                </div>
              )}
            </div>
          </div>
          <div className="w-1/4">
            <NGLabel>Item Count</NGLabel>
            <div>{totalItemCount}</div>
          </div>
        </div>
        <NGLabel className="mb-1">Items</NGLabel>
        <Table<ReleaseTableItem>
          tableId="release-detail-items"
          data={releaseTableItems}
          hideSettings
          columns={[
            ReleaseNameDescriptionColumn(undefined, { org: ConsoleContext.org || "", gvc: ConsoleContext.gvc || "" }),
            {
              id: "customInfo",
              label: "Info",
              cell: (p) => {
                const item = p.row.original;
                if (!item || !item.kind) {
                  return <span />;
                }

                if (item.notFound === true) {
                  return (
                    <Tooltip title={"Item not found"}>
                      <div className="flex items-center gap-2">
                        <Slash className={`feather-icon color-danger`} />
                        <span>Item not found</span>
                      </div>
                    </Tooltip>
                  );
                }

                if (item.kind === "workload") {
                  const _cell = WorkloadReadinessColumn().cell!;
                  return _cell(p as any);
                }

                return <span />;
              },
            },
            KindColumn(),
            GvcColumn(),
            TagsColumn(),
            CreatedColumn(),
            LastModifiedColumn(),
          ]}
        />
      </div>
    </>
  );
};

// Delete - change label to uninstall
// Modal.confirm({
//   title: "Are you sure you want to uninstall this app?",
//   content:
//     "All cpln objects created with this release will be deleted. Any data saved within them will be lost.",
//   okText: "Uninstall",
//   okButtonProps: { type: "primary", danger: true },
//   cancelText: "Cancel",
//   cancelButtonProps: { danger: false },
//   onOk: async () => {
//     try {
//       setIsLoadingUninstall(true);
//       const { data } = await request({
//         service: "marketplace",
//         method: "delete",
//         url: `/org/${ConsoleContext.org}/release/${releaseId}`,
//       });
//       console.log("response on uninstall", data);
//       notification.success({ message: "Success", description: "Uninstalled release" });
//       setIsLoadingUninstall(false);
//       navigate(`${basePath}/release`);
//     } catch (e) {
//       setIsLoadingUninstall(false);
//       console.log(e);
//       notification.warning({ message: "Failed", description: e.message });
//     }
//   },
// });
