import * as React from "react";
import { WorkloadDraftFirewallMobx } from "../../../mst/stores/workload.draft.firewall";
import { observer } from "mobx-react-lite";
import { Workload } from "../../../schema/types/workload/workload";
import { useTableKindId } from "../../../newcomponents/table/data/useTableKindId";
import { useTableItemQueryData } from "../../../newcomponents/table/data/useTableItemQueryData";
import { ngParseLink } from "../../../utils/linkParser/linkParser";
import { AxiosResponse } from "axios";
import { linksOf, request } from "../../../services/cpln";
import { sortBy } from "lodash";
import { Table } from "../../../newcomponents/table/table";
import { NameDescriptionNoLinkColumn } from "../../../newcomponents/table/columns/wellKnown/nameDescriptionNoLinkColumn";
import { WorkloadGvcColumn } from "../../../newcomponents/table/columns/workload/gvcColumn";
import { TagsColumn } from "../../../newcomponents/table/columns/wellKnown/tagsColumn";
import { CreatedColumn } from "../../../newcomponents/table/columns/wellKnown/createdColumn";
import { LastModifiedColumn } from "../../../newcomponents/table/columns/wellKnown/lastModifiedColumn";
import { NGButton } from "../../../newcomponents/button/Button";
import { PlusCircle } from "react-feather";
import { TableModal } from "../../../components/antd/TableModal";
import { TableHeaderRefreshButton } from "../../../newcomponents/table/components/RefreshButton";
import { CustomTableCPLNQuery } from "../../../newcomponents/table/components/TableCplnQuery";
import { NGLabel } from "../../../newcomponents/text/label";
import { ExternalLink } from "../../../newcomponents/table/components/ExternalLink";
import { DOCS_URL } from "../../../envVariables";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";

interface WorkloadTableItem extends Workload {
  type: string;
  gvc: string;
  selfLink: string;
}

interface Props {
  filterOutSameGVC: boolean;
  firewall: WorkloadDraftFirewallMobx;
}
const WorkloadCreateFirewallWorkloadRaw: React.FC<Props> = ({ filterOutSameGVC, firewall }) => {
  const { kind, id } = useTableKindId("workload", "workload-create-firewall-workload-add");
  const qData = useTableItemQueryData<Workload>(kind, { skipGvcScope: true, fetchAllPages: true });

  const [currentItems, setCurrentItems] = React.useState<WorkloadTableItem[]>([]);
  function getCurrentLinks(): string[] {
    return currentItems.map((i) => i.selfLink);
  }
  React.useEffect(() => {
    firewall.internal_setInboundAllowWorkload(getCurrentLinks());
  }, [currentItems, currentItems.length]);

  const [selectionsToRemove, setSelectionsToRemove] = React.useState<string[]>([]);
  const [selectionsToAdd, setSelectionsToAdd] = React.useState<string[]>([]);
  const [selectionItemsToAdd, setSelectionItemsToAdd] = React.useState<WorkloadTableItem[]>([]);
  const [isAddingItem, setIsAddingItem] = React.useState(false);

  function onRemove() {
    setCurrentItems((items) => items.filter((item) => !selectionsToRemove.includes(item.selfLink)));
    setSelectionsToRemove([]);
  }

  function onOkAddModal() {
    setCurrentItems((items) => {
      const newItems: WorkloadTableItem[] = [...items];
      const currentLinks = items.map((i) => i.selfLink);

      for (const itemToAdd of selectionItemsToAdd) {
        if (currentLinks.includes(itemToAdd.selfLink)) {
          continue;
        }

        newItems.push(itemToAdd);
      }

      return sortBy(newItems, "name");
    });

    setSelectionsToAdd([]);
    setSelectionItemsToAdd([]);
  }

  const workloadTableItems: WorkloadTableItem[] = qData.visibleItems
    .map((item) => {
      const selfLink = linksOf(item).self!;
      const { gvc } = ngParseLink(selfLink, { useInputCtx: true });
      return {
        ...item,
        selfLink: selfLink,
        gvc: gvc,
        type: item.spec?.type!,
      };
    })
    .filter((item) => {
      if (!filterOutSameGVC) {
        return true;
      }

      const { gvc: itemGvc } = ngParseLink(item.selfLink, { useInputCtx: true });
      const workloadGvc = ConsoleContext.gvc; // Basically evaluates to workload gvc if user is editing/creating a workload
      if (workloadGvc === itemGvc) {
        return false;
      }
      return true;
    })
    .filter((item) => !getCurrentLinks().includes(item.selfLink));

  return (
    <>
      <Table<WorkloadTableItem>
        tableId={"workload-firewall-workload"}
        title={() => (
          <div className="flex items-center">
            <NGLabel size={3}>Inbound Allow Workloads</NGLabel>
            <InfoTooltip
              title={[
                "A list of specific workloads which are allowed to access this workload internally.",
                `This list is only used if the 'inboundAllowType' is set to 'workload-list'.`,
              ]}
            />
          </div>
        )}
        data={currentItems}
        selectMode={"multi"}
        selectKey="selfLink"
        selections={selectionsToRemove}
        onSelectionsChange={setSelectionsToRemove}
        columns={[
          NameDescriptionNoLinkColumn(), //
          WorkloadGvcColumn() as any,
          TagsColumn(),
          CreatedColumn(),
          LastModifiedColumn(),
        ]}
        headerRenderer={() => (
          <>
            <NGButton
              variant={"danger"}
              size={"small"}
              outlined
              disabled={selectionsToRemove.length < 1}
              onClick={onRemove}
            >
              Remove
            </NGButton>
            <NGButton variant={"action"} size={"small"} onClick={() => setIsAddingItem(true)}>
              Add
            </NGButton>
          </>
        )}
        noItemsFoundRenderer={(table) => (
          <NGButton
            variant="primary"
            onClick={() => setIsAddingItem(true)}
            renderIcon={(_, props) => <PlusCircle {...props} />}
          >
            Add Workload
          </NGButton>
        )}
      />
      <TableModal
        open={isAddingItem}
        onCancel={() => setIsAddingItem(false)}
        onOk={() => {
          onOkAddModal();
          setIsAddingItem(false);
        }}
      >
        <Table<WorkloadTableItem>
          tableId={id}
          title={(className) => (
            <div className="flex gap-2">
              <div className={className}>Select Workloads to Add</div>
              <ExternalLink
                label="Not Seeing a Workload?"
                url={`${DOCS_URL}/reference/workload#internal`}
                disableCopy
              />
            </div>
          )}
          selectMode="multi"
          selectKey="selfLink"
          selections={selectionsToAdd}
          onSelectionsChange={(keys, items) => {
            setSelectionsToAdd(keys);
            setSelectionItemsToAdd(items!);
          }}
          data={workloadTableItems}
          headerRenderer={() => {
            return (
              <>
                <TableHeaderRefreshButton onClick={qData.fetchItems} />
                <CustomTableCPLNQuery
                  tableId={id}
                  kind={kind}
                  onQuery={qData.setQuery}
                  isLoading={qData.isLoadingQueried}
                />
              </>
            );
          }}
          enableFilter
          isLoading={qData.isLoading}
          columns={[
            NameDescriptionNoLinkColumn(), //
            WorkloadGvcColumn() as any,
            TagsColumn(),
            CreatedColumn(),
            LastModifiedColumn(),
          ]}
        />
      </TableModal>
    </>
  );
};

export const WorkloadCreateFirewallWorkload = observer(WorkloadCreateFirewallWorkloadRaw);
