import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { Mk8sCreateProviderGenericRoute } from "./generic/route";
import { Mk8sCreateProviderSelect } from "./select";
import { Mk8sCreateProviderAWSRoute } from "./aws/route";
import { Mk8sCreateProviderHetznerRoute } from "./hetzner/route";
import { Mk8sCreateProviderEphemeralRoute } from "./ephemeral/route";
import { Mk8sCreateProviderLambdalabsRoute } from "./lambdalabs/route";
import { Mk8sCreateProviderOblivusRoute } from "./oblivus/route";
import { Mk8sCreateProviderLinodeRoute } from "./linode/route";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <div>
      <Routes>
        <Route index element={<Mk8sCreateProviderSelect mk8sDraft={mk8sDraft} />} />
        <Route path={`aws/*`} element={<Mk8sCreateProviderAWSRoute mk8sDraft={mk8sDraft} />} />
        <Route path={`ephemeral/*`} element={<Mk8sCreateProviderEphemeralRoute mk8sDraft={mk8sDraft} />} />
        <Route path={`generic/*`} element={<Mk8sCreateProviderGenericRoute mk8sDraft={mk8sDraft} />} />
        <Route path={`hetzner/*`} element={<Mk8sCreateProviderHetznerRoute mk8sDraft={mk8sDraft} />} />
        <Route path={`lambdalabs/*`} element={<Mk8sCreateProviderLambdalabsRoute mk8sDraft={mk8sDraft} />} />
        <Route path={`linode/*`} element={<Mk8sCreateProviderLinodeRoute mk8sDraft={mk8sDraft} />} />
        <Route path={`oblivus/*`} element={<Mk8sCreateProviderOblivusRoute mk8sDraft={mk8sDraft} />} />
      </Routes>
    </div>
  );
};

export const Mk8sCreateProvider = observer(Mk8sCreateProviderRaw);
