import { types, Instance, getParent } from "mobx-state-tree";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { defaultValues } from "../base";
import { WorkloadDraftMobx } from "./workload.draft";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const WorkloadDraftOptionsModel = types
  .model({
    isLocal: types.boolean,
    isNew: types.boolean,
    _hasAutoscaling: types.optional(types.boolean, true),
    overrideAutoscaling: types.optional(types.boolean, false),
    _location: types.optional(types.string, ""),
    location: types.optional(StringModel, () => StringModel.create({ isRequired: true, label: "Location" })),
    _capacityAI: defaultValues.capacityAI,
    _debug: defaultValues.debug,
    _suspend: types.optional(types.boolean, false),
    _metric: types.optional(types.string, defaultValues.metric),
    _target: defaultValues.target.default,
    _metricPercentile: defaultValues.workload.options.metricPercentile,
    _minScale: defaultValues.minScale.default,
    _maxScale: defaultValues.maxScale.default,
    _scaleToZeroDelay: defaultValues.scaleToZeroDelay.default,
    _maxConcurrency: defaultValues.maxConcurrency.default,
    _timeoutSeconds: defaultValues.timeoutSecondsOptions.default,
    capacityAI: defaultValues.capacityAI,
    debug: defaultValues.debug,
    suspend: types.optional(types.boolean, false),
    metric: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Scaling Strategy",
        initialValue: defaultValues.metric,
        options: [
          { label: "Disabled", value: "disabled" },
          { label: "Concurrent Requests", value: "concurrency" },
          { label: "Requests Per Second", value: "rps" },
          { label: "CPU Utilization", value: "cpu" },
          { label: "Request Latency", value: "latency" },
        ],
      })
    ),
    target: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Target",
        isRequired: true,
        initialValue: defaultValues.target.default,
        min: defaultValues.target.min,
        max: defaultValues.target.max,
      })
    ),
    metricPercentile: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Metric Percentile",
        options: [
          { label: "p50", value: "p50" },
          { label: "p75", value: "p75" },
          { label: "p99", value: "p99" },
        ],
        initialValue: defaultValues.workload.options.metricPercentile,
      })
    ),
    minScale: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Min Scale",
        isRequired: true,
        initialValue: defaultValues.minScale.default,
        min: defaultValues.minScale.min,
      })
    ),
    maxScale: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Max Scale",
        isRequired: true,
        initialValue: defaultValues.maxScale.default,
        min: defaultValues.maxScale.min,
      })
    ),
    scaleToZeroDelay: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Scale Down Delay (seconds)",
        isRequired: true,
        initialValue: defaultValues.scaleToZeroDelay.default,
        min: defaultValues.scaleToZeroDelay.min,
        max: defaultValues.scaleToZeroDelay.max,
      })
    ),
    maxConcurrency: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Max Concurrency",
        isRequired: true,
        initialValue: defaultValues.maxConcurrency.default,
        min: defaultValues.maxConcurrency.min,
        max: defaultValues.maxConcurrency.max,
      })
    ),
    timeoutSeconds: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Timeout Seconds",
        isRequired: true,
        initialValue: defaultValues.timeoutSecondsOptions.default,
        min: defaultValues.timeoutSecondsOptions.min,
      })
    ),
  })
  .actions((self) => ({
    reset() {
      self.capacityAI = self._capacityAI;
      const { name: _locationName } = ngParseLink(self._location, { kind: "location" });
      self.overrideAutoscaling = false;
      self.location.setInitialValue(_locationName);
      self.debug = self._debug;
      self.target.setInitialValue(String(self._target));
      self.metric.setInitialValue(self._metric);
      self.metricPercentile.setInitialValue(self._metricPercentile);
      self.minScale.setInitialValue(String(self._minScale));
      self.maxScale.setInitialValue(String(self._maxScale));
      self.scaleToZeroDelay.setInitialValue(String(self._scaleToZeroDelay));
      self.maxConcurrency.setInitialValue(String(self._maxConcurrency));
      self.timeoutSeconds.setInitialValue(String(self._timeoutSeconds));
      self.suspend = self._suspend;
    },
    confirm() {
      if (!self._hasAutoscaling && self.overrideAutoscaling) {
        self._hasAutoscaling = true;
        self.overrideAutoscaling = false;
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setSuspend(value: boolean) {
      self.suspend = value;
    },
    setOverrideAutoscaling(value: boolean) {
      self.overrideAutoscaling = value;
    },
    setCapacityAI(value: boolean, forDefaultOptions: boolean) {
      self.capacityAI = value;
      if (forDefaultOptions) {
        self._capacityAI = value;
      }
    },
    setDebug(value: boolean) {
      self.debug = value;
    },
    _confirm() {
      self._location = self.location.value;
      self.location.confirm();
      self._capacityAI = self.capacityAI;
      self._debug = self.debug;
      self._metric = self.metric.value as any;
      self.metric.confirm();
      self._target = Number(self.target.value);
      self.target.confirm();
      self._metricPercentile = self.metricPercentile.value;
      self.metricPercentile.confirm();
      self._minScale = Number(self.minScale.value);
      self.minScale.confirm();
      self._maxScale = Number(self.maxScale.value);
      self.maxScale.confirm();
      self._scaleToZeroDelay = Number(self.scaleToZeroDelay.value);
      self.scaleToZeroDelay.confirm();
      self._maxConcurrency = Number(self.maxConcurrency.value);
      self.maxConcurrency.confirm();
      self._timeoutSeconds = Number(self.timeoutSeconds.value);
      self.timeoutSeconds.confirm();
      self.isNew = false;
      self._suspend = self.suspend;
    },
  }))
  .actions((self) => ({
    // TODO removed defaultOptions type
    defaultOptionsUpdated(defaultOptions: any) {
      if (defaultOptions.autoscaling) {
        if (self.target.value === String(defaultOptions.autoscaling.target)) {
          self._target = defaultOptions.autoscaling.target;
          self.target.setInitialValue(String(self._target));
        }
        if (self.metricPercentile.value === String(defaultOptions.autoscaling.metricPercentile)) {
          self._metricPercentile = defaultOptions.autoscaling.metricPercentile;
          self.metricPercentile.setInitialValue(self._metricPercentile);
        }
        if (self.metric.value === defaultOptions.autoscaling.metric) {
          self._metric = defaultOptions.autoscaling.metric;
          self.metric.setInitialValue(self._metric);
        }
        if (self.minScale.value === String(defaultOptions.autoscaling.minScale)) {
          self._minScale = defaultOptions.autoscaling.minScale;
          self.minScale.setInitialValue(String(self._minScale));
        }
        if (self.maxScale.value === String(defaultOptions.autoscaling.maxScale)) {
          self._maxScale = defaultOptions.autoscaling.maxScale;
          self.maxScale.setInitialValue(String(self._maxScale));
        }
        if (self.scaleToZeroDelay.value === String(defaultOptions.autoscaling.scaleToZeroDelay)) {
          self._scaleToZeroDelay = defaultOptions.autoscaling.scaleToZeroDelay;
          self.scaleToZeroDelay.setInitialValue(String(self._scaleToZeroDelay));
        }
        if (self.maxConcurrency.value === String(defaultOptions.autoscaling.maxConcurrency)) {
          self._maxConcurrency = defaultOptions.autoscaling.maxConcurrency;
          self.maxConcurrency.setInitialValue(String(self._maxConcurrency));
        }
      }
      if (self.timeoutSeconds.value === String(defaultOptions.timeoutSeconds)) {
        self._timeoutSeconds = defaultOptions.timeoutSeconds;
        self.timeoutSeconds.setInitialValue(String(self._timeoutSeconds));
      }
      if (self.capacityAI === defaultOptions.capacityAI) {
        self.setCapacityAI(defaultOptions.capacityAI, false);
      }
      if (self.debug === defaultOptions.debug) {
        self._debug = defaultOptions.debug;
        self.debug = self._debug;
      }
      if (self.suspend === defaultOptions.suspend) {
        self._suspend = defaultOptions.suspend;
        self.suspend = self._suspend;
      }
    },
  }))
  .views((self) => ({
    get isDirty() {
      const parent: WorkloadDraftMobx = getParent(self);
      let res = false;
      if (self.isLocal) {
        if (self.location.isDirty) res = true;
        if (self.capacityAI !== self._capacityAI) res = true;
      }
      if (self.overrideAutoscaling) res = true;
      if (self.debug !== self._debug) res = true;
      if (self.metric.isDirty) res = true;
      if (self.metric.value === "latency") {
        if (self.metricPercentile.isDirty) res = true;
      }
      if (self.target.isDirty) res = true;
      if (self.minScale.isDirty) res = true;
      if (self.metric.value !== "disabled") {
        if (self.maxScale.isDirty) res = true;
      }
      if (parent.isServerless || parent.isStandard) {
        if (self.scaleToZeroDelay.isDirty) res = true;
      }
      if (parent.isServerless) {
        if (self.maxConcurrency.isDirty) res = true;
      }
      if (self.timeoutSeconds.isDirty) res = true;
      if (self.isNew) res = true;
      if (self.suspend !== self._suspend) res = true;
      return res;
    },
    get isValid() {
      const parent: WorkloadDraftMobx = getParent(self);
      let res = true;
      if (self.isLocal) {
        if (self.location.value.length < 1) res = false;
      }
      const availableMetricOptions = ["concurrency", "cpu", "rps", "latency", "disabled"];
      if (!availableMetricOptions.includes(self.metric.value)) res = false;
      if (!self.target.isValid) res = false;
      if (!self.minScale.isValid) res = false;
      if (self.metric.value !== "disabled") {
        if (!self.maxScale.isValid) res = false;
      }
      if (parent.isServerless || parent.isStandard) {
        if (!self.scaleToZeroDelay.isValid) res = false;
      }
      if (parent.isServerless) {
        if (!self.maxConcurrency.isValid) res = false;
      }
      if (!self.timeoutSeconds.isValid) res = false;
      return res;
    },
    get asObject() {
      let parent: WorkloadDraftMobx = getParent(self);
      if (self.isLocal) {
        parent = getParent(self, 2);
      }
      const res: any = {
        debug: self.debug,
        capacityAI: self.capacityAI,
        timeoutSeconds: Number(self.timeoutSeconds.value),
        suspend: self.suspend,
      };
      if (parent.isStateful) {
        res.capacityAI = false;
      }
      if (self._hasAutoscaling || self.overrideAutoscaling) {
        res.autoscaling = {
          metric: self.metric.value,
          minScale: Number(self.minScale.value),
          maxScale: Number(self.maxScale.value),
          target: Number(self.target.value),
        };
        if (self.metric.value === "disabled") {
          res.autoscaling.maxScale = res.autoscaling.minScale;
        }
        if (self.metric.value === "latency") {
          res.autoscaling.metricPercentile = self.metricPercentile.value;
        }
        if (parent.isServerless || parent.isStandard) {
          res.autoscaling.scaleToZeroDelay = Number(self.scaleToZeroDelay.value);
        }
        if (parent.isServerless) {
          res.autoscaling.maxConcurrency = Number(self.maxConcurrency.value);
        }
      }
      if (self.isLocal) {
        const { absolute: locationLink } = ngParseLink(self.location.value, { kind: "location" });
        res.location = locationLink;
      }
      return res;
    },
  }))
  .views((self) => ({
    get isDirtyReason() {
      if (!self.isDirty) {
        return "WorkloadDraftOptions - Not Dirty";
      }
      let reason = "";
      if (self.isLocal) {
        if (self.location.isDirty) reason = self.location.isDirtyReason;
        if (self.capacityAI !== self._capacityAI)
          reason = `capacityAI: InitialValue -> ${self._capacityAI} | Value -> ${self.capacityAI}`;
      }
      if (self.debug !== self._debug) reason = `debug: InitialValue -> ${self._debug} | Value -> ${self.debug}`;
      if (self.metric.isDirty) reason = self.metric.isDirtyReason;
      if (self.metric.value === "latency") {
        if (self.metricPercentile.isDirty) reason = self.metricPercentile.isDirtyReason;
      } else {
        if (self.target.isDirty) reason = self.target.isDirtyReason;
      }
      if (self.minScale.isDirty) reason = self.minScale.isDirtyReason;
      if (self.metric.value !== "disabled") {
        if (self.maxScale.isDirty) reason = self.maxScale.isDirtyReason;
      }
      const parent: WorkloadDraftMobx = getParent(self);
      if (parent.isServerless || parent.isStandard) {
        if (self.scaleToZeroDelay.isDirty) reason = self.scaleToZeroDelay.isDirtyReason;
      }
      if (parent.isServerless) {
        if (self.maxConcurrency.isDirty) reason = self.maxConcurrency.isDirtyReason;
      }
      if (self.overrideAutoscaling) reason = "override: autoscaling";
      if (self.timeoutSeconds.isDirty) reason = self.timeoutSeconds.isDirtyReason;
      if (self.isNew) reason = "Options isNew is true";
      if (self.suspend !== self._suspend) {
        reason = "Suspend is changed";
      }
      return "WorkloadDraftOptions - " + reason;
    },
  }));
export interface WorkloadDraftOptionsMobx extends Instance<typeof WorkloadDraftOptionsModel> {}
