import objectHash from "object-hash";
import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { NameModel } from "../../mobxDataModels/nameModel";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { kindMobxToTagsModel } from "../mobxUtilts";
import { mk8sMobx } from "../kinds/mk8s/mk8s";
import { Mk8sDraftProviderGenericModel } from "./mk8s.draft.provider.generic";
import { Mk8sDraftProviderHetznerModel } from "./mk8s.draft.provider.hetzner";
import { Mk8sDraftProviderAWSModel } from "./mk8s.draft.provider.aws";
import { Mk8sDraftAddonFlexibleModel } from "./mk8s.draft.addon.flexible";
import { Mk8sDraftAddonAzureWorkloadIdentityModel } from "./mk8s.draft.addon.azureWorkloadIdentity";
import { Mk8sDraftProviderLinodeModel } from "./mk8s.draft.provider.linode";
import { Mk8sDraftProviderOblivusModel } from "./mk8s.draft.provider.oblivus";
import { Mk8sDraftAddonMetricsModel } from "./mk8s.draft.addon.metrics";
import { Mk8sDraftAddonLogsModel } from "./mk8s.draft.addon.logs";
import { Mk8sDraftProviderPaperSpaceModel } from "./mk8s.draft.provider.paperspace";
import { Mk8sDraftAddonNvidiaModel } from "./mk8s.draft.addon.nvidia";
import { Mk8sDraftAddonAWSEFSModel } from "./mk8s.draft.addon.awsefs";
import { Mk8sDraftFirewallModel } from "./mk8s.draft.firewall";
import { defaultValues } from "../base";
import { Mk8sDraftAutoscalerModel } from "./mk8s.draft.providerAutoscaler";
import { Mk8sDraftNetworkingModel } from "./mk8s.draft.providerNetworking";
import { Mk8sDraftAddonAWSECRModel } from "./mk8s.draft.addon.awsecr";
import { Mk8sDraftAddonAzureACRModel } from "./mk8s.draft.addon.azureACR";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { Mk8sDraftAddonAWSELBModel } from "./mk8s.draft.addon.awselb";
import { Mk8sDraftProviderEphemeralModel } from "./mk8s.draft.provider.ephemeral";
import { Mk8sDraftProviderLambdalabsModel } from "./mk8s.draft.provider.lambdalabs";

export const Mk8sDraftModel = types
  .model({
    _new: types.optional(types.boolean, false),
    _itemVersion: types.optional(types.number, 1),
    _name: types.optional(types.string, ""),
    _description: types.optional(types.string, ""),
    _provider: types.optional(types.string, "generic"),
    _version: types.optional(types.string, ""),
    name: types.optional(StringModel, () => NameModel.create()),
    description: types.optional(StringModel, () =>
      StringModel.create({
        label: "Description",
      }),
    ),
    firewall: types.optional(Mk8sDraftFirewallModel, () => Mk8sDraftFirewallModel.create()),
    provider: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Provider",
        initialValue: "generic",
        options: [
          { label: "AWS", value: "aws" },
          { label: "Ephemeral", value: "ephemeral" },
          { label: "Generic", value: "generic" },
          { label: "Hetzner", value: "hetzner" },
          { label: "Lambda Labs", value: "lambdalabs" },
          { label: "Linode", value: "linode" },
          { label: "Oblivus", value: "oblivus" },
          { label: "PaperSpace", value: "paperspace" },
        ],
      }),
    ),
    version: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Kubernetes Version",
        initialValue: "",
        options: [],
      }),
    ),
    provider_generic: types.optional(Mk8sDraftProviderGenericModel, () => Mk8sDraftProviderGenericModel.create()),
    provider_hetzner: types.optional(Mk8sDraftProviderHetznerModel, () => Mk8sDraftProviderHetznerModel.create()),
    provider_aws: types.optional(Mk8sDraftProviderAWSModel, () => Mk8sDraftProviderAWSModel.create()),
    provider_ephemeral: types.optional(Mk8sDraftProviderEphemeralModel, () => Mk8sDraftProviderEphemeralModel.create()),
    provider_linode: types.optional(Mk8sDraftProviderLinodeModel, () => Mk8sDraftProviderLinodeModel.create()),
    provider_oblivus: types.optional(Mk8sDraftProviderOblivusModel, () => Mk8sDraftProviderOblivusModel.create()),
    provider_lambdalabs: types.optional(Mk8sDraftProviderLambdalabsModel, () =>
      Mk8sDraftProviderLambdalabsModel.create(),
    ),
    provider_paperspace: types.optional(Mk8sDraftProviderPaperSpaceModel, () =>
      Mk8sDraftProviderPaperSpaceModel.create(),
    ),
    addon_dashboard: types.optional(Mk8sDraftAddonFlexibleModel, () => Mk8sDraftAddonFlexibleModel.create()),
    addon_azureWorkloadIdentity: types.optional(Mk8sDraftAddonAzureWorkloadIdentityModel, () =>
      Mk8sDraftAddonAzureWorkloadIdentityModel.create(),
    ),
    addon_awsWorkloadIdentity: types.optional(Mk8sDraftAddonFlexibleModel, () => Mk8sDraftAddonFlexibleModel.create()),
    addon_localPathStorage: types.optional(Mk8sDraftAddonFlexibleModel, () => Mk8sDraftAddonFlexibleModel.create()),
    addon_metrics: types.optional(Mk8sDraftAddonMetricsModel, () => Mk8sDraftAddonMetricsModel.create()),
    addon_logs: types.optional(Mk8sDraftAddonLogsModel, () => Mk8sDraftAddonLogsModel.create()),
    addon_nvidia: types.optional(Mk8sDraftAddonNvidiaModel, () => Mk8sDraftAddonNvidiaModel.create()),
    addon_awsEfs: types.optional(Mk8sDraftAddonAWSEFSModel, () => Mk8sDraftAddonAWSEFSModel.create()),
    addon_awsEcr: types.optional(Mk8sDraftAddonAWSECRModel, () => Mk8sDraftAddonAWSECRModel.create()),
    addon_awsElb: types.optional(Mk8sDraftAddonAWSELBModel, () => Mk8sDraftAddonAWSELBModel.create()),
    addon_azureAcr: types.optional(Mk8sDraftAddonAzureACRModel, () => Mk8sDraftAddonAzureACRModel.create()),
    addon_sysbox: types.optional(Mk8sDraftAddonFlexibleModel, () => Mk8sDraftAddonFlexibleModel.create()),
    tags: types.optional(TagsNewModel, () => TagsNewModel.create()),
    // as placeholder
    addon_idp_enabled: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    set_addon_idp_enabled(value: boolean) {
      self.addon_idp_enabled = value;
    },
    generalReset() {
      self.name.setInitialValue(self._name);
      self.description.setInitialValue(self._description);
      self.provider.setInitialValue(self._provider);
      self.version.setInitialValue(self._version);
    },
  }))
  .actions((self) => ({
    setDefaultVersion(version: string) {
      self._version = version;
      self.version.setInitialValue(version);
    },
    reset() {
      self.generalReset();
      self.firewall.reset();
      self.provider_generic.reset();
      self.provider_hetzner.reset();
      self.provider_aws.reset();
      self.provider_ephemeral.reset();
      self.provider_linode.reset();
      self.provider_oblivus.reset();
      self.provider_lambdalabs.reset();
      self.provider_paperspace.reset();
      self.addon_dashboard.reset();
      self.addon_awsWorkloadIdentity.reset();
      self.addon_sysbox.reset();
      self.addon_azureWorkloadIdentity.reset();
      self.addon_localPathStorage.reset();
      self.addon_metrics.reset();
      self.addon_logs.reset();
      self.addon_nvidia.reset();
      self.addon_awsEfs.reset();
      self.addon_awsEcr.reset();
      self.addon_awsElb.reset();
      self.addon_azureAcr.reset();
      self.tags.reset();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._name = self.name.value;
      self.name.confirm();
      self._description = self.description.value;
      self.description.confirm();
      self._provider = self.provider.value;
      self.provider.confirm();
      self._version = self.version.value;
      self.version.confirm();
      self.firewall.confirm();
      self.provider_generic.confirm();
      self.provider_hetzner.confirm();
      self.provider_aws.confirm();
      self.provider_ephemeral.confirm();
      self.provider_linode.confirm();
      self.provider_oblivus.confirm();
      self.provider_lambdalabs.confirm();
      self.provider_paperspace.confirm();
      self.addon_dashboard.confirm();
      self.addon_awsWorkloadIdentity.confirm();
      self.addon_sysbox.confirm();
      self.addon_azureWorkloadIdentity.confirm();
      self.addon_localPathStorage.confirm();
      self.addon_metrics.confirm();
      self.addon_logs.confirm();
      self.addon_nvidia.confirm();
      self.addon_awsEfs.confirm();
      self.addon_awsEcr.confirm();
      self.addon_awsElb.confirm();
      self.addon_azureAcr.confirm();
      self.tags.confirm();
    },
  }))
  .views((self) => ({
    get isGeneralDirty() {
      let res = false;
      if (self.name.isDirty) res = true;
      if (self.description.isDirty) res = true;
      if (self.provider.isDirty) res = true;
      if (self.version.isDirty) res = true;
      return res;
    },
    get isGeneralValid() {
      let res = true;
      if (!self.name.isValid) res = false;
      if (!self.description.isValid) res = false;
      if (self.version.value.length < 1) res = false;
      return res;
    },
    get invalidReasonGeneral() {
      let reason = "";
      if (!self.name.isValid) reason = "name";
      if (!self.description.isValid) reason = "description";
      if (self.version.value.length < 1) reason = "no version value";
      return reason;
    },
  }))
  .views((self) => ({
    get isProviderValid() {
      let res = true;
      if (!self.isGeneralValid) res = false;
      if (self.provider.value.length < 1) res = false;
      if (self.provider.value === "generic") {
        if (!self.provider_generic.isValid) res = false;
      }
      if (self.provider.value === "hetzner") {
        if (!self.provider_hetzner.isValid) res = false;
      }
      if (self.provider.value === "aws") {
        if (!self.provider_aws.isValid) res = false;
      }
      if (self.provider.value === "ephemeral") {
        if (!self.provider_ephemeral.isValid) res = false;
      }
      if (self.provider.value === "linode") {
        if (!self.provider_linode.isValid) res = false;
      }
      if (self.provider.value === "oblivus") {
        if (!self.provider_oblivus.isValid) res = false;
      }
      if (self.provider.value === "lambdalabs") {
        if (!self.provider_lambdalabs.isValid) res = false;
      }
      if (self.provider.value === "paperspace") {
        if (!self.provider_paperspace.isValid) res = false;
      }
      return res;
    },
    get invalidReasonProvider() {
      let reason = "";
      if (!self.isGeneralValid) reason = self.invalidReasonGeneral;
      if (!self.firewall.isValid) reason = self.firewall.invalidReason;
      if (self.provider.value.length < 1) reason = "no provider value";
      if (self.provider.value === "generic") {
        if (!self.provider_generic.isValid) reason = "generic - " + self.provider_generic.invalidReason;
      }
      if (self.provider.value === "hetzner") {
        if (!self.provider_hetzner.isValid) reason = "hetzner - " + self.provider_hetzner.invalidReason;
      }
      if (self.provider.value === "aws") {
        if (!self.provider_aws.isValid) reason = "aws - " + self.provider_aws.invalidReason;
      }
      if (self.provider.value === "ephemeral") {
        if (!self.provider_ephemeral.isValid) reason = "ephemeral - " + self.provider_ephemeral.invalidReason;
      }
      if (self.provider.value === "linode") {
        if (!self.provider_linode.isValid) reason = "linode";
      }
      if (self.provider.value === "oblivus") {
        if (!self.provider_oblivus.isValid) reason = "oblivus - " + self.provider_oblivus.invalidReason;
      }
      if (self.provider.value === "lambdalabs") {
        if (!self.provider_lambdalabs.isValid) reason = "lambdalabs - " + self.provider_lambdalabs.invalidReason;
      }
      if (self.provider.value === "paperspace") {
        if (!self.provider_paperspace.isValid) reason = "paperspace - " + self.provider_paperspace.invalidReason;
      }
      return reason;
    },
  }))
  .views((self) => ({
    get isAddonsValid() {
      let res = true;
      if (!self.isProviderValid) res = false;
      if (!self.addon_dashboard.isValid) res = false;
      if (!self.addon_awsWorkloadIdentity.isValid) res = false;
      if (!self.addon_sysbox.isValid) res = false;
      if (!self.addon_azureWorkloadIdentity.isValid) res = false;
      if (!self.addon_localPathStorage.isValid) res = false;
      if (!self.addon_metrics.isValid) res = false;
      if (!self.addon_logs.isValid) res = false;
      if (!self.addon_nvidia.isValid) res = false;
      if (!self.addon_awsEfs.isValid) res = false;
      if (!self.addon_awsEcr.isValid) res = false;
      if (!self.addon_awsElb.isValid) res = false;
      if (!self.addon_azureAcr.isValid) res = false;
      return res;
    },
    get invalidReasonAddons() {
      let reason = "";
      if (!self.isProviderValid) reason = self.invalidReasonProvider;
      if (!self.addon_dashboard.isValid) reason = "addon dashboard";
      if (!self.addon_awsWorkloadIdentity.isValid) reason = "addon aws";
      if (!self.addon_sysbox.isValid) reason = "addon sysbox";
      if (!self.addon_azureWorkloadIdentity.isValid) reason = "addon azure";
      if (!self.addon_localPathStorage.isValid) reason = "addon local";
      if (!self.addon_metrics.isValid) reason = "addon metrics";
      if (!self.addon_logs.isValid) reason = "addon logs";
      if (!self.addon_nvidia.isValid) reason = "addon nvidia";
      if (!self.addon_awsEfs.isValid) reason = "addon aws efs";
      if (!self.addon_awsEcr.isValid) reason = "addon aws ecr";
      if (!self.addon_awsElb.isValid) reason = "addon aws elb";
      if (!self.addon_azureAcr.isValid) reason = "addon azure acr";
      return reason;
    },
  }))
  .views((self) => ({
    get isFirewallValid() {
      let res = true;
      if (!self.isGeneralValid) res = false;
      if (!self.isProviderValid) res = false;
      if (!self.isAddonsValid) res = false;
      if (!self.firewall.isValid) res = false;
      return res;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.isGeneralDirty) res = true;
      if (self.firewall.isDirty) res = true;
      if (self.provider.value === "generic") {
        if (self.provider_generic.isDirty) res = true;
      }
      if (self.provider.value === "hetzner") {
        if (self.provider_hetzner.isDirty) res = true;
      }
      if (self.provider.value === "aws") {
        if (self.provider_aws.isDirty) res = true;
      }
      if (self.provider.value === "ephemeral") {
        if (self.provider_ephemeral.isDirty) res = true;
      }
      if (self.provider.value === "linode") {
        if (self.provider_linode.isDirty) res = true;
      }
      if (self.provider.value === "oblivus") {
        if (self.provider_oblivus.isDirty) res = true;
      }
      if (self.provider.value === "lambdalabs") {
        if (self.provider_lambdalabs.isDirty) res = true;
      }
      if (self.provider.value === "paperspace") {
        if (self.provider_paperspace.isDirty) res = true;
      }
      if (self.addon_dashboard.isDirty) res = true;
      if (self.addon_awsWorkloadIdentity.isDirty) res = true;
      if (self.addon_sysbox.isDirty) res = true;
      if (self.addon_azureWorkloadIdentity.isDirty) res = true;
      if (self.addon_localPathStorage.isDirty) res = true;
      if (self.addon_metrics.isDirty) res = true;
      if (self.addon_logs.isDirty) res = true;
      if (self.addon_nvidia.isDirty) res = true;
      if (self.addon_awsEfs.isDirty) res = true;
      if (self.addon_awsEcr.isDirty) res = true;
      if (self.addon_awsElb.isDirty) res = true;
      if (self.addon_azureAcr.isDirty) res = true;
      if (self.tags.isDirty) res = true;
      return res;
    },
    get isDirtyReason() {
      let reason = "";
      if (self.isGeneralDirty) reason = "general";
      if (self.firewall.isDirty) reason = self.firewall.dirtyReason;
      if (self.provider.value === "generic") {
        if (self.provider_generic.isDirty) reason = "generic provider - " + self.provider_generic.dirtyReason;
      }
      if (self.provider.value === "hetzner") {
        if (self.provider_hetzner.isDirty) reason = "hetzner provider - " + self.provider_hetzner.dirtyReason;
      }
      if (self.provider.value === "aws") {
        if (self.provider_aws.isDirty) reason = "aws provider - " + self.provider_aws.dirtyReason;
      }
      if (self.provider.value === "ephemeral") {
        if (self.provider_ephemeral.isDirty) reason = "ephemeral provider - " + self.provider_ephemeral.dirtyReason;
      }
      if (self.provider.value === "linode") {
        if (self.provider_linode.isDirty) reason = "linode provider";
      }
      if (self.provider.value === "oblivus") {
        if (self.provider_oblivus.isDirty) reason = "oblivus provider - " + self.provider_oblivus.dirtyReason;
      }
      if (self.provider.value === "lambdalabs") {
        if (self.provider_lambdalabs.isDirty) reason = "lambdalabs provider - " + self.provider_lambdalabs.dirtyReason;
      }
      if (self.provider.value === "paperspace") {
        if (self.provider_paperspace.isDirty) reason = "paperspace provider - " + self.provider_paperspace.dirtyReason;
      }
      if (self.addon_dashboard.isDirty) reason = self.addon_dashboard.dirtyReason;
      if (self.addon_awsWorkloadIdentity.isDirty) reason = self.addon_awsWorkloadIdentity.dirtyReason;
      if (self.addon_sysbox.isDirty) reason = self.addon_sysbox.dirtyReason;
      if (self.addon_azureWorkloadIdentity.isDirty) reason = self.addon_azureWorkloadIdentity.dirtyReason;
      if (self.addon_localPathStorage.isDirty) reason = self.addon_localPathStorage.dirtyReason;
      if (self.addon_metrics.isDirty) reason = self.addon_metrics.dirtyReason;
      if (self.addon_logs.isDirty) reason = self.addon_logs.dirtyReason;
      if (self.addon_nvidia.isDirty) reason = self.addon_nvidia.dirtyReason;
      if (self.addon_awsEfs.isDirty) reason = self.addon_awsEfs.dirtyReason;
      if (self.addon_awsEcr.isDirty) reason = self.addon_awsEcr.dirtyReason;
      if (self.addon_awsElb.isDirty) reason = self.addon_awsElb.dirtyReason;
      if (self.addon_azureAcr.isDirty) reason = self.addon_azureAcr.dirtyReason;
      if (self.tags.isDirty) reason = "tags";
      return reason;
    },
    get isValid() {
      let res = true;
      if (!self.isGeneralValid) res = false;
      if (!self.isProviderValid) res = false;
      if (!self.firewall.isValid) res = false;
      if (!self.isAddonsValid) res = false;
      if (!self.tags.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.isGeneralValid) reason = self.invalidReasonGeneral;
      if (!self.isProviderValid) reason = self.invalidReasonProvider;
      if (!self.firewall.isValid) reason = self.firewall.invalidReason;
      if (!self.isAddonsValid) reason = self.invalidReasonAddons;
      if (!self.tags.isValid) reason = "tags";
      return reason;
    },
  }))
  .views((self) => ({
    get asBodyObject() {
      const spec: any = {
        version: self.version.value,
        firewall: self.firewall.asObject,
        addOns: {},
      };

      if (self.provider.value === "generic") {
        spec.provider = {
          generic: self.provider_generic.asObject,
        };
      } else if (self.provider.value === "hetzner") {
        spec.provider = {
          hetzner: self.provider_hetzner.asObject,
        };
      } else if (self.provider.value === "aws") {
        spec.provider = {
          aws: self.provider_aws.asObject,
        };
      } else if (self.provider.value === "ephemeral") {
        spec.provider = {
          ephemeral: self.provider_ephemeral.asObject,
        };
      } else if (self.provider.value === "linode") {
        spec.provider = {
          linode: self.provider_linode.asObject,
        };
      } else if (self.provider.value === "oblivus") {
        spec.provider = {
          oblivus: self.provider_oblivus.asObject,
        };
      } else if (self.provider.value === "lambdalabs") {
        spec.provider = {
          lambdalabs: self.provider_lambdalabs.asObject,
        };
      } else if (self.provider.value === "paperspace") {
        spec.provider = {
          paperspace: self.provider_paperspace.asObject,
        };
      }

      if (self.addon_dashboard.isEnabled) {
        spec.addOns.dashboard = self.addon_dashboard.asObject;
      }
      if (self.addon_azureWorkloadIdentity.isEnabled) {
        spec.addOns.azureWorkloadIdentity = self.addon_azureWorkloadIdentity.asObject;
      }
      if (self.addon_awsWorkloadIdentity.isEnabled) {
        spec.addOns.awsWorkloadIdentity = self.addon_awsWorkloadIdentity.asObject;
      }
      if (self.addon_sysbox.isEnabled) {
        spec.addOns.sysbox = self.addon_sysbox.asObject;
      }
      if (self.addon_localPathStorage.isEnabled) {
        spec.addOns.localPathStorage = self.addon_localPathStorage.asObject;
      }
      if (self.addon_metrics.isEnabled) {
        spec.addOns.metrics = self.addon_metrics.asObject;
      }
      if (self.addon_logs.isEnabled) {
        spec.addOns.logs = self.addon_logs.asObject;
      }
      if (self.addon_nvidia.isEnabled) {
        spec.addOns.nvidia = self.addon_nvidia.asObject;
      }

      // awsEFS is only enabled for aws provider
      if (self.provider.value === "aws" && self.addon_awsEfs.isEnabled) {
        spec.addOns.awsEFS = self.addon_awsEfs.asObject;
      }

      if (self.addon_awsEcr.isEnabled) {
        spec.addOns.awsECR = self.addon_awsEcr.asObject;
      }
      if (self.addon_awsElb.isEnabled) {
        spec.addOns.awsELB = self.addon_awsElb.asObject;
      }

      if (self.addon_azureAcr.isEnabled) {
        spec.addOns.azureACR = self.addon_azureAcr.asObject;
      }

      const body: any = {
        name: self.name.value,
        description: self.description.value || self.name.value,
        tags: self.tags.asObject,
        "$replace/spec": spec,
      };
      return body;
    },
  }))
  .views((self) => ({
    get asObject() {
      let res = JSON.parse(JSON.stringify(self.asBodyObject));
      const _spec = res["$replace/spec"];
      res.spec = _spec;
      delete res["$replace/spec"];

      return res;
    },
  }))
  .views((self) => ({
    get hash() {
      return objectHash(self.asObject);
    },
  }));

export interface Mk8sDraftMobx extends Instance<typeof Mk8sDraftModel> {}

/*
org link
*/
export const Mk8sDraftStoreModel = types
  .model({
    orgName: types.string,
    orgLink: types.string,
    edit: types.maybe(Mk8sDraftModel),
  })
  .actions((self) => ({
    new() {
      self.edit = Mk8sDraftModel.create({
        _new: true,
        firewall: Mk8sDraftFirewallModel.create({
          _items: defaultValues.mk8s.firewall,
        }),
      });
    },
    start(mk8s: mk8sMobx) {
      self.edit = Mk8sDraftModel.create({
        _itemVersion: mk8s.version,
        _name: mk8s.name,
        _description: mk8s.description,
        _provider: !!mk8s.spec.provider.generic
          ? "generic"
          : !!mk8s.spec.provider.aws
          ? "aws"
          : !!mk8s.spec.provider.hetzner
          ? "hetzner"
          : !!mk8s.spec.provider.ephemeral
          ? "ephemeral"
          : !!mk8s.spec.provider.linode
          ? "linode"
          : !!mk8s.spec.provider.oblivus
          ? "oblivus"
          : !!mk8s.spec.provider.lambdalabs
          ? "lambdalabs"
          : "generic",
        _version: mk8s.spec.version,
        firewall:
          mk8s.spec.firewall.length > 0
            ? Mk8sDraftFirewallModel.create({
                _items: mk8s.spec.firewall.map((f) => {
                  return {
                    sourceCIDR: f.sourceCIDR,
                    description: f.description || "",
                  };
                }),
              })
            : Mk8sDraftFirewallModel.create(),
        provider_generic: !!mk8s.spec.provider.generic
          ? Mk8sDraftProviderGenericModel.create({
              _location: mk8s.spec.provider.generic.location,
              _nodePools: mk8s.spec.provider.generic.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                };
              }),
              networking: mk8s.spec.provider.generic.networking
                ? Mk8sDraftNetworkingModel.create({
                    _serviceNetwork: mk8s.spec.provider.generic.networking.serviceNetwork,
                    _podNetwork: mk8s.spec.provider.generic.networking.podNetwork,
                  })
                : Mk8sDraftNetworkingModel.create(),
            })
          : Mk8sDraftProviderGenericModel.create(),
        provider_hetzner: !!mk8s.spec.provider.hetzner
          ? Mk8sDraftProviderHetznerModel.create({
              _region: mk8s.spec.provider.hetzner.region,
              _tokenSecretLink: mk8s.spec.provider.hetzner.tokenSecretLink,
              _networkId: mk8s.spec.provider.hetzner.networkId,
              _firewallId: mk8s.spec.provider.hetzner.firewallId,
              _image: mk8s.spec.provider.hetzner.image,
              _sshKey: mk8s.spec.provider.hetzner.sshKey,
              _hetznerLabels: mk8s.spec.provider.hetzner.hetznerLabels,
              _floatingIPSelector: mk8s.spec.provider.hetzner.floatingIPSelector,
              _nodePools: mk8s.spec.provider.hetzner.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  serverType: n.serverType,
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                };
              }),
              _dedicatedServerNodePools: mk8s.spec.provider.hetzner.dedicatedServerNodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                };
              }),
              autoscaler: mk8s.spec.provider.hetzner.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.hetzner.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.hetzner.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.hetzner.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.hetzner.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
              _preInstallScript: mk8s.spec.provider.hetzner.preInstallScript,
              networking: mk8s.spec.provider.hetzner.networking
                ? Mk8sDraftNetworkingModel.create({
                    _serviceNetwork: mk8s.spec.provider.hetzner.networking.serviceNetwork,
                    _podNetwork: mk8s.spec.provider.hetzner.networking.podNetwork,
                  })
                : Mk8sDraftNetworkingModel.create(),
            })
          : Mk8sDraftProviderHetznerModel.create(),
        provider_aws: !!mk8s.spec.provider.aws
          ? Mk8sDraftProviderAWSModel.create({
              _region: mk8s.spec.provider.aws.region,
              _image: {
                recommended: mk8s.spec.provider.aws.image.recommended || "",
                exact: mk8s.spec.provider.aws.image.exact || "",
              },
              _deployRoleArn: mk8s.spec.provider.aws.deployRoleArn,
              _vpcId: mk8s.spec.provider.aws.vpcId,
              _keyPair: mk8s.spec.provider.aws.keyPair,
              _diskEncryptionKeyArn: mk8s.spec.provider.aws.diskEncryptionKeyArn,
              securityGroupIds: ListOfItemsModel.create({
                _items: (mk8s.spec.provider.aws.securityGroupIds || []).map((v) => ({ firstValue: v })),
              }),
              _awsTags: mk8s.spec.provider.aws.awsTags,
              _skipCreateRoles: mk8s.spec.provider.aws.skipCreateRoles,
              _nodePools: mk8s.spec.provider.aws.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  instanceTypes: [...n.instanceTypes],
                  overrideImage: n.overrideImage.recommended
                    ? { recommended: n.overrideImage.recommended }
                    : { exact: n.overrideImage.exact },
                  bootDiskSize: Number(n.bootDiskSize),
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                  onDemandBaseCapacity: n.onDemandBaseCapacity,
                  onDemandPercentageAboveBaseCapacity: n.onDemandPercentageAboveBaseCapacity,
                  spotAllocationStrategy: n.spotAllocationStrategy,
                  subnetIds: [...n.subnetIds],
                  extraSecurityGroupIds: [...n.extraSecurityGroupIds],
                };
              }),
              autoscaler: mk8s.spec.provider.aws.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.aws.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.aws.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.aws.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.aws.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
              _preInstallScript: mk8s.spec.provider.aws.preInstallScript,
              networking: mk8s.spec.provider.aws.networking
                ? Mk8sDraftNetworkingModel.create({
                    _serviceNetwork: mk8s.spec.provider.aws.networking.serviceNetwork,
                    _podNetwork: mk8s.spec.provider.aws.networking.podNetwork,
                  })
                : Mk8sDraftNetworkingModel.create(),
            })
          : Mk8sDraftProviderAWSModel.create(),
        provider_ephemeral: !!mk8s.spec.provider.ephemeral
          ? Mk8sDraftProviderEphemeralModel.create({
              _location: mk8s.spec.provider.ephemeral.location,
              _nodePools: mk8s.spec.provider.ephemeral.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  count: n.count,
                  arch: n.arch,
                  flavor: n.flavor,
                  cpu: n.cpu,
                  memory: n.memory,
                };
              }),
            })
          : Mk8sDraftProviderEphemeralModel.create(),
        provider_linode: !!mk8s.spec.provider.linode
          ? Mk8sDraftProviderLinodeModel.create({
              _region: mk8s.spec.provider.linode.region,
              _image: mk8s.spec.provider.linode.image,
              _nodePools: mk8s.spec.provider.linode.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  serverType: n.serverType,
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                  overrideImage: n.overrideImage,
                  subnetId: n.subnetId,
                };
              }),
              _preInstallScript: mk8s.spec.provider.linode.preInstallScript,
              _vpcId: mk8s.spec.provider.linode.vpcId,
              _firewallId: mk8s.spec.provider.linode.firewallId,
              _tokenSecretLink: mk8s.spec.provider.linode.tokenSecretLink,
              _authorizedUsers: [...mk8s.spec.provider.linode.authorizedUsers],
              _authorizedKeys: [...mk8s.spec.provider.linode.authorizedKeys],
              networking: mk8s.spec.provider.linode.networking
                ? Mk8sDraftNetworkingModel.create({
                    _serviceNetwork: mk8s.spec.provider.linode.networking.serviceNetwork,
                    _podNetwork: mk8s.spec.provider.linode.networking.podNetwork,
                  })
                : Mk8sDraftNetworkingModel.create(),
              autoscaler: mk8s.spec.provider.linode.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.linode.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.linode.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.linode.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.linode.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
            })
          : Mk8sDraftProviderLinodeModel.create(),
        provider_oblivus: !!mk8s.spec.provider.oblivus
          ? Mk8sDraftProviderOblivusModel.create({
              _datacenter: mk8s.spec.provider.oblivus.datacenter,
              _unmanagedNodePools: mk8s.spec.provider.oblivus.unmanagedNodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                };
              }),
              _nodePools: mk8s.spec.provider.oblivus.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  instanceType: n.instanceType,
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                };
              }),
              _preInstallScript: mk8s.spec.provider.oblivus.preInstallScript,
              _tokenSecretLink: mk8s.spec.provider.oblivus.tokenSecretLink,
              _sshKeys: [...mk8s.spec.provider.oblivus.sshKeys],
              autoscaler: mk8s.spec.provider.oblivus.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.oblivus.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.oblivus.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.oblivus.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.oblivus.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
            })
          : Mk8sDraftProviderOblivusModel.create(),
        provider_lambdalabs: !!mk8s.spec.provider.lambdalabs
          ? Mk8sDraftProviderLambdalabsModel.create({
              _region: mk8s.spec.provider.lambdalabs.region,
              _unmanagedNodePools: mk8s.spec.provider.lambdalabs.unmanagedNodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                };
              }),
              _nodePools: mk8s.spec.provider.lambdalabs.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  instanceType: n.instanceType,
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                };
              }),
              _preInstallScript: mk8s.spec.provider.lambdalabs.preInstallScript,
              _tokenSecretLink: mk8s.spec.provider.lambdalabs.tokenSecretLink,
              _sshKey: mk8s.spec.provider.lambdalabs.sshKey,
              autoscaler: mk8s.spec.provider.lambdalabs.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.lambdalabs.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.lambdalabs.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.lambdalabs.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.lambdalabs.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
            })
          : Mk8sDraftProviderLambdalabsModel.create(),
        provider_paperspace: !!mk8s.spec.provider.paperspace
          ? Mk8sDraftProviderPaperSpaceModel.create({
              _region: mk8s.spec.provider.paperspace.region,
              _unmanagedNodePools: mk8s.spec.provider.paperspace.unmanagedNodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                };
              }),
            })
          : Mk8sDraftProviderPaperSpaceModel.create(),
        addon_dashboard: !!mk8s.spec.addOns?.dashboard
          ? Mk8sDraftAddonFlexibleModel.create({ _isEnabled: true })
          : Mk8sDraftAddonFlexibleModel.create(),
        addon_awsWorkloadIdentity: !!mk8s.spec.addOns?.awsWorkloadIdentity
          ? Mk8sDraftAddonFlexibleModel.create({ _isEnabled: true })
          : Mk8sDraftAddonFlexibleModel.create(),
        addon_sysbox: !!mk8s.spec.addOns?.sysbox
          ? Mk8sDraftAddonFlexibleModel.create({ _isEnabled: true })
          : Mk8sDraftAddonFlexibleModel.create(),
        addon_localPathStorage: !!mk8s.spec.addOns?.localPathStorage
          ? Mk8sDraftAddonFlexibleModel.create({ _isEnabled: true })
          : Mk8sDraftAddonFlexibleModel.create(),
        addon_azureWorkloadIdentity: !!mk8s.spec.addOns?.azureWorkloadIdentity
          ? Mk8sDraftAddonAzureWorkloadIdentityModel.create({
              _isEnabled: true,
              _tenantId: mk8s.spec.addOns?.azureWorkloadIdentity?.tenantId,
            })
          : Mk8sDraftAddonAzureWorkloadIdentityModel.create(),
        addon_metrics: !!mk8s.spec.addOns?.metrics
          ? Mk8sDraftAddonMetricsModel.create({
              _isEnabled: true,
              _coreDns: mk8s.spec.addOns?.metrics?.coreDns,
              _kubelet: mk8s.spec.addOns?.metrics?.kubelet,
              _kubeState: mk8s.spec.addOns?.metrics?.kubeState,
              _nodeExporter: mk8s.spec.addOns?.metrics?.nodeExporter,
              _apiserver: mk8s.spec.addOns?.metrics?.apiserver,
              _cadvisor: mk8s.spec.addOns?.metrics?.cadvisor,
              _scrapeAnnotated: !!mk8s.spec.addOns?.metrics?.scrapeAnnotated,
              _scrapeAnnotated_intervalSeconds: mk8s.spec.addOns?.metrics?.scrapeAnnotated?.intervalSeconds,
              _scrapeAnnotated_includeNamespaces: mk8s.spec.addOns?.metrics?.scrapeAnnotated?.includeNamespaces,
              _scrapeAnnotated_excludeNamespaces: mk8s.spec.addOns?.metrics?.scrapeAnnotated?.excludeNamespaces,
              _scrapeAnnotated_retainLabels: mk8s.spec.addOns?.metrics?.scrapeAnnotated?.retainLabels,
            })
          : Mk8sDraftAddonMetricsModel.create(),
        addon_logs: !!mk8s.spec.addOns?.logs
          ? Mk8sDraftAddonLogsModel.create({
              _isEnabled: true,
              _auditEnabled: mk8s.spec.addOns?.logs?.auditEnabled,
              _includeNamespaces: mk8s.spec.addOns?.logs?.includeNamespaces,
              _excludeNamespaces: mk8s.spec.addOns?.logs?.excludeNamespaces,
            })
          : Mk8sDraftAddonLogsModel.create(),
        addon_nvidia: !!mk8s.spec.addOns?.nvidia
          ? Mk8sDraftAddonNvidiaModel.create({
              _isEnabled: true,
            })
          : Mk8sDraftAddonNvidiaModel.create(),
        addon_awsEfs:
          !!mk8s.spec.provider.aws && !!mk8s.spec.addOns?.awsEFS
            ? Mk8sDraftAddonAWSEFSModel.create({
                _isEnabled: true,
                _roleArn: mk8s.spec.addOns.awsEFS.roleArn,
              })
            : Mk8sDraftAddonAWSEFSModel.create(),
        addon_awsEcr: !!mk8s.spec.addOns?.awsECR
          ? Mk8sDraftAddonAWSECRModel.create({
              _isEnabled: true,
              _roleArn: mk8s.spec.addOns.awsECR.roleArn,
            })
          : Mk8sDraftAddonAWSECRModel.create(),
        addon_awsElb: !!mk8s.spec.addOns?.awsELB
          ? Mk8sDraftAddonAWSELBModel.create({
              _isEnabled: true,
              _roleArn: mk8s.spec.addOns.awsELB.roleArn,
            })
          : Mk8sDraftAddonAWSELBModel.create(),
        addon_azureAcr: !!mk8s.spec.addOns?.azureACR
          ? Mk8sDraftAddonAzureACRModel.create({
              _isEnabled: true,
              _clientId: mk8s.spec.addOns.azureACR.clientId,
            })
          : Mk8sDraftAddonAzureACRModel.create(),
        tags: TagsNewModel.create({
          tags: kindMobxToTagsModel(mk8s),
        }),
      });

      if (self.edit.provider.value !== "aws") {
        self.edit.addon_awsEcr.setRoleArnRequired(true);
        self.edit.addon_awsElb.setRoleArnRequired(true);
      }
    },
    reset() {
      self.edit?.reset();
    },
  }));
export interface Mk8sDraftStoreMobx extends Instance<typeof Mk8sDraftStoreModel> {}
