import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../../providerAutoscaler";
import { ProviderPreInstallScript } from "../../../providerPreInstallScript";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderOblivusAdvancedRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const o = mk8sDraft.provider_oblivus!;

  return (
    //
    <>
      <div className="mb-4">
        <ProviderPreInstallScript provider="oblivus" preInstallScript={o.preInstallScript} />
      </div>
      <div className="mb-4">
        <ProviderAutoscaler autoscaler={o.autoscaler} />
      </div>
    </>
  );
};

export const Mk8sCreateProviderOblivusAdvanced = observer(Mk8sCreateProviderOblivusAdvancedRaw);
