import * as React from "react";
import { Apply } from "../../mobxStores/apply/apply";
import { CodeEditor } from "../group/identityMatcher/codeEditor";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Layout } from "../../layout";
import { NGButton } from "../../newcomponents/button/Button";
import { NGInput } from "../../newcomponents/input/input";
import { NGLabel } from "../../newcomponents/text/label";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { NGSwitch } from "../../newcomponents/switch";
import { observer } from "mobx-react-lite";
import { parentLink, request, resourceLink } from "../../services/cpln";
import { RcFile } from "antd/lib/upload/interface";
import { Terminal } from "react-feather";
import { Upload, notification } from "antd";
import { useNavigate } from "react-router-dom";
import jsYaml from "js-yaml";
import NGAlert from "../../newcomponents/alert";
import { getExportData, k8sKeySort } from "../../services/utils";
import { convertAllLinksToRelative, ngParseLink } from "../../utils/linkParser/linkParser";
import { Loader } from "../../components/layout/loader";
import { guessFileTextLanguage as guessLanguage } from "../../utils/guessTextFormat";
import { InfoTooltip } from "../../components/InfoTooltip";
import { DOCS_URL } from "../../envVariables";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { Theme } from "../../mobxStores/uiData/theme";

type ApplyLanguage = "json" | "yaml";
const CPLNApplyRaw = () => {
  const navigate = useNavigate();
  const [isLoadingFromLink, setIsLoadingFromLink] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [fileText, setFileText] = React.useState(Apply.initialContent);
  const [fileObjects, setFileObjects] = React.useState<any[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isDelete, setIsDelete] = React.useState(false);
  const [orgOverride, setOrgOverride] = React.useState(ConsoleContext.org || "");
  const [gvcOverride, setGVCOverride] = React.useState(ConsoleContext.gvc || "");
  const [language, setLanguage] = React.useState<ApplyLanguage>("yaml");
  const [isDragging, setIsDragging] = React.useState(false);

  React.useEffect(() => {
    if (Apply.itemLink) {
      fetchContentFromItemLink();
    } else {
      setFileText(Apply.initialContent);
      Apply.setInitialContent("");
    }
  }, []);

  async function fetchContentFromItemLink() {
    setIsLoadingFromLink(true);
    const { kind, name } = ngParseLink(Apply.itemLink);
    const data = await getExportData({ kind, name, selfLink: Apply.itemLink } as any, true);
    const yaml = jsYaml.dump(convertAllLinksToRelative(data), { indent: 2, noRefs: true, sortKeys: k8sKeySort });
    setFileText(yaml);
    setIsLoadingFromLink(false);
  }

  React.useEffect(() => {
    if (!fileText) {
      setErrorMessage("");
      setLanguage("yaml");
    }

    const { result, error } = guessLanguage(fileText, "yaml");

    setLanguage(result as any);
    setErrorMessage(error);

    if (!!error) {
      setFileObjects([]);
    } else {
      // @ts-ignore
      const objects: any = jsYaml.loadAll(fileText, { json: true });
      setFileObjects(objects);
    }
  }, [fileText]);

  async function onApply() {
    setIsLoading(true);
    const failures: string[] = [];
    for (let fileObject of fileObjects) {
      const isApiList = ["list", "queryresult"].includes(fileObject.kind);
      const isArray = Array.isArray(fileObject);
      if (isApiList) {
        for (let item of fileObject.items) {
          try {
            await applyItem(item);
          } catch (e) {
            let errorMessage = e?.response?.data?.message;
            if (!errorMessage) errorMessage = e.message;
            failures.push(errorMessage);
          }
        }
      } else if (isArray) {
        for (let item of fileObject) {
          try {
            await applyItem(item);
          } catch (e) {
            let errorMessage = e?.response?.data?.message;
            if (!errorMessage) errorMessage = e.message;
            failures.push(errorMessage);
          }
        }
      } else {
        try {
          await applyItem(fileObject);
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          failures.push(errorMessage);
        }
      }
    }
    setIsLoading(false);

    if (failures.length > 0) {
      notification.warning({
        message: "Failure",
        description: (
          <div className="flex flex-col">
            <div>Some items failed</div>
            <ul>
              {failures.map((f) => (
                <li>{f}</li>
              ))}
            </ul>
          </div>
        ),
      });
    } else {
      notification.success({
        message: "Success",
        description: "Applied all data",
      });
      if (fileObjects.length === 1 && !isDelete) {
        const itemKind = fileObjects[0].kind;
        if (!["org", "gvc"].includes(itemKind)) {
          let link = resourceLink(itemKind, fileObjects[0].name, {
            org: orgOverride || ConsoleContext.org,
            gvc: fileObjects[0].gvc || gvcOverride || ConsoleContext.gvc,
          });
          navigate(`/console${link}`);
          return;
        }
      }
      setTimeout(() => {
        window.location.pathname = "/console";
      }, 100);
    }
  }

  async function applyItem(item: any) {
    let link = "";
    try {
      link = resourceLink(item.kind, item.name, {
        org: orgOverride || ConsoleContext.org,
        gvc: item.gvc || gvcOverride || ConsoleContext.gvc,
      });
    } catch (e) {
      throw new Error("Not a valid item");
    }

    if (isDelete) {
      return await request({ method: "delete", url: link, body: item });
    }
    link = parentLink(item.kind, {
      org: orgOverride || ConsoleContext.org,
      gvc: item.gvc || gvcOverride || ConsoleContext.gvc,
    });

    return await request({ method: "put", url: link, body: item });
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    setIsDragging(true);
  }

  function handleDragLeave(event: React.DragEvent<HTMLDivElement>): void {
    setIsDragging(false);
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    setIsDragging(false);

    const files: FileList = event.dataTransfer.files;

    if (files.length == 0) {
      notification.error({ message: "No files were detected in the drop. Please try again." });
    }

    // Create a new FileReader instance to read the file content
    const reader = new FileReader();

    // Define the onload event to handle when the file reading operation is successful
    reader.onload = () => {
      if (reader.result === null) {
        notification.error({ message: "Failed to read file content. The file might be empty or corrupted." });
        return;
      }

      setFileText(reader.result as string);
    };

    reader.onerror = () => {
      notification.error({ message: "An error occurred while reading the file. Please try again." });
    };

    // Read the content of the file as text
    reader.readAsText(files[0]);
  }

  if (isLoadingFromLink) {
    return <Loader reason={"Fetching data from item link"} />;
  }

  return (
    <Layout showLeftMenu={false}>
      <div style={{ display: "flex", alignItems: "center", gap: "0.625rem" }}>
        <Terminal color="var(--color-label)" size={20} />
        <span style={{ color: "var(--color-label)" }}>cpln apply</span>
      </div>
      <div className="mb-4 my-2 flex flex-col gap-2">
        <span>Automate modifications to the system using JSON or YAML input.</span>
        <span>
          Functions the same as the CLI's{" "}
          <a className="ngfocus color-link" href={`${DOCS_URL}/reference/cli#apply`} target={"_blank"}>
            cpln apply
          </a>{" "}
          command.
        </span>
        <span>
          An existing item with the specified name is replaced with the YAML, or a new item is created if it doesn't
          exist.
        </span>
        <div className="w-full border-b" style={{ height: 1 }} />
      </div>
      <div className="flex items-center mb-4 gap-6">
        <Upload
          accept={".json, .yaml, .yml"}
          beforeUpload={async (file: RcFile) => {
            if (!file) {
              setFileText("");
              setErrorMessage("");
              return;
            }
            try {
              const text = await file.text();
              setFileText(text);
            } catch (e) {
              setFileText("");
              setErrorMessage(e.message);
            }
            return false;
          }}
          fileList={[]}
        >
          <NGButton variant="secondary" onClick={() => {}}>
            Upload JSON or YAML File
          </NGButton>
        </Upload>
        <div className="flex items-center gap-2">
          <NGLabel>Org</NGLabel>
          <NGKindSelect
            kind="org"
            optionValueAs="name"
            value={orgOverride}
            onChange={setOrgOverride}
            placeholder={ConsoleContext.org || "Org"}
            style={{ width: 200 }}
          />
        </div>
        <div className="flex items-center gap-2">
          <NGLabel>GVC</NGLabel>
          {orgOverride !== ConsoleContext.org ? (
            <NGInput
              value={gvcOverride}
              onChange={(e) => setGVCOverride(e.target.value)}
              placeholder={ConsoleContext.gvc || "GVC"}
              style={{ width: 200 }}
            />
          ) : (
            <NGKindSelect
              kind="gvc"
              optionValueAs="name"
              value={gvcOverride}
              onChange={setGVCOverride}
              placeholder={ConsoleContext.gvc || "GVC"}
              style={{ width: 200 }}
            />
          )}
        </div>
        <div className="flex items-center">
          <NGSwitch value={isDelete} onChange={(value) => setIsDelete(value)}>
            <NGLabelText>Use as Delete</NGLabelText>
          </NGSwitch>
          <InfoTooltip
            title="When enabled, instead of updating/creating the items, it deletes them by sending the HTTP requests with DELETE method."
            overlayStyle={{ maxWidth: 450 }}
          />
        </div>
      </div>
      <div
        className={`relative ${isDragging ? "bg-black" : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <CodeEditor
          delay
          lint
          value={fileText}
          setValue={setFileText}
          language={language}
          options={{ basicSetup: { lineNumbers: true, autocompletion: false } }}
        />
        {fileText.length == 0 ? (
          <div
            className="absolute inset-0 flex items-center justify-center pointer-events-none select-none text-2xl"
            style={{ color: Theme.theme === "dark" ? `var(--color-gray-800)` : `var(--color-gray-400)` }}
          >
            {isDragging ? "Drop Here" : "Write or Drag & Drop YAML or JSON File"}
          </div>
        ) : null}
      </div>
      {errorMessage ? <NGAlert className="mt-2" type={"error"} message={errorMessage} /> : null}
      <div className="modal-actions" style={{ marginTop: "2rem" }}>
        <NGButton
          style={{ width: 215, marginRight: 10 }}
          size="toRemoveLarge"
          variant="secondary"
          onClick={() => (Apply.prevPath ? navigate(Apply.prevPath) : navigate("/console"))}
          disabled={isLoading}
        >
          Cancel
        </NGButton>
        <NGButton
          style={{ width: 215, marginLeft: 10 }}
          size="toRemoveLarge"
          variant="primary"
          onClick={onApply}
          loading={isLoading}
          disabled={isLoading || !fileText || !!errorMessage || fileObjects.length < 1}
        >
          Apply
        </NGButton>
      </div>
    </Layout>
  );
};

export const CPLNApply = observer(CPLNApplyRaw);
