import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftAutoscalerMobx } from "../../mst/stores/mk8s.draft.providerAutoscaler";
import { NGLabel } from "../../newcomponents/text/label";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { NGInputListMst } from "../../newcomponents/inputList/inputListMst";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { InfoTooltip } from "../../components/InfoTooltip";

interface Props {
  autoscaler: Mk8sDraftAutoscalerMobx;
}
const ProviderAutoscalerRaw: React.FC<Props> = ({ autoscaler }) => {
  const allOptions = [
    { label: "random", value: "random" },
    { label: "most-pods", value: "most-pods" },
    { label: "least-waste", value: "least-waste" },
    { label: "price", value: "price" },
    { label: "priority", value: "priority" },
  ];

  function getAvailableOptions() {
    const currentItems = autoscaler.expanders.items.map((i) => i.firstValue);
    const availableOptions = allOptions.filter((opt) => !currentItems.includes(opt.value));
    return availableOptions;
  }

  function updateAvailableOptions() {
    setExpanderOptions(getAvailableOptions());
  }

  const [expanderOptions, setExpanderOptions] = React.useState(getAvailableOptions());

  React.useEffect(() => {
    updateAvailableOptions();
  }, [autoscaler.expanders.items.length]);

  return (
    <>
      <NGLabel>Autoscaler</NGLabel>
      <div className="flex items-start">
        <NGInputListMst
          label="Expander"
          styles={{ container: { width: 450, minWidth: 450 } }}
          noItemLabel={`Default value is ["most-pods"]`}
          data={autoscaler.expanders}
          canAdd={() => autoscaler.expanders.items.length < 3}
          draggable
          firstInput={(item) => (
            <NGSelect
              value={item.firstValue}
              options={expanderOptions}
              onChange={(v) => item.setFirstValue(v)}
              invalid={!item.firstValue}
              ignoreTouched
              allowEmpty={false}
              placeholder="Select an Option"
            />
          )}
        />
        <div style={{ transform: "translateY(6px)" }}>
          <InfoTooltip
            title={[
              `Type of node group expander to be used in scale up. Available values: [random,most-pods,least-waste,price,priority,grpc].`,
              `Specifying multiple values separated by commas will call the expanders in succession until there is only one option remaining.`,
              `Ties still existing after this process are broken randomly. (default "random")`,
            ]}
          />
        </div>
      </div>

      <NGFormElement
        name={"autoscaler.unneededTime"}
        label={autoscaler.unneededTime.label}
        value={autoscaler.unneededTime.value}
        onChange={autoscaler.unneededTime.setValue}
        required={autoscaler.unneededTime.isRequired}
        error={autoscaler.unneededTime.error}
        info={["How long a node should be unneeded before it is eligible for scale down (default 10m0s)"]}
      />

      <NGFormElement
        name={"autoscaler.unreadyTime"}
        label={autoscaler.unreadyTime.label}
        value={autoscaler.unreadyTime.value}
        onChange={autoscaler.unreadyTime.setValue}
        required={autoscaler.unreadyTime.isRequired}
        error={autoscaler.unreadyTime.error}
        info={["How long an unready node should be unneeded before it is eligible for scale down (default 20m0s)"]}
      />

      <NGFormElement
        name={"autoscaler.utilizationThreshold"}
        label={autoscaler.utilizationThreshold.label}
        value={autoscaler.utilizationThreshold.value}
        onChange={autoscaler.utilizationThreshold.setValue}
        required={autoscaler.utilizationThreshold.isRequired}
        error={autoscaler.utilizationThreshold.error}
        info={[
          `Sum of cpu or memory of all pods running on the node divided by node's corresponding allocatable resource, below which a node can be considered for scale down (default 0.7)`,
        ]}
      />
    </>
  );
};

export const ProviderAutoscaler = observer(ProviderAutoscalerRaw);
