import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { Download, Upload } from "react-feather";
import { DefaultEnvironmentVariable } from "./defaultEnvironmentVariable/defaultEnvironmentVariable";
import { STORAGE_KEY_DEFAULT_VARIABLES } from "../../../envVariables";
import { EnvVarsMobx } from "../../../mobxDataModels/envVarsModel";
import { FormButtons } from "../../../components/forms/formButtons";
import { parseEnvFile } from "../../../services/utils";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGSwitch } from "../../../newcomponents/switch";
import NGAlert from "../../../newcomponents/alert";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { EnvironmentVariable } from "./environmentVariable";
import { NGLabel } from "../../../newcomponents/text/label";

export interface EnvVarInherit {
  isActive: boolean;
  env: { name: string; value: string }[];
  value: boolean;
  setValue: any;
}

interface Props {
  env_version?: number;
  env_image?: string;
  env_gvc?: string;
  env_gvcAlias?: string;
  env_org?: string;
  env_workload?: string;
  env: EnvVarsMobx;
  filename: string;
  hasDefaultEnv: boolean;
  inherit?: EnvVarInherit;
  saveForm?: {
    isActive: boolean;
    onReset: any;
    onSave: any;
    isLoading: boolean;
    isDirty: boolean;
  };
}

const EnvironmentVariablesRaw: React.FC<Props> = ({
  env_version,
  env_image,
  env_gvc,
  env_gvcAlias,
  env_org,
  env_workload,
  env,
  filename,
  hasDefaultEnv,
  inherit = {
    isActive: false,
    env: [],
    value: false,
    setValue: () => {},
  },
  saveForm = {
    isActive: false,
  },
}) => {
  function getShowDefaultVariables() {
    return localStorage.getItem(STORAGE_KEY_DEFAULT_VARIABLES) === "true";
  }
  const [showDefaultVariables, setShowDefaultVariables] = React.useState(getShowDefaultVariables());
  React.useEffect(() => {
    localStorage.setItem(STORAGE_KEY_DEFAULT_VARIABLES, String(showDefaultVariables));
  }, [showDefaultVariables]);

  const defaultEnvVariables = [
    {
      name: "CPLN_GLOBAL_ENDPOINT", // full url, but the given domain can be different for each user or workload in prod
      value: "@runtime",
    },
    {
      name: "CPLN_GVC", // gvc name
      value: env_gvc || "@runtime",
    },
    {
      name: "CPLN_GVC_ALIAS",
      value: env_gvcAlias || "@runtime",
    },
    {
      name: "CPLN_LOCATION", //*
      value: "@runtime",
    },
    {
      name: "CPLN_NAMESPACE", //*
      value: "@runtime",
    },
    {
      name: "CPLN_PROVIDER", //*
      value: "@runtime",
    },
    {
      name: "CPLN_ORG", // org link
      value: env_org || "@runtime",
    },
    {
      name: "CPLN_WORKLOAD", // workload link
      value: env_workload || "@runtime",
    },
    {
      name: "CPLN_WORKLOAD_VERSION",
      value: String(env_version) || "@runtime",
    },
    {
      name: "CPLN_TOKEN",
      value: "@runtime",
    },
    {
      name: "CPLN_IMAGE",
      value: env_image || "@runtime",
    },
  ];

  function onUploadEnvFile(e: any) {
    const input: HTMLInputElement = e.target;
    if (!input.value || !input.files || input.files.length < 1) {
      input.value = "";
      return;
    }

    const file = input.files[0];
    if (!file) {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
      return;
    }

    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = function (evt) {
      try {
        const content: string = evt.target?.result as string;
        const rawVars = Object.entries(parseEnvFile(content)).map(([name, value]) => ({ name, value }));
        const overriddenKeys = env.addFromFile(rawVars);
        if (overriddenKeys.length > 0) {
          notification.info({
            duration: null,
            message: "Some Variables Were Overridden",
            description: (
              <>
                <div>Overridden Keys Are:</div>
                <ul>
                  {overriddenKeys.map((envVarKey, index) => (
                    <li key={envVarKey + String(index)} className="block my-1">
                      {envVarKey}
                    </li>
                  ))}
                </ul>
              </>
            ),
          });
        }
      } catch (e) {
        console.error(e.message);
        notification.warning({
          message: "Failed",
          description: "File is invalid",
        });
        input.value = "";
      }
    };
    reader.onerror = function () {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
    };
    input.value = "";
  }

  async function onExport() {
    try {
      let blob = ``;
      for (let defaultVar of env.editVars) {
        blob += `${defaultVar.name}=${defaultVar.value}\n`;
      }
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = `${filename}.env`;
      const file = new Blob([blob], { type: "text/plain" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
    } catch (e) {
      notification.warning({ message: "Failed", description: "Try again" });
    }
  }

  function handleCopyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({ message: "Copied environment variable to clipboard" });
  }

  return (
    <>
      <div className="flex items-center mb-4">
        <div className="flex items-center gap-4">
          <div className="text-2xl">Environment Variables</div>
          <NGButton
            variant="action"
            size="small"
            onClick={() => {
              const index = env.add();
              setTimeout(() => {
                const tagValueInput = document.querySelector<HTMLInputElement>(`[data-tagindex="${index}"]`);
                if (tagValueInput) {
                  tagValueInput.focus();
                }
              }, 100);
            }}
            disabled={!env.isValid}
            data-testid="action-add"
          >
            Add
          </NGButton>
          {/* TODO disabled style, tooltip to inform why disabled */}
          <NGButton
            variant="secondary"
            outlined
            size="small"
            tabIndex={0}
            className="cursor-pointer text-sm focus text-link hover:text-link-400 flex items-center"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                document.getElementById("envFile")?.click();
              }
            }}
            renderIcon={(hover, props) => <Download {...props} />}
            onClick={() => document.getElementById("envFile")?.click()}
          >
            Import
            <input
              id="envFile"
              className="invisible"
              style={{ width: 0, height: 0 }}
              onChange={onUploadEnvFile}
              type={"file"}
              multiple={false}
            />
          </NGButton>
          <NGButton
            variant="secondary"
            outlined
            size="small"
            renderIcon={(hover, props) => <Upload {...props} />}
            aria-disabled={env.vars.length === 0}
            disabled={env.vars.length === 0}
            onClick={onExport}
          >
            Export
          </NGButton>
        </div>
        {inherit.isActive ? (
          <div className="flex items-center ml-4">
            <NGSwitch value={inherit.value} onChange={(checked) => inherit.setValue(checked)}>
              <NGLabelText>Inherit GVC</NGLabelText>
            </NGSwitch>
            <InfoTooltip title={["Appends environment variables defined in the GVC to this workload."]} />
          </div>
        ) : null}
        {hasDefaultEnv ? (
          <div className="flex items-center ml-4">
            <NGSwitch value={showDefaultVariables} onChange={(checked) => setShowDefaultVariables(checked)}>
              <NGLabelText>Show Defaults</NGLabelText>
            </NGSwitch>
            <InfoTooltip
              title={[
                "Every workload is injected environment variables with Control Plane Platform related information.",
                "Enable this flag to view them in this page.",
              ]}
            />
          </div>
        ) : null}
      </div>
      <div>
        {env.editVars.length > 0 ? null : (
          <div className={"no-items-found mb-4"}>
            <span>No Environment Variables Found</span>
          </div>
        )}
        <div className="flex flex-col gap-1 mb-10">
          {env.editVars.length > 0 ? (
            <div className="flex items-center">
              <div style={{ width: "calc((100% - 90px) / 3)" }}>
                <NGLabel>Name</NGLabel>
              </div>
              <div className="ml-2" style={{ width: "calc((100% - 90px) / 3 * 2 / 12 * 3)" }}>
                Type
              </div>
              <div style={{ width: "calc((100% - 90px) / 3 * 2 / 12 * 9)" }}>
                <NGLabel>Value</NGLabel>
              </div>
            </div>
          ) : null}
          {env.editVars.map((envVar) => (
            <EnvironmentVariable key={envVar.index} env={env} envVar={envVar} inherit={inherit} />
          ))}
        </div>
        {inherit.isActive && inherit.value ? (
          <div className="mb-10">
            <div className="text-xl mb-2">
              Inherited{" "}
              <span className="text-sm">- You can override by using a custom variable with the same name.</span>
            </div>
            <div className="flex flex-col gap-1">
              {inherit.env.length < 1 ? (
                <div className={"no-items-found"}>
                  <span>No Environment Variables to Inherit</span>
                </div>
              ) : (
                <div className="flex items-center">
                  <div className="w-1/2">
                    <NGLabel>Name</NGLabel>
                  </div>
                  <div className="w-1/2">
                    <NGLabel>Value</NGLabel>
                  </div>
                </div>
              )}
              {inherit.env.map((e) => (
                <DefaultEnvironmentVariable
                  onClick={() => handleCopyToClipboard(`$(${e.name})`)}
                  key={e.name}
                  name={e.name}
                  value={e.value}
                />
              ))}
            </div>
          </div>
        ) : null}
        {hasDefaultEnv && showDefaultVariables ? (
          <div>
            <div className="text-xl mb-2">Default</div>
            <div className="flex flex-col gap-1">
              <div className="flex items-center">
                <div className="w-1/2">
                  <NGLabel>Name</NGLabel>
                </div>
                <div className="w-1/2">
                  <NGLabel>Value</NGLabel>
                </div>
              </div>
              {defaultEnvVariables.map((e) => (
                <DefaultEnvironmentVariable
                  onClick={() => handleCopyToClipboard(`$(${e.name})`)}
                  key={e.name}
                  name={e.name}
                  value={e.value}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {saveForm.isActive && saveForm.isDirty ? (
        <NGAlert
          type="info"
          className="mt-4"
          message="Any change will initiate the deployment of workloads that have the 'inheritEnv' flag enabled in any of their containers"
        />
      ) : null}
      {saveForm.isActive ? (
        <FormButtons
          onReset={saveForm.onReset}
          onSave={saveForm.onSave}
          resetDisabled={saveForm.isLoading || !saveForm.isDirty}
          saveDisabled={saveForm.isLoading || !saveForm.isDirty}
          loading={saveForm.isLoading}
        />
      ) : null}
    </>
  );
};

export const EnvironmentVariables = observer(EnvironmentVariablesRaw);
