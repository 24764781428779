import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { IdentityMobx, IdentityModel } from "../../mst/kinds/identity";
import { IdentityDetail } from "./detail";
import { IdentityDraftModel } from "../../mst/stores/identity.draft";
import { request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";

type RouteParams = "identity";
const IdentityDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { identity: identityName } = useParams<RouteParams>();
  const [identity, setIdentity] = React.useState<IdentityMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);
  const draftRef = React.useRef(IdentityDraftModel.create());
  const draft = draftRef.current;

  React.useEffect(() => {
    fetchItem();
    return () => {
      draft.setAsNew();
    };
  }, [identityName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      let identityInstance: IdentityMobx = null as any;
      const { data } = await request({ url: resourceLink("identity", identityName) });
      identityInstance = IdentityModel.create(data);
      setIdentity(identityInstance!);
      await draft.reset();
      await draft.setAsDraft(identityInstance!);
      setIsLoading(false);
    } catch (e) {
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching identity to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"identity"} canCreate={true} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {identity.name} - Identity - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: identity, fetchItem }}>
        <NGFormContext.Provider value={formDataRef.current}>
          <IdentityDetail identity={identity} identityDraft={draft} />
        </NGFormContext.Provider>
      </DetailContext.Provider>
    </>
  );
};

export const IdentityDetailRoute = observer(IdentityDetailRouteRaw);
