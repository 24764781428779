import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { AlertCircle, PlusCircle } from "react-feather";
import { FormLabel } from "../../components/forms/formLabel";
import { Loader } from "../../components/layout/loader";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { mk8sMobx } from "../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../mst/stores/mk8s.draft";
import { homeLink, request, resourceLink } from "../../services/cpln";
import { randomName } from "../../services/utils";
import { getTemplate } from "./kubeConfigTemplate";
import { NGButton } from "../../newcomponents/button/Button";
import NGAlert from "../../newcomponents/alert";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { NGInput } from "../../newcomponents/input/input";
import { NGLabel } from "../../newcomponents/text/label";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const KubeConfigRaw: React.FC<Props> = ({ mk8s }) => {
  const [isReady, setIsReady] = React.useState(false);
  const [caCert, setCaCert] = React.useState<string>("");
  const [serviceAccName, setServiceAccName] = React.useState("");
  const [newServiceAccName, setNewServiceAccName] = React.useState("");
  const [isCreatingServiceAccount, setIsCreatingServiceAccount] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    prepare();
  }, []);

  async function prepare() {
    setIsReady(false);
    await getCaCert();
    setIsReady(true);
  }

  async function getCaCert() {
    try {
      const { data } = await request({ url: mk8s.selfLink + "/-cacerts" });
      setCaCert(data.cacerts);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed to fetch cacert", description: errorMessage });
      setError(
        "Cannot get cacert for the Kubernetes Cluster item. Please try again or wait until the cluster is ready."
      );
    }
  }

  async function getKey(serviceAccountName: string, keyDescription: string) {
    try {
      const { data }: any = await request({
        method: "post",
        url: resourceLink("serviceaccount", serviceAccountName) + "/-addKey",
        body: { description: keyDescription },
      });
      setError("");
      return data.key;
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed to get service account key", description: errorMessage });
      return "";
    }
  }

  async function createKubeConfig() {
    try {
      setIsLoading(true);
      const serviceAccountKey = await getKey(serviceAccName, randomName());
      if (!serviceAccountKey) {
        setIsLoading(false);
        return;
      }

      const _kubeConfig = getTemplate(
        ConsoleContext.org || "",
        mk8s.name,
        mk8s.alias,
        serviceAccName,
        serviceAccountKey,
        caCert,
        mk8s.status?.serverUrl || ""
      );

      downloadKubeConfig(_kubeConfig);
      setIsLoading(false);

      notification.success({
        message: "Success",
        description: "Generated & Downloaded kubeconfig",
      });
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    }
  }

  function downloadKubeConfig(blob: string) {
    const a = document.createElement("a");
    a.style.display = "none";
    a.classList.add("cpln-temp-a");
    a.download = `${mk8s.name}-kubeconfig.yaml`;
    const file = new Blob([blob], { type: "text/yaml" });
    const href = URL.createObjectURL(file);
    a.href = href;
    a.click();
  }

  if (!isReady) {
    return <Loader reason={"getting cacert"} />;
  }

  if (error) {
    return (
      <div className="flex items-center gap-2">
        <NGAlert title={"Error"} type={"error"} message={error} />
        <NGButton variant={"secondary"} onClick={prepare}>
          Retry
        </NGButton>
      </div>
    );
  }

  return (
    <div>
      <FormLabel size={"base"}>kubeconfig</FormLabel>
      <div className="flex items-center gap-2 mt-4">
        <NGKindSelect
          style={{ width: 450 }}
          kind={"serviceaccount"}
          value={serviceAccName}
          onChange={(value) => setServiceAccName(value)}
          buttons={[
            {
              title: "Create",
              render: () => <PlusCircle className="h-4" />,
              onClick: () => setIsCreatingServiceAccount(true),
            },
          ]}
        />
      </div>
      <div className="flex items-center gap-1 text-sm mt-2 mb-4">
        <AlertCircle className={`feather-icon color-primary`} />
        <div>Create or select a service account. A new key will be created to be used for the kubeconfig file.</div>
      </div>
      <div className="flex items-center gap-2">
        <NGButton
          disabled={!serviceAccName || isLoading}
          loading={isLoading}
          variant="primary"
          style={{ width: 450 }}
          onClick={() => createKubeConfig()}
        >
          Generate & Download kubeconfig
        </NGButton>
      </div>
      {isCreatingServiceAccount ? (
        <Modal
          open={isCreatingServiceAccount}
          title={"Create a Service Account"}
          onCancel={() => {
            setIsCreatingServiceAccount(false);
            setNewServiceAccName("");
          }}
          footer={
            <div className="modal-actions">
              <NGButton
                variant="secondary"
                onClick={() => {
                  setIsCreatingServiceAccount(false);
                  setNewServiceAccName("");
                }}
              >
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                onClick={async () => {
                  setIsLoading(true);
                  try {
                    await request({
                      url: homeLink("serviceaccount"),
                      body: { name: newServiceAccName },
                      method: "post",
                    });
                  } catch (e) {
                    let errorMessage = e?.response?.data?.message;
                    if (!errorMessage) errorMessage = e.message;
                    notification.warning({ message: "Failed to create service account", description: errorMessage });
                  }
                  setIsCreatingServiceAccount(false);
                  setServiceAccName(newServiceAccName);
                  setIsLoading(false);
                }}
                disabled={!newServiceAccName}
              >
                Create
              </NGButton>
            </div>
          }
        >
          <div>
            <NGLabel>New Service Account Name</NGLabel>
            <NGInput value={newServiceAccName} onChange={(e) => setNewServiceAccName(e.target.value)} />
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export const KubeConfig = observer(KubeConfigRaw);
