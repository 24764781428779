import { makeAutoObservable } from "mobx";
import { request } from "../../services/cpln";
import { Org } from "../../schema/types/org";
import { User } from "../user/user";
import { FullMetadata, ListResult, getDownloadURL, getMetadata, getStorage, listAll, ref } from "firebase/storage";

interface CustomLogo {
  slug: string;
  url: string;
}

class CustomLogoContextMobx {
  lightSlug: string = "";
  darkSlug: string = "";
  customLogos: CustomLogo[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  // Called from console context setOrg
  *checkCustomLogo(orgName: string) {
    try {
      this.lightSlug = "";
      this.darkSlug = "";
      if (!orgName) {
        return;
      }

      const orgRes = yield request({ url: `/org/${orgName}` });
      const org: Org = orgRes.data;
      if (!org.tags["cpln/custom-logo"]) {
        return;
      }

      const lightLogoSlug = org.tags["cpln/custom-logo"];
      const darkLogoSlug = org.tags["cpln/custom-logo-dark"] || lightLogoSlug;

      const storage = getStorage(User.getFirebaseApp());
      const storageRef = ref(storage, "custom-logo");
      const allLogos: ListResult = yield listAll(storageRef);
      this.customLogos = [];
      for (const itemRef of allLogos.items) {
        const metadata: FullMetadata = yield getMetadata(itemRef);
        const slug = metadata.customMetadata!.slug;
        if (![lightLogoSlug, darkLogoSlug].includes(slug)) {
          continue;
        }

        const url: string = yield getDownloadURL(itemRef);
        this.customLogos.push({ slug: slug, url: url });
      }

      this.lightSlug = lightLogoSlug;
      this.darkSlug = darkLogoSlug;
    } catch (e) {
      console.log("failed to get custom logos", e.message);
      this.lightSlug = "";
      this.darkSlug = "";
      this.customLogos = [];
    }
  }

  get hasCustomLogo() {
    return !!this.lightSlug;
  }

  get lightLogo(): CustomLogo {
    return this.customLogos.find((c) => c.slug === this.lightSlug)!;
  }

  get darkLogo(): CustomLogo {
    return this.customLogos.find((c) => c.slug === this.darkSlug)!;
  }
}

export const CustomLogoContext = new CustomLogoContextMobx();
