import * as React from "react";
import { homeLink, linksOf, request } from "../../services/cpln";
import NGAlert from "../../newcomponents/alert";
import { Secret } from "../../schema/types/secret";
import { Table } from "../../newcomponents/table/table";

const CPLN_HELM_RELEASE_PREFIX = "cpln-release-";

export const HelmReleasesList: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [releases, setReleases] = React.useState<Secret[]>([]);

  React.useEffect(() => {
    fetchReleases();
  }, []);

  async function fetchReleases() {
    try {
      setIsLoading(true);
      let _items: Secret[] = [];
      let nextLink: string | undefined = undefined;

      const url = homeLink("secret") + "/-query";
      const body = {
        kind: "secret",
        spec: { match: "any", terms: [{ op: "~", property: "name", value: CPLN_HELM_RELEASE_PREFIX }] },
      };
      const { data } = await request({ method: "post", url: url, body: body });
      _items = data.items;
      nextLink = linksOf(data).next;

      while (nextLink) {
        const { data } = await request({ url: nextLink, body: body });
        nextLink = linksOf(data).next;
        _items = _items.concat(data.items);
      }
      setReleases(_items.filter((i) => i.name.startsWith(CPLN_HELM_RELEASE_PREFIX)));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setError(errorMessage);
    }
  }

  if (error) {
    return <NGAlert type={"error"} title="Error" message={error} />;
  }

  return (
    <>
      <Table<Secret>
        tableId="helm-release-list"
        data={releases}
        enableFilter
        title={"Helm Releases"}
        columns={[{ id: "name", label: "Name" }]}
        isLoading={isLoading}
      />
    </>
  );
};
