import React from "react";
import { observer } from "mobx-react-lite";
import { linksOf, request, resourceLink } from "../../../services/cpln";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { Route, useParams } from "react-router-dom";
import { fetchReleaseItems } from "../utils";
import { ngParseLink } from "../../../utils/linkParser/linkParser";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { ReleaseInstance, ReleaseState } from "../types/release";
import { DetailLayout } from "../../../components/detail/layout";
import { DetailContext } from "../../../components/detail/detailContext";
import { ReleaseInfo } from "./info";
import { ReleaseUpgrade } from "./upgrade";
import { ReleaseRevisions } from "./revisions";
import { Loader } from "../../../components/layout/loader";

type RouteParams = "id";
const MarketplaceReleaseDetailRaw: React.FC = () => {
  const { id: releaseId } = useParams<RouteParams>();

  const basePathUpdated = useBasePath(`/marketplace/release/${releaseId}/*`);

  const [isLoading, setIsLoading] = React.useState(true);
  const [release, setRelease] = React.useState<ReleaseInstance>(null as any);

  const [items, setItems] = React.useState<any[]>([]);
  const [itemsNotFound, setItemsNotFound] = React.useState<any[]>([]);
  const [state, setState] = React.useState<ReleaseState>({ deployments: [], release: "", schemaVersion: "" });

  React.useEffect(() => {
    fetchRelease();
  }, []);

  React.useEffect(() => {
    if (!release) {
      return;
    }

    fetchState();
  }, [release]);

  React.useEffect(() => {
    if (!release) {
      return;
    }

    fetchItems();
  }, [state]);

  React.useEffect(() => {
    if (!release) {
      return;
    }

    // TODO Get agent health
    // TODO Get volumeset info
  }, [items]);

  async function fetchRelease() {
    try {
      setIsLoading(true);
      const { data } = await request({
        service: "marketplace",
        url: `/org/${ConsoleContext.org}/release/${releaseId}`,
      });
      setRelease({
        ...data, //
        kind: "marketplace-release",
        // TODO fix
        lastModified: "",
        created: "",
        version: 0,
        tags: {
          "cpln/protected": true,
        },
      });
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
    }
  }

  async function fetchState() {
    try {
      const { data } = await request({
        url: resourceLink("secret", `cpln-release-${release.name}`) + "/-reveal",
      });
      setState(JSON.parse(data.data.payload));
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
    }
  }

  async function fetchItems() {
    try {
      const revision = state.deployments.toSorted((a, b) => {
        if (a.revision > b.revision) return -1;
        if (b.revision > a.revision) return 1;
        return 0;
      })[0];
      const _itemResults = await fetchReleaseItems(revision.resources);
      const _itemsNotFound = _itemResults.filter((_i) => _i.notFound === true);
      const _items = _itemResults.filter((_i) => !_i.notFound);

      const kindOrder = [
        "gvc",
        "workload",
        "volumeset",
        "identity",
        "domain",
        "agent",
        "secret",
        "cloudaccount",
        "group",
        "serviceaccount",
        "policy",
        "auditctx",
      ];

      let _sortedItems: any[] = [];
      for (const kind of kindOrder) {
        _sortedItems = _sortedItems.concat(..._items.filter((i) => i.kind === kind));
      }
      setItems(_sortedItems);
      let _sortedItemsNotFound: any[] = [];
      for (const kind of kindOrder) {
        _sortedItemsNotFound = _sortedItemsNotFound.concat(..._itemsNotFound.filter((i) => i.kind === kind));
      }
      setItemsNotFound(_sortedItemsNotFound);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
      setIsLoading(false);
    }
  }

  const usedGvcs: string[] = [];
  for (const item of items) {
    const { isValid, name: gvcName } = ngParseLink(linksOf(item).gvc || "", { useInputCtx: true });
    if (isValid && !usedGvcs.includes(gvcName)) {
      usedGvcs.push(gvcName);
    }
  }

  if (!release || isLoading) {
    return <Loader reason={"Fetching data"} />;
  }

  return (
    <>
      <BasePathContext.Provider value={basePathUpdated}>
        <DetailContext.Provider value={{ item: release, fetchItem: fetchRelease }}>
          <DetailLayout
            links={[
              { label: "Info", url: "-info" },
              { label: "Upgrade", url: "-upgrade" },
              { label: "Revisions", url: "-revisions" },
            ]}
          >
            <Route
              path={`-info`}
              element={
                <ReleaseInfo release={release} usedGvcs={usedGvcs} items={items} itemsNotFound={itemsNotFound} />
              }
            />
            <Route path={`-upgrade`} element={<ReleaseUpgrade release={release} />} />
            <Route path={`-revisions`} element={<ReleaseRevisions release={release} state={state} />} />
          </DetailLayout>
        </DetailContext.Provider>
      </BasePathContext.Provider>
    </>
  );
};

export const MarketplaceReleaseDetail = observer(MarketplaceReleaseDetailRaw);
