import { types } from "mobx-state-tree";
import isIp from "is-ip";
import { getLetters, getNumbers, parseCronSchedule } from "../services/utils";
import { LoggingCloudWatchModelRetentionDaysOptions } from "../mst/kinds/org";

type validationArray = ((label: string, value: string) => true | string)[];
interface InputValidations {
  name: validationArray;
  email: validationArray;
  envVarName: validationArray;
  url: validationArray;
  domain: validationArray;
  domainWithoutPattern: validationArray;
  subDomain: validationArray;
  containerName: validationArray;
  cidr: validationArray;
  ipv4: validationArray;
  hostname: validationArray;
  deleteString: validationArray;
  awsAccessKey: validationArray;
  awsRoleArn: validationArray;
  awsRoleName: validationArray;
  awsPolicyRef: validationArray;
  awsAccountId: validationArray;
  ecrRepo: validationArray;
  base64: validationArray;
  gcpProjectId: validationArray;
  gcpServiceAccount: validationArray;
  gcpRoleName: validationArray;
  identityNetworkResourceServiceName: validationArray;
  phone: validationArray;
  workingDir: validationArray;
  uuid: validationArray;
  azureResourceGroupName: validationArray;
  azureStorageAccountName: validationArray;
  https: validationArray;
  memoryResource: validationArray;
  awsPrivateLinkEndpointServiceName: validationArray;
  gcpServiceConnectTargetService: validationArray;
  domainRoutePrefix: validationArray;
  domainRouteHostPrefix: validationArray;
  jobSchedule: validationArray;
  coralogix: validationArray;
  natsAccountId: validationArray;
  natsPrivateKey: validationArray;
  ngsPubSub: validationArray;
  ngsTTL: validationArray;
  number: validationArray;
  port: validationArray;
  tracingSampling: validationArray;
  emailAccountPassword: validationArray;
  labelTaintKey: validationArray;
  labelTaintValue: validationArray;
  nodePoolName: validationArray;
  mk8sAwsDeployRoleArn: validationArray;
  mk8sAwsEFSRoleArn: validationArray;
  mk8sAwsAmiExact: validationArray;
  mk8sAwsSecurityGroup: validationArray;
  mk8sAwsSubnet: validationArray;
  mk8sAwsVpcId: validationArray;
  mk8sAwsDiskEncryptionKeyArn: validationArray;
  goDuration: validationArray;
  volumesetSchedule: validationArray;
  cloudWatchRetentionDays: validationArray;
  storageClassSuffix: validationArray;
  mk8sAwsTags: validationArray;
  mk8sHetznerLabelsKey: validationArray;
  mk8sHetznerFloatingIpValidLabel: validationArray;
  envoyDurationString: validationArray;
}
export const inputValidations: InputValidations = {
  name: [
    (label, value) => {
      if (value.length > 64) return `${label} must be less than 64 characters.`;
      if (/^[a-z][-a-z0-9]([-a-z0-9])*[a-z0-9]$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  containerName: [
    (label, value) => {
      if (value.length > 64) return `${label} must be less than 64 characters.`;
      if (/^[a-z][-a-z0-9]([-a-z0-9])*[a-z0-9]$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
    (label, value) => {
      if (value.startsWith("cpln-")) return `${label} cannot start with cpln-`;
      if (["istio-proxy", "queue-proxy", "istio-validation"].includes(value))
        return `${label} cannot be a reserved value.`;
      return true;
    },
  ],
  envVarName: [
    (label, value) => {
      if (value.startsWith("CPLN_")) {
        return `CPLN_* is reserved internally.`;
      }
      if (["K_SERVICE", "K_CONFIGURATION", "K_REVISION"].includes(value)) {
        return `${value} is reserved internally.`;
      }
      if (/^[-._a-zA-Z][-._a-zA-Z0-9]*$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      if (value.length > 120) {
        return `${label} exceeds 120 chars.`;
      }
      return true;
    },
  ],
  url: [
    (label, value) => {
      if (!value.match(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i)) return `${label} must be a URL.`;
      return true;
    },
  ],
  domain: [
    (label, value) => {
      if (value.length > 256) {
        return `${label} is invalid.`;
      }

      const segments = value.split(".");
      for (let i = 0; i < segments.length; ++i) {
        const segment = segments[i];
        if (!segment.length) {
          return `${label} is invalid.`;
        }
      }

      return true;
    },
    (label, value) => {
      const minDomainSegments = 2;
      const segments = value.split(".");
      if (segments.length < minDomainSegments) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  domainWithoutPattern: [
    // domain copy start
    (label, value) => {
      if (value.length > 256) {
        return `${label} is invalid.`;
      }

      const segments = value.split(".");
      for (let i = 0; i < segments.length; ++i) {
        const segment = segments[i];
        if (!segment.length) {
          return `${label} is invalid.`;
        }
      }

      return true;
    },
    (label, value) => {
      const minDomainSegments = 2;
      const segments = value.split(".");
      if (segments.length < minDomainSegments) {
        return `${label} is invalid.`;
      }
      return true;
    },
    // domain copy end
    (label, value) => {
      if (value.includes("*")) {
        return `${label} cannot have wildcard`;
      }

      return true;
    },
  ],
  subDomain: [
    (label, value) => {
      if (value.length > 256) {
        return `${label} is invalid.`;
      }

      const segments = value.split(".");

      for (let i = 0; i < segments.length; ++i) {
        const segment = segments[i];
        if (!segment.length) {
          return `${label} is invalid.`;
        }
      }

      return true;
    },
    (label, value) => {
      const minDomainSegments = 3;
      const segments = value.split(".");
      if (segments.length < minDomainSegments) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  cidr: [
    (_label, value) => {
      let isArray: false | "comma" | "space" = false;
      if (value.trim().includes(",")) {
        isArray = "comma";
      } else if (value.trim().includes(" ")) {
        isArray = "space";
      }
      let arrayToCheck: string[] = [];
      if (isArray) {
        arrayToCheck = value
          .trim()
          .split(isArray === "comma" ? "," : " ")
          .map((x) => x.trim())
          .filter(Boolean);
      } else {
        arrayToCheck.push(value.trim());
      }
      for (const item of arrayToCheck) {
        const res = isValid(item);
        if (res !== true) {
          return res;
        }
      }
      return true;

      function isValid(value: string) {
        const valueIp = value.split("/").shift();
        const valueCIDR = value.split("/").length > 1 ? value.split("/").pop() : "";
        let ipVersion = isIp.version(valueIp);
        if (!ipVersion) return "Invalid IP.";
        const minCIDR = 0;
        let maxCIDR = ipVersion === 4 ? 32 : 128;
        if (!valueCIDR) return true;
        const numberCIDR = Number(valueCIDR);
        if (Number.isNaN(numberCIDR)) return "Invalid CIDR.";
        if (numberCIDR < minCIDR) return "Invalid CIDR.";
        if (numberCIDR > maxCIDR) return "Invalid CIDR.";
        return true;
      }
    },
  ],
  ipv4: [
    (_label, value) => {
      const valueIp = value.split("/").shift();
      let ipVersion = isIp.version(valueIp);
      if (!ipVersion) return "Invalid IP";
      if (String(ipVersion) !== "4") return "Invalid IP.";
      return true;
    },
  ],
  hostname: [
    (label, value) => {
      const dnsRegex = /^(?![0-9]+$)(?!.*-$)([*]?)(?!-)[a-z0-9-.]+$/;
      if (value.match(dnsRegex)) return true;
      return `${label} is invalid.`;
    },
  ],
  email: [
    (label, value) => {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value.match(emailRegex)) return true;
      return `${label} is invalid.`;
    },
  ],
  deleteString: [
    (label, value) => {
      if (value !== "delete") return `${label} must be "delete".`;
      return true;
    },
  ],
  awsAccessKey: [
    (_label, value) => {
      if (!value.match(/^AKIA.*/)) {
        return "Access Key is invalid.";
      }
      return true;
    },
  ],
  awsRoleArn: [
    (_label, value) => {
      if (!value.match(/^arn:.*/)) {
        return "Role Arn is invalid.";
      }
      return true;
    },
  ],
  awsRoleName: [
    (_label, value) => {
      if (!value.match(/^([a-zA-Z0-9/+=,.@_-])+$/)) {
        return "Role Name is invalid.";
      }
      return true;
    },
  ],
  awsPolicyRef: [
    (_label, value) => {
      if (!value.match(/^(aws::)?([a-zA-Z0-9/+=,.@_-])+$/)) {
        return "Policy Ref is invalid.";
      }
      return true;
    },
  ],
  awsAccountId: [
    (_label, value) => {
      if (!value.match(/^\d{12}$/)) return `AWS Account Id is invalid.`;
      return true;
    },
  ],
  ecrRepo: [
    (_label, value) => {
      try {
        const knownRegions = [
          "ap-northeast-1",
          "ap-northeast-2",
          "ap-northeast-3",
          "ap-south-1",
          "ap-southeast-1",
          "ap-southeast-2",
          "ca-central-1",
          "eu-central-1",
          "eu-north-1",
          "eu-west-1",
          "eu-west-2",
          "eu-west-3",
          "sa-east-1",
          "us-east-1",
          "us-east-2",
          "us-west-1",
          "us-west-2",
        ];

        const url = new URL("https://" + value);
        const parts = url.hostname.split(".");
        switch (true) {
          case parts.length != 6:
          case !/[0-9]{12}/.test(parts[0]):
          case parts[1] != "dkr":
          case parts[2] != "ecr":
          case !knownRegions.includes(parts[3]):
          case parts[4] != "amazonaws":
          case parts[5] != "com":
            return `Not valid. Example: ACCOUNT_ID.dkr.ecr.REGION.amazonaws.com/REPO`;
          default:
            return true;
        }
      } catch (e) {
        return `Not valid. Example: ACCOUNT_ID.dkr.ecr.REGION.amazonaws.com/REPO`;
      }
    },
  ],
  base64: [
    (_label, value) => {
      if (value.length < 1) return true;
      const regex = /^[A-Za-z0-9+/\r\n]+={0,2}$/;
      if (value.match(regex)) return true;
      return "Value is not base64.";
    },
  ],
  gcpProjectId: [
    (label, value) => {
      if (value.length < 6) return `${label} must be at least 6 characters.`;
      if (value.match(/[a-z]([a-z]|-|[0-9])+/)) return true;
      return `${label} is invalid.`;
    },
  ],
  gcpServiceAccount: [
    (_label, value) => {
      if (!value.match(/^.*\.gserviceaccount\.com$/)) {
        return `Service Account is invalid.`;
      }
      return true;
    },
  ],
  gcpRoleName: [
    (_label, value) => {
      if (!value.match(/^roles\/([a-zA-Z0-9])+(\.([a-zA-Z0-9])+)?$/)) {
        return `Role Name is invalid.`;
      }
      return true;
    },
  ],
  identityNetworkResourceServiceName: [
    (label, value) => {
      let nameRes: string | true = true;
      let domainRes: string | true = true;
      if (value.length > 64) nameRes = `${label} must be less than 64 characters.`;
      if (/^[a-z][-a-z0-9]([-a-z0-9])*[a-z0-9]$/.test(value) === false) {
        nameRes = `${label} is invalid.`;
      }

      if (value.length > 256) {
        domainRes = `${label} is invalid.`;
      }

      const segments = value.split(".");
      for (let i = 0; i < segments.length; ++i) {
        const segment = segments[i];
        if (!segment.length) {
          domainRes = `${label} is invalid.`;
        }
      }

      const minDomainSegments = 2;
      if (segments.length < minDomainSegments) {
        domainRes = `${label} is invalid.`;
      }

      if (nameRes === true || domainRes === true) {
        return true;
      }
      return nameRes || domainRes;
    },
  ],
  phone: [
    (label, value) => {
      let numberCount = 0;
      for (let char of value) {
        const charNumber = Number(char);
        if (Number.isNaN(charNumber)) {
          continue;
        }
        numberCount += 1;
      }

      if (numberCount < 10) return `${label} should be at least 10 characters.`;
      return true;
    },
  ],
  workingDir: [
    (label, value) => {
      // TODO fix with better libs
      // try {
      //   const normalized = pathNormalize(value);
      //   if (!pathIsAbsolute(normalized)) {
      //     return `${label} must be an absolute path.`;
      //   }
      // } catch (e) {
      //   console.error("validation workingDir failed", e.message);
      //   return `${label} is invalid.`;
      // }
      return true;
    },
  ],
  uuid: [
    (label, value) => {
      const uuidRegex = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/;
      if (value.match(uuidRegex)) return true;
      return `${label} must be in UUID format.`;
    },
  ],
  azureResourceGroupName: [
    (label, value) => {
      const regex = /^[-\w\._\(\)]+$/;
      if (value.match(regex)) return true;
      return `${label} is invalid.`;
    },
  ],
  azureStorageAccountName: [
    (label, value) => {
      const regex = /^[a-z0-9]{3,24}$/;
      if (value.match(regex)) return true;
      return `${label} is invalid.`;
    },
  ],
  https: [
    (label, value) => {
      if (value.startsWith("https://")) return true;
      return `${label} must have https:// scheme.`;
    },
  ],
  memoryResource: [
    (label, value) => {
      const regex = /^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$/;
      if (value.match(regex)) return true;
      return `${label} is invalid.`;
    },
  ],
  awsPrivateLinkEndpointServiceName: [
    (label, value) => {
      const regex = /^com\.amazonaws\.vpce\.[^.]+.vpce-.+$/;
      if (value.match(regex)) return true;
      return `${label} is invalid.`;
    },
  ],
  gcpServiceConnectTargetService: [
    (label, value) => {
      // .custom((value, helpers) => {
      //   let res = _.trimStart(value, '/');
      //   res = _.trimEnd(res, '/');
      //   return res;
      // }),

      // example: projects/coke-267310/regions/us-east1/serviceAttachments/my-service
      const regex = /\/?projects\/(.+)\/regions\/(.+)\/serviceAttachments\/(.+)\/?/;
      if (value.match(regex)) return true;
      return `${label} is invalid.`;
    },
  ],
  domainRoutePrefix: [
    (label, value) => {
      if (value === "/") return true;
      const regex = /^\/[0-9a-zA-Z-\._~\/]*$/;
      if (value.match(regex)) return true;
      return `${label} is invalid.`;
    },
  ],
  domainRouteHostPrefix: [
    (label, value) => {
      const regex = /^[0-9a-zA-Z-\._]*$/;
      if (value.match(regex)) return true;
      return `${label} is invalid.`;
    },
  ],
  jobSchedule: [
    (label, value) => {
      if (value.trim().length < 1) {
        return "Schedule is required";
      }

      if (
        !value.match(
          /^(((\*(\/\d+)?)|(\d+(-\d+)?))(,((\*(\/\d+)?)|(\d+(-\d+)?)))*)(\s(((\*(\/\d+)?)|(\d+(-\d+)?))(,((\*(\/\d+)?)|(\d+(-\d+)?)))*)){4}$/
        )
      ) {
        return `${label} is not in standard cron format.`;
      }
      const pieces = value.split(/\s+/);
      if (pieces.length != 5) {
        return `${label} requires 5 sections.`;
      }

      const errorMsg = checkCronValues(value);
      if (errorMsg) {
        return errorMsg;
      }
      return true;
    },
  ],
  coralogix: [
    (_label, value) => {
      try {
        const patterns = ["{org}", "{gvc}", "{workload}", "{location}"];
        const matchAll = value.matchAll(/\{.+?\}/g);
        let match = matchAll.next();

        do {
          if (!patterns.includes(match.value[0])) {
            return `The pattern contains unsupported var ${match.value}`;
          }
          match = matchAll.next();
        } while (!match.done);

        return true;
      } catch (e) {
        return true;
      }
    },
  ],
  natsAccountId: [
    (label, value) => {
      if (value.length < 1) return `${label} is invalid.`;
      if (value.match(/^A[A-Z0-9]{55}$/)) return true;
      return `${label} is invalid.`;
    },
  ],
  natsPrivateKey: [
    (label, value) => {
      if (value.length < 1) return `${label} is invalid.`;
      if (value.match(/^SA[A-Z0-9]{56}$/)) return true;
      return `${label} is invalid.`;
    },
  ],
  ngsPubSub: [
    (label, value) => {
      if (value.length < 1) return `${label} is invalid.`;
      const fwc = ">";
      const tsep = ".";

      let sfwc = false;
      const tokens = value.split(tsep);

      for (let t of tokens) {
        const length = t.length;
        if (length == 0 || sfwc) {
          return `${label} is invalid.`;
        }
        if (length > 1) {
          if (/\t\n\f\r /.test(t)) {
            return `${label} is invalid.`;
          }
          continue;
        }

        switch (t[0]) {
          case fwc:
            sfwc = true;
            continue;
          case " ":
          case "\t":
          case "\n":
          case "\r":
          case "\f":
            return `${label} is invalid.`;
        }
      }
      return true;
    },
  ],
  ngsTTL: [
    (label, value) => {
      if (value.match(/^[0-9]+(ms|s|m|h)$/)) return true;
      return `${label} is invalid.`;
    },
  ],
  number: [
    // -1 limit is hardcoded, make it customizable when needed
    (label, value) => {
      for (let charIndexStr in value.split("")) {
        const charIndex = Number(charIndexStr);
        const char = value[charIndex];
        if (char === "-" && charIndex === 0) {
          if (value.length < 2) {
            return `${label} must be a number.`;
          }
          continue;
        }
        const charNumber = Number(char);
        if (Number.isNaN(charNumber)) {
          return `${label} must be a number.`;
        }
      }
      if (value) {
        const n = Number(value);
        if (n < -1) {
          return `${label} must be at least -1.`;
        }
      }
      return true;
    },
  ],
  port: [
    (label, value) => {
      const reservedPorts = [8012, 8022, 9090, 9091, 15000, 15001, 15006, 15020, 15021, 15090, 41000];
      const valueNumber = Number(value);
      const valueIsNaN = Number.isNaN(valueNumber);
      if (valueIsNaN) {
        return `${label} must be a number.`;
      }
      if (reservedPorts.includes(valueNumber)) {
        return `Port is reserved.`;
      }
      return true;
    },
  ],
  tracingSampling: [
    (label, value) => {
      const valueNumber = Number(value);
      if (Number.isNaN(valueNumber)) {
        return `${label} must be a number.`;
      }
      if (valueNumber < 0) {
        return `${label} must be 0 or higher.`;
      }
      if (valueNumber < 0) {
        return `${label} must be 100 or fewer.`;
      }
      if (value.includes(".")) {
        const precisionPart = value.split(".")[1];
        if (precisionPart.length > 2) {
          return `Precision can be 2 at most. Ex: 1.23, 7.59`;
        }
      }
      return true;
    },
  ],
  emailAccountPassword: [
    (label, value) => {
      if (value.length < 8) {
        return `Must be at least 8 characters.`;
      }
      return true;
    },
    (label, value) => {
      let hasLowercaseLetter = false;
      for (const letter of value) {
        if (
          getLetters()
            .map((l) => l.toLowerCase())
            .includes(letter)
        ) {
          hasLowercaseLetter = true;
        }
      }
      if (!hasLowercaseLetter) {
        return "Must have a lowercase letter";
      }
      return true;
    },
    (label, value) => {
      let hasUppercaseLetter = false;
      for (const letter of value) {
        if (
          getLetters()
            .map((l) => l.toUpperCase())
            .includes(letter)
        ) {
          hasUppercaseLetter = true;
        }
      }
      if (!hasUppercaseLetter) {
        return "Must have an uppercase letter";
      }
      return true;
    },
    (label, value) => {
      let hasNumber = false;
      for (const letter of value) {
        if (
          getNumbers()
            .map((n) => n.toString())
            .includes(letter)
        ) {
          hasNumber = true;
        }
      }
      if (!hasNumber) {
        return "Must have a number";
      }
      return true;
    },
  ],
  labelTaintKey: [
    (label, value) => {
      // TODO not used
      if (value.length < 1) return `${label} is Required.`;
      if (/^[a-z][-a-z0-9]([-a-z0-9])*[a-z0-9]$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  labelTaintValue: [
    (label, value) => {
      // TODO not used
      if (value.length < 1) return `${label} is Required.`;
      if (/^[a-z][-a-z0-9]([-a-z0-9])*[a-z0-9]$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  nodePoolName: [
    (label, value) => {
      if (value.length < 1) return `${label} is Required.`;
      if (/^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  mk8sAwsDeployRoleArn: [
    (label, value) => {
      if (/^arn:(aws):iam::[0-9]+:role\/.+$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  mk8sAwsEFSRoleArn: [
    (label, value) => {
      if (/^arn:(aws):iam::[0-9]+:role\/.+/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  mk8sAwsAmiExact: [
    (label, value) => {
      if (/^(ami-[0-9a-f]+)$|^(resolve:ssm:.+)$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  mk8sAwsSecurityGroup: [
    (label, value) => {
      if (/^(sg-[a-f0-9]+)$|^(resolve:ssm:.+)$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  mk8sAwsSubnet: [
    (label, value) => {
      if (/^(subnet-[a-f0-9]+)$|^(resolve:ssm:.+)$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  mk8sAwsVpcId: [
    (label, value) => {
      if (/^(vpc-[a-f0-9]+)$|^(resolve:ssm:.+)$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  mk8sAwsDiskEncryptionKeyArn: [
    (label, value) => {
      if (/^(arn:aws:kms:.+)$|^(resolve:ssm:.+)$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  goDuration: [
    (label, value) => {
      if (/^(\d+(?:\.\d+)?)([hms]+)$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  volumesetSchedule: [
    (label, value) => {
      try {
        let parsed = parseCronSchedule(value);
        if (
          parsed.minuteSegments.length > 1 ||
          parsed.minuteSegments[0].wildcard ||
          parsed.minuteSegments[0].absoluteRangeMax != parsed.minuteSegments[0].absoluteRangeMin
        ) {
          return "Snapshots cannot be scheduled more frequently than once an hour";
        }
      } catch (e) {
        return e.message;
      }
      return true;
    },
  ],
  cloudWatchRetentionDays: [
    (label, value) => {
      if (!LoggingCloudWatchModelRetentionDaysOptions.includes(Number(value))) {
        return `Value must be one of '${LoggingCloudWatchModelRetentionDaysOptions.join(", ")}'`;
      }
      return true;
    },
  ],
  storageClassSuffix: [
    (label, value) => {
      if (/^[a-zA-Z][0-9a-zA-Z\-_]*$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  mk8sAwsTags: [
    (label, value) => {
      if (/^[ a-zA-Z0-9=_+:./@-]+$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  mk8sHetznerLabelsKey: [
    (label, value) => {
      if (/^[a-zA-Z0-9/_-]+$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
  mk8sHetznerFloatingIpValidLabel: [
    (label, value) => {
      if (value.includes(",")) {
        return `Item cannot contain ,`;
      }

      if (value.includes("=")) {
        return `Item cannot contain =`;
      }

      return true;
    },
  ],
  envoyDurationString: [
    (label, value) => {
      if (/^\d+(?:\.\d+)?s$/.test(value) === false) {
        return `${label} is invalid.`;
      }
      return true;
    },
  ],
};

export const ValidationKeyMobxProperty = types.maybe(
  types.enumeration([
    "name",
    "email",
    "envVarName",
    "url",
    "domain",
    "domainWithoutPattern",
    "subDomain",
    "containerName",
    "cidr",
    "ipv4",
    "hostname",
    "deleteString",
    "awsAccessKey",
    "awsRoleArn",
    "awsRoleName",
    "awsPolicyRef",
    "awsAccountId",
    "ecrRepo",
    "base64",
    "gcpProjectId",
    "gcpServiceAccount",
    "gcpRoleName",
    "identityNetworkResourceServiceName",
    "phone",
    "workingDir",
    "uuid",
    "azureResourceGroupName",
    "azureStorageAccountName",
    "https",
    "memoryResource",
    "awsPrivateLinkEndpointServiceName",
    "gcpServiceConnectTargetService",
    "domainRoutePrefix",
    "domainRouteHostPrefix",
    "jobSchedule",
    "coralogix",
    "natsAccountId",
    "natsPrivateKey",
    "ngsPubSub",
    "ngsTTL",
    "number",
    "port",
    "tracingSampling",
    "emailAccountPassword",
    "labelTaintKey",
    "labelTaintValue",
    "nodePoolName",
    "mk8sAwsDeployRoleArn",
    "mk8sAwsEFSRoleArn",
    "mk8sAwsAmiExact",
    "mk8sAwsSecurityGroup",
    "mk8sAwsSubnet",
    "mk8sAwsVpcId",
    "mk8sAwsDiskEncryptionKeyArn",
    "goDuration",
    "volumesetSchedule",
    "cloudWatchRetentionDays",
    "storageClassSuffix",
    "mk8sAwsTags",
    "mk8sHetznerLabels",
    "mk8sHetznerFloatingIpValidLabel",
    "envoyDurationString",
  ])
);

function checkCronValues(cronExpression: string): string | undefined {
  const pieces = cronExpression.split(/\s/);
  const err1 = checkCronValuesAgainstRange("minute", extractCronValues(pieces[0]), 0, 59);
  const err2 = checkCronValuesAgainstRange("hour", extractCronValues(pieces[1]), 0, 23);
  const err3 = checkCronValuesAgainstRange("day (month)", extractCronValues(pieces[2]), 1, 31);
  const err4 = checkCronValuesAgainstRange("month", extractCronValues(pieces[3]), 1, 12);
  const err5 = checkCronValuesAgainstRange("day (week)", extractCronValues(pieces[4]), 0, 6);
  if (err1) {
    return err1;
  }
  if (err2) {
    return err2;
  }
  if (err3) {
    return err3;
  }
  if (err4) {
    return err4;
  }
  if (err5) {
    return err5;
  }
  return undefined;
}

function checkCronValuesAgainstRange(
  segmentName: string,
  values: number[],
  min: number,
  max: number
): string | undefined {
  for (const i in values) {
    if (values[i] < min || values[i] > max) {
      return `an invalid ${segmentName} was found: ${values[i]}. Valid ${segmentName} values are in [${min}, ${max}]`;
    }
  }
  return undefined;
}

function extractCronValues(cronSegment: string): number[] {
  let extractedValues: number[] = [];
  const pieces = cronSegment.split(/,/);
  for (const i in pieces) {
    const pieceValues = pieces[i].replace("*/", "").split(/-/);
    if (pieceValues.length > 1 && pieceValues[1] <= pieceValues[0]) {
      throw new Error(`an invalid range was found in the ${cronSegment} segment: ${pieceValues[0]}-${pieceValues[1]}`);
    }
    for (const j in pieceValues) {
      extractedValues.push(parseInt(pieceValues[j]));
    }
  }
  return extractedValues;
}

// TODO
export function getValidationMessageForName(value: string, label?: string) {
  const prefix = label ? `${label} ` : `Value `;
  if (!value) {
    return `${prefix} is required.`;
  }
  if (value.length > 64) {
    return `${prefix} must be less than 64 characters.`;
  }
  if (/^[a-z]/.test(value.slice(0, 1)) === false) {
    return `${prefix} must begin with a character`;
  }

  return undefined;

  // /^[a-z][-a-z0-9]([-a-z0-9])*[a-z0-9]$/
}
