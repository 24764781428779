import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { IS_SENTRY_ENABLED, IS_TEST_OR_DEVELOPMENT } from "../envVariables";

export function setupSentry() {
  if (IS_TEST_OR_DEVELOPMENT) {
    return;
  }
  if (!IS_SENTRY_ENABLED) {
    return;
  }

  const environment = IS_TEST_OR_DEVELOPMENT ? "local" : process.env.REACT_APP_SENTRY_ENVIRONMENT;
  try {
    // @ts-ignore
    const about = require("./../about.json");
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0,
      debug: IS_TEST_OR_DEVELOPMENT && process.env.REACT_APP_SENTRY_ENABLE === "true",
      release: about.version,
      maxBreadcrumbs: 30,
      normalizeDepth: 5,
      environment,
      autoSessionTracking: false,
      beforeBreadcrumb(crumb) {
        if (crumb.category === "console") return null;
        if (crumb.category === "xhr" && crumb.data?.status_code < 400) return null;
        if (crumb.category === "xhr" && crumb.data?.status_code === 403) return null;
        if (crumb.category === "xhr" && crumb.data?.status_code === 404) return null;
        if (crumb.category === "ui.click") return null;
        if (crumb.category === "ui.input") return null;
        return crumb;
      },
    });
  } catch (e) {
    console.error("about.json is not found");
  }
}
