import { observer } from "mobx-react-lite";
import * as React from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { NGButton } from "../../../../../newcomponents/button/Button";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { NGSelect } from "../../../../../newcomponents/select/ngselect";
import { NGInput } from "../../../../../newcomponents/input/input";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";

type RouteParams = "index";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderLambdalabsUnmanagedNodePoolRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const l = mk8sDraft.provider_lambdalabs;
  const np = l.unmanagedNodePools.find((np) => np.index === index);

  if (!np) {
    return <Navigate to={`${pathname.split("/-unmanaged-node-pools")[0]}/-unmanaged-node-pools`} />;
  }

  const pre = `lambdalabs.unmanagedNodePools[${index}].`;

  return (
    <div>
      <NGFormElement
        name={`${pre}name`}
        label={np.name.label}
        value={np.name.value}
        onChange={np.name.setValue}
        required
        error={!l.isUnmanagedNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
      />
      <NGInputListMst
        label="Labels"
        className="mb-8"
        data={np.labels}
        firstInput={(item) => (
          <NGInput
            required
            invalid={!item.firstValue}
            ignoreTouched
            placeholder="Key"
            value={item.firstValue}
            onChange={(e) => item.setFirstValue(e.target.value)}
          />
        )}
        secondInput={(item) => (
          <NGInput
            required
            invalid={!item.secondValue}
            ignoreTouched
            placeholder="Value"
            value={item.secondValue}
            onChange={(e) => item.setSecondValue(e.target.value)}
          />
        )}
      />
      <NGInputListMst
        label="Taints"
        data={np.taints}
        firstInput={(item) => (
          <NGInput
            required
            invalid={!item.firstValue}
            ignoreTouched
            placeholder="Key"
            value={item.firstValue}
            onChange={(e) => item.setFirstValue(e.target.value)}
          />
        )}
        secondInput={(item) => (
          <NGInput
            required
            invalid={!item.secondValue}
            ignoreTouched
            placeholder="Value"
            value={item.secondValue}
            onChange={(e) => item.setSecondValue(e.target.value)}
          />
        )}
        thirdInput={(item) => (
          <NGSelect
            placeholder="Effect"
            invalid={!item.thirdValue}
            ignoreTouched
            value={item.thirdValue}
            onChange={(value) => item.setThirdValue(value)}
            options={[
              { label: "NoSchedule", value: "NoSchedule" },
              { label: "PreferNoSchedule", value: "PreferNoSchedule" },
              { label: "NoExecute", value: "NoExecute" },
            ]}
          />
        )}
      />
      <NGButton
        style={{ width: 220 }}
        variant={"danger"}
        outlined
        onClick={() => {
          l.removeUnmanagedNodePoolAt(np.index);
          navigate(`${pathname.split("/-unmanaged-node-pools")[0]}/-unmanaged-node-pools`);
        }}
      >
        Delete Node Pool
      </NGButton>
    </div>
  );
};

export const Mk8sCreateProviderLambdalabsUnmanagedNodePool = observer(Mk8sCreateProviderLambdalabsUnmanagedNodePoolRaw);
