import clsx from "clsx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { InnerButtonProps, NGInput, NGInputProps } from "../input/input";
import { NGError } from "../text/error";
import { NGFormLabel } from "../text/formLabel";
import { NGSelect, NGSelectProps } from "../select/ngselect";
import { NGAutocomplete, NGAutocompleteProps } from "../select/ngautocomplete";
import { NGCombobox, NGComboboxProps } from "../select/ngcombobox";
import { NGKindSelect, NGKindSelectProps } from "../select/ngkindselect";
import { useNGFormContext } from "../../reactContexts/ngFormContext";
import { Kind } from "../../mst/base";
import { InfoTooltip } from "../../components/InfoTooltip";
import { gvcScopedKinds } from "../../utils/kinds";

// interface Props<T = any> {
interface Props {
  // options
  // required

  name: string;
  label: string;
  labelRender?: () => React.ReactElement;
  placeholder?: string;
  description?: string;
  info?: string[];
  as?: "input" | "select" | "autocomplete" | "combobox" | "kindselect"; // | "multiline"
  value: string;
  onChange: (value: string) => void;
  kind?: Kind;
  error?: string;
  required?: boolean;
  innerButtons?: InnerButtonProps[];
  inputProps?: Omit<NGInputProps, "value" | "onChange" | "placeholder">;
  kindSelectProps?: Omit<NGKindSelectProps<any>, "value" | "onChange" | "kind" | "placeholder">;
  options?: any[];
  selectProps?: Omit<NGSelectProps<any>, "value" | "onChange" | "options" | "placeholder">;
  autocompleteProps?: Omit<NGAutocompleteProps<any>, "value" | "onChange" | "options" | "placeholder">;
  comboboxProps?: Omit<NGComboboxProps<any>, "value" | "onChange" | "options" | "placeholder">;
  className?: string;
  style?: React.CSSProperties;
}
const NGFormElementRaw: React.FC<Props> = ({
  name,
  label,
  labelRender,
  placeholder,
  description,
  info,
  as = "input",
  value,
  onChange,
  kind,
  error,
  required,
  innerButtons,
  inputProps,
  kindSelectProps,
  options,
  selectProps,
  autocompleteProps,
  comboboxProps,
  className,
  style,
}) => {
  const formData = useNGFormContext();

  const ignoreTouched = inputProps?.ignoreTouched || selectProps?.ignoreTouched || false;

  const showError = !ignoreTouched && formData ? formData.get(name).touched : true;

  return (
    <div className={clsx("mb-4", className)} style={style}>
      <div className="flex items-center gap-2">
        <NGFormLabel name={name} required={required} invalid={(required && !value) || !!error}>
          {label}
        </NGFormLabel>
        {labelRender ? labelRender() : null}
      </div>
      {description ? <div>{description}</div> : null}
      {/* must be different for multiline */}
      <div className="flex items-center">
        {as === "input" ? (
          <NGInput
            {...inputProps!}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            name={name}
            placeholder={placeholder}
            required={required}
            invalid={showError && !!error}
            style={{ width: 450 }}
            buttons={innerButtons}
          />
        ) : as === "select" ? (
          <NGSelect
            {...selectProps!}
            value={value}
            onChange={onChange}
            options={options!}
            name={name}
            placeholder={placeholder}
            invalid={showError && !!error}
            style={{ width: 450 }}
            buttons={innerButtons}
          />
        ) : as === "autocomplete" ? (
          <NGAutocomplete
            {...autocompleteProps!}
            value={value}
            onChange={onChange}
            options={options!}
            name={name}
            placeholder={placeholder}
            invalid={showError && !!error}
            style={{ width: 450 }}
            buttons={innerButtons}
          />
        ) : as === "combobox" ? (
          <NGCombobox
            {...comboboxProps!}
            value={value}
            onChange={onChange}
            options={options!}
            name={name}
            placeholder={placeholder}
            invalid={showError && !!error}
            style={{ width: 450 }}
            buttons={innerButtons}
          />
        ) : as === "kindselect" ? (
          <NGKindSelect
            {...kindSelectProps!}
            value={value}
            onChange={onChange}
            kind={kind!}
            fetchAll={gvcScopedKinds.includes(kind!)}
            name={name}
            placeholder={placeholder}
            invalid={showError && !!error}
            style={{ width: 450 }}
            buttons={innerButtons}
          />
        ) : null}
        {info ? <InfoTooltip title={info} /> : null}
      </div>
      <div className="ngformelement-error" style={{ width: 450 }}>
        {showError ? (
          required && !value ? (
            <NGError>{label} is required</NGError>
          ) : error ? (
            <NGError>{error}</NGError>
          ) : null
        ) : null}
      </div>
    </div>
  );
};

export const NGFormElement = observer(NGFormElementRaw);
