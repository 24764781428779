import * as React from "react";
import { observer } from "mobx-react-lite";
import { DomainDraftMobx } from "../../../mst/stores/domain.draft";
import { DomainDraftPortMobx } from "../../../mst/stores/domain.draft.port";
import { DomainPortRouteMobx } from "../../../mst/stores/domain.draft.port.route";
import { request } from "../../../services/cpln";
import { NGInput } from "../../../newcomponents/input/input";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";
import { NGAutocomplete } from "../../../newcomponents/select/ngautocomplete";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { Workload } from "../../../schema/types/workload/workload";

// TODO changed type here

interface Props {
  draft: DomainDraftMobx;
  port: DomainDraftPortMobx;
  route: DomainPortRouteMobx;
}
const DomainNewSpecPortRouteRaw: React.FC<Props> = ({ draft, port, route }) => {
  React.useEffect(() => {
    if (route.workloadName.length < 3) {
      return;
    }
    updatePortType();
  }, [route.workloadName, port.resetCounter]);

  async function updatePortType() {
    const options: string[] = [];
    try {
      const { data } = await request<Workload>({ url: route.workloadLink });
      // TODO can containers be undefined?
      for (let container of data.spec?.containers || []) {
        if (container.ports) {
          for (let port of container.ports) {
            options.push(String(port.number));
          }
        }
        if (container.port) {
          options.push(String(container.port));
        }
      }
      if (route.portInput.value && !options.some((o) => o === route.portInput.value)) {
        options.push(String(route.portInput.value));
      }
      route.setPortOptions(options);
      if (options.length > 0) {
        route.setPortType("select");
      } else {
        route.setPortType("input");
      }
    } catch (e) {
      route.setPortOptions(options);
      route.setPortType("input");
    }
  }

  return (
    <div className="mb-2 relative flex items-center gap-2">
      <NGSelect
        style={{ width: 100 }}
        value={route.method.value}
        onChange={route.method.setValue}
        options={route.method.options}
      />
      {route.method.value === "prefix" ? (
        <NGInput
          style={{ width: 150 }}
          value={route.prefix.value}
          onChange={(e) => route.prefix.setValue(e.target.value)}
        />
      ) : (
        <NGInput
          style={{ width: 150 }}
          value={route.regex.value}
          onChange={(e) => route.regex.setValue(e.target.value)}
        />
      )}
      <NGInput
        style={{ width: 150 }}
        value={route.replacePrefix.value}
        onChange={(e) => route.replacePrefix.setValue(e.target.value)}
      />
      {draft.routeGvcHasDedicatedLoadBalancer ? (
        <NGInput
          style={{ width: 150 }}
          value={route.hostPrefix.value}
          onChange={(e) => route.hostPrefix.setValue(e.target.value)}
        />
      ) : null}

      <div className="flex-grow">
        <NGKindSelect
          value={route.workloadName}
          onChange={route.setWorkloadName}
          kind={"workload"}
          fetchAll
          // extraInfoFns={[(item: Workload) => item.spec?.type!]}
          queries={[{ rel: "gvc", value: draft.routeGvcName }]}
        />
      </div>

      {route.portType === "input" ? (
        <NGInput
          style={{ width: 110 }}
          value={route.portInput.value}
          onChange={(e) => route.portInput.setValue(e.target.value)}
        />
      ) : (
        <NGAutocomplete
          style={{ width: 110 }}
          value={route.portInput.value}
          options={route.portOptions.map((v) => ({ label: v, value: v }))}
          onChange={(value) => route.portInput.setValue(value)}
        />
      )}

      <button onClick={() => port.removeRoute(route.id)} className={`inline-block h-full ngfocus color-danger ml-2`}>
        Remove
      </button>
    </div>
  );
};

export const DomainNewSpecPortRoute = observer(DomainNewSpecPortRouteRaw);
