import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentMethodAdd } from "./paymentMethods/add";
import { PaymentMethodsList } from "./paymentMethods/list";
import { observer } from "mobx-react-lite";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

const BillingAccountPaymentMethodsRaw: React.FC = () => {
  const navigate = useNavigate();
  const { AccountUUID, PaymentMethods, Roles } = BillingContext;

  React.useEffect(() => {
    if (!Roles.includes("billing_admin") && PaymentMethods.length < 1) {
      navigate(`/billing/account/${AccountUUID}`);
      return;
    }
  }, []);

  if (!Roles.includes("billing_admin") && PaymentMethods.length < 1) {
    return null;
  }

  return (
    <Routes>
      {Roles.includes("billing_admin") ? (
        <Route
          path={`-add`}
          element={
            <Elements stripe={stripePromise}>
              <PaymentMethodAdd />
            </Elements>
          }
        />
      ) : null}
      <Route index element={<PaymentMethodsList />} />
    </Routes>
  );
};

export const BillingAccountPaymentMethods = observer(BillingAccountPaymentMethodsRaw);
