import * as React from "react";
import { observer } from "mobx-react-lite";
import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../services/cpln";
import { DryRunAlert } from "../../dryRunAlert";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGRadioGroup } from "../../../../newcomponents/radioGroup";
import { NGSelect } from "../../../../newcomponents/select/ngselect";
import { NGButton } from "../../../../newcomponents/button/Button";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { useNGFormContext } from "../../../../reactContexts/ngFormContext";
import { NGProviderBrowser } from "../../ngProviderBrowser";
import { MoreHorizontal } from "react-feather";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGFormLabel } from "../../../../newcomponents/text/formLabel";
import { AwsSsmIcon } from "../../awsSsmIcon";

type RouteParams = "index";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderAWSNodePoolRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [browserKey, setBrowserKey] = React.useState("");
  const formData = useNGFormContext();

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const a = mk8sDraft.provider_aws;
  const np = a.nodePools.find((np) => np.index === index);

  // TODO fix
  // React.useEffect(() => {
  //   const minString = np.minSize.value;
  //   const maxString = np.maxSize.value;
  //   const min = Number(minString);
  //   const max = Number(maxString);
  //   if (!Number.isNaN(min)) {
  //     np.onDemandBaseCapacity.setMin(min);
  //   }
  //   if (!Number.isNaN(max)) {
  //     np.onDemandBaseCapacity.setMax(max);
  //   }
  // }, [np.minSize.value, np.maxSize.value]);

  if (!np) {
    return <Navigate to={`${pathname.split("/-node-pools")[0]}/-node-pools`} />;
  }

  const pre = `aws.nodePools[${index}].`;

  return (
    <>
      <div>
        <NGFormElement
          name={`${pre}name`}
          label={np.name.label}
          value={np.name.value}
          onChange={np.name.setValue}
          required
          error={!a.isNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
        />

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}minSize`}
            label={np.minSize.label}
            value={np.minSize.value}
            onChange={np.minSize.setValue}
            required={np.minSize.isRequired}
            error={np.minSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.minSize.setValue}
              path={`spec.provider.aws.nodePools[${index}].minSize`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}maxSize`}
            label={np.maxSize.label}
            value={np.maxSize.value}
            onChange={np.maxSize.setValue}
            required={np.maxSize.isRequired}
            error={np.maxSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.maxSize.setValue}
              path={`spec.provider.aws.nodePools[${index}].maxSize`}
            />
          </div>
        </div>

        <NGFormLabel
          name={np.overrideImage.type === "recommended" ? `${pre}image.recommended` : `${pre}image.exact`}
          invalid={np.overrideImage.type === "exact" && !np.overrideImage.exact.isValid}
        >
          Override Image
        </NGFormLabel>
        <NGRadioGroup
          options={[
            { label: "Recommended", value: "recommended" },
            { label: "Exact", value: "exact" },
          ]}
          value={np.overrideImage.type}
          onChange={(value) => np.overrideImage.setType(value as any)}
        />
        <div className="mb-4 flex items-start gap-2">
          {np.overrideImage.type === "recommended" ? (
            <NGSelect
              name={`${pre}image.recommended`}
              style={{ width: 450, minWidth: 450 }}
              value={np.overrideImage.recommended}
              onChange={np.overrideImage.setRecommended}
              allowEmpty
              options={a._recommendedAmis.map((ami) => ({ label: ami, value: ami }))}
            />
          ) : np.overrideImage.type === "exact" ? (
            <NGInput
              name={`${pre}image.exact`}
              style={{ width: 450, minWidth: 450 }}
              value={np.overrideImage.exact.value}
              onChange={(e) => np.overrideImage.exact.setValue(e.target.value)}
            />
          ) : null}

          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            path={`spec.provider.aws.nodePools[${index}].overrideImage.${np.overrideImage.type}`}
            paths={[
              `spec.provider.aws.nodePools[${index}].overrideImage`,
              `spec.provider.aws.nodePools[${index}].overrideImage.exact`,
              `spec.provider.aws.nodePools[${index}].overrideImage.recommended`,
            ]}
          />
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}bootDiskSize`}
            label={np.bootDiskSize.label}
            value={np.bootDiskSize.value}
            onChange={np.bootDiskSize.setValue}
            required={np.bootDiskSize.isRequired}
            error={np.bootDiskSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.bootDiskSize.setValue}
              path={`spec.provider.aws.nodePools[${index}].bootDiskSize`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}onDemandBaseCapacity`}
            label={np.onDemandBaseCapacity.label}
            value={np.onDemandBaseCapacity.value}
            onChange={np.onDemandBaseCapacity.setValue}
            required={np.onDemandBaseCapacity.isRequired}
            error={np.onDemandBaseCapacity.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.onDemandBaseCapacity.setValue}
              path={`spec.provider.aws.nodePools[${index}].onDemandBaseCapacity`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}onDemandPercentageAboveBaseCapacity`}
            label={np.onDemandPercentageAboveBaseCapacity.label}
            value={np.onDemandPercentageAboveBaseCapacity.value}
            onChange={np.onDemandPercentageAboveBaseCapacity.setValue}
            required={np.onDemandPercentageAboveBaseCapacity.isRequired}
            error={np.onDemandPercentageAboveBaseCapacity.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.onDemandPercentageAboveBaseCapacity.setValue}
              path={`spec.provider.aws.nodePools[${index}].onDemandPercentageAboveBaseCapacity`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}spotAllocationStrategy`}
            as={"select"}
            label={np.spotAllocationStrategy.label}
            options={np.spotAllocationStrategy.options}
            value={np.spotAllocationStrategy.value}
            onChange={np.spotAllocationStrategy.setValue}
            required={true}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.spotAllocationStrategy.setValue}
              path={`spec.provider.aws.nodePools[${index}].spotAllocationStrategy`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGInputListMst
            label="Instance Types"
            className="mb-8"
            styles={{ container: { width: 450, minWidth: 450 } }}
            data={np.instanceTypes}
            required
            error={np.instanceTypes.items.length < 1 ? "At least 1 instance type is required" : ""}
            invalid={!np.isInstanceTypesValid}
            firstInput={(item) => (
              <NGInput
                required
                invalid={!item.firstValue}
                ignoreTouched
                placeholder="Key"
                value={item.firstValue}
                onChange={(e) => item.setFirstValue(e.target.value)}
                buttons={[
                  {
                    title: "Browse",
                    onClick: () => setBrowserKey("instanceType"),
                    render: () => <MoreHorizontal />,
                  },
                ]}
              />
            )}
          />
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            path={`spec.provider.aws.nodePools[${index}].instanceTypes`}
            paths={np.instanceTypes.items.map(
              (_, iIndex) => `spec.provider.aws.nodePools[${index}].instanceTypes[${iIndex}]`,
            )}
          />
        </div>

        {/* TODO fix metadata */}
        {/* 
      label browse 
      list item remove
      input add
      */}
        {/* TODO fix metadata */}
        {/* <div className="flex flex-col gap-2 mb-2">
          {np.instanceTypes.map((instanceType, instanceTypeIndex) => {
            let metadata: any = mk8sDraft.provider_aws._instanceTypes.find((i) => i.instanceType === instanceType);

            return (
              <div className="flex items-start gap-2">
                <NGTextArea key={instanceType} disabled value={instanceType} style={{ width: 450 }} />
                <NGButton onClick={() => np.removeInstanceTypeAt(instanceTypeIndex)} variant={"danger"} outlined>
                  Remove
                </NGButton>
                {metadata ? (
                <div
                  className="px-2 py-1 border ml-2 flex items-center text-sm"
                  style={{ height: 34, transform: "translateY(-4px)" }}
                >
                  <div>{metadata.cpuType}</div>
                  <span className="mr-1">, </span>
                  <div>{metadata.cores} Cores</div>
                  <span className="mr-1">, </span>
                  <div>{metadata.memory} Gi Mem</div>
                  <span className="mr-1">, </span>
                  <div>{metadata.storage}</div>
                </div>
              ) : null}
              </div>
            );
          })}
        </div> */}

        <div className="flex gap-2 items-start">
          <NGInputListMst
            label="Subnet IDs"
            labelRender={() => <AwsSsmIcon />}
            className="mb-8"
            styles={{ container: { width: 450, minWidth: 450 } }}
            data={np.subnetIds}
            required
            error={np.subnetIds.items.length < 1 ? "At least 1 subnet id is required" : ""}
            invalid={!np.isSubnetIdsValid}
            firstInput={(item) => (
              <NGInput
                required
                invalid={!item.firstValue}
                ignoreTouched
                placeholder="Key"
                value={item.firstValue}
                onChange={(e) => item.setFirstValue(e.target.value)}
                buttons={[
                  {
                    title: "Browse",
                    onClick: () => setBrowserKey("subnetId"),
                    render: () => <MoreHorizontal />,
                  },
                ]}
              />
            )}
          />
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            path={`spec.provider.aws.nodePools[${index}].subnetIds`}
            paths={np.subnetIds.items.map((_, sIndex) => `spec.provider.aws.nodePools[${index}].subnetIds[${sIndex}]`)}
          />
        </div>
        <div className="flex gap-2 items-start">
          <NGInputListMst
            label="Extra Security Group IDs"
            labelRender={() => <AwsSsmIcon />}
            className="mb-8"
            styles={{ container: { width: 450, minWidth: 450 } }}
            data={np.extraSecurityGroupIds}
            firstInput={(item) => (
              <NGInput
                required
                invalid={!item.firstValue}
                ignoreTouched
                placeholder="Key"
                value={item.firstValue}
                onChange={(e) => item.setFirstValue(e.target.value)}
                buttons={[
                  {
                    title: "Browse",
                    onClick: () => setBrowserKey("extraSecurityGroupId"),
                    render: () => <MoreHorizontal />,
                  },
                ]}
              />
            )}
          />
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            path={`spec.provider.aws.nodePools[${index}].extraSecurityGroupIds`}
          />
        </div>
        <NGInputListMst
          label="Labels"
          className="mb-8"
          data={np.labels}
          firstInput={(item) => (
            <NGInput
              required
              invalid={!item.firstValue}
              ignoreTouched
              placeholder="Key"
              value={item.firstValue}
              onChange={(e) => item.setFirstValue(e.target.value)}
            />
          )}
          secondInput={(item) => (
            <NGInput
              required
              invalid={!item.secondValue}
              ignoreTouched
              placeholder="Value"
              value={item.secondValue}
              onChange={(e) => item.setSecondValue(e.target.value)}
            />
          )}
        />
        <NGInputListMst
          label="Taints"
          data={np.taints}
          firstInput={(item) => (
            <NGInput
              required
              invalid={!item.firstValue}
              ignoreTouched
              placeholder="Key"
              value={item.firstValue}
              onChange={(e) => item.setFirstValue(e.target.value)}
            />
          )}
          secondInput={(item) => (
            <NGInput
              required
              invalid={!item.secondValue}
              ignoreTouched
              placeholder="Value"
              value={item.secondValue}
              onChange={(e) => item.setSecondValue(e.target.value)}
            />
          )}
          thirdInput={(item) => (
            <NGSelect
              placeholder="Effect"
              invalid={!item.thirdValue}
              ignoreTouched
              value={item.thirdValue}
              onChange={(value) => item.setThirdValue(value)}
              options={[
                { label: "NoSchedule", value: "NoSchedule" },
                { label: "PreferNoSchedule", value: "PreferNoSchedule" },
                { label: "NoExecute", value: "NoExecute" },
              ]}
            />
          )}
        />
        <NGButton
          style={{ width: 220 }}
          variant={"danger"}
          outlined
          onClick={() => {
            a.removeNodePoolAt(np.index);
            navigate(`${pathname.split("/-node-pools")[0]}/-node-pools`);
          }}
        >
          Delete Node Pool
        </NGButton>
      </div>
      {browserKey === "instanceType" ? (
        <NGProviderBrowser
          width={1550}
          title="Browse AWS Instance Types"
          distinctFilters={["Cores", "Hypervisor", "Arch", "GPU Type", "GPU Memory"]}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: `instance-types-${mk8sDraft.provider_aws.region.value}`,
                cluster: mk8sDraft.asObject,
              },
            });
            let instanceTypes = data.data;
            instanceTypes = instanceTypes.sort((a: any, b: any) => {
              return a.price - b.price || a.cores - b.cores || a.memory - b.memory || a.instanceType - b.instanceType;
            });
            return instanceTypes;
          }}
          multipleChoice
          onOk={(values) => np.instanceTypes.setItems((values as string[]).map((v) => ({ firstValue: v })))}
          onClose={() => setBrowserKey("")}
          rightAlign={["Cost per Month"]}
          labels={[
            "Name",
            "CPU Type",
            "Cores",
            "Memory",
            "GPU Type",
            "GPU Memory",
            "Storage",
            "Hypervisor",
            "Arch",
            "Cost per Month",
          ]}
          sortable
          // arch: "arm64"
          // clockSpeed: "2.5 GHz"
          // cores: 16
          // cpuType: "AWS Graviton2 Processor"
          // dedicatedEbsThroughput: "4500 Mbps"
          // gpuMemory: 0
          // gpuType: ""
          // gpus: 0
          // hypervisor: "nitro"
          // instanceFamily: "Memory optimized"
          // instanceType: "r6g.4xlarge"
          // maxPods: 234
          // memory: 128
          // network: "Up to 10 Gigabit"
          // price: 710.14
          // storage: "EBS only"

          getData={(item: any) => {
            return [
              item.instanceType,
              item.instanceType,
              item.cpuType,
              item.cores,
              [item.memory, `${item.memory}Gi`],
              [item.gpuType, item.gpuType || "-"],
              [item.gpuMemory, item.gpuMemory ? item.gpuMemory : "-"],
              item.storage,
              item.hypervisor,
              item.arch,
              [item.price, `$${item.price.toFixed(2)}`],
            ];
          }}
        />
      ) : null}
      {browserKey === "subnetId" ? (
        <NGProviderBrowser
          title="Browse AWS Subnets"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ec2-subnets",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.Subnets;
          }}
          multipleChoice
          onOk={(values) => np.subnetIds.setItems((values as string[]).map((v) => ({ firstValue: v })))}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Subnet ID", "Availability Zone", "CIDR"]}
          getData={(item: any) => {
            let name = "-";
            const nameTag = (item.Tags || []).find((t: any) => t.Key === "Name");
            if (nameTag) {
              name = nameTag.Value;
            }
            return [item.SubnetId, name, item.SubnetId, item.AvailabilityZone, item.CidrBlock];
          }}
        />
      ) : null}
      {browserKey === "extraSecurityGroupId" ? (
        <NGProviderBrowser
          title="Browse AWS Security Groups"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ec2-securityGroups",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.SecurityGroups;
          }}
          multipleChoice
          onOk={(values) => np.extraSecurityGroupIds.setItems((values as string[]).map((v) => ({ firstValue: v })))}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Description", "Security Group ID"]}
          getData={(item: any) => [item.GroupId, item.GroupName, item.Description, item.GroupId]}
        />
      ) : null}
    </>
  );
};

export const ProviderAWSNodePool = observer(ProviderAWSNodePoolRaw);
