import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../../providerAutoscaler";
import { ProviderPreInstallScript } from "../../../providerPreInstallScript";
import { ProviderNetworking } from "../../../providerNetworking";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../../newcomponents/input/input";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { getDryRunErrorText } from "../../../getDryRunErrorText";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderLinodeAdvancedRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const l = mk8sDraft.provider_linode!;

  const authorizedKeysDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    canShow: true,
    path: "spec.provider.linode.authorizedKeys",
    paths: l.authorizedKeys.items.map((_, idx) => `spec.provider.linode.authorizedKeys[${idx}]`),
  });

  return (
    //
    <>
      <NGInputListMst
        data={l.authorizedKeys}
        styles={{ header: { width: "unset" } }}
        className="mb-4"
        firstInput={(i) => (
          // TODOmk8s make this multiline
          <NGInput ignoreTouched value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />
        )}
        label="Authorized Keys"
        invalid={authorizedKeysDryRun.severity === "error"}
        error={authorizedKeysDryRun.severity === "error" ? authorizedKeysDryRun.message : ""}
        warning={authorizedKeysDryRun.severity === "error" ? "" : authorizedKeysDryRun.message}
      />
      <div className="mb-4">
        <ProviderPreInstallScript provider="linode" preInstallScript={l.preInstallScript} />
      </div>
      <div className="mb-4">
        <ProviderNetworking clusterDraft={mk8sDraft} networking={l.networking} />
      </div>
      <div className="mb-4">
        <ProviderAutoscaler autoscaler={l.autoscaler} />
      </div>
    </>
  );
};

export const Mk8sCreateProviderLinodeAdvanced = observer(Mk8sCreateProviderLinodeAdvancedRaw);
