import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { defaultValues } from "../base";
import { Mk8sDraftUnmanagedNodePoolModel, Mk8sDraftUnmanagedNodePoolReadOnlyModel } from "./mk8s.draft.provider.common";
import {
  Mk8sDraftProviderHetznerNodePoolModel,
  Mk8sDraftProviderHetznerNodePoolReadonlyModel,
} from "./mk8s.draft.provider.hetzner.nodePool";
import { Mk8sDraftAutoscalerModel } from "./mk8s.draft.providerAutoscaler";
import { Mk8sDraftNetworkingModel } from "./mk8s.draft.providerNetworking";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { inputValidations } from "../../mobxDataModels/validations";

export const Mk8sDraftProviderHetznerModel = types
  .model({
    _region: types.optional(types.string, ""),
    region: types.optional(StringModel, () => StringModel.create({ label: "Region", isRequired: true })),
    _hetznerLabels: types.optional(types.frozen(), {}),
    hetznerLabels: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _floatingIPSelector: types.optional(types.frozen(), {}),
    floatingIPSelector: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _preInstallScript: types.optional(types.string, ""),
    preInstallScript: types.optional(StringModel, () => StringModel.create({ label: "Pre Install Script" })),
    _tokenSecretLink: types.optional(types.string, ""),
    tokenSecretName: types.optional(types.string, ""),
    _networkId: types.optional(types.string, ""),
    networkId: types.optional(StringModel, () => StringModel.create({ label: "Network Id", isRequired: true })),
    _firewallId: types.optional(types.string, ""),
    firewallId: types.optional(StringModel, () => StringModel.create({ label: "Firewall Id" })),
    _image: types.optional(types.string, ""),
    image: types.optional(StringModel, () => StringModel.create({ label: "Image" })),
    _sshKey: types.optional(types.string, ""),
    sshKey: types.optional(StringModel, () => StringModel.create({ label: "SSH Key Id" })),
    _nodePools: types.array(Mk8sDraftProviderHetznerNodePoolReadonlyModel),
    nodePools: types.array(Mk8sDraftProviderHetznerNodePoolModel),
    autoscaler: types.optional(Mk8sDraftAutoscalerModel, () => Mk8sDraftAutoscalerModel.create()),
    networking: types.optional(Mk8sDraftNetworkingModel, () => Mk8sDraftNetworkingModel.create()),
    removed: types.optional(types.boolean, false),
    _dedicatedServerNodePools: types.array(Mk8sDraftUnmanagedNodePoolReadOnlyModel),
    dedicatedServerNodePools: types.array(Mk8sDraftUnmanagedNodePoolModel),
    removedDedicated: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get _tokenSecretName() {
      const { name: tokenSecretName } = ngParseLink(self._tokenSecretLink, { kind: "secret" });
      return tokenSecretName;
    },
    get tokenSecretLink() {
      const { absolute } = ngParseLink(self.tokenSecretName, { kind: "secret" });
      return absolute;
    },
    get hetznerLabelsAsObject() {
      let res: any = {};
      for (let item of self.hetznerLabels.items) {
        res[item.firstValue] = item.secondValue;
      }
      return res;
    },
    get floatingIpSelectorAsObject() {
      let res: any = {};
      for (let item of self.floatingIPSelector.items) {
        res[item.firstValue] = item.secondValue;
      }
      return res;
    },
  }))
  .actions((self) => ({
    reset() {
      self.preInstallScript.setInitialValue(self._preInstallScript);
      self.region.setInitialValue(self._region);
      self.tokenSecretName = self._tokenSecretName;
      self.networkId.setInitialValue(self._networkId);
      self.firewallId.setInitialValue(self._firewallId);
      self.image.setInitialValue(self._image || defaultValues.mk8s.provider.hetzner.image);
      self.sshKey.setInitialValue(self._sshKey);

      self.hetznerLabels = ListOfItemsModel.create({
        _items: Object.entries(self._hetznerLabels).map((entry) => ({
          firstValue: entry[0],
          secondValue: entry[1] as string,
        })),
      });

      self.floatingIPSelector = ListOfItemsModel.create({
        _items: Object.entries(self._floatingIPSelector).map((entry) => ({
          firstValue: entry[0],
          secondValue: entry[1] as string,
        })),
      });

      self.nodePools.clear();
      for (let index in self._nodePools) {
        const _nodePool = self._nodePools[index];

        self.nodePools.push(
          Mk8sDraftProviderHetznerNodePoolModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          })
        );
      }
      self.removed = false;

      self.dedicatedServerNodePools.clear();
      for (let index in self._dedicatedServerNodePools) {
        const _nodePool = self._dedicatedServerNodePools[index];

        self.dedicatedServerNodePools.push(
          Mk8sDraftUnmanagedNodePoolModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          })
        );
      }
      self.removedDedicated = false;

      self.autoscaler.reset();
      self.networking.reset();
    },
    confirm() {
      self.preInstallScript.confirm();
      self._preInstallScript = self.preInstallScript.value;
      self.region.confirm();
      self._region = self.region.value;
      self._tokenSecretLink = self.tokenSecretLink;
      self.networkId.confirm();
      self._networkId = self.networkId.value;
      self.firewallId.confirm();
      self._firewallId = self.firewallId.value;
      self.image.confirm();
      self._image = self.image.value;
      self.sshKey.confirm();
      self._sshKey = self.sshKey.value;

      self._hetznerLabels = self.hetznerLabelsAsObject;
      self.hetznerLabels.confirm();

      self._floatingIPSelector = self.floatingIpSelectorAsObject;
      self.floatingIPSelector.confirm();

      self._nodePools.clear();
      for (let nodePool of self.nodePools) {
        nodePool.confirm();
        self._nodePools.push(
          Mk8sDraftProviderHetznerNodePoolReadonlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({ key: key, value: value })) as any,
            taints: nodePool.asObject.taints,
            serverType: nodePool.serverType.value,
            minSize: String(nodePool.minSize.value).length > 0 ? Number(nodePool.minSize.value) : undefined,
            maxSize: String(nodePool.maxSize.value).length > 0 ? Number(nodePool.maxSize.value) : undefined,
          })
        );
      }
      self.removed = false;

      self._dedicatedServerNodePools.clear();
      for (let nodePool of self.dedicatedServerNodePools) {
        nodePool.confirm();
        self._dedicatedServerNodePools.push(
          Mk8sDraftUnmanagedNodePoolReadOnlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({ key: key, value: value })) as any,
            taints: nodePool.asObject.taints,
          })
        );
      }
      self.removedDedicated = false;

      self.autoscaler.confirm();
      self.networking.confirm();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setTokenSecretName(value: string) {
      self.tokenSecretName = value;
    },
    addNodePool() {
      let index = 0;
      if (self.nodePools.length > 0) {
        index = self.nodePools[self.nodePools.length - 1].index + 1;
      }
      self.nodePools.push(
        Mk8sDraftProviderHetznerNodePoolModel.create({
          index,
          status: "added",
        })
      );
    },
    removeNodePoolAt(index: number) {
      const node = self.nodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removed = true;
      }
      self.nodePools.remove(node);
    },
    addDedicatedServerNodePool() {
      let index = 0;
      if (self.dedicatedServerNodePools.length > 0) {
        index = self.dedicatedServerNodePools[self.dedicatedServerNodePools.length - 1].index + 1;
      }
      self.dedicatedServerNodePools.push(
        Mk8sDraftUnmanagedNodePoolModel.create({
          index,
          status: "added",
        })
      );
    },
    removeDedicatedServerNodePoolAt(index: number) {
      const node = self.dedicatedServerNodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removedDedicated = true;
      }
      self.dedicatedServerNodePools.remove(node);
    },
  }))
  .views((self) => ({
    isNodePoolNameValid(index: number) {
      let res = true;
      const node = self.nodePools.find((np) => np.index === index)!;
      const pools = [...self.nodePools.filter((np) => np.index !== index).slice()];
      if (pools.some((np) => np.name.value === node.name.value)) {
        res = false;
      }
      return res;
    },
    get anyNodePoolNameConflicts() {
      for (const nodePool of self.nodePools) {
        if (
          [...self.nodePools.filter((np) => np.index !== nodePool.index).slice()].some(
            (np) => np.name.value === nodePool.name.value
          )
        ) {
          return true;
        }
      }
      return false;
    },
    isDedicatedServerNodePoolNameValid(index: number) {
      let res = true;
      const node = self.dedicatedServerNodePools.find((np) => np.index === index)!;
      const pools = [...self.dedicatedServerNodePools.filter((np) => np.index !== index).slice()];
      if (pools.some((np) => np.name.value === node.name.value)) {
        res = false;
      }
      return res;
    },
    get anyDedicatedServerNodePoolNameConflicts() {
      for (const nodePool of self.dedicatedServerNodePools) {
        if (
          [...self.dedicatedServerNodePools.filter((np) => np.index !== nodePool.index).slice()].some(
            (np) => np.name.value === nodePool.name.value
          )
        ) {
          return true;
        }
      }
      return false;
    },
    get isHetznerLabelsValid() {
      let res = true;
      if (self.hetznerLabels.items.length > 10) res = false;
      for (const item of self.hetznerLabels.items) {
        if (item.firstValue.length < 1) {
          res = false;
          break;
        }
        if (item.firstValue.length > 63) {
          res = false;
          break;
        }
        if (item.secondValue.length > 255) {
          res = false;
          break;
        }
        // TODO move to a validate function
        for (const validate of inputValidations.mk8sHetznerLabelsKey) {
          const validationResKey = validate("", item.firstValue);
          if (validationResKey !== true) {
            res = false;
            break;
          }
        }
      }
      return res;
    },
    get hetznerLabelsErrorMessage(): string {
      let res = "";
      if (self.hetznerLabels.items.length > 10) res = "Cannot have more than 10 items.";
      for (const item of self.hetznerLabels.items) {
        if (item.firstValue.length < 1) {
          res = "Key cannot be empty.";
          break;
        }
        if (item.firstValue.length > 63) {
          res = "Key must be less than 64 characters.";
          break;
        }
        if (item.secondValue.length > 255) {
          res = "Value must be less than 256 characters.";
          break;
        }
        // TODO move to a validate function
        for (const validate of inputValidations.mk8sHetznerLabelsKey) {
          const validationResKey = validate("", item.firstValue);
          if (validationResKey !== true) {
            res = validationResKey;
            break;
          }
        }
      }
      return res;
    },
    get isFloatingIPSelectorValid() {
      let res = true;
      for (const item of self.floatingIPSelector.items) {
        if (item.firstValue.length < 1) {
          res = false;
          break;
        }
        // TODO move to a validate function
        for (const validate of inputValidations.mk8sHetznerFloatingIpValidLabel) {
          const validationResKey = validate("", item.firstValue);
          if (validationResKey !== true) {
            res = false;
            break;
          }
          const validationResValue = validate("", item.secondValue);
          if (validationResValue !== true) {
            res = false;
            break;
          }
        }
      }
      return res;
    },
    get floatingIPSelectorErrorMessage(): string {
      let res = "";
      for (const item of self.floatingIPSelector.items) {
        if (item.firstValue.length < 1) {
          res = "Key cannot be empty.";
          break;
        }
        // TODO move to a validate function
        for (const validate of inputValidations.mk8sHetznerFloatingIpValidLabel) {
          const validationResKey = validate("", item.firstValue);
          if (validationResKey !== true) {
            res = validationResKey;
            break;
          }
          const validationResValue = validate("", item.secondValue);
          if (validationResValue !== true) {
            res = validationResValue;
            break;
          }
        }
      }
      return res;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self._nodePools.length !== self.nodePools.length) res = true;
      if (self.removed) res = true;
      if (self.nodePools.some((n) => n.isDirty)) res = true;
      if (self._dedicatedServerNodePools.length !== self.dedicatedServerNodePools.length) res = true;
      if (self.removedDedicated) res = true;
      if (self.dedicatedServerNodePools.some((n) => n.isDirty)) res = true;
      if (self.region.isDirty) res = true;
      if (self.hetznerLabels.isDirty) res = true;
      if (self.floatingIPSelector.isDirty) res = true;
      if (self.tokenSecretName !== self._tokenSecretName) res = true;
      if (self.networkId.isDirty) res = true;
      if (self.firewallId.isDirty) res = true;
      if (self.image.isDirty) res = true;
      if (self.sshKey.isDirty) res = true;
      if (self.autoscaler.isDirty) res = true;
      if (self.networking.isDirty) res = true;
      if (self.preInstallScript.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self._nodePools.length !== self.nodePools.length) reason = "node pool length";
      if (self.removed) reason = "removed";
      if (self.nodePools.some((n) => n.isDirty))
        reason = self.nodePools.find((n) => n.isDirty)?.dirtyReason || "node pools has dirty";
      if (self._dedicatedServerNodePools.length !== self.dedicatedServerNodePools.length)
        reason = "dedicated pool length";
      if (self.removedDedicated) reason = "removed dedicated";
      if (self.dedicatedServerNodePools.some((n) => n.isDirty))
        reason = self.dedicatedServerNodePools.find((n) => n.isDirty)?.dirtyReason || "dedicated pool has dirty";
      if (self.region.isDirty) reason = "region";
      if (self.hetznerLabels.isDirty) reason = "hetznerLabels";
      if (self.floatingIPSelector.isDirty) reason = "floatingIpSelector";
      if (self.tokenSecretName !== self._tokenSecretName) reason = "token secret link";
      if (self.networkId.isDirty) reason = "network id";
      if (self.firewallId.isDirty) reason = "firewall id";
      if (self.image.isDirty) reason = "image";
      if (self.sshKey.isDirty) reason = "ssh key";
      if (self.autoscaler.isDirty) reason = self.autoscaler.dirtyReason;
      if (self.networking.isDirty) reason = self.networking.dirtyReason;
      if (self.preInstallScript.isDirty) reason = "preInstallScript";
      return reason;
    },
    get isValid() {
      let res = true;
      if (self.anyNodePoolNameConflicts) res = false;
      if (self.anyDedicatedServerNodePoolNameConflicts) res = false;
      if (self.nodePools.some((n) => !n.isValid)) res = false;
      if (self.dedicatedServerNodePools.some((n) => !n.isValid)) res = false;
      if (!self.isHetznerLabelsValid) res = false;
      if (!self.isFloatingIPSelectorValid) res = false;
      if (!self.region.isValid) res = false;
      if (!self.tokenSecretName) res = false;
      if (!self.networkId.isValid) res = false;
      if (!self.firewallId.isValid) res = false;
      if (!self.image.isValid) res = false;
      if (!self.sshKey.isValid) res = false;
      if (!self.autoscaler.isValid) res = false;
      if (!self.networking.isValid) res = false;
      if (!self.preInstallScript.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.anyNodePoolNameConflicts) reason = "node pool name conflict";
      if (self.anyDedicatedServerNodePoolNameConflicts) reason = "node pool name conflict";
      if (self.nodePools.some((n) => !n.isValid)) reason = self.nodePools.find((n) => !n.isValid)?.invalidReason || "";
      if (self.dedicatedServerNodePools.some((n) => !n.isValid))
        reason = self.dedicatedServerNodePools.find((n) => !n.isValid)?.invalidReason || "";
      if (!self.isHetznerLabelsValid) reason = "hetzner labels";
      if (!self.isFloatingIPSelectorValid) reason = "floating ip selector";
      if (!self.region.isValid) reason = "region";
      if (!self.image.isValid) reason = "image";
      if (!self.autoscaler.isValid) reason = self.autoscaler.invalidReason;
      if (!self.networking.isValid) reason = self.networking.invalidReason;
      if (!self.preInstallScript.isValid) reason = "preInstallScript";
      return reason;
    },
    get asObject() {
      let obj: any = {
        region: self.region.value,
        hetznerLabels: self.hetznerLabelsAsObject,
        tokenSecretLink: self.tokenSecretLink,
        networkId: self.networkId.value,
        autoscaler: self.autoscaler.asObject,
        networking: self.networking.asObject,
        preInstallScript: self.preInstallScript.value,
        nodePools: self.nodePools.map((n) => n.asObject),
        dedicatedServerNodePools: self.dedicatedServerNodePools.map((n) => n.asObject),
      };

      if (self.floatingIPSelector.items.length > 0) {
        obj.floatingIPSelector = self.floatingIpSelectorAsObject;
      }

      if (self.image.value) {
        obj.image = self.image.value || defaultValues.mk8s.provider.hetzner.image;
      }

      if (self.firewallId.value) {
        obj.firewallId = self.firewallId.value;
      }

      if (self.sshKey.value) {
        obj.sshKey = self.sshKey.value;
      }
      return obj;
    },
  }));
export interface Mk8sDraftProviderHetznerMobx extends Instance<typeof Mk8sDraftProviderHetznerModel> {}
