import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { linksOf, request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST, VOLUMESET_COMMANDS_INTERVAL_MS } from "../../envVariables";
import { VolumeSetMobx, VolumeSetModel } from "../../mst/kinds/volumeset";
import { VolumeSetDetail } from "./detail";
import { getDraftVolumeSet, VolumeSetDraftMobx } from "../../mst/stores/volumeset.draft";
import { Command } from "../../mst/kinds/command";
import { VolumesetCommandsContext } from "../../reactContexts/volumesetCommandsContext";
import { v4 as uuidv4 } from "uuid";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";

type RouteParams = "volumeset";
const VolumeSetDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { volumeset: volumeSetName } = useParams<RouteParams>();
  const [volumeSet, setVolumeSet] = React.useState<VolumeSetMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);
  const draftRef = React.useRef<VolumeSetDraftMobx>(null as any);

  const [commands, setCommands] = React.useState<Command[]>([]);
  const [isLoadingCommands, setIsLoadingCommands] = React.useState(false);
  const [hashCommands, setHashCommands] = React.useState(uuidv4());
  async function fetchCommands() {
    try {
      setIsLoadingCommands(true);
      const { data } = await request({ url: `${volumeSet.selfLink}/-command` });
      let _nextLink: undefined | string;
      let _commands = data.items;
      _nextLink = linksOf(data).next;
      while (!!_nextLink) {
        const { data: nextData } = await request({ url: _nextLink });
        _commands = _commands.concat(nextData.items);
        _nextLink = linksOf(nextData).next;
      }
      setCommands(_commands);
      setHashCommands(uuidv4());
      setIsLoadingCommands(false);
    } catch (e) {
      setIsLoadingCommands(false);
    }
  }
  React.useEffect(() => {
    if (volumeSet) {
      fetchCommands();
    }
    const intervalId = setInterval(() => {
      fetchCommands();
    }, VOLUMESET_COMMANDS_INTERVAL_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, [volumeSet]);

  React.useEffect(() => {
    fetchItem();
  }, [volumeSetName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data: volumeSetRes } = await request({ url: resourceLink("volumeset", volumeSetName) });
      console.log("%c%s", "color: #5200cc", "voluemsets: ", volumeSetRes);
      const volumeSetInstance: VolumeSetMobx = VolumeSetModel.create(volumeSetRes);
      setVolumeSet(volumeSetInstance);
      draftRef.current = getDraftVolumeSet(volumeSetInstance);
      setIsLoading(false);
    } catch (e) {
      console.error(e.message);
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching volumeset to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"volumeset"} canCreate={true} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {volumeSet.name} - Volume Set - From many clouds, one™
        </title>
      </Helmet>
      <NGFormContext.Provider value={formDataRef.current}>
        <DetailContext.Provider value={{ item: volumeSet, fetchItem }}>
          <VolumesetCommandsContext.Provider
            value={{
              fetchCommands: fetchCommands,
              commands: commands,
              isLoading: isLoadingCommands,
              hashCommands: hashCommands,
            }}
          >
            <VolumeSetDetail volumeSet={volumeSet} volumeSetDraft={draftRef.current} />
          </VolumesetCommandsContext.Provider>
        </DetailContext.Provider>
      </NGFormContext.Provider>
    </>
  );
};

export const VolumeSetDetailRoute = observer(VolumeSetDetailRouteRaw);
