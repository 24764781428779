import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { DetailLayout } from "../../components/detail/layout";
import { Info } from "./info";
import { Events } from "../../components/detail/events";
import { AccessReport } from "../../components/detail/accessReport";
import { useDetailContext } from "../../components/detail/detailContext";
import { DomainDNS } from "./dns";
import { DomainSpec } from "./spec";
import { TagsNew } from "../../components/detail/tagsNew";
import { DomainMobx, DomainStatus } from "../../mst/kinds/domain";
import { AuditTrail } from "../../components/detail/auditTrail";
import { DomainEditNewRoute } from "./create_new/edit_new_route";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";
interface Props {
  status: DomainStatus | null;
  isLoadingStatus: boolean;
}
const DomainDetailRaw: React.FC<Props> = ({ status, isLoadingStatus }) => {
  const basePath = useBasePath("/domain/:domain/*");

  const { item } = useDetailContext();
  const domain = item as DomainMobx;

  const eventlogHook = useEventlogs(domain.selfLink);

  const [links] = React.useState<{ label: string; url: string }[]>([
    { label: "Info", url: "-info" },
    { label: "Spec", url: "-spec" },
    { label: "DNS", url: "-dns" },
    { label: "Tags", url: "-tags" },
    { label: "Events", url: "-events" },
    { label: "Audit Trail", url: `-audittrail` },
    { label: "Access Report", url: "-accessreport" },
    { label: "Edit", url: "-edit" },
  ]);

  return (
    <>
      <BasePathContext.Provider value={basePath}>
        <DetailLayout links={links as any}>
          <Route path={`-info`} element={<Info status={status} isLoadingStatus={isLoadingStatus} />} />
          <Route path={`-spec`} element={<DomainSpec />} />
          <Route path={`-dns`} element={<DomainDNS />} />
          <Route path={`-tags`} element={<Tags link={domain.selfLink} tags={domain.tags} />} />
          <Route
            path={`-events`}
            element={
              <Events
                kind={"domain"}
                eventlogs={eventlogHook.eventlogs}
                isLoading={eventlogHook.isLoading}
                fetchEventlogs={eventlogHook.fetchEventlogs}
              />
            }
          />
          <Route path={`-audittrail`} element={<AuditTrail kind="domain" resourceId={item.id} />} />
          <Route path={`-accessreport`} element={<AccessReport itemLink={(item as any).selfLink} />} />
          <Route path={`-edit`} element={<DomainEditNewRoute />} />
        </DetailLayout>
      </BasePathContext.Provider>
    </>
  );
};

export const DomainDetail = observer(DomainDetailRaw);
