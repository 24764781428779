import { StringModel, StringMobx } from "./stringModel";

interface Props {
  value?: string;
  label?: string;
}
export const NameModel = {
  create: (props?: Props): StringMobx => {
    if (!props) props = {};
    return StringModel.create({
      label: props.label || "Name",
      examples: ["foo", "bar-123"],
      isRequired: true,
      initialValue: props.value,
      validationKey: "name",
      transformKey: "lowerCase",
    });
  },
};
