import * as React from "react";
import { Deployment } from "../../schema/types/workload/deployment";
import { linksOf, parentLink, request, resourceLink } from "../../services/cpln";
import { sortBy } from "lodash";
import { ngParseLink } from "../../utils/linkParser/linkParser";

interface WorkloadDeploymentsMap {
  [_: string]: Deployment[];
}

interface DeploymentsReducerType {
  state: DeploymentsReducerState;
  dispatch: React.Dispatch<DeploymentsReducerAction>;
  fetchDeployments: (workloadNames: string[], intervalMs?: number) => () => void;
}

export function useDeploymentsReducer(): DeploymentsReducerType {
  const initialState: DeploymentsReducerState = {
    workloadName: "",
    // TODO support workloadLink to support showing workloads from all gvcs
    requestedWorkloadNames: [],
    deploymentsMap: {},
    lastModified: new Date().toISOString(),
  };
  const [state, dispatch] = React.useReducer(deploymentsReducer, initialState);

  async function _fetchDeployments(workloadNames: string[]) {
    try {
      // TODO support multiple gvcs
      if (workloadNames.length > 1) {
        const url = parentLink("workload") + "/-deployment";
        const batchResponse = await request({ url: url });
        const fetchedWorkloadNames: string[] = [];
        for (const deployment of batchResponse.data.items) {
          const workloadLink = linksOf(deployment).workload;
          if (!workloadLink) {
            continue;
          }
          const { name: fetchedWorkloadName } = ngParseLink(workloadLink, { useInputCtx: true });
          if (!fetchedWorkloadNames.includes(fetchedWorkloadName)) {
            fetchedWorkloadNames.push(fetchedWorkloadName);
          }
        }
        for (const fetchedWorkloadName of fetchedWorkloadNames) {
          dispatch({
            type: DeploymentsReducerActionType.REGISTER_REQUESTED_WORKLOAD,
            payload: { workloadName: fetchedWorkloadName, deployments: [] },
          });
        }
        dispatch({
          type: DeploymentsReducerActionType.BATCH_DEPLOYMENT,
          payload: { workloadName: "", deployments: batchResponse.data.items },
        });
      } else {
        const workloadName = workloadNames[0];

        const workloadLink = resourceLink("workload", workloadName);
        const deploymentLink = workloadLink + "/deployment";
        const { data } = await request({ url: deploymentLink });
        const deployments = data.items;

        dispatch({
          type: DeploymentsReducerActionType.SET_DEPLOYMENTS,
          payload: { workloadName: workloadName, deployments: deployments },
        });
      }
    } catch (e) {}
  }

  function fetchDeployments(workloadNames: string[], intervalMs = 0) {
    _fetchDeployments(workloadNames);
    if (intervalMs < 1) {
      return () => {};
    }
    const id = setInterval(() => {
      _fetchDeployments(workloadNames);
    }, intervalMs);
    return () => {
      clearInterval(id);
    };
  }

  // TODO can move starting a websocket and returning its unsubscribe

  return { state, dispatch, fetchDeployments };
}

export enum DeploymentsReducerActionType {
  SET_DEPLOYMENTS = "SET_DEPLOYMENTS",
  UPDATE_DEPLOYMENT = "UPDATE_DEPLOYMENT",
  CLEAR_DEPLOYMENTS = "CLEAR_DEPLOYMENTS",
  BATCH_DEPLOYMENT = "BATCH_DEPLOYMENT",
  REGISTER_REQUESTED_WORKLOAD = "REGISTER_REQUESTED_WORKLOADT",
}

interface DeploymentsReducerAction {
  type: DeploymentsReducerActionType;
  payload: { workloadName: string; deployments: Deployment[] };
}

interface DeploymentsReducerState {
  workloadName: string;
  requestedWorkloadNames: string[];
  deploymentsMap: WorkloadDeploymentsMap;
  lastModified: string;
}

function deploymentsReducer(state: DeploymentsReducerState, action: DeploymentsReducerAction): DeploymentsReducerState {
  const { type, payload } = action;

  const now = new Date().toISOString();

  const requestedWorkloadNames = [...state.requestedWorkloadNames];
  if (!requestedWorkloadNames.includes(payload.workloadName) && !!payload.workloadName) {
    requestedWorkloadNames.push(payload.workloadName);
  }

  switch (type) {
    case DeploymentsReducerActionType.CLEAR_DEPLOYMENTS:
      return {
        ...state,
        requestedWorkloadNames: [],
        deploymentsMap: {},
        lastModified: now,
      };
    case DeploymentsReducerActionType.SET_DEPLOYMENTS:
      return {
        ...state,
        requestedWorkloadNames: requestedWorkloadNames,
        deploymentsMap: {
          ...state.deploymentsMap,
          [payload.workloadName]: sortDeployments(payload.deployments),
        },
        lastModified: now,
      };
    case DeploymentsReducerActionType.UPDATE_DEPLOYMENT:
      const workloadDeployments: Deployment[] = state.deploymentsMap[payload.workloadName] || [];
      const deployment = payload.deployments[0];

      let oldDeployment: Partial<Deployment> = {};
      const indexOf = workloadDeployments.findIndex((d) => d.name === deployment.name);
      if (indexOf >= 0) {
        oldDeployment = JSON.parse(JSON.stringify(workloadDeployments[indexOf]));
        workloadDeployments.splice(indexOf, 1);
      }
      workloadDeployments.push(Object.assign(oldDeployment, deployment, { kind: "deployment" }));

      return {
        ...state,
        requestedWorkloadNames: requestedWorkloadNames,
        deploymentsMap: {
          ...state.deploymentsMap,
          [payload.workloadName]: sortDeployments(workloadDeployments),
        },
        lastModified: now,
      };
    case DeploymentsReducerActionType.BATCH_DEPLOYMENT:
      const deploymentsMap: { [_: string]: Deployment[] } = {};
      for (const deployment of payload.deployments) {
        const deploymentWorkloadName = ngParseLink(linksOf(deployment).workload!, { useInputCtx: true }).name;
        if (!deploymentsMap[deploymentWorkloadName]) {
          deploymentsMap[deploymentWorkloadName] = [];
        }
        deploymentsMap[deploymentWorkloadName].push(deployment);
      }

      return {
        ...state,
        requestedWorkloadNames: requestedWorkloadNames,
        deploymentsMap: deploymentsMap,
        lastModified: now,
      };
    case DeploymentsReducerActionType.REGISTER_REQUESTED_WORKLOAD:
      return {
        ...state,
        requestedWorkloadNames: requestedWorkloadNames,
      };
    default:
      return state;
  }
}

// by deployment name and status version
function sortDeployments(deployments: Deployment[]): Deployment[] {
  const res = sortBy(
    deployments.map((deployment) => {
      if (deployment.status?.versions) {
        deployment.status.versions = sortBy(deployment.status.versions, "workload").toReversed();
      }
      return deployment;
    }),
    "name",
  );
  return res;
}
