import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const CreateAddonsAWSEFSRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const v = mk8sDraft.addon_awsEfs.roleArn;
  return (
    <div>
      <div className="mb-2">AWS EFS</div>
      <NGFormElement
        name={"awsEfs"}
        label={v.label}
        value={v.value}
        onChange={v.setValue}
        error={v.error}
        inputProps={{ ignoreTouched: true }}
      />
    </div>
  );
};

export const CreateAddonsAWSEFS = observer(CreateAddonsAWSEFSRaw);
