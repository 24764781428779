import { observer } from "mobx-react-lite";
import * as React from "react";
import { mk8sMobx } from "../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import NGAlert from "../../../newcomponents/alert";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const AddonsAWSELBRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const v = mk8sDraft.addon_awsElb.roleArn;
  return (
    <div>
      <div className="mb-2">AWS ELB</div>
      <NGFormElement
        name={"awsElb"}
        label={v.label}
        value={v.value}
        onChange={v.setValue}
        error={v.error}
        inputProps={{ ignoreTouched: true }}
      />
      {mk8sDraft.provider.value === "aws" ? <NGAlert title="Role ARN is optional with AWS Provider." /> : null}
    </div>
  );
};

export const AddonsAWSELB = observer(AddonsAWSELBRaw);
