import * as React from "react";
import { observer } from "mobx-react-lite";
import { FormLabel } from "../../../../components/forms/formLabel";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { InfoTooltip } from "../../../../components/InfoTooltip";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderGenericRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const g = mk8sDraft.provider_generic;
  if (!g) {
    return null;
  }

  return (
    //
    <div className="flex flex-col gap-4">
      <div>
        <FormLabel>Provider</FormLabel>
        <span>Generic</span>
      </div>
      <div>
        <div className="flex items-center">
          <FormLabel>Location</FormLabel>
          <InfoTooltip title="It is not allowed to change the location of an Mk8s cluster with Generic provider" />
        </div>
        <span>{g.location.value}</span>
      </div>
    </div>
  );
};

export const ProviderGeneric = observer(ProviderGenericRaw);
