import React, { PropsWithChildren, useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  useHover,
  useDismiss,
  useRole,
  useInteractions,
  flip,
  FloatingPortal,
  Placement,
  useClick,
} from "@floating-ui/react";
import "./index.scss";
import clsx from "clsx";

type TooltipTriggerOption = "click" | "hover";
export interface NGTooltipProps extends PropsWithChildren {
  title?: string;
  message?: string;
  render?: () => React.ReactElement;
  // NOTE prop will accept either of (title, render) (title, message) (message) (render)
  placement?: Placement;
  delayMs?: number;
  disable?: boolean;
  trigger?: TooltipTriggerOption[];
  className?: string;
  style?: React.CSSProperties;
}

// TODO should stay visible when hovering over the content
export const NGTooltip: React.FC<NGTooltipProps> = ({
  title,
  message,
  render,
  placement = "bottom-start",
  delayMs = 200,
  disable,
  trigger = ["hover", "click"],
  children,
  className = "",
  style = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip({ fallbackAxisSideDirection: "start" }), shift()],
  });

  const hover = useHover(context, { restMs: delayMs });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const triggerInteractions =
    trigger.includes("hover") && trigger.includes("click")
      ? [hover, click, dismiss, role]
      : trigger.includes("hover")
      ? [hover, dismiss, role]
      : trigger.includes("click")
      ? [click, dismiss, role]
      : [dismiss, role];

  const { getReferenceProps, getFloatingProps } = useInteractions(triggerInteractions);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()} className={clsx("ngtooltip", className)} style={style}>
        {children}
      </div>
      <FloatingPortal>
        {isOpen && !disable && (
          <div className="ngtooltip-content" ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            {title ? <strong>{title}</strong> : null}
            {render ? render() : message ? <p>{message}</p> : null}
          </div>
        )}
      </FloatingPortal>
    </>
  );
};
