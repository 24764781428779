import * as React from "react";
import { Info, XCircle, CheckCircle, AlertTriangle, X } from "react-feather";
import styles from "./index.module.scss";
import clsx from "clsx";

interface CommonProps {
  type?: "success" | "warning" | "error" | "info";
  size?: "normal" | "small";
  title?: string;
  style?: React.CSSProperties | undefined;
  closable?: boolean;
  className?: string | undefined;
}
interface PropsBasic extends CommonProps {
  message?: string;
}

interface PropsAdvanced extends CommonProps {
  renderIcon?: (hover: boolean, props: any) => React.ReactElement;
  render?: () => React.ReactElement;
}

// TODO fix this typescript to not allow message and render at the same time
type Props = PropsBasic & PropsAdvanced;

const NGAlert: React.FC<Props> = ({
  type = "info",
  title,
  size = "normal",
  style,
  closable,
  className = "",
  message,
  renderIcon,
  render,
}) => {
  const color = "currentColor";
  const [hover, setHover] = React.useState(false);
  const [closed, setClosed] = React.useState(false);

  const handleMouseOver = () => setHover(true);
  const handleMouseOut = () => setHover(false);

  const iconSize = size === "small" ? 15 : 18;
  const iconRenderer = () => {
    if (renderIcon) {
      return renderIcon.call(undefined, hover, { size: iconSize, style: { height: iconSize, width: iconSize, color } });
    }

    const icons = {
      error: <XCircle size={iconSize} color={color} />,
      info: <Info size={iconSize} color={color} />,
      success: <CheckCircle size={iconSize} color={color} />,
      warning: <AlertTriangle size={iconSize} color={color} />,
    };

    return icons[type] || null;
  };

  if (closed) {
    return null;
  }

  return (
    <div
      className={clsx("ngalert", styles.ngalert, styles[type], styles[size], className)}
      style={style}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={styles.body}>
        <span className="pointer-events-none">{iconRenderer()}</span>
        <div className={styles.content}>
          {title ? <span className={styles.title}>{title}</span> : null}
          {message ? <span className={styles.message}>{message}</span> : null}
          {render ? render() : null}
        </div>
      </div>
      {closable && (
        <button onClick={() => setClosed(true)} className={`${styles.close} ngfocus`}>
          <X color="currentColor" size={18} />
        </button>
      )}
    </div>
  );
};

export default NGAlert;
