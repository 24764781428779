import { observer } from "mobx-react-lite";
import * as React from "react";
import { mk8sMobx } from "../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const AddonsAzureACRRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const v = mk8sDraft.addon_azureAcr.clientId;
  return (
    <div>
      <div className="mb-2">Azure Container Registry (ACR)</div>
      <NGFormElement
        name={"azureAcr"}
        label={v.label}
        value={v.value}
        onChange={v.setValue}
        error={v.error}
        inputProps={{ ignoreTouched: true }}
      />
    </div>
  );
};

export const AddonsAzureACR = observer(AddonsAzureACRRaw);
