import * as React from "react";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { Routes, Route, Navigate } from "react-router-dom";
import { TabsLayout } from "../../../components/tabsLayout/tabsLayout";
import { General } from "../container/general";
import { Resource } from "../container/resource";
import { EnvironmentVariables } from "../container/environmentVariables";
import { Command } from "../container/command";
import { HealthCheck } from "../container/healthCheck";
import { observer } from "mobx-react-lite";
import { WorkloadDraftContainerMobx } from "../../../mst/stores/workload.draft.container";
import { Volumes } from "../container/volumes";
import { Metrics } from "../container/metrics";
import { LifecycleHooks } from "../container/lifecycleHooks";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { BasePathContext } from "../../../reactContexts/basePathContext";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  containerDraft: WorkloadDraftContainerMobx;
  containerIndex: number;
  gvcEnv: any;
}
const WorkloadCreateContainerRaw: React.FC<Props> = ({ workloadDraft, containerDraft, containerIndex, gvcEnv }) => {
  const basePath = React.useContext(BasePathContext);
  const containersPath = `${basePath}/-containers`;
  const containerPath = `${containersPath}/${containerIndex}`;

  const links = [
    { url: `${containerPath}/-general`, label: "General" },
    { url: `${containerPath}/-resource`, label: "Resources" },
    { url: `${containerPath}/-command`, label: "Command" },
    { url: `${containerPath}/-env`, label: "Env Variables" },
    { url: `${containerPath}/-lifecycle`, label: "Lifecycle" },
    { url: `${containerPath}/-volumes`, label: "Volumes" },
    { url: `${containerPath}/-metrics`, label: "Metrics" },
    workloadDraft.isCron ? undefined : { url: `${containerPath}/-readiness`, label: "Readiness" },
    workloadDraft.isCron ? undefined : { url: `${containerPath}/-liveness`, label: "Liveness" },
  ].filter(Boolean);

  return (
    <>
      <TabsLayout links={links as any}>
        <Routes>
          <Route index element={<Navigate to={`-general`} />} />
          <Route
            path={`-general`}
            element={<General workloadDraft={workloadDraft} containerDraft={containerDraft} />}
          />
          <Route
            path={`-resource`}
            element={<Resource workloadDraft={workloadDraft} containerDraft={containerDraft} />}
          />
          <Route path={`-command`} element={<Command containerDraft={containerDraft} />} />
          <Route
            path={`-env`}
            element={
              <EnvironmentVariables
                env={containerDraft.env}
                hasDefaultEnv={true}
                filename={`${workloadDraft.name.value}-${containerDraft.name.value}-env`}
                env_gvc={containerDraft.envCPLN_GVC}
                env_gvcAlias={containerDraft.envCPLN_GVC_ALIAS}
                env_image={containerDraft.image.value}
                env_org={containerDraft.envCPLN_ORG}
                env_version={workloadDraft._version}
                env_workload={`/org/${ConsoleContext.org}/gvc/${ConsoleContext.gvc}/workload/${workloadDraft.name.value}`}
                inherit={{
                  isActive: true,
                  env: gvcEnv,
                  value: containerDraft.inheritEnv,
                  setValue: containerDraft.setInheritEnv,
                }}
              />
            }
          />
          <Route
            path={`-lifecycle`}
            element={<LifecycleHooks workloadDraft={workloadDraft} containerDraft={containerDraft} />}
          />
          <Route
            path={`-volumes`}
            element={<Volumes containerDraft={containerDraft} workloadDraft={workloadDraft} />}
          />
          <Route
            path={`-metrics`}
            element={<Metrics containerDraft={containerDraft} workloadDraft={workloadDraft} />}
          />
          {workloadDraft.isCron ? null : (
            <Route
              path={`-readiness`}
              element={
                <HealthCheck
                  type={"readiness"}
                  container={containerDraft}
                  probe={containerDraft.readinessProbe}
                  useProbe={containerDraft.useReadinessProbe}
                  setUseProbe={containerDraft.setUseReadinessProbe}
                />
              }
            />
          )}
          {workloadDraft.isCron ? null : (
            <Route
              path={`-liveness`}
              element={
                <HealthCheck
                  type={"liveness"}
                  container={containerDraft}
                  probe={containerDraft.livenessProbe}
                  useProbe={containerDraft.useLivenessProbe}
                  setUseProbe={containerDraft.setUseLivenessProbe}
                />
              }
            />
          )}
        </Routes>
      </TabsLayout>
    </>
  );
};

export const WorkloadCreateContainer = observer(WorkloadCreateContainerRaw);
