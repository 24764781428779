import { types } from "mobx-state-tree";
import { TaintModel, UnmanagedPoolModel } from "./common";
import { AutoscalerModel } from "./autoscaler";
import { PreInstallScriptProperty } from "./preInstallScript";

const LambdaLabsProviderNodePoolModel = types.model("LambdaLabs Provider Node Pool", {
  name: types.string,
  labels: types.frozen(),
  taints: types.array(TaintModel),
  minSize: types.number,
  maxSize: types.number,
  instanceType: types.string,
});

export const LambdaLabsProviderModel = types.model("LambdaLabs Provider", {
  region: types.optional(types.string, ""), // us-tx-1
  tokenSecretLink: types.string,
  nodePools: types.array(LambdaLabsProviderNodePoolModel),
  sshKey: types.optional(types.string, ""),
  unmanagedNodePools: types.array(UnmanagedPoolModel),
  autoscaler: types.optional(AutoscalerModel, () => AutoscalerModel.create()),
  preInstallScript: PreInstallScriptProperty,
});
