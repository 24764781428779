import * as React from "react";
import { Modal, notification } from "antd";
import { StringModel } from "../../mobxDataModels/stringModel";
import { observer } from "mobx-react-lite";
import { cancelSentTask, checkTaskStatus, fetchSentPendingTasks, inviteUsers } from "../../services/task";
import { inputValidations } from "../../mobxDataModels/validations";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { NGButton } from "../../newcomponents/button/Button";
import { NGLabel } from "../../newcomponents/text/label";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { NGInput } from "../../newcomponents/input/input";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { NGForm } from "../../newcomponents/form/ngform";
import { NGError } from "../../newcomponents/text/error";
import { Tooltip } from "../../components/Tooltip";
import { Table } from "../../newcomponents/table/table";
import { Task } from "../../schema/types/task";
import { CreatedColumn } from "../../newcomponents/table/columns/wellKnown/createdColumn";

const InviteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { org } = ConsoleContext;
  const [invites, setInvites] = React.useState<Task[]>([]);

  const [groupName, setGroupName] = React.useState(`viewers`);
  const [invitations, setInvitations] = React.useState<{ email: string; group: string }[]>([]);
  const emailRef = React.useRef(
    StringModel.create({
      label: "Email",
      transformKey: "lowerCase",
      validationKey: "email",
    })
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = React.useState(false);
  const [selectedTaskId, setSelectedTaskId] = React.useState<string>("");
  const [selectionsToRemove, setSelectionsToRemove] = React.useState<string[]>([]);

  React.useEffect(() => {
    updateInvites();
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(invitations.length > 0 || isLoading);
  }, [invitations.length, isLoading]);

  useCleanPrompt();

  async function updateInvites() {
    const tasks = await fetchSentPendingTasks();
    setInvites(tasks);
  }

  function addEmail() {
    if (invitations.some((i) => i.email === emailRef.current.value)) return;
    const _invitations = [...invitations];
    const { absolute: groupLink } = ngParseLink(groupName, { kind: "group" });
    var newInvitation = { email: emailRef.current.value, group: groupLink };
    _invitations.push(newInvitation);
    setInvitations(_invitations);
    emailRef.current.reset();
  }

  async function invite() {
    try {
      setIsLoading(true);

      const succeededEmails: string[] = [];
      const failedEmails: { email: string; message: string }[] = [];

      for (let invitation of invitations) {
        let inviteRes;
        try {
          inviteRes = await inviteUsers([invitation.email], invitation.group);
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          failedEmails.push({ email: invitation.email, message: errorMessage });
          continue;
        }
        for (let entry of Object.entries(inviteRes.invitations)) {
          const email: string = entry[0];
          succeededEmails.push(email);
        }

        for (let entry of Object.entries(inviteRes.errors)) {
          const email: string = entry[0];
          const messageHolder: any = entry[1];
          failedEmails.push({ email, message: messageHolder.message.replace(/user/i, email) });
        }
      }

      if (succeededEmails.length > 0) {
        notification.success({
          duration: null,
          message: "Success",
          description: (
            <>
              <ul>
                {succeededEmails.map((email, index) => (
                  <li key={email + String(index)} className="block my-2">
                    {email} invited.
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      }
      if (failedEmails.length > 0) {
        notification.warning({
          duration: null,
          message: "Failure",
          description: (
            <>
              <span>Failed Invitations:</span>
              <ul>
                {failedEmails.map(({ email, message }, index) => (
                  <li key={email + String(index)} className="my-2">
                    <span className="block text-sm">{email}</span>
                    <span className="block text-xs">
                      {message}
                      {message.endsWith(".") ? "" : "."}
                    </span>
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      }

      await updateInvites();

      setInvitations([]);
      emailRef.current.reset();
      setGroupName("viewers");
      setIsLoading(false);
    } catch (e) {
      console.error("Org InviteUser", e);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function removeEmail(email: string) {
    setInvitations((inv) => inv.filter((i) => i.email !== email));
  }

  function onDeleteTask() {
    if (selectionsToRemove.length < 1) {
      return;
    }
    setSelectedTaskId(selectionsToRemove[0]);
    setSelectionsToRemove([]);
  }

  function onCSVChange(e: any) {
    const input: HTMLInputElement = e.target;
    if (!input.value || !input.files || input.files.length < 1) {
      input.value = "";
      return;
    }

    const file = input.files[0];
    if (!file) {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
      return;
    }

    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = function (evt) {
      try {
        const result: string = evt.target?.result as string;
        const rows = result.split("\n");
        let rawInvitations: { email: string; group: string }[] = [];
        for (let row of rows) {
          const columns = row.split(",");
          const email = columns[0];
          let group = columns.length > 1 ? columns[1] : "none";
          // clean email
          try {
            if (
              !email ||
              !email.includes("@") ||
              email.split("@").length !== 2 ||
              email.split("@")[0].length < 2 ||
              email.split("@")[1].length < 2
            ) {
              continue;
            }
            let invalid = false;
            for (let validation of inputValidations.email) {
              if (validation("invite", email) !== true) {
                invalid = true;
              }
            }
            if (invalid === true) {
              continue;
            }
          } catch (e) {
            continue;
          }
          // clean group
          try {
            if (!!group && group !== "none") {
              // either starts with this orgs group link, or only a string
              if (group.includes("/") && !group.startsWith(`/org/${org}/group/`)) {
                group = "none";
              } else if (
                group.startsWith(`/org/${org}/group/`) &&
                group.split(`/org/${org}/group/`).length !== 2 &&
                group.split(`/org/${org}/group/`).filter(Boolean).length !== 1
              ) {
                group = "none";
              } else if (group.startsWith(`/org/${org}/group/`) && group.split(`/org/${org}/group/`)[1].includes("/")) {
                group = "none";
              } else if (group.startsWith(`/org/${org}/group/`) && group.split(`/org/${org}/group/`)[1].length < 3) {
                group = "none";
              } else if (!group.startsWith(`/org/${org}/group/`) && group.length >= 3) {
                group = `/org/${org}/group/${group}`;
              } else if (!group.startsWith(`/org/${org}/group/`)) {
                group = "none";
              }
            }
          } catch (e) {
            continue;
          }

          rawInvitations.push({ email, group });
        }

        rawInvitations = rawInvitations.filter((inv) => !invitations.map((i) => i.email).includes(inv.email));
        setInvitations([...invitations, ...rawInvitations]);
      } catch (e) {
        notification.warning({
          message: "Failed",
          description: "File is invalid",
        });
        input.value = "";
      }
    };
    reader.onerror = function () {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
    };
    input.value = "";
  }

  async function confirmTaskDeletion(): Promise<void> {
    try {
      setIsLoadingDelete(true);
      const status = await checkTaskStatus(selectedTaskId);
      if (status !== "pending") {
        await updateInvites();
        notification.warning({
          message: "Failed",
          description: `The task is already ${status === "canceled" ? "cancelled" : "completed"}`,
        });
        setSelectedTaskId("");
        setIsLoadingDelete(false);
        return;
      }
      await cancelSentTask(selectedTaskId);
      await updateInvites();
      notification.success({
        message: "Success",
        description: "Deleted invite",
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
    }
    setSelectedTaskId("");
    setIsLoadingDelete(false);
  }

  function cancelTaskDeletion(): void {
    setSelectedTaskId("");
    setIsLoadingDelete(false);
  }

  return (
    <>
      <NGFormContext.Provider value={formDataRef.current}>
        <NGForm data={formDataRef.current}>
          <div className="mb-4 mt-8 text-2xl">Invite Users</div>
          <div>Invite users to this org and associate them with an optional group.</div>
          <div style={{ width: 666 }}>
            <div>Add Email to Invitation List</div>
            <div className="flex mt-2">
              <NGInput
                name="email"
                placeholder="Email"
                className="mr-4"
                style={{ width: 450 }}
                value={emailRef.current.value}
                onChange={(e) => emailRef.current.setValue(e.target.value)}
              />
              <NGKindSelect
                value={groupName}
                kind={"group"}
                onChange={(value) => setGroupName(value)}
                style={{ width: 200 }}
              />
            </div>
            <div className="mt-2 flex justify-between" style={{ width: 666 }}>
              {formDataRef.current.get("email").touched && !emailRef.current.isValid ? (
                <NGError>{emailRef.current.error}</NGError>
              ) : invitations.some((i) => i.email === emailRef.current.value) ? (
                <NGError>Email is already in the invitation list.</NGError>
              ) : (
                <div />
              )}

              <NGButton
                disabled={
                  !emailRef.current.value ||
                  !emailRef.current.isValid ||
                  invitations.some((i) => i.email === emailRef.current.value)
                }
                onClick={addEmail}
                style={{ width: 200 }}
                className="ml-2"
                variant={"action"}
              >
                Add to Invitation List
              </NGButton>
            </div>
            <div className="flex items-center justify-between mt-4 mb-2">
              <NGLabel>Invitation List</NGLabel>
              <label
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("csvFile")?.click();
                  }
                }}
                className="cursor-pointer ngfocus color-link text-sm"
              >
                Upload from CSV{" "}
                <input
                  id={"csvFile"}
                  accept={".csv"}
                  onChange={onCSVChange}
                  type={"file"}
                  className="invisible"
                  multiple={false}
                  style={{ width: 0 }}
                />
              </label>
            </div>
            {invitations.length === 0 ? null : (
              <div
                className="flex items-center w-full mt-2 border px-4 py-1 table-labels"
                style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
              >
                <div className={"w-6/12"}>
                  <span>Email</span>
                </div>
                <div className={"w-3/12"}>
                  <span>Group</span>
                </div>
                <div className={"w-3/12"}>
                  <span></span>
                </div>
              </div>
            )}
            {invitations.length === 0 ? (
              <div className="border rounded p-8 mb-6">
                <div className="text-center text-2xl">Invitation list is empty</div>
              </div>
            ) : null}
            {invitations.map((invitation, index) => (
              <div
                key={invitation.email + String(index)}
                className="flex items-center w-full border border-t-0 h-12 px-4"
              >
                <div className={"w-6/12"}>
                  <span>{invitation.email}</span>
                </div>
                <div className={"w-3/12"}>
                  <Tooltip title={invitation.group || "None"}>
                    <span>{invitation.group !== "none" ? invitation.group.split("/")[4] : "None"}</span>
                  </Tooltip>
                </div>
                <div className={"w-3/12 flex items-center justify-end"}>
                  <button className="focus color-danger" onClick={() => removeEmail(invitation.email)}>
                    Remove
                  </button>
                </div>
              </div>
            ))}
            {invitations.length === 0 ? null : (
              <div className="flex items-center justify-end my-6">
                <NGButton
                  style={{ width: 200 }}
                  disabled={isLoading || invitations.length < 1 || emailRef.current.value.length > 0}
                  loading={isLoading}
                  variant={"primary"}
                  onClick={invite}
                >
                  Confirm Invitations
                </NGButton>
              </div>
            )}
          </div>
        </NGForm>
      </NGFormContext.Provider>
      <Table<Task>
        tableId="task-invites"
        title={"Pending Invites"}
        selectMode={"single"}
        selectKey={"id"}
        selections={selectionsToRemove}
        onSelectionsChange={setSelectionsToRemove}
        data={invites}
        columns={[
          { id: "description", label: "Message" }, //
          { id: "targetEmail", label: "Email" },
          CreatedColumn(),
        ]}
        headerRenderer={() => (
          <>
            <NGButton
              variant={"danger"}
              size={"small"}
              outlined
              disabled={selectionsToRemove.length < 1}
              onClick={onDeleteTask}
            >
              Remove
            </NGButton>
          </>
        )}
      />
      {selectedTaskId ? (
        <Modal
          title="Do you want to delete this invite?"
          open={true}
          maskClosable={!isLoadingDelete}
          destroyOnClose
          onCancel={cancelTaskDeletion}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={cancelTaskDeletion} disabled={isLoadingDelete}>
                Close
              </NGButton>
              <NGButton
                variant="danger"
                loading={isLoadingDelete}
                disabled={isLoadingDelete}
                onClick={confirmTaskDeletion}
              >
                Delete
              </NGButton>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export const Invite = observer(InviteRaw);
