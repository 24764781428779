import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../services/cpln";
import { DryRunAlert } from "../../dryRunAlert";
import { NGButton } from "../../../../newcomponents/button/Button";
import { useNGFormContext } from "../../../../reactContexts/ngFormContext";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { MoreHorizontal } from "react-feather";
import { NGProviderBrowser } from "../../ngProviderBrowser";
import { NGSelect } from "../../../../newcomponents/select/ngselect";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";

type RouteParams = "index";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderHetznerNodePoolRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [browserKey, setBrowserKey] = React.useState("");
  const formData = useNGFormContext();

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const h = mk8sDraft.provider_hetzner;
  const np = h.nodePools.find((np) => np.index === index);

  // TODO fix
  // React.useEffect(() => {
  //   const minString = np.minSize.value;
  //   const min = Number(minString);
  //   if (!Number.isNaN(min)) {
  //     np.maxSize.setMin(min);
  //   }
  // }, [np.minSize.value]);

  if (!np) {
    return <Navigate to={`${pathname.split("/-node-pools")[0]}/-node-pools`} />;
  }

  const pre = `hetzner.nodePools[${index}].`;

  return (
    <>
      <div>
        <NGFormElement
          name={`${pre}name`}
          label={np.name.label}
          value={np.name.value}
          onChange={np.name.setValue}
          required
          error={!h.isNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
        />

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}overrideImage`}
            label={np.overrideImage.label}
            value={np.overrideImage.value}
            onChange={np.overrideImage.setValue}
            required={np.overrideImage.isRequired}
            error={np.overrideImage.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => setBrowserKey("overrideImage"),
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.overrideImage.setValue}
              path={`spec.provider.hetzner.nodePools[${index}].overrideImage`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}serverType`}
            label={np.serverType.label}
            value={np.serverType.value}
            onChange={np.serverType.setValue}
            required={np.serverType.isRequired}
            error={np.serverType.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => setBrowserKey("serverType"),
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.serverType.setValue}
              path={`spec.provider.hetzner.nodePools[${index}].serverType`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}minSize`}
            label={np.minSize.label}
            value={np.minSize.value}
            onChange={np.minSize.setValue}
            required={np.minSize.isRequired}
            error={np.minSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.minSize.setValue}
              path={`spec.provider.hetzner.nodePools[${index}].minSize`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}maxSize`}
            label={np.maxSize.label}
            value={np.maxSize.value}
            onChange={np.maxSize.setValue}
            required={np.maxSize.isRequired}
            error={np.maxSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.maxSize.setValue}
              path={`spec.provider.hetzner.nodePools[${index}].maxSize`}
            />
          </div>
        </div>
        <NGInputListMst
          label="Labels"
          className="mb-8"
          data={np.labels}
          firstInput={(item) => (
            <NGInput
              required
              invalid={!item.firstValue}
              ignoreTouched
              placeholder="Key"
              value={item.firstValue}
              onChange={(e) => item.setFirstValue(e.target.value)}
            />
          )}
          secondInput={(item) => (
            <NGInput
              required
              invalid={!item.secondValue}
              ignoreTouched
              placeholder="Value"
              value={item.secondValue}
              onChange={(e) => item.setSecondValue(e.target.value)}
            />
          )}
        />
        <NGInputListMst
          label="Taints"
          data={np.taints}
          firstInput={(item) => (
            <NGInput
              required
              invalid={!item.firstValue}
              ignoreTouched
              placeholder="Key"
              value={item.firstValue}
              onChange={(e) => item.setFirstValue(e.target.value)}
            />
          )}
          secondInput={(item) => (
            <NGInput
              required
              invalid={!item.secondValue}
              ignoreTouched
              placeholder="Value"
              value={item.secondValue}
              onChange={(e) => item.setSecondValue(e.target.value)}
            />
          )}
          thirdInput={(item) => (
            <NGSelect
              placeholder="Effect"
              invalid={!item.thirdValue}
              ignoreTouched
              value={item.thirdValue}
              onChange={(value) => item.setThirdValue(value)}
              options={[
                { label: "NoSchedule", value: "NoSchedule" },
                { label: "PreferNoSchedule", value: "PreferNoSchedule" },
                { label: "NoExecute", value: "NoExecute" },
              ]}
            />
          )}
        />
        <NGButton
          style={{ width: 220 }}
          variant={"danger"}
          outlined
          onClick={() => {
            h.removeNodePoolAt(np.index);
            navigate(`${pathname.split("/-node-pools")[0]}/-node-pools`);
          }}
        >
          Delete Node Pool
        </NGButton>
      </div>
      {browserKey === "overrideImage" ? (
        <NGProviderBrowser
          title="Browse Hetzner Images"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "images",
                cluster: mk8sDraft.asObject,
              },
            });
            const _images = data.images.sort((a: any, b: any) => {
              if (a.name > b.name) {
                return 1;
              }
              if (a.name < b.name) {
                return -1;
              }
              return 0;
            });

            const images: any[] = [];

            for (const image of _images) {
              const foundImage = images.find((i) => i.name === image.name);
              if (foundImage) {
                if (!foundImage.architecture.includes(image.architecture)) {
                  foundImage.architecture.push(image.architecture);
                }
              } else {
                image.architecture = [image.architecture];
                images.push(image);
              }
            }
            return images;
          }}
          onOk={(value) => np.overrideImage.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Description", "Disk Size", "Architecture"]}
          getData={(item: any) => [
            item.name,
            item.name,
            item.description,
            `${item.disk_size}Gi`,
            item.architecture.join(", "),
          ]}
        />
      ) : null}
      {browserKey === "serverType" ? (
        <NGProviderBrowser
          title="Browse Hetzner Server Types"
          fetchData={async () => {
            // TODOMK8S paginate
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "server_types",
                cluster: mk8sDraft.asObject,
              },
            });
            let items = data.server_types;
            items = items.filter((i: any) => (i.prices as any[]).some((p) => p.location === h.region.value));
            return items;
          }}
          sortable
          onOk={(value) => np.serverType.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "CPU Type", "Architecture", "Cores", "Memory", "Disk", "Included Traffic", "Cost per Month"]}
          rightAlign={["Cost per Month"]}
          distinctFilters={["CPU Type", "Architecture", "Memory"]}
          // architecture: "x86"
          // cores: 1
          // cpu_type: "shared"
          // deprecated: false
          // deprecation: null
          // description: "CX11"
          // disk: 20
          // id: 1
          // included_traffic: 21990232555520
          // memory: 2
          // name: "cx11"
          // storage_type: "local"
          // prices: [
          //   {
          //     location: "fsn1"
          //     price_hourly: {gross: "0.0052000000000000", net: "0.0052000000"}
          //     gross: "0.0052000000000000"
          //     net: "0.0052000000"
          //     price_monthly: {gross: "3.2900000000000000", net: "3.2900000000"}
          //     gross: "3.2900000000000000"
          //     net: "3.2900000000"
          //   }
          // ]

          getData={(item: any) => {
            const includedTraffic = item.included_traffic / 1_024 / 1_024 / 1_024 / 1_024;

            let cost = "N/A";
            const priceItem = (item.prices as any[]).find((p) => p.location === h.region.value);
            let price = 0;
            if (priceItem) {
              price = Number(priceItem.price_monthly.net);
              cost = price.toLocaleString("en-US", { style: "currency", currency: "USD" });
            }

            return [
              //
              item.name,
              item.name,
              item.cpu_type,
              item.architecture,
              item.cores,
              [item.memory, `${item.memory}Gi`],
              [item.disk, `${item.disk}Gi`],
              [includedTraffic, `${includedTraffic.toFixed(0)}TB`],
              [price, cost],
            ];
          }}
        />
      ) : null}
    </>
  );
};

export const ProviderHetznerNodePool = observer(ProviderHetznerNodePoolRaw);
