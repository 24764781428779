import * as React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { AgentMobx, AgentModel } from "../../../mst/kinds/agent";
import { request } from "../../../services/cpln";
import { LoadingOutlined } from "@ant-design/icons";
import { IdentityDraftNetworkResourceMobx } from "../../../mst/stores/identity.draft.networkresource";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { ngParseLink } from "../../../utils/linkParser/linkParser";
import { IPorPort } from "../../../components/networkResources/ipOrPort";
import { Tooltip } from "../../../components/Tooltip";
import { InfoTooltip } from "../../../components/InfoTooltip";

interface Props {
  type: "fqdn" | "ip";
  resource: IdentityDraftNetworkResourceMobx;
  isViewResolverIP: boolean;
}

const NetworkResourceTableItemRaw: React.FC<Props> = ({ type, resource, isViewResolverIP }) => {
  const { org } = ConsoleContext;
  const [agent, setAgent] = React.useState<AgentMobx | null>(null);

  // Use Effects //
  React.useEffect(() => {
    const { absolute: agentLink } = ngParseLink(resource.agentName, { kind: "agent" });
    request({ url: agentLink }).then(({ data }) => {
      setAgent(AgentModel.create(data));
    });
  }, []);

  return (
    <>
      <div className="w-3/12 pr-2">
        <Tooltip title={resource.resourceName} open={resource.resourceName.length > 16}>
          <div className="truncate pr-2">{resource.resourceName}</div>
        </Tooltip>
      </div>
      {type === "fqdn" ? (
        <div className={`w-${isViewResolverIP ? "2/12" : "3/12"} pr-2`}>
          <Tooltip title={resource.fqdnInput.value} open={resource.fqdnInput.value.length > 16}>
            <div className="truncate pr-2">{resource.fqdnInput.value}</div>
          </Tooltip>
        </div>
      ) : null}
      <div
        className={`w-${
          type === "fqdn" ? (isViewResolverIP ? "2/12" : "3/12") : "3/12"
        } flex flex-col items-start pr-2`}
      >
        <Link className={`truncate ngfocus color-link`} to={`/console/org/${org}/agent/${resource.agentName}`}>
          {resource.agentName}
        </Link>
        {agent?.isInfoLoading ? (
          <LoadingOutlined />
        ) : agent?.info.lastActive ? (
          <div className="text-sm flex">
            <span>Last Active {agent.lastActiveText}</span>
            <InfoTooltip title={agent.envText} />
          </div>
        ) : agent ? (
          <span className={`text-sm color-danger`}>Not Active</span>
        ) : null}
      </div>
      {type === "ip" ? (
        <div className="w-3/12 flex flex-wrap items-start pr-2 gap-1">
          {resource.IPs.map((ip) => (
            <IPorPort item={ip} />
          ))}
        </div>
      ) : null}
      {type === "fqdn" && isViewResolverIP ? <div className="w-2/12">{resource.resolverIpInput.value}</div> : null}
      <div className={`${type === "fqdn" ? "w-2/12" : "w-2/12"} flex flex-wrap items-start pr-2 gap-1`}>
        {resource.ports.map((port) => (
          <IPorPort item={port} />
        ))}
      </div>
    </>
  );
};

export const NetworkResourceTableItem = observer(NetworkResourceTableItemRaw);
