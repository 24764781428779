import React from "react";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import "./select.css";
import { observer } from "mobx-react-lite";
import { ChevronDown, ChevronUp, X } from "react-feather";
import { useNGFormContext } from "../../reactContexts/ngFormContext";
import { InnerButtonProps } from "../input/input";
import { Tooltip } from "../../components/Tooltip";
import { OptionRendererProps } from "./types";
import { DefaultGetOptionLabel, DefaultGetOptionValue, DefaultOptionRenderer } from "./utils";

// TODO allow to use with nglabel, ngdescription, ngerror message and connect with aria components, can use a context

export type NGSelectProps<T> = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  options: T[];
  labelOptions?: string[];
  disabledOptions?: string[];
  disabled?: boolean;
  invalid?: boolean;
  ignoreTouched?: boolean;
  name?: string;
  // --
  renderOption?: (props: OptionRendererProps<T>) => React.ReactElement;
  getOptionLabel?: (option: T) => string;
  getOptionValue?: (option: T) => string;
  // TODO support to keep focus on click, or focusing back to this after modal
  buttons?: InnerButtonProps[];
  allowEmpty?: boolean;
  size?: "toRemoveLarge" | "normal" | "small";
  //
  style?: React.CSSProperties;
  className?: string;
  "data-testid"?: string;
};

// TODO support id, arialabel, data-*
function NGSelectRaw<T>({
  value,
  onChange,
  placeholder = "",
  options: allOptions,
  labelOptions = [],
  disabledOptions = [],
  disabled = false,
  invalid = false,
  ignoreTouched = false,
  name,
  // --
  renderOption = DefaultOptionRenderer,
  getOptionLabel = DefaultGetOptionLabel as any,
  getOptionValue = DefaultGetOptionValue as any,
  buttons = [],
  allowEmpty = false,
  size = "normal",
  //
  style = {},
  className = "",
  "data-testid": testId,
}: NGSelectProps<T>) {
  /* Form state START */
  const formData = useNGFormContext();
  const idRef = React.useRef(formData ? formData.id : uuidv4());
  const id = name ? `${idRef.current}${name}` : idRef.current;
  const [isFocused, setIsFocused] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [visited, setVisited] = React.useState(false);
  React.useEffect(() => {
    if (isFocused) {
      if (name && formData) {
        formData.set(name, { focused: true });
      }
      if (!visited) {
        setVisited(true);
        if (name && formData) {
          formData.set(name, { visited: true });
        }
      }
      return;
    }

    if (visited && !touched) {
      setTouched(true);
      if (name && formData) {
        formData.set(name, { focused: false, touched: true });
      }
    }
  }, [isFocused]);
  /* Form state END */

  const options = allOptions.filter((option) => {
    const optionValue = getOptionValue(option);
    if (labelOptions.includes(optionValue) || disabledOptions.includes(optionValue)) {
      return false;
    }
    return true;
  });
  const option = options.find((o) => getOptionValue(o) === value);
  const label = option ? getOptionLabel(option) : value;

  const comboboxRef = React.useRef<HTMLDivElement>(null as any);
  const inputRef = React.useRef<HTMLInputElement>(null as any);
  const buttonRef = React.useRef<HTMLButtonElement>(null as any);
  const listboxRef = React.useRef<HTMLUListElement>(null as any);

  // when option is clicked or pointer up on anywhere, focuses back on input and clears this in 400, oninputblur is ignored for the first 200 ms
  const preventedBlurRef = React.useRef(false);
  const [hoveredOption, setHoveredOption] = React.useState<string>("");
  const [listboxOpen, setListboxOpen] = React.useState(false);

  // TODO fix
  const [listboxPosition, setListboxPosition] = React.useState<"bottom" | "top">("bottom");

  /* #region - Handle vertical placement of listbox */
  // START - Handle vertical placement of listbox
  React.useEffect(() => {
    if (listboxOpen) {
      checkBoundingBox();
    } else {
      setListboxPosition("bottom");
      setHoveredOption("");
    }
  }, [listboxOpen, allOptions]);

  function setValue(newValue: string) {
    onChange(newValue);
  }

  function checkBoundingBox() {
    let bounds = listboxRef.current.getBoundingClientRect();
    checkVerticalBounding(bounds);
  }

  function checkVerticalBounding(bounds: DOMRect) {
    let windowHeight = window.innerHeight;
    if (bounds.bottom > windowHeight && bounds.top < 0) {
      return;
    }
    if (bounds.bottom > windowHeight) {
      setListboxPosition("top");
    }
    if (bounds.top < 0) {
      setListboxPosition("bottom");
    }
  }
  // END - Handle placement of listbox
  /* #endregion */

  // Handle outside click
  React.useEffect(() => {
    const onDocumentPointerUp = (e: PointerEvent) => {
      const target = e.target as HTMLElement;
      let contains = false;
      if (inputRef.current.contains(target)) {
        contains = true;
      }
      if (buttonRef.current?.contains(target)) {
        contains = true;
      }
      if (listboxRef.current.contains(target)) {
        contains = true;
      }
      if (!contains) {
        setIsFocused(false);
      } else {
        inputRef.current.focus();
        preventedBlurRef.current = true;
        setTimeout(() => {
          preventedBlurRef.current = false;
        }, 400);
      }
    };

    document.addEventListener("pointerup", onDocumentPointerUp);
    return () => {
      document.removeEventListener("pointerup", onDocumentPointerUp);
    };
  }, []);

  React.useEffect(() => {
    setListboxOpen(isFocused);
  }, [isFocused]);

  // Scroll navigated option into view
  React.useEffect(() => {
    if (!hoveredOption) {
      return;
    }
    const node = document.getElementById(`${id}-option-${hoveredOption}`);
    if (!node) {
      return;
    }
    node.scrollIntoView({ behavior: "auto", block: "nearest" });
  }, [hoveredOption]);

  function onEmptyClick() {
    setValue("");
    setListboxOpen(false);
    inputRef.current.focus();
  }

  function onInputClick() {
    // TODO changed and didnt test
    // if not focused yet, it will open, if already focused, toggle
    if (!isFocused) {
      setListboxOpen(true);
    } else {
      setListboxOpen((val: any) => !val);
    }
  }

  function onListboxButtonClick() {
    onInputClick();
    inputRef.current.focus();
  }

  function onInputFocus(e: any) {
    // Delaying to keep track of whether the focus is by mouse or not
    // Mouse focus opens the listbox, keyboard doesnt
    setTimeout(() => {
      setIsFocused(true);
    }, 100);

    // if (onFocus) {
    //   onFocus(e);
    // }
  }

  function onInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      if (!preventedBlurRef.current) {
        setIsFocused(false);

        // if (onBlur) {
        //   onBlur(e);
        // }
      }
      preventedBlurRef.current = false;
    }, 200);
  }

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    switch (e.code) {
      case "Enter":
        if (!listboxOpen) {
          setListboxOpen(true);
        } else if (hoveredOption) {
          setValue(hoveredOption);
          setListboxOpen(false);
        }
        break;
      case "Down":
      case "ArrowDown":
        if (options.length < 1) {
          return;
        }
        setListboxOpen(true);
        if (!hoveredOption) {
          setHoveredOption(getOptionValue(options[0]));
        } else {
          const hoveredIndex = options.findIndex((o) => getOptionValue(o) === hoveredOption);
          if (hoveredIndex === options.length - 1) {
            setHoveredOption(getOptionValue(options[0]));
          } else {
            setHoveredOption(getOptionValue(options[hoveredIndex + 1]));
          }
        }
        break;
      case "Up":
      case "ArrowUp":
        if (options.length < 1) {
          return;
        }
        setListboxOpen(true);
        const lastIndex = options.length - 1;
        if (!hoveredOption) {
          setHoveredOption(getOptionValue(options[lastIndex]));
        } else {
          const hoveredIndex = options.findIndex((o) => getOptionValue(o) === hoveredOption);
          if (hoveredIndex === 0) {
            setHoveredOption(getOptionValue(options[lastIndex]));
          } else {
            setHoveredOption(getOptionValue(options[hoveredIndex - 1]));
          }
        }
        break;
      case "Escape":
      case "Esc":
        if (listboxOpen) {
          setListboxOpen(false);
        } else if (allowEmpty) {
          setValue("");
        }
        break;
      default:
        break;
    }
  }

  function onOptionClick(_: React.MouseEvent<HTMLLIElement, MouseEvent>, option: T) {
    const optionValue = getOptionValue(option);
    setValue(optionValue);
    setListboxOpen(false);
  }

  return (
    <>
      {/* error: invalid */}
      <div
        data-testid={testId}
        className={clsx("ngselect", className, {
          hoveringfocus: !disabled,
          large: size === "toRemoveLarge",
          normal: size === "normal",
          small: size === "small",
          invalid: invalid && !isFocused && (ignoreTouched || touched),
        })}
        style={style}
      >
        <div ref={comboboxRef}>
          <div
            className={clsx(
              "ngfocus" //
              // TODO can use below method when we find a more reliable method of keeping the visual focus as a fallback
              // { visualngfocus: !!preventedBlurRef.current }
            )}
          >
            <input
              id={id}
              ref={inputRef}
              name={name}
              disabled={disabled}
              value={label}
              onChange={() => {}}
              style={{ cursor: "default", caretColor: "transparent" }}
              onClick={() => onInputClick()}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              onKeyDown={onInputKeyDown}
              className={clsx("", {
                // hasOption: filteredOptions.length > 0,
              })}
              type={"text"}
              role={"combobox"}
              placeholder={placeholder}
              aria-autocomplete={"list"}
              aria-expanded={listboxOpen ? "true" : "false"}
              aria-controls={`${id}-listbox`}
              aria-activedescendant={value ? `${id}-option-${value}` : ""}
              autoComplete={"off"}
            />
            {buttons?.map(({ render, onClick, title }) => (
              <Tooltip key={title} title={title}>
                <button
                  // id={`${idPrefixRef.current}-custombutton`}
                  disabled={disabled}
                  // TODO
                  // aria-label={"custombutton"}
                  onClick={onClick}
                  className={clsx("ngfocus nginnerbutton")}
                >
                  {render()}
                </button>
              </Tooltip>
            ))}
            {allowEmpty && label.length > 0 ? (
              <Tooltip title="Clear">
                <button
                  id={`${id}-custombutton`}
                  disabled={disabled}
                  aria-label={"custombutton"}
                  onClick={onEmptyClick}
                  className={clsx("ngfocus nginnerbutton innerbutton-clear")}
                >
                  <X className="h-4" />
                </button>
              </Tooltip>
            ) : null}
            <Tooltip title={listboxOpen ? "Close" : "Open"}>
              <button
                id={`${id}-button`}
                disabled={disabled}
                ref={buttonRef}
                tabIndex={-1}
                // aria-label={accessibilityLabel}
                aria-expanded={listboxOpen ? "true" : "false"}
                aria-controls={`${id}-listbox`}
                onClick={onListboxButtonClick}
                className={clsx("ngfocus nginnerbutton", `innerbutton-listbox`, {
                  // noOptions: filteredOptions.length < 1,
                  // open: listboxOpen,
                })}
              >
                {listboxOpen ? <ChevronUp className="h-4" /> : <ChevronDown className="h-4" />}
              </button>
            </Tooltip>
          </div>
          <ul
            id={`${id}-listbox`}
            ref={listboxRef}
            role={"listbox"}
            className={clsx("max-h-64 overflow-y-auto", {
              open: listboxOpen && options.length > 0,
              top: listboxPosition === "top", // TODO implement
            })}
          >
            {listboxOpen &&
              allOptions.map((option) => {
                const optionValue = getOptionValue(option);
                const isSelected = value === optionValue;
                const isHovered = hoveredOption === optionValue;

                const props = {
                  id: `${id}-option-${optionValue}`,
                  role: "option",
                  "data-testid": `option-${optionValue}`,
                  "data-cy": optionValue,
                  "aria-selected": isSelected ? "true" : "false",
                  onClick: (e: any) => onOptionClick(e, option),
                  onMouseOver: () => setHoveredOption(optionValue),
                  onMouseOut: () => {
                    setHoveredOption((oldOptionValue) => {
                      if (oldOptionValue === optionValue) {
                        return "";
                      }
                      return oldOptionValue;
                    });
                  },
                };

                return renderOption({
                  option: option,
                  props,
                  getOptionLabel,
                  getOptionValue,
                  isSelected,
                  isHovered,
                  isLabel: labelOptions.includes(optionValue),
                  isDisabled: disabledOptions.includes(optionValue),
                });
              })}
          </ul>
        </div>
      </div>
    </>
  );
}

export const NGSelect = observer(NGSelectRaw);
