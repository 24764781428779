import { notification } from "antd";
import { Kind } from "../../schema/types/base";
import { homeLink, linksOf, parentLink, request } from "../../services/cpln";
import { clearItem, toSortedJSON } from "../../services/utils";
import { gvcScopedKinds } from "../../utils/kinds";
import { convertAllLinksToRelative } from "../../utils/linkParser/linkParser";
import { workloadHelpers } from "./workload.helpers";
import jsYaml from "js-yaml";

export const itemHelpers = ({ org, gvc, kind }: { org: string; gvc: string | null; kind: Kind }) => {
  return {
    getFileName(): string {
      let fileName: string = org;

      if (gvcScopedKinds.includes(kind)) {
        fileName += `_${gvc}`;
      }

      fileName += `_${kind}s`;

      return fileName;
    },
    handleAutoDownloadWithElementA(blobData: any, extension: "json" | "yaml" | "tf", filename?: string) {
      const file = new Blob([blobData], { type: `text/${extension}` });
      const href = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = (filename || this.getFileName()) + "." + extension;
      a.href = href;
      a.click();
    },
    async handleExportItems(links: string[], slim: boolean) {
      let _items: any[] = [];

      if (links && links.length > 0) {
        const itemPromises: Promise<any>[] = [];
        for (let link of links) {
          itemPromises.push(request({ url: link }));
        }
        const itemResponses: any[] = await Promise.allSettled(itemPromises);
        _items = itemResponses
          .filter((response) => response.status === "fulfilled")
          .map((response) => response.value.data);
      } else {
        let nextLink: string | undefined = parentLink(kind as any);
        while (!!nextLink) {
          const { data }: any = await request({ url: nextLink });
          _items = _items.concat(data.items);
          nextLink = linksOf(data).next;
        }
      }

      if (kind === "secret") {
        const revealPromises: Promise<any>[] = [];
        for (let _item of _items) {
          revealPromises.push(request({ url: linksOf(_item).reveal! }));
        }
        const revealedItems: any[] = await Promise.allSettled(revealPromises);

        for (let revealedRes of revealedItems) {
          if (revealedRes.status !== "fulfilled") {
            continue;
          }
          const revealed = revealedRes.value.data;
          const index = _items.findIndex((i) => i.name === revealed.name);
          if (index >= 0) {
            _items[index] = revealed;
          }
        }
      }

      if (kind === "workload") {
        const deploymentPromises: Promise<any>[] = [];
        for (let _item of _items) {
          deploymentPromises.push(request({ url: linksOf(_item).deployment! }));
        }
        const deploymentResponses: any[] = await Promise.allSettled(deploymentPromises);

        for (let deploymentRes of deploymentResponses) {
          const index = _items.findIndex((i) => linksOf(i).self === linksOf(deploymentRes.value.data).workload);
          if (index >= 0) {
            if (deploymentRes.status !== "fulfilled") {
              _items[index].status.ready = "unknown";
              _items[index].status.readyLatest = "unknown";
              continue;
            }

            const health = workloadHelpers.getHealth(deploymentRes.value.data.items, _items[index]);
            _items[index].status.ready = health.isReady;
            _items[index].status.readyLatest = health.isLatestReady;
            _items[index].status.readyCheckTimestamp = new Date().toISOString();
          }
        }
      }

      for (let _itemIndex in _items) {
        let _item = _items[_itemIndex];
        if (slim) {
          clearItem(_item);
        }
        _item = convertAllLinksToRelative(_item);
        _item = toSortedJSON(_item);
        _items[_itemIndex] = _item;
      }

      return links.length === 1 ? _items[0] : _items;
    },
    async handleExportJSON(links: string[], slim: boolean) {
      try {
        const data = await this.handleExportItems(links, slim);
        const blob = JSON.stringify(data, null, 2);
        this.handleAutoDownloadWithElementA(blob, "json");
      } catch (e) {
        notification.warning({ message: "Failed", description: e.message });
      }
    },
    async handleExportYaml(links: string[], slim: boolean) {
      try {
        const data = await this.handleExportItems(links, slim);
        const blob = jsYaml.dump(data, { indent: 2, noRefs: true });
        this.handleAutoDownloadWithElementA(blob, "yaml");
      } catch (e) {
        notification.warning({ message: "Failed", description: e.message });
      }
    },
    async handleExportTerraform(links: string[]) {
      const notificationKey: string = "terraform-exporter";
      notification.open({
        key: notificationKey,
        type: "info",
        message: "Terraform Exporter",
        description: "Fetching data from the server, please wait",
      });

      try {
        if (links && links.length > 0) {
          let { data } = await request({
            service: "terraform-exporter",
            url: "/batch",
            method: "post",
            body: { links: links },
          });
          this.handleAutoDownloadWithElementA(convertAllLinksToRelative(data), "tf");
        } else {
          let url = homeLink(kind as any);
          if (gvcScopedKinds.includes(kind)) {
            url = parentLink(kind as any);
          }
          let { data } = await request({ service: "terraform-exporter", url: url });
          this.handleAutoDownloadWithElementA(convertAllLinksToRelative(data), "tf");
        }
      } catch (e) {
        notification.warning({ message: "Failed", description: e.message });
      }

      notification.destroy(notificationKey);
    },
  };
};
