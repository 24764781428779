import { types, Instance } from "mobx-state-tree";
import { isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import md5 from "md5";

const ListItemModel = types
  .model({
    id: types.optional(types.string, () => uuidv4()),
    chosen: types.optional(types.boolean, false),
    firstValue: types.optional(types.string, ""),
    secondValue: types.optional(types.string, ""),
    thirdValue: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setFirstValue(value: string) {
      self.firstValue = value;
    },
    setSecondValue(value: string) {
      self.secondValue = value;
    },
    setThirdValue(value: string) {
      self.thirdValue = value;
    },
  }));
export interface ListItemMobx extends Instance<typeof ListItemModel> {}

export const ListOfItemsModel = types
  .model({
    // initialFirstValue: types.optional(types.string, ""),
    // initialSecondValue: types.optional(types.string, ""),
    // initialThirdValue: types.optional(types.string, ""),
    _items: types.array(ListItemModel),
    items: types.array(ListItemModel),
    selections: types.array(types.string),
  })
  .actions((self) => ({
    reset() {
      self.items.clear();
      for (let item of self._items) {
        const id = item.id || uuidv4();
        self.items.push({ ...item, id: id });
      }
    },
    confirm() {
      self._items.clear();
      let itemsClone = JSON.parse(JSON.stringify(self.items));
      for (let item of itemsClone) {
        self._items.push(item);
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isAllSelected() {
      return self.items.length > 0 && self.selections.length === self.items.length;
    },
  }))
  .actions((self) => ({
    add() {
      const item = ListItemModel.create();
      // const item = ListItemModel.create({
      //   firstValue: self.initialFirstValue,
      //   secondValue: self.initialSecondValue,
      //   thirdValue: self.initialThirdValue,
      // });
      self.items.push(item);
      return item.id;
    },
    remove(idOrIds: string | string[]) {
      let ids = idOrIds;
      if (!Array.isArray(idOrIds)) {
        ids = [idOrIds];
      }

      for (const id of ids) {
        const item = self.items.find((i) => i.id === id);
        if (!item) {
          continue;
        }
        self.items.remove(item);
      }
    },
    removeSelections() {
      for (const id of self.selections) {
        const item = self.items.find((i) => i.id === id);
        if (!item) {
          continue;
        }
        self.items.remove(item);
      }
      self.selections.clear();
    },
    select(id: string) {
      if (self.selections.includes(id)) {
        return;
      }
      self.selections.push(id);
    },
    deselect(id: string) {
      if (!self.selections.includes(id)) {
        return;
      }
      self.selections.remove(id);
    },
    toggleAllSelect() {
      if (self.isAllSelected) {
        self.selections.clear();
      } else {
        self.selections.clear();
        for (const item of self.items) {
          self.selections.push(item.id);
        }
      }
    },
    setItems(items: any[]) {
      self.items.clear();
      for (const item of items) {
        self.items.push(item);
      }
    },
    setInitialItems(items: any[]) {
      self._items.clear();
      for (const item of items) {
        self._items.push(item);
      }
    },
  }))
  .actions((self) => ({
    toggleSelect(id: string) {
      if (self.selections.includes(id)) {
        self.deselect(id);
      } else {
        self.select(id);
      }
    },
  }))
  .views((self) => ({
    get isDirty() {
      const _itemsObject = self._items.map((i) => ({ f: i.firstValue, s: i.secondValue, t: i.thirdValue }));
      const itemsObject = self.items.map((i) => ({ f: i.firstValue, s: i.secondValue, t: i.thirdValue }));

      return !isEqual(_itemsObject, itemsObject);
    },
    get hash() {
      const asArray = self.items.map((i) => ({ f: i.firstValue, s: i.secondValue, t: i.thirdValue }));
      return md5(JSON.stringify(asArray));
    },
  }));

export interface ListOfItemsMobx extends Instance<typeof ListOfItemsModel> {}
