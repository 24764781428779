import * as React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CreateFormLink } from "../../pages/org/create/create";
import "./../../pages/create.css";
import { observer } from "mobx-react-lite";
import { ArrowLeft, ArrowRight } from "react-feather";
import { ViewModal } from "../modals/viewModal";
import clsx from "clsx";
import { BasePathContext } from "../../reactContexts/basePathContext";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { useSetPromptShouldBlock } from "../../reactHooks/useSetPromptShouldBlock";
import { InnerNavLink, NGDetailNav } from "../detail/ngNav";
import { useMediaQuerycontext } from "../detail/mediaQueryContext";

interface Props extends React.PropsWithChildren {
  // TODO isActive only needed for create
  ngLinks?: (InnerNavLink & { isActive: boolean })[];
  useNewNav?: boolean;
  getPreview?: () => Promise<Object> | Object;
  shouldBlockNavigation?: (nextLocation: { pathname: string }, currentLocation: { pathname: string }) => boolean;
  links?: CreateFormLink[];
  LinksRender?: any;
  title: string;
  name?: string;
  isLoading: boolean;
  canCreate: boolean;
  onCancel?: () => void;
  next: {
    isActive: boolean;
    label: string;
    url: string;
  };
  render?: any;
  onConfirm: () => void;
  hideButtons?: boolean;
  disallowCancel?: boolean;
  className?: string;
}
const CreateLayoutRaw: React.FC<Props> = ({
  ngLinks,
  useNewNav = false,
  title,
  name,
  getPreview,
  shouldBlockNavigation,
  links,
  LinksRender,
  isLoading,
  canCreate,
  children,
  onCancel,
  next,
  render = () => null,
  onConfirm,
  hideButtons = false,
  disallowCancel = false,
  className = "",
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = React.useState(false);
  const [body, setBody] = React.useState({});

  const handlePreview = () => {
    const result = getPreview && getPreview();
    if (result instanceof Promise) {
      setIsPreviewLoading(true);
      result
        .then((data) => setBody(data))
        .catch(() => setBody({}))
        .finally(() => {
          setIsPreviewLoading(false);
          setIsPreviewOpen((x) => !x);
        });
    } else {
      setBody(result || {});
      setIsPreviewOpen((x) => !x);
    }
  };

  const basePath = React.useContext(BasePathContext);
  function localOnCancel() {
    if (onCancel) {
      onCancel();
    }
    PromptContext.setIsDisabled(true);
    if (basePath.includes("/billing/")) {
      navigate("/billing");
    } else {
      navigate(basePath.split("/-create")[0]);
    }
  }

  React.useEffect(() => {
    PromptContext.setWhen(true);
  }, []);

  useCleanPrompt();
  useSetPromptShouldBlock(shouldBlockNavigation);

  const { max_w_1024 } = useMediaQuerycontext();

  const isAccount = className.includes("account-create");
  const useAlternativeButtons = isAccount && max_w_1024;

  return (
    <>
      {isPreviewOpen && getPreview ? (
        <ViewModal
          kind={title.toLowerCase().split("create ")[1].toLocaleLowerCase()}
          onClose={handlePreview}
          title={title.toLowerCase().split("create ")[1].toUpperCase() + ` - ${name}`}
          object={body}
          visible={isPreviewOpen}
          filename={`${name}-${title.toLowerCase().split("create ")[1].toLocaleLowerCase()}`}
        />
      ) : null}
      <div data-testid="create-form" className={`create-layout relative ${className}`}>
        <div className="flex n-detail-body">
          <div className="n-detail-nav-parent create-nav-parent mr-4" style={{ width: 200 }}>
            {useNewNav && ngLinks ? (
              <NGDetailNav links={ngLinks as any} />
            ) : links ? (
              links.map((link, index) =>
                // @ts-ignore
                link.isActive ? (
                  <NavLink
                    data-testid={`create-${link.name.toLowerCase()}`}
                    key={link.value}
                    to={`${basePath}/${link.value}`}
                    className={({ isActive }) =>
                      clsx("create-link create-link__inactive focus", {
                        "create-link__disabled": !link.isActive,
                        "push-top": !!link.pushTop,
                        "create-link__active": !!isActive,
                      })
                    }
                  >
                    <div className="number">
                      <span>{Number(index) + 1}</span>
                    </div>
                    <div className="content">{link.name}</div>
                  </NavLink>
                ) : (
                  <span
                    key={link.value}
                    className={`create-link create-link__inactive create-link__disabled ${
                      link.pushTop === true ? "push-top" : ""
                    }`}
                  >
                    <div className="number">
                      <span>{Number(index) + 1}</span>
                    </div>
                    <div className="content">{link.name}</div>
                  </span>
                )
              )
            ) : (
              <LinksRender />
            )}
          </div>
          <div className="n-detail-content">
            <div className="create-form">
              {getPreview ? (
                <NGButton
                  loading={isPreviewLoading}
                  disabled={!canCreate}
                  className="right-8"
                  style={{ position: "absolute" }}
                  variant={"secondary"}
                  onClick={handlePreview}
                >
                  Preview
                </NGButton>
              ) : null}
              <div className="create-form-content">
                {name ? (
                  <div className="mb-8 flex items-center gap-4">
                    <div style={{ minHeight: 48 }} className="inline-flex flex-col justify-center">
                      <div>{title.toLowerCase().split("create ")[1].toUpperCase()}</div>
                      <div>{name}</div>
                    </div>
                    {render()}
                  </div>
                ) : (
                  <div style={{ minHeight: 48 }} className="create-form-header">
                    {title}
                  </div>
                )}
                {children}
              </div>
              {hideButtons ? null : (
                <div className="create-layout-buttons flex items-center mt-8 w-full">
                  {useAlternativeButtons ? (
                    <>
                      {disallowCancel ? null : pathname.includes("-address") ? (
                        <NGButton
                          size={"toRemoveLarge"}
                          style={{ width: 264, marginRight: 10 }}
                          className="mr-4 flex-grow"
                          variant={"secondary"}
                          onClick={() => navigate(pathname.replace("-address", "-general"))}
                          renderIcon={(_, props) => <ArrowLeft {...props} />}
                        >
                          Back
                        </NGButton>
                      ) : (
                        <NGButton
                          size={"toRemoveLarge"}
                          style={{ width: 264, marginRight: 10 }}
                          className="mr-4 flex-grow"
                          data-testid="form-cancel"
                          disabled={isLoading}
                          variant={"danger"}
                          onClick={localOnCancel}
                        >
                          Cancel
                        </NGButton>
                      )}
                      {pathname.includes("-general") ? (
                        <NGButton
                          size={"toRemoveLarge"}
                          style={{ width: 264, marginRight: 10, marginLeft: disallowCancel ? 0 : 10 }}
                          variant={"primary"}
                          className="flex-grow"
                          data-testid="form-next"
                          disabled={isLoading || !next.isActive}
                          onClick={() => navigate(`${basePath}/${next.url}`)}
                          renderIcon={(_, props) => <ArrowRight {...props} />}
                        >
                          {next.label}
                        </NGButton>
                      ) : (
                        <NGButton
                          size={"toRemoveLarge"}
                          data-testid="form-create"
                          style={{ width: 264, marginLeft: 10 }}
                          variant={"action"}
                          className="flex-grow"
                          loading={isLoading}
                          disabled={isLoading || !canCreate}
                          onClick={() => {
                            onConfirm();
                          }}
                        >
                          Create
                        </NGButton>
                      )}
                    </>
                  ) : (
                    <>
                      {disallowCancel ? null : (
                        <NGButton
                          size={"toRemoveLarge"}
                          style={{ width: 264, marginRight: 10 }}
                          className="mr-4 flex-grow"
                          data-testid="form-cancel"
                          disabled={isLoading}
                          variant={"danger"}
                          onClick={localOnCancel}
                        >
                          Cancel
                        </NGButton>
                      )}
                      <NGButton
                        size={"toRemoveLarge"}
                        style={{ width: 264, marginRight: 10, marginLeft: disallowCancel ? 0 : 10 }}
                        variant={"primary"}
                        className="flex-grow"
                        data-testid="form-next"
                        disabled={isLoading || !next.isActive}
                        onClick={() => navigate(`${basePath}/${next.url}`)}
                        renderIcon={(_, props) => <ArrowRight {...props} />}
                      >
                        {next.label}
                      </NGButton>
                      <NGButton
                        size={"toRemoveLarge"}
                        data-testid="form-create"
                        style={{ width: 264, marginLeft: 10 }}
                        variant={"action"}
                        className="flex-grow"
                        loading={isLoading}
                        disabled={isLoading || !canCreate}
                        onClick={() => {
                          onConfirm();
                        }}
                      >
                        Create
                      </NGButton>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const CreateLayout = observer(CreateLayoutRaw);
