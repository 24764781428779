import * as React from "react";
import { observer } from "mobx-react-lite";
import { StringMobx } from "../../mobxDataModels/stringModel";
import { NGLabel } from "../../newcomponents/text/label";
import { CodeEditor } from "../group/identityMatcher/codeEditor";
import { InfoTooltip } from "../../components/InfoTooltip";
import { DryRunAlert } from "./dryRunAlert";
import { mk8sDryRun } from "../../mobxStores/dryRun/mk8s";

interface Props {
  provider: string;
  preInstallScript: StringMobx;
}
const ProviderPreInstallScriptRaw: React.FC<Props> = ({ provider, preInstallScript }) => {
  return (
    <>
      <div className="flex items-center mb-1">
        <NGLabel>Pre Install Script</NGLabel>
        <InfoTooltip title="How long a node should be unneeded before it is eligible for scale down (default 10m0s)" />
      </div>
      <CodeEditor
        language={"shell"}
        options={{ basicSetup: { autocompletion: false } }}
        value={preInstallScript.value}
        setValue={preInstallScript.setValue}
      />
      <div className="mt-2">
        <DryRunAlert
          key={mk8sDryRun.key}
          canShow={true}
          dryRunResponse={mk8sDryRun.response}
          path={`spec.provider.${provider}.preInstallScript`}
        />
      </div>
    </>
  );
};

export const ProviderPreInstallScript = observer(ProviderPreInstallScriptRaw);
