import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { ServiceAccountDetail } from "./detail";
import { request, resourceLink } from "../../services/cpln";
import { ServiceAccountMobx, ServiceaccountModel } from "../../mst/kinds/serviceaccount";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";

type RouteParams = "serviceaccount";
const ServiceAccountDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { serviceaccount: serviceaccountName } = useParams<RouteParams>();
  const [serviceaccount, setServiceaccount] = React.useState<ServiceAccountMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
  }, [serviceaccountName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("serviceaccount", serviceaccountName) });
      setServiceaccount(ServiceaccountModel.create(data));
      setIsLoading(false);
    } catch (e) {
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching serviceaccount to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"serviceaccount"} canCreate={true} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {serviceaccount.name} - Service Account - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: serviceaccount, fetchItem }}>
        <NGFormContext.Provider value={formDataRef.current}>
          <ServiceAccountDetail serviceaccount={serviceaccount} />
        </NGFormContext.Provider>
      </DetailContext.Provider>
    </>
  );
};

export const ServiceAccountDetailRoute = observer(ServiceAccountDetailRouteRaw);
