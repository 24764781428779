import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { SecretMobx, SecretModel } from "../../mst/kinds/secret";
import { SecretDetail } from "./detail";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";

type RouteParams = "secret";
const SecretDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { secret: secretName } = useParams<RouteParams>();
  const [secret, setSecret] = React.useState<SecretMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
  }, [secretName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("secret", secretName) });
      setSecret(SecretModel.create(data));
      setIsLoading(false);
    } catch (e) {
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching secret to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"secret"} canCreate={true} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {secret.name} - Secret - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: secret, fetchItem }}>
        <NGFormContext.Provider value={formDataRef.current}>
          <SecretDetail secret={secret} />
        </NGFormContext.Provider>
      </DetailContext.Provider>
    </>
  );
};

export const SecretDetailRoute = observer(SecretDetailRouteRaw);
