import { types, Instance, flow } from "mobx-state-tree";
import { mk8sMobx } from "../kinds/mk8s/mk8s";
import { Mk8sDraftStoreModel } from "./mk8s.draft";

export const Mk8sDraftManagerModel = types
  .model("WorkloadStore", {
    draft: types.optional(Mk8sDraftStoreModel, () =>
      Mk8sDraftStoreModel.create({
        orgName: "notset",
        orgLink: "@runtime",
      })
    ),
  })
  .actions((self) => {
    const startDraftDetail = flow(function* (mk8s: mk8sMobx) {
      try {
        self.draft.start(mk8s);
      } catch (e) {
        console.error(e.message);
      }
    });
    const startDraftCreate = flow(function* () {
      self.draft.new();
    });
    return {
      startDraftDetail,
      startDraftCreate,
    };
  });

export interface WorkloadStoreMobx extends Instance<typeof Mk8sDraftManagerModel> {}
