import * as React from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { homeLink, linksOf, request } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { RepositoryDetail } from "./repositoryDetail";
import { Image } from "./../../schema/types/image";

const RepositoryDetailRouteRaw: React.FC = () => {
  const { pathname } = useLocation();

  function getRepository() {
    return pathname.split("/repository/")[1].split("/-")[0];
  }
  const [repository, setRepository] = React.useState(getRepository());

  React.useEffect(() => {
    setRepository(getRepository());
  }, [pathname]);

  const [isFetchingMore, setIsFetchingMore] = React.useState(false);
  const [images, setImages] = React.useState<Image[]>(null as any);
  const [nextLink, setNextLink] = React.useState<string | undefined>(undefined);
  const [isLoadingImages, setIsLoadingImages] = React.useState(true);
  const [isIncorrectRepository, setIsIncorrectRepository] = React.useState(false);

  React.useEffect(() => {
    fetchItems();
  }, [repository]);

  React.useEffect(() => {
    fetchNextPage();
  }, [nextLink]);

  async function fetchItems() {
    try {
      setIsLoadingImages(true);
      const body = {
        kind: "image",
        spec: {
          match: "all",
          terms: [
            {
              op: "=",
              property: "repository",
              value: repository,
            },
          ],
        },
      };
      let url: string | undefined = homeLink("image") + "/-query";
      const { data } = await request({ method: "post", url: url, body: body });
      setImages(data.items);
      setNextLink(linksOf(data).next);
      setIsLoadingImages(false);
    } catch (e) {
      setIsIncorrectRepository(true);
      setIsLoadingImages(false);
    }
  }

  async function fetchNextPage() {
    if (!nextLink) {
      return;
    }
    try {
      let allImages: Image[] = [...images];
      const { data } = await request({ url: nextLink });
      allImages = allImages.concat(data.items);
      setImages(allImages);
      setNextLink(linksOf(data).next);
      setIsFetchingMore(false);
    } catch (e) {
      setNextLink(undefined);
      setIsFetchingMore(false);
    }
  }

  if (isLoadingImages) {
    return <Loader fullScreen reason={"fetching images to show repository detail"} />;
  }

  if (isIncorrectRepository) {
    return <IncorrectItem kind={"image"} canCreate={false} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {repository} - Image Repository - From many clouds, one™
        </title>
      </Helmet>
      <RepositoryDetail
        repository={repository}
        images={images}
        refreshImages={fetchItems}
        isFetchingMore={isFetchingMore}
      />
    </>
  );
};

export const RepositoryDetailRoute = observer(RepositoryDetailRouteRaw);
