import { types, Instance } from "mobx-state-tree";
import { WorkloadDraftLoadBalancerDirectModel } from "./workload.draft.lb.direct";

export const WorkloadDraftLoadBalancerModel = types
  .model({
    direct: types.optional(WorkloadDraftLoadBalancerDirectModel, () => WorkloadDraftLoadBalancerDirectModel.create()),
  })
  .actions((self) => ({
    reset() {
      self.direct.reset();
    },
    confirm() {
      self.direct.confirm();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isDirty() {
      return self.direct.isDirty;
    },
    get isValid() {
      return self.direct.isValid;
    },
    get asObject() {
      return {
        direct: self.direct.asObject,
      };
    },
  }));
export interface WorkloadDraftLoadBalancerMobx extends Instance<typeof WorkloadDraftLoadBalancerModel> {}
