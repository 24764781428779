import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../services/cpln";
import { DryRunAlert } from "../../dryRunAlert";
import { MoreHorizontal, PlusCircle } from "react-feather";
import { NGKindSelect } from "../../../../newcomponents/select/ngkindselect";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGFormLabel } from "../../../../newcomponents/text/formLabel";
import NGAlert from "../../../../newcomponents/alert";
import { NGProviderCreateOpaqueSecret } from "../../ngProviderCreateOpaqueSecret";
import { NGProviderBrowser } from "../../ngProviderBrowser";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../newcomponents/input/input";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderOblivusRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const o = mk8sDraft.provider_oblivus!;

  const [browserKey, setBrowserKey] = React.useState("");

  return (
    <>
      <div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"oblivus.datacenter"}
            label={o.datacenter.label}
            value={o.datacenter.value}
            onChange={o.datacenter.setValue}
            required={o.datacenter.isRequired}
            error={o.datacenter.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("locations"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={o.datacenter.setValue}
              path={"spec.provider.oblivus.datacenter"}
            />
          </div>
        </div>

        <div className="mb-4">
          <NGFormLabel required invalid={!o.tokenSecretName}>
            Oblivus Token Secret
          </NGFormLabel>
          <div className="mb-2 flex items-start gap-2">
            <NGKindSelect
              style={{ width: 450 }}
              value={o.tokenSecretName}
              invalid={!o.tokenSecretName}
              onChange={(value) => o.setTokenSecretName(value)}
              kind={"secret"}
              queries={[{ property: "type", value: "opaque" }]}
              buttons={[
                {
                  title: "Create",
                  render: () => <PlusCircle className="h-4" />,
                  onClick: () => setBrowserKey("createSecret"),
                },
              ]}
            />
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              path={"spec.provider.oblivus.tokenSecretLink"}
            />
          </div>
          <NGAlert
            style={{ width: 450 }}
            render={() => (
              <div className="flex flex-col text-sm">
                <span>An opaque secret that contains your Oblivus token.</span>
                <span>It will be used to manage servers in your Oblivus account.</span>
              </div>
            )}
          />
        </div>

        {/* // TODOmk8s improve/fix to work with form data and dry run error */}
        <NGInputListMst
          data={o.sshKeys}
          firstInput={(i) => (
            <NGInput
              value={i.firstValue}
              onChange={(e) => i.setFirstValue(e.target.value)}
              buttons={[
                { title: "Browse", onClick: () => setBrowserKey("ssh-keys"), render: () => <MoreHorizontal /> },
              ]}
            />
          )}
          label="SSH Key Names"
        />
        {/* <div className="flex gap-2 items-start">
          <NGFormElement
            name={"oblivus.sshKey"}
            label={o.sshKey.label}
            value={o.sshKey.value}
            onChange={o.sshKey.setValue}
            required={o.sshKey.isRequired}
            error={o.sshKey.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("ssh-keys"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={o.sshKey.setValue}
              path={"spec.provider.oblivus.sshKey"}
            />
          </div>
        </div> */}
      </div>

      {browserKey === "createSecret" ? (
        <NGProviderCreateOpaqueSecret
          onOk={(secretName: string) => o.setTokenSecretName(secretName)}
          onClose={() => setBrowserKey("")}
          title={"Create Oblivus Token Secret"}
          dataLabel={"Oblivus Token"}
        />
      ) : null}
      {browserKey === "locations" ? (
        <NGProviderBrowser
          title="Browse Oblivus Data Centers"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "locations",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          onOk={(value) => o.datacenter.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Description"]}
          getData={(item: any) => [item.name, item.name, item.description]}
        />
      ) : null}
      {browserKey === "ssh-keys" ? (
        <NGProviderBrowser
          key={o.tokenSecretName}
          title="Browse Oblivus SSH Keys"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ssh-keys",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          // onOk={(value) => o.sshKey.setValue(value as string)}
          // TODOmk8s fix
          onOk={() => {}}
          onClose={() => setBrowserKey("")}
          labels={["Name"]}
          getData={(item: any) => [String(item.name), item.name]}
        />
      ) : null}
    </>
  );
};

export const ProviderOblivus = observer(ProviderOblivusRaw);
