import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NGSwitch } from "../../../../newcomponents/switch";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGLabel } from "../../../../newcomponents/text/label";
import { NGLabelText } from "../../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../../components/InfoTooltip";

// TODO
// includeNamespaces: RegularExpression, // means "all" if missing
// excludeNamespaces: RegularExpression, // means non if missing

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const CreateAddonsMetricsRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <div>
      <div className="mb-2">Metrics</div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.coreDns}
          onChange={(checked) => mk8sDraft.addon_metrics.setCoreDns(checked)}
        >
          <NGLabelText>Core DNS</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable scraping of core-dns service"]} />
      </div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.kubelet}
          onChange={(checked) => mk8sDraft.addon_metrics.setKubelet(checked)}
        >
          <NGLabelText>Kubelet</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable scraping kubelet stats"]} />
      </div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.kubeState}
          onChange={(checked) => mk8sDraft.addon_metrics.setKubeState(checked)}
        >
          <NGLabelText>Kube State</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable kube-state metrics"]} />
      </div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.nodeExporter}
          onChange={(checked) => mk8sDraft.addon_metrics.setNodeExporter(checked)}
        >
          <NGLabelText>Node Exporter</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable collecting node-level stats (disk, network, filesystem, etc)"]} />
      </div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.apiserver}
          onChange={(checked) => mk8sDraft.addon_metrics.setApiserver(checked)}
        >
          <NGLabelText>API Server</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable scraping apiserver stats"]} />
      </div>
      <div className="flex items-center mb-4">
        <NGSwitch
          value={mk8sDraft.addon_metrics.cadvisor}
          onChange={(checked) => mk8sDraft.addon_metrics.setCadvisor(checked)}
        >
          <NGLabelText>cAdvisor</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={["Enable CNI-level container stats"]} />
      </div>
      <div className="p-4 border rounded" style={{ width: 484 }}>
        <NGSwitch
          value={mk8sDraft.addon_metrics.scrapeAnnotated}
          onChange={(checked) => mk8sDraft.addon_metrics.setScrapeAnnotated(checked)}
        >
          <NGLabelText>Scrape Annotated</NGLabelText>
        </NGSwitch>
        <div className="mb-2">Scrape pods annotated with prometheus.io/scrape=true</div>
        {mk8sDraft.addon_metrics.scrapeAnnotated ? (
          <>
            <NGFormElement
              name={`metrics.intervalSeconds`}
              label={mk8sDraft.addon_metrics.scrapeAnnotated_intervalSeconds.label}
              value={mk8sDraft.addon_metrics.scrapeAnnotated_intervalSeconds.value}
              required={mk8sDraft.addon_metrics.scrapeAnnotated_intervalSeconds.isRequired}
              error={mk8sDraft.addon_metrics.scrapeAnnotated_intervalSeconds.error}
              onChange={mk8sDraft.addon_metrics.scrapeAnnotated_intervalSeconds.setValue}
              inputProps={{ ignoreTouched: true }}
            />
            <NGFormElement
              name={`metrics.includeNamespaces`}
              label={mk8sDraft.addon_metrics.scrapeAnnotated_includeNamespaces.label}
              placeholder="Regex"
              value={mk8sDraft.addon_metrics.scrapeAnnotated_includeNamespaces.value}
              required={mk8sDraft.addon_metrics.scrapeAnnotated_includeNamespaces.isRequired}
              error={mk8sDraft.addon_metrics.scrapeAnnotated_includeNamespaces.error}
              onChange={mk8sDraft.addon_metrics.scrapeAnnotated_includeNamespaces.setValue}
              inputProps={{ ignoreTouched: true }}
            />
            <NGFormElement
              name={`metrics.excludeNamespaces`}
              label={mk8sDraft.addon_metrics.scrapeAnnotated_excludeNamespaces.label}
              placeholder="Regex"
              value={mk8sDraft.addon_metrics.scrapeAnnotated_excludeNamespaces.value}
              required={mk8sDraft.addon_metrics.scrapeAnnotated_excludeNamespaces.isRequired}
              error={mk8sDraft.addon_metrics.scrapeAnnotated_excludeNamespaces.error}
              onChange={mk8sDraft.addon_metrics.scrapeAnnotated_excludeNamespaces.setValue}
              inputProps={{ ignoreTouched: true }}
            />
            <NGFormElement
              name={`metrics.retainLabels`}
              label={mk8sDraft.addon_metrics.scrapeAnnotated_retainLabels.label}
              placeholder="Regex"
              value={mk8sDraft.addon_metrics.scrapeAnnotated_retainLabels.value}
              required={mk8sDraft.addon_metrics.scrapeAnnotated_retainLabels.isRequired}
              error={mk8sDraft.addon_metrics.scrapeAnnotated_retainLabels.error}
              onChange={mk8sDraft.addon_metrics.scrapeAnnotated_retainLabels.setValue}
              inputProps={{ ignoreTouched: true }}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export const CreateAddonsMetrics = observer(CreateAddonsMetricsRaw);
