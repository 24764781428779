import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateAddonsLocalPathStorageRaw: React.FC<Props> = ({ mk8sDraft }) => {
  return (
    <div>
      <div>Local Path Storage</div>
    </div>
  );
};

export const Mk8sCreateAddonsLocalPathStorage = observer(Mk8sCreateAddonsLocalPathStorageRaw);
