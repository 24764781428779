import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../mobxDataModels/stringModel";
import { AgentMobx } from "../../mst/kinds/agent";
import { FormButtons } from "../../components/forms/formButtons";
import { Copy, ExternalLink, Minus, Plus } from "react-feather";
import { useDetailContext } from "../../components/detail/detailContext";
import { NGLabel } from "../../newcomponents/text/label";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { CodeEditor } from "../group/identityMatcher/codeEditor";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { InfoTooltip } from "../../components/InfoTooltip";
import { DOCS_URL } from "../../envVariables";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  agent: AgentMobx;
}
const InfoRaw: React.FC<Props> = ({ agent }) => {
  const { fetchItem } = useDetailContext();
  const descriptionRef = React.useRef(StringModel.create({ label: "Description", initialValue: agent.description }));
  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isEnvironmentOpen, setIsEnvironmentOpen] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    let res = false;
    if (descriptionRef.current.isDirty) res = true;
    setIsDirty(res);
  }, [descriptionRef.current.isDirty]);

  function toggleEnvironment() {
    setIsEnvironmentOpen((val) => !val);
  }

  function reset() {
    descriptionRef.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      const description = descriptionRef.current.value || null;
      await agent.patch({
        description,
      });
      descriptionRef.current.confirm();
      if (!descriptionRef.current.value) {
        descriptionRef.current.setInitialValue(agent.name);
      }
      notification.success({
        message: "Success",
        description: "Updated Agent",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        reset();
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <NGFormElement
        name="description"
        label={descriptionRef.current.label}
        value={descriptionRef.current.value}
        onChange={descriptionRef.current.setValue}
      />
      <div className="mb-4 flex items-center">
        <div className="w-4/12">
          <div className="flex items-center">
            <NGLabel>Last Active</NGLabel>
            <InfoTooltip title="Recent heartbeat from the agent" />
          </div>
          <div>
            <span
              className={`${
                agent.isActive ? "bg-color-action pulse" : "bg-color-danger"
              } rounded-full mr-1 inline-block`}
              style={{ width: 10, height: 10 }}
            />
            {agent.lastActiveText}
          </div>
        </div>
        <div className="w-4/12">
          <div className="flex items-center">
            <NGLabel>Service Count</NGLabel>
            <InfoTooltip title="How many unique service/ports are handled by this agent" />
          </div>
          <div>{agent.info.serviceCount}</div>
        </div>
        <div className="w-4/12">
          <div className="flex items-center">
            <NGLabel>Peer Count</NGLabel>
            <InfoTooltip
              title="Roughly how many locations this agent is connected to. May be more than the number of location due to scaling"
              overlayStyle={{ maxWidth: 450 }}
            />
          </div>
          <div>{agent.info.peerCount}</div>
        </div>
      </div>
      <div className="mb-4" style={{ width: 450 }}>
        <span>See</span>
        <a href={`${DOCS_URL}/reference/agent`} target={"_blank"} className="color-link mx-1">
          <span>docs</span>
          <ExternalLink className="h-4 w-4 inline-block" style={{ transform: "translateY(2px)", marginLeft: 2 }} />
        </a>
        <span>
          to learn how to set up <span className="font-semibold">Agents</span>.
        </span>
      </div>
      {agent.info.env && Object.keys(agent.info.env).length > 0 ? (
        <div className="mb-4">
          <div className="flex items-center">
            <button className="ngfocus color-link" onClick={toggleEnvironment}>
              Environment{" "}
              {isEnvironmentOpen ? (
                <Minus
                  data-testid="toggle-env"
                  className="ml-1 feather-icon inline-block"
                  style={{ transform: "translateY(2px)" }}
                />
              ) : (
                <Plus
                  data-testid="toggle-env"
                  className="ml-1 feather-icon inline-block"
                  style={{ transform: "translateY(2px)" }}
                />
              )}
            </button>
            {isEnvironmentOpen ? (
              <button
                onClick={() => {
                  navigator.clipboard.writeText(JSON.stringify(agent.info.env || {}, null, 2));
                  notification.success({ message: "Copied to clipboard" });
                }}
                className="ngfocus color-link-hover"
              >
                <Copy className="ml-2 feather-icon" />
              </button>
            ) : null}
          </div>
          {isEnvironmentOpen ? (
            <CodeEditor
              value={(agent.envText as string[]).join("\n")}
              setValue={() => {}}
              language={"markdown"}
              options={{
                basicSetup: {
                  highlightActiveLine: false,
                  highlightActiveLineGutter: false,
                  lineNumbers: true,
                  autocompletion: false,
                  foldGutter: false,
                },
                readOnly: true,
              }}
            />
          ) : null}
        </div>
      ) : null}
      <TagLinksTable
        tableId="agent-info-tag-links"
        tags={Object.entries(agent.tags || {})
          .map(([tagKey, tagValue]) => ({ key: tagKey, value: (tagValue as string) || "" }))
          .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
        styles={{ header: { marginBottom: 0 } }}
      />
      <FormButtons
        onSave={save}
        onReset={reset}
        resetDisabled={isLoading || !isDirty}
        saveDisabled={isLoading || !isDirty}
        loading={isLoading}
      />
    </>
  );
};

export const Info = observer(InfoRaw);
