import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../providerAutoscaler";
import { ProviderNetworking } from "../../providerNetworking";
import { ProviderPreInstallScript } from "../../providerPreInstallScript";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";
import { NGSwitch } from "../../../../newcomponents/switch";
import { NGLabelText } from "../../../../newcomponents/text/labelText";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderAWSAdvancedRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const a = mk8sDraft.provider_aws!;

  return (
    //
    <div>
      <NGInputListMst
        label="AWS Tags"
        className="mb-8"
        data={a.awsTags}
        firstInput={(item) => (
          <NGInput
            required
            // TODO make regex and length check
            invalid={!item.firstValue}
            ignoreTouched
            placeholder="Key"
            value={item.firstValue}
            onChange={(e) => item.setFirstValue(e.target.value)}
          />
        )}
        secondInput={(item) => (
          <NGInput
            required
            // TODO make regex and length check for invalidation
            ignoreTouched
            placeholder="Value"
            value={item.secondValue}
            onChange={(e) => item.setSecondValue(e.target.value)}
          />
        )}
      />
      <NGSwitch className="mb-4" value={a.skipCreateRoles} onChange={(checked) => a.setSkipCreateRoles(checked)}>
        <NGLabelText>Skip Create Roles</NGLabelText>
      </NGSwitch>
      <div className="mb-4">
        <ProviderPreInstallScript provider="aws" preInstallScript={a.preInstallScript} />
      </div>
      <div className="mb-4">
        <ProviderNetworking clusterDraft={mk8sDraft} networking={a.networking} />
      </div>
      <div>
        <ProviderAutoscaler autoscaler={a.autoscaler} />
      </div>
    </div>
  );
};

export const ProviderAWSAdvanced = observer(ProviderAWSAdvancedRaw);
