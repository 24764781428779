import * as React from "react";
import { ReleaseInstance } from "../types/release";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { BasePathContext } from "../../../reactContexts/basePathContext";
import { useNavigate } from "react-router-dom";
import { TemplateItem } from "../types/template";
import { request } from "../../../services/cpln";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { Modal, notification } from "antd";
import jsYaml from "js-yaml";
import NGAlert from "../../../newcomponents/alert";
import { Loader } from "../../../components/layout/loader";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import { NGLabel } from "../../../newcomponents/text/label";
import { CodeEditor } from "../../group/identityMatcher/codeEditor";
import { NGButton } from "../../../newcomponents/button/Button";
import { Copy, Download } from "react-feather";
import { k8sKeySort } from "../../../services/utils";
import { useDetailContext } from "../../../components/detail/detailContext";
import { getTemplateVersion, getVersions } from "../utils";

interface Props {
  release: ReleaseInstance;
}
export const ReleaseUpgrade: React.FC<Props> = ({ release }) => {
  const formDataRef = React.useRef(new NGFormData());

  const { fetchItem } = useDetailContext();

  const basePath = React.useContext(BasePathContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [template, setTemplate] = React.useState<TemplateItem>(null as any);
  const [isLoadingUpgrade, setIsLoadingUpgrade] = React.useState(false);

  const versions = getVersions(template);
  const templateVersion = getTemplateVersion(template, release.templateVersion);

  const [initialValues, setInitialValues] = React.useState("");
  const [values, setValues] = React.useState("");

  const [isTemplateView, setIsTemplateView] = React.useState(false);
  const [helmTemplates, setHelmTemplates] = React.useState<any[]>([]);

  React.useEffect(() => {
    fetchTemplate();
  }, []);

  async function fetchTemplate() {
    try {
      setIsLoading(true);
      setError("");
      const { data } = await request({ service: "marketplace", url: `/template/${release.templateId}` });
      setTemplate(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      setError(errorMessage);
    }
  }

  React.useEffect(() => {
    if (!template) {
      return;
    }
    if (!versions.includes(release.templateVersion)) {
      // TODO show error
      return;
    }
    const values = template.metadata.versions[release.templateVersion].values;
    setInitialValues(values);
  }, [template]);

  React.useEffect(() => {
    if (!initialValues) {
      return;
    }
    setValues(initialValues);
  }, [initialValues]);

  async function handleUpgrade() {
    try {
      setIsLoadingUpgrade(true);
      await request({
        service: "marketplace",
        method: "post",
        url: `/org/${ConsoleContext.org}/release`,
        body: {
          name: release.name,
          gvc: release.gvc,
          templateId: release.templateId,
          templateVersion: release.templateVersion,
          values: values,
        },
      });
      notification.success({ message: "Success", description: "Upgraded template" });
      setIsLoadingUpgrade(false);
      navigate(basePath);
      if (fetchItem) {
        fetchItem();
      }
    } catch (e) {
      setIsLoadingUpgrade(false);
      let errorMessage = e.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      if (errorMessage.startsWith("Command failed: ")) {
        errorMessage = `[` + errorMessage.split(`[`)[1];
      }
      notification.warning({ message: "Failed", description: errorMessage });
    }
  }

  async function handleTemplate() {
    try {
      setIsLoadingUpgrade(true);
      const { data } = await request({
        service: "marketplace",
        method: "post",
        url: `/org/${ConsoleContext.org}/template`,
        body: {
          name: release.name,
          gvc: release.gvc,
          templateId: template.id,
          version: release.templateVersion,
          values: values,
        },
      });
      setHelmTemplates(jsYaml.loadAll(data.message));
      setIsTemplateView(true);
      notification.success({ message: "Success", description: "Templated" });
      setIsLoadingUpgrade(false);
    } catch (e) {
      setIsLoadingUpgrade(false);
      setHelmTemplates([]);
      let errorMessage = e.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      if (errorMessage.startsWith("Command failed: ")) {
        errorMessage = `[` + errorMessage.split(`[`)[1];
      }
      notification.warning({ message: "Failed", description: errorMessage });
    }
  }

  if (error) {
    return <NGAlert type={"error"} title={"Failed to fetch template values"} message={error} />;
  }

  if (!template || isLoading) {
    return (
      <div className="relative">
        <Loader
          reason="Fetching template or values"
          style={{
            width: 100,
            height: 100,
            margin: 0,
            left: `calc(50% - 50px)`,
            right: "unset",
            top: 150,
            bottom: "unset",
          }}
        />
      </div>
    );
  }

  return (
    <NGFormContext.Provider value={formDataRef.current}>
      <div style={{ maxWidth: 900 }}>
        <div className="header-inputs">
          <div className="flex items-center mb-4">
            <div style={{ width: 50 }}>
              <img className="object-fit" src={`/resources/marketplace/template/${template.id}/icon.png`} />
            </div>
            <div className="flex flex-col ml-4">
              <span className="leading-none text-lg font-semibold">{template.name}</span>
              <span className="leading-none text-sm">{templateVersion.chart.category}</span>
            </div>
          </div>
          <div className="flex items-start">
            <div style={{ width: 289, marginRight: 16 }}>
              <NGFormLabel>GVC</NGFormLabel>
              <div className="mb-4">{release.gvc}</div>
            </div>
            <div style={{ width: 289, marginRight: 16 }}>
              <NGFormLabel>Release Name</NGFormLabel>
              <div className="mb-4">{release.name}</div>
            </div>
            <div style={{ width: 290 }}>
              <NGFormLabel>Template Version</NGFormLabel>
              <div className="mb-4">{release.templateVersion}</div>
            </div>
          </div>
        </div>
        <NGLabel>Values</NGLabel>
        <div className="text-sm">
          <CodeEditor language="yaml" options={{}} value={values} setValue={setValues} />
        </div>
        <div className="mt-4 flex items-center">
          <NGButton
            style={{ width: 220, marginRight: 10 }}
            disabled={values === initialValues || isLoadingUpgrade}
            variant={"danger"}
            outlined
            onClick={() => setValues(initialValues)}
          >
            Reset to Defaults
          </NGButton>
          <NGButton
            style={{ width: 220, marginRight: 10 }}
            // TODO we can have a way of validating the values
            disabled={isLoadingUpgrade}
            loading={isLoadingUpgrade}
            variant={"primary"}
            onClick={() => handleTemplate()}
          >
            Template
          </NGButton>
          <NGButton
            style={{ width: 220 }}
            disabled={isLoadingUpgrade}
            loading={isLoadingUpgrade}
            variant={"action"}
            onClick={() => handleUpgrade()}
          >
            Upgrade App
          </NGButton>
        </div>
      </div>
      {isTemplateView ? (
        <Modal
          open={isTemplateView}
          width={1000}
          styles={{ body: { height: "70vh", padding: 0 } }}
          onOk={() => handleUpgrade()}
          onCancel={() => setIsTemplateView(false)}
          maskClosable={false}
          closable={false}
          okText={"Install"}
          okButtonProps={{ loading: isLoadingUpgrade, disabled: isLoadingUpgrade }}
          cancelText={"Close"}
          cancelButtonProps={{ disabled: isLoadingUpgrade }}
        >
          <div className="flex items-center gap-2 mb-4">
            <NGLabel>{helmTemplates.length} Items</NGLabel>
            <div className="flex-grow" />
            <NGButton
              onClick={() => {
                navigator.clipboard.writeText(jsYaml.dump(helmTemplates, { sortKeys: k8sKeySort }));
                notification.success({ message: "Copied all to clipboard" });
              }}
              variant="secondary"
              size={"small"}
              renderIcon={(_, props) => <Copy {...props} />}
            >
              Copy
            </NGButton>
            <NGButton
              onClick={() => {
                notification.success({ message: "Download initiated" });
                const blob = jsYaml.dump(helmTemplates, { sortKeys: k8sKeySort });
                const file = new Blob([blob], { type: `text/yaml` });
                const href = URL.createObjectURL(file);
                const a = document.createElement("a");
                a.style.display = "none";
                a.classList.add("cpln-temp-a");
                a.download = "hemlTemplates.yaml";
                a.href = href;
                a.click();
              }}
              variant="secondary"
              size={"small"}
              renderIcon={(_, props) => <Download {...props} />}
            >
              Download
            </NGButton>
          </div>
          <CodeEditor
            language="yaml"
            options={{
              readOnly: true,
              style: {
                height: "calc(100% - 50px)",
                overflow: "auto",
              },
              className: "text-sm",
            }}
            value={jsYaml.dump(helmTemplates, { sortKeys: k8sKeySort })}
            setValue={() => {}}
            minHeight="calc(100% - 50px)"
          />
        </Modal>
      ) : null}
    </NGFormContext.Provider>
  );
};
