import * as React from "react";
import { Command } from "../mst/kinds/command";

interface VolumesetCommandsContextType {
  commands: Command[];
  fetchCommands: () => Promise<void>;
  isLoading: boolean;
  hashCommands: string;
}

export const VolumesetCommandsContext = React.createContext<VolumesetCommandsContextType>(null as any);

export const useVolumesetCommandsContext = () => {
  return React.useContext(VolumesetCommandsContext);
};
