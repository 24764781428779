import { flow, makeObservable, observable } from "mobx";
import { automatedRequest, homeLink, linksOf } from "../../services/cpln";
interface Domain {
  name: string;
  status: {
    endpoints: {
      url: string;
      workloadLink: string;
    }[];
  };
}

class DomainContextMobx {
  items: Domain[] = [];
  isLoading: boolean = false;
  requestCounter: number = 0;

  constructor() {
    makeObservable(this, {
      items: observable,
      isLoading: observable,
      requestCounter: observable,
      requestItems: flow,
    });
  }

  *requestItems() {
    try {
      this.isLoading = true;
      let nextLink: string | undefined = homeLink("domain");
      this.items = [];
      while (nextLink) {
        // @ts-ignore yield
        const { data } = yield automatedRequest({ url: nextLink });
        for (let item of data.items) {
          this.items.push({
            ...item,
            status: { ...item.status, endpoints: item.status.endpoints || [] } || { endpoints: [] },
          });
        }
        nextLink = linksOf(data).next;
      }
      this.isLoading = false;
    } catch (e) {
      this.items = [];
      this.isLoading = false;
    } finally {
      this.requestCounter += 1;
    }
  }
}

export const DomainContext = new DomainContextMobx();
