import { makeAutoObservable } from "mobx";
import { DebugContext } from "../debug";

class PromptMobx {
  key: number = 0;
  isDisabled: boolean;
  when: boolean;
  shouldBlock: (nextLocation: { pathname: string }, currentLocation: { pathname: string }) => boolean;
  onDiscard?: () => void;

  constructor() {
    makeAutoObservable(this);
    this.increaseKey();
    this.isDisabled = false;
    this.when = false;
    this.shouldBlock = () => true;
  }

  public increaseKey(reason?: string, value?: any) {
    this.key += 1;
    if (DebugContext.debug) {
      console.log(
        `PromptContext key: ${this.key}${
          reason ? ` reason: ${reason}${(value || "").length > 0 ? String(value) : ""}` : ""
        }`
      );
    }
  }

  public setIsDisabled(value: boolean) {
    this.increaseKey("setIsDisabled", value);
    this.isDisabled = value;
  }
  public setWhen(value: boolean) {
    this.increaseKey("setWhen", value);
    this.when = value;
  }
  public setShouldBlock(fn: (nextLocation: { pathname: string }, currentLocation: { pathname: string }) => boolean) {
    this.increaseKey("setShouldBlock");
    this.shouldBlock = fn;
  }
  public setOnDiscard(fn?: () => void) {
    this.increaseKey("setOnDiscard");
    this.onDiscard = fn;
  }
}

export const PromptContext = new PromptMobx();
