import * as React from "react";
import { FormElement } from "../../components/forms/formElement";
import { UserMobx } from "../../mst/kinds/user";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  user: UserMobx;
}
export const Info: React.FC<Props> = ({ user }) => {
  return (
    <div>
      <div className="mb-4">
        <FormElement label={"Email"} value={user.email} />
      </div>
      <TagLinksTable
        tableId="user-info-tag-links"
        tags={Object.entries(user.tags || {})
          .map(([tagKey, tagValue]) => ({ key: tagKey, value: (tagValue as string) || "" }))
          .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
        styles={{ header: { marginBottom: 0 } }}
      />
    </div>
  );
};
