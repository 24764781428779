import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadMobx } from "../../mst/kinds/workload";
import { getToken, request, resourceLink } from "../../services/cpln";
import { Terminal } from "xterm";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { notification } from "antd";
import { StringModel } from "../../mobxDataModels/stringModel";
import { useDebounce } from "../../components/table/useDebounce";
import { v4 as uuidv4 } from "uuid";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { ChevronDown, ChevronUp, Loader, RefreshCcw } from "react-feather";
import { CodeSnippet } from "../../components/generic/codeSnippet/codeSnippet";
import { Buffer } from "buffer";

interface SocketMessage {
  type: string;
  buffer: number[];
  width: number;
  height: number;
}
import { NGButton } from "../../newcomponents/button/Button";
import { NGInput } from "../../newcomponents/input/input";
import NGAlert from "../../newcomponents/alert";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { NGLabel } from "../../newcomponents/text/label";
import { DOCS_URL } from "../../envVariables";
import { Gvc } from "../../schema/types/gvc";
import { Location } from "../../schema/types/location";

interface Props {
  workload: WorkloadMobx;
}
const WorkloadConnectRaw: React.FC<Props> = ({ workload }) => {
  const { org, gvc } = ConsoleContext;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isConnecting, setIsConnecting] = React.useState(false);
  const [replica, setReplica] = React.useState("");
  const [lastUsedReplica, setLastUsedReplica] = React.useState("");
  const [remoteHost, setRemoteHost] = React.useState("");

  const [gvcLocations, setGVCLocations] = React.useState<string[]>([]);
  const [replicas, setReplicas] = React.useState<string[]>([]);
  const [counter, _setCounter] = React.useState(0);
  const commandRef = React.useRef(
    StringModel.create({
      label: "Command",
      initialValue: localStorage.getItem(`connect-command-${org}-${gvc}-${workload.name}`) || "bash",
      isRequired: true,
    })
  );

  const [disconnected, setDisconnected] = React.useState(false);
  const [isTerminalOpen, setIsTerminalOpen] = React.useState(false);
  const socketRef = React.useRef<WebSocket | null>(null);
  const [debouncedCounter, setDebouncedCounter] = useDebounce("1", 250);
  const [isConnectHelpOpen, setIsConnectHelpOpen] = React.useState(true);

  const locationRef = React.useRef(
    SelectModel.create({
      label: "Location",
      options: [{ label: "Select a Location", value: "select" }],
      initialValue: "select",
    })
  );
  const containerRef = React.useRef(
    SelectModel.create({
      label: "Container",
      options: workload.containerNames.map((container) => ({ label: container, value: container })),
      initialValue: workload.containerNames[0],
    })
  );

  const terminalRef = React.useRef<Terminal>(getTerminal());

  React.useEffect(() => {
    fetchGVCLocations();

    const resizeObserver = new ResizeObserver(() => {
      incrementDebouncedCounter();
    });

    const terminalParent = document.getElementById("terminal-parent");
    if (terminalParent) {
      resizeObserver.observe(terminalParent);
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }

      if (terminalParent) {
        resizeObserver.unobserve(terminalParent);
      }
    };
  }, []);

  React.useEffect(() => {
    const _value = localStorage.getItem("show-workload-connect-terminal-command");
    if (_value === null) {
      return;
    }
    setIsConnectHelpOpen(_value === "true");
  }, []);

  React.useEffect(() => {
    localStorage.setItem(`connect-command-${org}-${gvc}-${workload.name}`, commandRef.current.value);
  }, [commandRef.current.value]);

  React.useEffect(() => {
    if (gvcLocations.length < 1) {
      return;
    }
    locationRef.current.setOptions([
      { label: "Select a Location", value: "select" },
      ...gvcLocations.map((loc) => ({ value: loc, label: loc })),
    ]);
    locationRef.current.setInitialValue(gvcLocations[0]);
    locationRef.current.setOptions(gvcLocations.map((loc) => ({ value: loc, label: loc })));
  }, [gvcLocations]);

  React.useEffect(() => {
    const loc = locationRef.current.value;
    if (loc === "select") {
      return;
    }
    fetchRemoteHost();
  }, [locationRef.current.value]);

  React.useEffect(() => {
    fit();
  }, [debouncedCounter]);

  function incrementDebouncedCounter() {
    setDebouncedCounter((_d) => String(Number(_d) + 1));
  }

  async function fetchRemoteHost() {
    try {
      setIsLoading(true);
      const deploymentLink = `${resourceLink("workload", workload.name)}/deployment/${locationRef.current.value}`;
      const { data: deployment } = await request({ url: deploymentLink });
      setRemoteHost(deployment.status.remote);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed to fetch clusterId of deployment" });
      setRemoteHost("");
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if (!remoteHost) {
      setReplicas([]);
      return;
    }
    fetchReplicas();
  }, [remoteHost]);

  function setCounter() {
    _setCounter((x) => x + 1);
  }

  function getTerminal(): Terminal {
    return new Terminal({ cursorBlink: true, cursorStyle: "underline", cursorWidth: 5 });
  }

  async function fetchGVCLocations() {
    try {
      setIsLoading(true);
      const { data: gvcRes } = await request<Gvc>({ url: resourceLink("gvc", gvc!) });
      const locationNames = (gvcRes.spec?.staticPlacement?.locationLinks || []).map(
        (link: string) => link.split("/")[4]
      );
      const validLocationNames: string[] = [];
      for (let locationName of locationNames) {
        try {
          const { data: locationRes } = await request<Location>({ url: resourceLink("location", locationName) });
          if (locationRes.spec?.enabled) {
            validLocationNames.push(locationName);
          }
        } catch (e) {
          validLocationNames.push(locationName);
        }
      }
      setGVCLocations(validLocationNames);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.warning({ message: "Failed to fetch gvc locations" });
    }
  }

  async function fetchReplicas() {
    try {
      setIsLoading(true);
      const url = `${remoteHost}/replicas/org/${org}/gvc/${gvc}/workload/${workload.name}`;
      const res = await request({ service: "self", url: url });
      setReplicas(res.data.items || []);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.warning({
        message: "Failed to fetch replica list",
      });
      setReplicas([]);
    }
  }

  async function fetchAndReturnReplicas(): Promise<string[]> {
    try {
      setIsLoading(true);
      const url = `${remoteHost}/replicas/org/${org}/gvc/${gvc}/workload/${workload.name}`;
      const res = await request({ service: "self", url: url });
      setIsLoading(false);
      return res.data.items;
    } catch (e) {
      setIsLoading(false);
      return [];
    }
  }

  // Socket and Terminal Handling Functions

  function setSocketListeners() {
    if (!socketRef.current) {
      return;
    }

    let replicaToUse = replica;
    if (!replicaToUse && !!lastUsedReplica && replicas.includes(lastUsedReplica)) {
      replicaToUse = lastUsedReplica;
    }
    if (!replicaToUse) {
      replicaToUse = replicas[0];
    }
    setLastUsedReplica(replicaToUse);

    socketRef.current.onopen = async () => {
      window.location.hash = "#socket=opened";
      const accessToken = await getToken();

      // Generate a new terminal instance
      terminalRef.current = getTerminal();
      setCounter();

      const dimensions = getDimensions();

      // Initialize request object
      const requestObj: any = {
        token: accessToken,
        org,
        gvc,
        pod: replicaToUse,
        container: containerRef.current.value,
        shell: commandRef.current.value,
        width: dimensions.cols,
        height: dimensions.rows,
        requestVersion: "v1",
      };

      // Initiate connection
      socketRef.current!.send(JSON.stringify(requestObj));

      // Mount xTerm
      terminalRef.current.open(document.getElementById("terminal")!);
      setIsConnecting(true);
      setIsTerminalOpen(true);
      setTimeout(() => {
        terminalRef.current.focus();
      }, 100);
      incrementDebouncedCounter();

      // Feed input from xTerm to socket
      terminalRef.current.onData((val) => {
        if (socketRef.current) {
          const message: SocketMessage = {
            type: "data",
            buffer: Array.from(Buffer.from(val)),
            width: terminalRef.current.cols,
            height: terminalRef.current.rows,
          };

          socketRef.current.send(JSON.stringify(message));
        }
      });
    };

    socketRef.current.onmessage = async (event) => {
      setIsConnecting(false);

      try {
        let msg = event.data;

        // Feed socket response to xTerm
        const arrayBuffer = await (msg as Blob).arrayBuffer();
        var string = new TextDecoder().decode(arrayBuffer);
        if (terminalRef.current) {
          terminalRef.current.write(string);
        }
      } catch (e) {
        console.error("onmessage error", e.message);
      }
    };

    socketRef.current.onclose = () => {
      window.location.hash = "#socket=closed";
      setIsConnecting(false);
      socketOnClose(false);
    };

    socketRef.current.onerror = () => {
      setIsConnecting(false);
      socketOnClose(false);

      notification.error({
        message: `Unable to reach remote endpoint ${remoteHost}. Please ensure that this address is not blocked by your firewall or contact support for assistance.`,
      });
    };
  }

  function clearSocketListeners() {
    if (!socketRef.current) {
      return;
    }

    socketRef.current.onopen = () => {};
    socketRef.current.onmessage = () => {};
    socketRef.current.onclose = () => {};
    socketRef.current.onerror = () => {};
  }

  function socketOnClose(forced: boolean) {
    if (forced) {
      if (terminalRef.current) {
        terminalRef.current.dispose();
        setIsTerminalOpen(false);
      }
      terminalRef.current = null as any;
      const terminalElement = document.getElementById("terminal");
      if (terminalElement) {
        const child = terminalElement.firstChild;
        if (child) {
          terminalElement.removeChild(child);
        }
      }
    }
    clearSocketListeners();
    socketRef.current = null;
    setCounter();
    setDisconnected(!forced);
  }

  function forceCloseConnection() {
    socketOnClose(true);
    setCounter();
  }

  async function onConnect() {
    setDisconnected(false);
    socketOnClose(true);
    const client = new WebSocket(`${remoteHost.replace("https://", "wss://")}/remote`);
    socketRef.current = client;
    setSocketListeners();
  }

  async function onReconnect() {
    try {
      const _replicas = await fetchAndReturnReplicas();
      if (!_replicas.includes(lastUsedReplica)) {
        throw new Error("failed");
      } else {
        onConnect();
      }
    } catch (e) {
      notification.warning({ message: "Failed", description: "Replica is not found." });
      forceCloseConnection();
    }
  }

  async function onDetach() {
    const token = await getToken();

    const command = commandRef.current.value;
    const container = containerRef.current.value;
    const location = locationRef.current.value;

    let hash = `org=${org}&gvc=${gvc}&replica=${lastUsedReplica}&remoteHost=${remoteHost}&token=${token}`;
    hash += `&command=${command}&container=${container}&location=${location}&workload=${workload.name}`;

    window.open(
      `/connect/#${encodeURI(hash)}`,
      uuidv4(),
      "left=20,top=20,width=800,height=500,toolbar=no,resizable=yes,location=no"
    );
  }

  function fit() {
    if (!terminalRef.current) {
      return;
    }

    const dimensions = getDimensions();

    if (terminalRef.current.rows !== dimensions.rows || terminalRef.current.cols !== dimensions.cols) {
      terminalRef.current.resize(dimensions.cols, dimensions.rows);

      if (socketRef.current) {
        const message: SocketMessage = {
          type: "resize",
          buffer: [],
          width: terminalRef.current.cols,
          height: terminalRef.current.rows,
        };

        socketRef.current.send(JSON.stringify(message));
      }
    }
  }

  function getDimensions() {
    const terminal = terminalRef.current;
    const currentDimensions = { cols: terminal.cols, rows: terminal.rows };

    if (!terminal.element || !terminal.element.parentElement) {
      return currentDimensions;
    }

    const core = (terminal as any)._core;

    if (core._renderService.dimensions.actualCellWidth === 0 || core._renderService.dimensions.actualCellHeight === 0) {
      return currentDimensions;
    }

    const parentElementStyle = window.getComputedStyle(terminal.element.parentElement);
    const parentElementHeight = parseInt(parentElementStyle.getPropertyValue("height"));
    const parentElementWidth = Math.max(0, parseInt(parentElementStyle.getPropertyValue("width")));
    const elementStyle = window.getComputedStyle(terminal.element);
    const elementPadding = {
      top: parseInt(elementStyle.getPropertyValue("padding-top")),
      bottom: parseInt(elementStyle.getPropertyValue("padding-bottom")),
      right: parseInt(elementStyle.getPropertyValue("padding-right")),
      left: parseInt(elementStyle.getPropertyValue("padding-left")),
    };
    const elementPaddingVer = elementPadding.top + elementPadding.bottom;
    const elementPaddingHor = elementPadding.right + elementPadding.left;
    const availableHeight = parentElementHeight - elementPaddingVer;
    const availableWidth = parentElementWidth - elementPaddingHor - core.viewport.scrollBarWidth;
    const geometry = {
      cols: Math.max(2, Math.floor(availableWidth / core._renderService.dimensions.actualCellWidth)),
      rows: Math.max(2, Math.floor(availableHeight / core._renderService.dimensions.actualCellHeight)),
    };
    return geometry;
  }

  function handleCollapseToggle() {
    const _value = !isConnectHelpOpen;
    setIsConnectHelpOpen(!isConnectHelpOpen);
    localStorage.setItem("show-workload-connect-terminal-command", _value ? "true" : "false");
  }

  const canConnect =
    !!locationRef.current.value && !!containerRef.current.value && !!remoteHost && !!commandRef.current.value;

  const cliConnectCommand = `cpln workload connect ${workload.name} --location ${locationRef.current.value} ${
    replica && `--replica ${replica} `
  }--container ${containerRef.current.value} --shell ${commandRef.current.value} --org ${org} --gvc ${gvc}`;
  const cliConnectDescription = (
    <p>
      Connect via terminal using the{" "}
      <a href={`${DOCS_URL}/reference/cli#workload-connect`} target="_blank" className="color-link">
        Workload Connect
      </a>{" "}
      CLI command:
    </p>
  );

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="text-2xl">Connect</div>
          {socketRef.current && !disconnected ? (
            <NGButton size={"small"} variant={"primary"} onClick={onDetach}>
              Detach
            </NGButton>
          ) : null}
          {disconnected ? <NGAlert size="small" message={"Disconnected"} /> : null}
        </div>
        {canConnect ? (
          <button onClick={handleCollapseToggle} className="flex items-center ngfocus color-link">
            <span>{isConnectHelpOpen ? "Hide Terminal Command" : "Show Terminal Command"}</span>
            {isConnectHelpOpen ? <ChevronUp className="feather-icon" /> : <ChevronDown className="feather-icon" />}
          </button>
        ) : null}
      </div>
      {isConnectHelpOpen && canConnect ? (
        <div className={`mb-4 p-4 border`}>
          {cliConnectDescription}
          <CodeSnippet code={cliConnectCommand} className="mt-3 text-sm" />
        </div>
      ) : null}
      <div className="flex items-center gap-4 mb-4">
        <div>
          <NGLabel>{locationRef.current.label}</NGLabel>
          <NGSelect
            style={{ width: 450 }}
            value={locationRef.current.value}
            onChange={locationRef.current.setValue as any}
            options={locationRef.current.options}
          />
        </div>
        <div>
          <NGLabel>{containerRef.current.label}</NGLabel>
          <NGSelect
            style={{ width: 450 }}
            value={containerRef.current.value}
            onChange={containerRef.current.setValue as any}
            options={containerRef.current.options}
          />
        </div>
      </div>
      {replicas.length > 0 ? (
        <div className="flex items-center gap-4 mb-4">
          <div>
            <NGLabel>Replica</NGLabel>
            <NGSelect
              style={{ width: 450 }}
              value={replica}
              onChange={(val: any) => setReplica(val)}
              options={[
                { value: "", label: "Auto", isLabel: false, disabled: false },
                ...replicas.map((_replica) => ({
                  label: _replica,
                  value: _replica,
                  isLabel: false,
                  disabled: false,
                })),
              ]}
              buttons={[{ title: "Refresh", onClick: fetchReplicas, render: () => <RefreshCcw className="h-4" /> }]}
            />
          </div>
          <div>
            <NGLabel>Command</NGLabel>
            <NGInput
              style={{ width: 450 }}
              value={commandRef.current.value}
              onChange={(e) => commandRef.current.setValue(e.target.value)}
            />
          </div>
          <div>
            <NGLabel className="invisible">Connect</NGLabel>
            <NGButton
              variant={socketRef.current ? "danger" : "primary"}
              outlined={!!socketRef.current}
              disabled={isLoading || !canConnect}
              loading={isLoading}
              onClick={socketRef.current ? forceCloseConnection : disconnected ? onReconnect : onConnect}
            >
              {socketRef.current ? "Disconnect" : disconnected ? "Reconnect" : "Connect"}
            </NGButton>
          </div>
        </div>
      ) : (
        <div style={{ width: 450 }} className="mb-4 flex gap-4 items-center">
          {isLoading ? (
            <NGAlert type="info" className="flex-grow" message="Fetching replicas." />
          ) : (
            <NGAlert type="warning" className="flex-grow" message="No replica was found." />
          )}
          <NGButton loading={isLoading} disabled={isLoading} variant={"secondary"} onClick={fetchReplicas}>
            Refresh
          </NGButton>
        </div>
      )}
      <div
        id="terminal-parent"
        className={`relative p-4 bg-black ${disconnected ? "" : "resize overflow-scroll"} ${
          isTerminalOpen ? "visible" : "invisible"
        }`}
      >
        <div id={`terminal`} className="w-full h-full bg-black" />
        {isTerminalOpen && isConnecting ? (
          <div
            className="absolute p-2 rounded bg-dark text-white"
            style={{ left: "50%", top: "50%", zIndex: 10, transform: "translateX(-50%) translateY(-50%)" }}
          >
            <Loader className="feather-icon animate-spin" />
          </div>
        ) : null}
      </div>
      <div className="absolute invisible" style={{ left: -999 }}>
        {counter}
      </div>
    </div>
  );
};

export const WorkloadConnect = observer(WorkloadConnectRaw);
