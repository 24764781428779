import * as React from "react";
import { GVCMobx } from "../../mst/kinds/gvc";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { FormButtons } from "../../components/forms/formButtons";
import { useDetailContext } from "../../components/detail/detailContext";
import { StringMobx } from "../../mobxDataModels/stringModel";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { NGSwitch } from "../../newcomponents/switch";
import NGAlert from "../../newcomponents/alert";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useNGFormContext } from "../../reactContexts/ngFormContext";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { NGLabelText } from "../../newcomponents/text/labelText";

interface Props {
  gvc: GVCMobx;
}

const LoadBalancerRaw: React.FC<Props> = ({ gvc }) => {
  const formData = useNGFormContext();
  const { fetchItem } = useDetailContext();

  function getDefaultIsDedicatedLoadBalancerEnabled() {
    return gvc.spec.loadBalancer?.dedicated || false;
  }
  const [isDedicatedLoadBalancerEnabled, setIsDedicatedLoadBalancerEnabled] = React.useState(
    getDefaultIsDedicatedLoadBalancerEnabled()
  );

  function getDefaultTrustedProxies() {
    return gvc.spec.loadBalancer?.trustedProxies || 0;
  }
  const trustedProxiesRef = React.useRef<StringMobx>(
    NumberModel.create({
      label: "Trusted Proxies",
      min: 0,
      max: 2,
      initialValue: getDefaultTrustedProxies(),
      isRequired: true,
    })
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [promptWhen, setPromptWhen] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(promptWhen);
  }, [promptWhen]);

  useCleanPrompt();

  React.useEffect(() => {
    let res = false;
    if (isDedicatedLoadBalancerEnabled !== getDefaultIsDedicatedLoadBalancerEnabled()) res = true;
    if (isDedicatedLoadBalancerEnabled) {
      if (trustedProxiesRef.current.isDirty) res = true;
    }
    setIsDirty(res);
  }, [isDedicatedLoadBalancerEnabled, trustedProxiesRef.current.isDirty, gvc.version]);

  React.useEffect(() => {
    let res = true;
    if (isDedicatedLoadBalancerEnabled) {
      if (!trustedProxiesRef.current.isValid) res = false;
    }
    setIsValid(res);
  }, [isDedicatedLoadBalancerEnabled, trustedProxiesRef.current.isValid, gvc.version]);

  React.useEffect(() => {
    setPromptWhen(isDirty);
  }, [isDirty]);

  async function confirm() {
    try {
      setIsLoading(true);
      const body: any = { spec: {} };
      body.spec.loadBalancer = {
        dedicated: isDedicatedLoadBalancerEnabled,
      };

      if (
        isDedicatedLoadBalancerEnabled &&
        trustedProxiesRef.current.value.length > 0 &&
        trustedProxiesRef.current.isValid
      ) {
        body.spec.loadBalancer.trustedProxies = Number(trustedProxiesRef.current.value);
      }
      await gvc.patch(body);
      setPromptWhen(false);
      trustedProxiesRef.current.confirm();
      notification.success({
        message: "Success",
        description: "Updated GVC Locations",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  async function reset() {
    setIsDedicatedLoadBalancerEnabled(getDefaultIsDedicatedLoadBalancerEnabled());
    trustedProxiesRef.current.reset();
  }

  return (
    <>
      <div className="mb-4">
        <NGAlert
          type="info"
          message={`Creates a dedicated load balancer in each location and enables additional Domain features: custom ports,
            protocols and wildcard hostnames. Charges apply for each location.`}
        />
        <div className="mb-1 mt-4 flex items-center">
          <NGSwitch
            data-testid="switch-capacity-ai"
            value={isDedicatedLoadBalancerEnabled}
            onChange={setIsDedicatedLoadBalancerEnabled}
          >
            <NGLabelText>Dedicated Load Balancer</NGLabelText>
          </NGSwitch>
        </div>
      </div>
      {isDedicatedLoadBalancerEnabled ? (
        <>
          <NGFormElement
            label={"Trusted Proxies"}
            required
            value={trustedProxiesRef.current.value}
            onChange={trustedProxiesRef.current.setValue}
            name="trustedProxies"
            info={[
              "Controls the address used for request logging and for setting the X-Envoy-External-Address header.",
              "If set to 1, then the last address in an existing X-Forwarded-For header will be used in place of the source client IP address.",
              "If set to 2, then the second to last address in an existing X-Forwarded-For header will be used in place of the source client IP address. If the XFF header does not have at least two addresses or does not exist then the source client IP address will be used instead.",
              "Default value is 0.",
            ]}
            error={trustedProxiesRef.current.error}
          />
        </>
      ) : null}
      <FormButtons
        loading={isLoading}
        resetDisabled={isLoading || !isDirty}
        saveDisabled={isLoading || !isDirty || !isValid}
        onReset={reset}
        onSave={confirm}
      />
    </>
  );
};

export const LoadBalancer = observer(LoadBalancerRaw);
