import React from "react";
import { observer } from "mobx-react-lite";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { TemplateItem } from "../types/template";
import { Theme } from "../../../mobxStores/uiData/theme";
import { NGChip } from "../../../newcomponents/chip";
import { getTemplateVersion, getVersions } from "../utils";

interface Props {
  template: TemplateItem;
  unfiltered?: boolean;
}
const MarketplaceTemplateCardRaw: React.FC<Props> = ({ template, unfiltered }) => {
  const versions = getVersions(template);
  const templateVersion = getTemplateVersion(template, versions[versions.length - 1]);

  return (
    <>
      <Link
        to={`${template.id}`}
        className={clsx("template-card p-4 rounded-md cursor-pointer")}
        style={{
          minHeight: 180,
          backgroundColor: Theme.theme === "dark" ? "rgb(52, 66, 86)" : "rgb(253, 253, 253)",
          opacity: unfiltered ? 0.5 : undefined,
        }}
      >
        <div className="h-1/3 flex justify-between items-start gap-4">
          <div
            className="h-full flex items-center justify-center"
            style={{
              width: "auto",
              maxWidth: 75,
              height: 49,
              backgroundColor: template.id === "pgedge" ? "#02062b" : undefined,
              paddingInline: template.id === "pgedge" ? 6 : undefined,
              borderRadius: template.id === "pgedge" ? 6 : undefined,
            }}
          >
            <img
              className="object-contain w-full h-full"
              src={`/resources/marketplace/template/${template.id}/icon.png`}
            />
          </div>
          <NGChip variant={`info`} size="small" label={versions[versions.length - 1]} />
        </div>
        <div className="h-2/3 flex flex-col pt-2">
          <div className="text-lg font-semibold template-card-name">{template.name}</div>
          <div className="text-sm">{templateVersion.chart.description}</div>
        </div>
      </Link>
    </>
  );
};

export const MarketplaceTemplateCard = observer(MarketplaceTemplateCardRaw);
