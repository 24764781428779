import * as React from "react";
import { Kind } from "../../mst/base";
import { nameOfKind } from "../../services/cpln";
import { NGButton } from "../../newcomponents/button/Button";
import { NGInput } from "../../newcomponents/input/input";
import { observer } from "mobx-react-lite";
import { PlusCircle } from "react-feather";
import { PushpinTwoTone } from "@ant-design/icons";
import { Tooltip } from "../Tooltip";
import { UIData } from "../../mobxStores/uiData/uiData";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Scrollbars from "react-custom-scrollbars";
import "./index.scss";

enum OPTION_POSITION {
  FIRST,
  LAST,
}

export interface LinkWithChangeItem {
  labels: string[];
  value: string;
}

interface IProps {
  kind: Kind;
  label: string | null;
  value?: string | null;
  style?: React.CSSProperties;
  items: LinkWithChangeItem[];
  createItem?: () => void;
  selectItem: (name: string) => void;
  canBePinned: boolean;

  /* Overflow Options */
  dropdownButtonRef?: React.MutableRefObject<HTMLButtonElement>;
  dropdownRef?: React.MutableRefObject<HTMLDivElement>;
  portalRendered?: boolean;
  showDropdown?: boolean;
  setShowDropdown?: (value: boolean) => void;
}

const SelectMenuRaw = (props: IProps) => {
  /* Definitions */
  const kindName = nameOfKind(props.kind);
  // const otherItems = props.items.filter((item) => item.value !== props.label);
  const otherItems = props.items;
  const showSearch = otherItems.length > 6;
  const showCreateButton = props.createItem != null;
  const isPinned = (props.kind === "org" && UIData.isOrgPinned) || (props.kind === "gvc" && UIData.isGVCPinned);

  /* use State */
  const [searchContent, setSearchContent] = useState("");
  const [activeOptionIndex, setActiveOptionIndex] = useState(-1);
  const [filteredItems, setFilteredItems] = useState<LinkWithChangeItem[]>([]);

  /* use Ref */
  const scrollbarRef = useRef<Scrollbars>(null as any);

  /* use Effect */
  useEffect(() => {
    if (props.showDropdown === true) {
      focusOnOption(OPTION_POSITION.FIRST);
    } else {
      setActiveOptionIndex(-1);
    }
  }, [props.showDropdown]);

  useEffect(() => {
    const _filteredItems = searchItemsByPriority(props.items, searchContent);
    setFilteredItems(_filteredItems);

    if (props.showDropdown) {
      setActiveOptionIndex(getActiveOptionIndex(_filteredItems));
    }
  }, [searchContent, props.items.length]);

  /* Functions */
  function searchItemsByPriority(items: LinkWithChangeItem[], searchContent: string): LinkWithChangeItem[] {
    if (!searchContent) {
      return items;
    }

    const startsWithItems: LinkWithChangeItem[] = [];
    const includesItems: LinkWithChangeItem[] = [];

    // Convert searchContent to lowercase for case-insensitive comparison
    const searchLower: string = searchContent.toLowerCase();

    for (const item of items) {
      // Iterate over item labels
      for (const label of item.labels) {
        // Convert label to lowercase for case-insensitive comparison
        const labelLower = label.toLowerCase();

        // Accumulate items that starts with / includes search lower accordingly
        if (labelLower.startsWith(searchLower)) {
          startsWithItems.push(item);
          break;
        } else if (labelLower.includes(searchLower)) {
          includesItems.push(item);
          break;
        }
      }
    }

    return [...startsWithItems, ...includesItems];
  }

  function handleKeyUpEvent(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key !== "Escape") {
      return;
    }
    setSearchContent("");
    if (props.setShowDropdown) props.setShowDropdown(false);
    if (props.dropdownButtonRef) props.dropdownButtonRef.current.focus();
  }

  function handleSearchKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== "Enter") {
      return;
    }
    if (activeOptionIndex < 0) {
      return;
    }
    const item = filteredItems[activeOptionIndex];
    if (item.value !== props.label) {
      props.selectItem(item.value);
    }
    if (props.setShowDropdown) props.setShowDropdown(false);
  }

  function focusOnOption(optionPosition: OPTION_POSITION) {
    if (filteredItems.length < 1) {
      return;
    }

    let dataPosition: string;
    switch (optionPosition) {
      case OPTION_POSITION.FIRST:
        dataPosition = "firstitem";
        break;
      case OPTION_POSITION.LAST:
        dataPosition = "lastitem";
        break;
      default:
        dataPosition = "firstitem";
        break;
    }

    const el: HTMLButtonElement | null = document.querySelector(
      `[data-subject="${props.kind}"][data-${dataPosition}="true"]`
    );
    if (el) {
      el.focus();
    }
  }

  function handleBackwardFocusKeyDown(e: React.KeyboardEvent<HTMLButtonElement | HTMLInputElement>) {
    if (e.key === "Tab" && e.shiftKey) {
      e.preventDefault();

      // const isSearchElement = e.target === searchInputRef.current;
      // const isCreateElement = !isSearchElement;

      // search create exist together
      // if on create, go to search
      if (showSearch) {
        //  && isCreateElement
        // searchInputRef.current.focus();
      } else if (showSearch) {
        //  && isSearchElement
        // if on search, go to last item
        focusOnOption(OPTION_POSITION.LAST);
      } else if (!showSearch) {
        //  && isCreateElement
        // create shows only - go to last item
        focusOnOption(OPTION_POSITION.LAST);
      }
    }
  }

  function handleSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchContent(event.target.value);
  }

  function handleCreateClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (props.createItem == null) {
      return;
    }
    if (props.setShowDropdown) props.setShowDropdown(false);
    props.createItem();
  }

  function getActiveOptionIndex(collection: LinkWithChangeItem[]) {
    if (collection.length < 1 || !props.showDropdown) {
      return -1;
    }

    return !!searchContent ? 0 : -1;
  }

  function handleOptionKeyDown(index: number, e: React.KeyboardEvent<HTMLButtonElement>) {
    if (e.key === "Tab" && e.shiftKey && index === 0 && !showSearch && !showCreateButton) {
      e.preventDefault();
      focusOnOption(OPTION_POSITION.LAST);
    }
    if (e.key === "Tab" && !e.shiftKey && activeOptionIndex === filteredItems.length - 1) {
      e.preventDefault();

      // if (showSearch) {
      //   searchInputRef.current.focus();
      // } else
      // if (showCreateButton) {
      //   createButtonRef.current.focus();
      // } else {
      focusOnOption(OPTION_POSITION.FIRST);
      // }

      setActiveOptionIndex(getActiveOptionIndex(filteredItems));
      scrollbarRef.current?.scrollToTop();
    }
  }

  function handleOptionMouseEnter(index: number) {
    setActiveOptionIndex(index);
  }

  function handleOptionMouseLeave() {
    setActiveOptionIndex(getActiveOptionIndex(filteredItems));
  }

  function handleSelectItemClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, name: string) {
    e.stopPropagation();
    if (name !== props.label) {
      props.selectItem(name);
    }
    if (props.setShowDropdown) props.setShowDropdown(false);
  }

  return (
    <div
      id="change-dropdown-parent"
      ref={props.dropdownRef}
      onKeyUp={handleKeyUpEvent}
      style={props.style}
      className={clsx(
        "select-window",
        props.dropdownRef && "useFadeInOutAnim",
        props.showDropdown ? `open fadeIn` : `closed`,
        props.portalRendered && !props.showDropdown ? "fadeOut" : "",
        props.portalRendered ? "" : "shrink"
      )}
    >
      <div className="select-window-head">
        <div className="select-window-head-titles">
          <span>Selected {kindName}</span>
          <label>{props.label}</label>
        </div>
        {props.canBePinned ? (
          <PushpinTwoTone
            style={{ fontSize: "1.25rem" }}
            onClick={() => {
              if (props.kind === "gvc") UIData.setIsGVCPinned(!UIData.isGVCPinned);
              else if (props.kind === "org") UIData.setIsOrgPinned(!UIData.isOrgPinned);
              if (props.setShowDropdown) props.setShowDropdown(false);
            }}
            twoToneColor={isPinned ? "#0084FF" : "#BFBFBF"}
          />
        ) : null}
      </div>
      {showSearch || showCreateButton ? (
        <div className="select-window-actions">
          {showSearch && (
            <NGInput
              value={searchContent}
              size={"normal"}
              style={{ width: "100%" }}
              // TODO maybe use a merged ref solution
              // ref={searchInputRef}
              placeholder="Search"
              onKeyUp={handleSearchKeyUp}
              onKeyDown={handleBackwardFocusKeyDown}
              onChange={handleSearchInputChange}
              autoFocus={!isPinned}
            />
          )}
          {showCreateButton && (
            <NGButton
              variant="action"
              size={"normal"}
              style={{ width: "100%" }}
              renderIcon={(hover, props) => <PlusCircle size={21} {...props} />}
              onKeyDown={handleBackwardFocusKeyDown}
              onClick={handleCreateClick}
              iconPosition="right"
              autoFocus={!isPinned && !showSearch}
            >
              Create {kindName}
            </NGButton>
          )}
        </div>
      ) : null}
      {otherItems.length === 0 ? (
        <div className={clsx("no-items-found", "space")}>
          <span>No Other {kindName} Found</span>
        </div>
      ) : (
        <Scrollbars ref={scrollbarRef}>
          <div className="select-window-list">
            {filteredItems.length === 0 ? (
              <div className="no-items-found">
                <span>No Search Results</span>
              </div>
            ) : (
              filteredItems.map((item, index) => {
                const label = item.labels[0];
                const isSelected = item.value === props.value;
                const isFirstItem = index == 0;
                const isLastItem = index == filteredItems.length - 1;
                return (
                  <Tooltip key={item.value} title={label.length <= 26 ? "" : label} placement="right">
                    <button
                      key={item.value}
                      onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => handleOptionKeyDown(index, e)}
                      onMouseEnter={() => handleOptionMouseEnter(index)}
                      onMouseLeave={handleOptionMouseLeave}
                      onFocus={() => handleOptionMouseEnter(index)}
                      onClick={(e) => handleSelectItemClick(e, item.value)}
                      data-subject={props.kind}
                      data-firstitem={isFirstItem ? "true" : "false"}
                      data-lastitem={isLastItem ? "true" : "false"}
                      data-testid={`${item.value}-${props.dropdownButtonRef ? "overflow" : "pinned"}`}
                      style={{ display: "inline", overflow: "hidden", textOverflow: "ellipsis" }}
                      className={clsx(`menu-link ngfocus w-full text-left whitespace-nowrap`, {
                        selected: isSelected,
                      })}
                    >
                      {label}
                    </button>
                  </Tooltip>
                );
              })
            )}
            {/* Add additional spacing at the end of the scrollbar element */}
            <div style={{ height: "2px", flexShrink: 0 }} />
          </div>
        </Scrollbars>
      )}
    </div>
  );
};

export const SelectMenu = observer(SelectMenuRaw);
