import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../services/cpln";
import { DryRunAlert } from "../../dryRunAlert";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { MoreHorizontal } from "react-feather";
import { NGProviderBrowser } from "../../ngProviderBrowser";
import { NGSelect } from "../../../../newcomponents/select/ngselect";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";

type RouteParams = "index";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderLambdalabsNodePoolRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [browserKey, setBrowserKey] = React.useState("");

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const l = mk8sDraft.provider_lambdalabs;
  const np = l.nodePools.find((np) => np.index === index);

  // TODO fix
  // React.useEffect(() => {
  //   const minString = np.minSize.value;
  //   const min = Number(minString);
  //   if (!Number.isNaN(min)) {
  //     np.maxSize.setMin(min);
  //   }
  // }, [np.minSize.value]);

  if (!np) {
    return <Navigate to={`${pathname.split("/-node-pools")[0]}/-node-pools`} />;
  }

  const pre = `lambdalabs.nodePools[${index}].`;

  return (
    <>
      <div>
        <NGFormElement
          name={`${pre}name`}
          label={np.name.label}
          value={np.name.value}
          onChange={np.name.setValue}
          required
          error={!l.isNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
        />

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}instanceType`}
            label={np.instanceType.label}
            value={np.instanceType.value}
            onChange={np.instanceType.setValue}
            required={np.instanceType.isRequired}
            error={np.instanceType.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => setBrowserKey("instanceType"),
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.instanceType.setValue}
              path={`spec.provider.lambdalabs.nodePools[${index}].instanceType`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}minSize`}
            label={np.minSize.label}
            value={np.minSize.value}
            onChange={np.minSize.setValue}
            required={np.minSize.isRequired}
            error={np.minSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.minSize.setValue}
              path={`spec.provider.lambdalabs.nodePools[${index}].minSize`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}maxSize`}
            label={np.maxSize.label}
            value={np.maxSize.value}
            onChange={np.maxSize.setValue}
            required={np.maxSize.isRequired}
            error={np.maxSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.maxSize.setValue}
              path={`spec.provider.lambdalabs.nodePools[${index}].maxSize`}
            />
          </div>
        </div>
        <NGInputListMst
          label="Labels"
          className="mb-8"
          data={np.labels}
          firstInput={(item) => (
            <NGInput
              required
              invalid={!item.firstValue}
              ignoreTouched
              placeholder="Key"
              value={item.firstValue}
              onChange={(e) => item.setFirstValue(e.target.value)}
            />
          )}
          secondInput={(item) => (
            <NGInput
              required
              invalid={!item.secondValue}
              ignoreTouched
              placeholder="Value"
              value={item.secondValue}
              onChange={(e) => item.setSecondValue(e.target.value)}
            />
          )}
        />
        <NGInputListMst
          label="Taints"
          data={np.taints}
          firstInput={(item) => (
            <NGInput
              required
              invalid={!item.firstValue}
              ignoreTouched
              placeholder="Key"
              value={item.firstValue}
              onChange={(e) => item.setFirstValue(e.target.value)}
            />
          )}
          secondInput={(item) => (
            <NGInput
              required
              invalid={!item.secondValue}
              ignoreTouched
              placeholder="Value"
              value={item.secondValue}
              onChange={(e) => item.setSecondValue(e.target.value)}
            />
          )}
          thirdInput={(item) => (
            <NGSelect
              placeholder="Effect"
              invalid={!item.thirdValue}
              ignoreTouched
              value={item.thirdValue}
              onChange={(value) => item.setThirdValue(value)}
              options={[
                { label: "NoSchedule", value: "NoSchedule" },
                { label: "PreferNoSchedule", value: "PreferNoSchedule" },
                { label: "NoExecute", value: "NoExecute" },
              ]}
            />
          )}
        />
        <NGButton
          style={{ width: 220 }}
          variant={"danger"}
          outlined
          onClick={() => {
            l.removeNodePoolAt(np.index);
            navigate(`${pathname.split("/-node-pools")[0]}/-node-pools`);
          }}
        >
          Delete Node Pool
        </NGButton>
      </div>
      {browserKey === "instanceType" ? (
        <NGProviderBrowser
          title="Browse Lambda Labs Instance Types"
          fetchData={async () => {
            // TODOMK8S paginate
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "instance-types",
                cluster: mk8sDraft.asObject,
              },
            });
            return Object.values(data.data).map((v: any) => v.instance_type);
          }}
          sortable
          onOk={(value) => np.instanceType.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Cores", "Memory", "Storage", "GPUs", "Cost per Month"]}
          rightAlign={["Cost per Month"]}
          distinctFilters={["Cores", "GPUs", "Memory"]}
          getData={(item: any) => {
            const priceCentsPerDay = Number(item.price_cents_per_hour || 0) * 24;
            const priceCentsPerMonth = priceCentsPerDay * 30;
            const price = priceCentsPerMonth / 100;
            const cost = price.toLocaleString("en-US", { style: "currency", currency: "USD" });

            return [
              //
              item.name,
              item.name,
              item.specs.vcpus,
              [item.specs.memory_gib, `${item.specs.memory_gib}Gi`],
              [item.specs.storage_gib, `${item.specs.storage_gib}Gi`],
              item.specs.gpus,
              [price, cost],
            ];
          }}
        />
      ) : null}
    </>
  );
};

export const ProviderLambdalabsNodePool = observer(ProviderLambdalabsNodePoolRaw);
