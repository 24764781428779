import * as React from "react";
import { useParams } from "react-router-dom";
import { request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import NGAlert from "../../newcomponents/alert";
import { observer } from "mobx-react-lite";
import { Secret } from "../../schema/types/secret";

type RouteParams = "releaseName";
const HelmReleaseDetailRaw: React.FC = () => {
  const { releaseName } = useParams<RouteParams>();

  const [release, setRelease] = React.useState<Secret>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    fetchRelease();
  }, []);

  async function fetchRelease() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("secret", releaseName) });
      setRelease(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setError(errorMessage);
    }
  }

  if (isLoading) {
    return <Loader reason="fetching helm releases" />;
  }

  if (error) {
    return <NGAlert type={"error"} message={error} />;
  }

  return <div>Release: {release.name}</div>;
};

export const HelmReleaseDetail = observer(HelmReleaseDetailRaw);
