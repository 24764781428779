import * as React from "react";
import { useDetailContext } from "../../components/detail/detailContext";
import { StringModel } from "../../mobxDataModels/stringModel";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { Copy, Minus, Plus } from "react-feather";
import { AuditContextMobx } from "../../mst/kinds/auditContext";
import { NGButton } from "../../newcomponents/button/Button";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { CodeEditor } from "../group/identityMatcher/codeEditor";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  auditContext: AuditContextMobx;
}
const InfoRaw: React.FC<Props> = ({ auditContext }) => {
  const { fetchItem } = useDetailContext();
  const descriptionRef = React.useRef(
    StringModel.create({ label: "Description", initialValue: auditContext.description }),
  );
  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isStatusOpen, setIsStatusOpen] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    let res = false;
    if (descriptionRef.current.isDirty) res = true;
    setIsDirty(res);
  }, [descriptionRef.current.isDirty]);

  function toggleStatus() {
    setIsStatusOpen((val) => !val);
  }

  function reset() {
    descriptionRef.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      const description = descriptionRef.current.value || null;
      await auditContext.patch({
        description,
      });
      descriptionRef.current.confirm();
      if (!descriptionRef.current.value) {
        descriptionRef.current.setInitialValue(auditContext.name);
      }
      notification.success({
        message: "Success",
        description: "Updated Audit Context",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <NGInputAdapter data={descriptionRef.current} className="mb-4" style={{ width: 450 }} />
      {auditContext.status ? (
        <div className="mb-4" style={{ width: 450 }}>
          <div style={{ display: "flex", gap: "0.375rem" /* 6px */ }}>
            <NGButton
              variant="secondary"
              outlined
              size="small"
              onClick={toggleStatus}
              renderIcon={(hover, props) =>
                isStatusOpen ? (
                  <Minus data-testid="toggle-env" {...props} />
                ) : (
                  <Plus data-testid="toggle-env" {...props} />
                )
              }
            >
              Status
            </NGButton>
            {!isStatusOpen ? (
              <NGButton
                variant="secondary"
                outlined
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(JSON.stringify(auditContext.status || {}, null, 2));
                  notification.success({ message: "Copied to clipboard" });
                }}
                renderIcon={(hover, props) => <Copy {...props} />}
              >
                Copy
              </NGButton>
            ) : null}
          </div>
          {isStatusOpen ? (
            <CodeEditor
              value={(auditContext.statusText as string[]).join("\n")}
              setValue={() => {}}
              language={"markdown"}
              options={{
                basicSetup: {
                  highlightActiveLine: false,
                  highlightActiveLineGutter: false,
                  lineNumbers: true,
                  autocompletion: false,
                  foldGutter: false,
                },
                readOnly: true,
              }}
            />
          ) : null}
        </div>
      ) : null}
      <TagLinksTable
        tableId="auditctx-info-tag-links"
        tags={Object.entries(auditContext.tags || {})
          .map(([tagKey, tagValue]) => ({ key: tagKey, value: (tagValue as string) || "" }))
          .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
        styles={{ header: { marginBottom: 0 } }}
      />
      <div className="flex items-center">
        <NGButton
          style={{ width: 215, marginRight: 10 }}
          disabled={isLoading || !isDirty}
          variant={"danger"}
          outlined
          onClick={reset}
        >
          Reset
        </NGButton>
        <NGButton
          variant={"primary"}
          style={{ width: 215, marginLeft: 10 }}
          disabled={isLoading || !isDirty}
          loading={isLoading}
          onClick={save}
        >
          Save
        </NGButton>
      </div>
    </>
  );
};

export const Info = observer(InfoRaw);
