import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";
import { NGLabelText } from "../../../newcomponents/text/labelText";

interface Props {
  workloadDraft: WorkloadDraftMobx;
}
const WorkloadCreateSecurityOptionsRaw: React.FC<Props> = ({ workloadDraft }) => {
  const options = workloadDraft.securityOptions;

  return (
    <>
      <div className="mb-4">
        <NGSwitch value={options.configure} onChange={options.setConfigure}>
          <NGLabelText>Configure Security Options</NGLabelText>
        </NGSwitch>
      </div>
      {options.configure ? (
        <NGInputAdapter
          style={{ width: 450 }}
          data={options.filesystemGroupId}
          infoContents={["The group id assigned to any mounted volume."]}
        />
      ) : null}
    </>
  );
};

export const WorkloadCreateSecurityOptions = observer(WorkloadCreateSecurityOptionsRaw);
