import * as React from "react";
import "./responsive.css";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../../../mobxDataModels/stringModel";
import { request } from "../../../../services/cpln";
import { AccountCreateInfo } from "./info";
import { AccountCreateAddress } from "./address";
import { slug } from "github-slugger";
import { v4 as uuidv4 } from "uuid";
import { CreateFormLink } from "../../../org/create/create";
import { AccountCreateOrgGVC } from "./orgGVC";
import { AccountCreatePaymentMethod } from "./addPaymentMethod";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createOrgGVC } from "../../../../services/onboard";
import {
  REFERRAL_SERVICE_PARTNER_URL,
  HUBSPOT_SERVICE_URL,
  IS_DEPLOYMENT_ENV_TEST,
  IS_HUBSPOT_ACCOUNT_CREATED_FLOW_ENABLED,
  IS_HUBSPOT_INTEGRATION_ENABLED,
  IS_DEPLOYMENT_ENV_PROD,
} from "../../../../envVariables";
import { User } from "../../../../mobxStores/user/user";
import { UserData } from "../../../../mobxStores/userData/userData";
import { BillingContext } from "../../../../mobxStores/billingContext/billingContext";
import { UIData } from "../../../../mobxStores/uiData/uiData";
import { getReferralCookies } from "../../../../services/utils";
import { BasePathContext, useBasePath } from "../../../../reactContexts/basePathContext";
import { PromptContext } from "../../../../mobxStores/prompt/prompt";
import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { NGFormData } from "../../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../../reactContexts/ngFormContext";
import { CreateAccountLayout } from "./accountCreateLayout";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

const BillingAccountCreateRaw: React.FC = () => {
  const location = useLocation();
  const formDataRef = React.useRef(new NGFormData());
  const basePath = location.pathname.includes("/-createfirst")
    ? useBasePath("/account/-createfirst/*", true, "billing")
    : useBasePath("/account/-create/*", true, "billing");
  const navigate = useNavigate();

  const [ensuredMainCookie, setEnsuredMainCookie] = React.useState(false);

  const [externalId, setExternalId] = React.useState<string>(null as any);
  const [externalHandled, setExternalHandled] = React.useState(false);

  const [formState, setFormState] = React.useState<"account" | "paymentmethod" | "orggvc">("account");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isGeneralValid, setIsGeneralValid] = React.useState(false);
  const [isAccountAddressValid, setIsAccountAddressValid] = React.useState(false);
  const [isValidAccount, setIsValidAccount] = React.useState(false);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [accountId, setAccountId] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  const [isConfirmedPaymentMethod, setIsConfirmedPaymentMethod] = React.useState(false);
  const [canCreatePaymentMethod, setCanCreatePaymentMethod] = React.useState(false);
  const [isCreatedPaymentMethod, setIsCreatedPaymentMethod] = React.useState(false);

  const [isInformHubspotUnnecessary, setInformHubspotUnnecessary] = React.useState(false);
  const [isInformingHubspot, setIsInformingHubspot] = React.useState(false);
  const [hubspotInformerTrigger, setHubspotInformerTrigger] = React.useState(0);

  const fullNameRef = React.useRef(StringModel.create({ label: "Full Name", isRequired: true }));
  const accountNameRef = React.useRef(StringModel.create({ label: "Account Name" }));
  const orgNameRef = React.useRef(
    StringModel.create({ label: "Org Name", isRequired: true, validationKey: "name", examples: ["org-1", "my-org"] })
  );
  const gvcNameRef = React.useRef(
    StringModel.create({
      label: "GVC Name",
      isRequired: true,
      validationKey: "name",
      initialValue: "default-gvc",
      examples: ["gvc-1", "my-gvc"],
    })
  );
  const [locations, setLocations] = React.useState<string[]>([]);
  const companyRef = React.useRef(StringModel.create({ label: "Company", isRequired: true }));
  const jobTitleRef = React.useRef(StringModel.create({ label: "Job Title", isRequired: true }));
  const linkedinRef = React.useRef(StringModel.create({ label: "Linkedin Profile", isRequired: false }));
  const phoneRef = React.useRef(
    StringModel.create({
      label: "Phone",
      isRequired: true,
      validationKey: "phone",
      transformKey: "phone",
      examples: ["+0123456789"],
    })
  );

  const accountAddressCountryRef = React.useRef(
    StringModel.create({ label: "Country", isRequired: true, initialValue: "United States" })
  );
  const accountAddressStateRef = React.useRef(StringModel.create({ label: "State" }));
  const accountAddressCityRef = React.useRef(StringModel.create({ label: "City", isRequired: true }));
  const accountAddressPostalCodeRef = React.useRef(StringModel.create({ label: "Zip Code", isRequired: true }));
  const accountAddressLine1Ref = React.useRef(StringModel.create({ label: "Address Line 1", isRequired: true }));
  const accountAddressLine2Ref = React.useRef(StringModel.create({ label: "Address Line 2" }));

  const [canCreate, setCanCreate] = React.useState(false);

  React.useEffect(() => {
    ensureCookieFromWebsite();
    setEnsuredMainCookie(true);
  }, []);

  React.useEffect(() => {
    if (!ensuredMainCookie) {
      return;
    }

    try {
      if (UIData.initialUrl.includes("?id=")) {
        setExternalId(UIData.initialUrl.split("?id=")[1] || "");
      } else if (!!BillingContext.ReferralId) {
        setExternalId(BillingContext.ReferralId);
        BillingContext.setReferralId("");
      } else {
        setExternalId("");
      }
    } catch (e) {
      setExternalId("");
    }
    UIData.clearInitialUrl();
  }, [ensuredMainCookie]);

  React.useEffect(() => {
    if (!ensuredMainCookie) {
      return;
    }

    const id = setInterval(() => {
      setHubspotInformerTrigger((x) => x + 1);
    }, 2 * 60 * 1000);
    return () => {
      clearInterval(id);
    };
  }, [ensuredMainCookie]);

  React.useEffect(() => {
    informHubspot();
  }, [hubspotInformerTrigger]);

  React.useEffect(() => {
    console.error("Acc Create External Id", externalId);
    if (externalId === null) {
      return;
    }
    if (externalHandled) {
      return;
    }
    if (!externalId) {
      setExternalHandled(true);
      return;
    }
    request({ url: `${REFERRAL_SERVICE_PARTNER_URL}/referral/${externalId}`, service: "self" })
      .then(({ data }) => {
        let { accountName, company, fullName, phone, address, email } = data;
        if (email !== User.email) {
          setExternalId("");
          setExternalHandled(true);
          return;
        }
        if (!address) {
          address = {};
        }
        const { city, country, line1, line2, postalCode, state } = address;
        accountNameRef.current.setValue(accountName);
        companyRef.current.setValue(company);
        // not persisting job title and linkedin profile for external id
        fullNameRef.current.setValue(fullName);
        phoneRef.current.setValue(phone);
        accountAddressCityRef.current.setValue(city);
        accountAddressCountryRef.current.setValue(country);
        accountAddressLine1Ref.current.setValue(line1);
        accountAddressLine2Ref.current.setValue(line2);
        accountAddressPostalCodeRef.current.setValue(postalCode);
        accountAddressStateRef.current.setValue(state);
        setExternalHandled(true);
      })
      .catch(() => {
        setExternalHandled(true);
      });
  }, [externalId]);

  React.useEffect(() => {
    if (formState === "paymentmethod") {
      navigate(`${basePath}/-paymentmethod`);
    } else if (formState === "orggvc") {
      navigate(`${basePath}/-orggvc`);
    }
  }, [formState]);

  React.useEffect(() => {
    if (!isCreatedPaymentMethod) {
      return;
    }

    if (isDisabled) {
      executeDisabledFlow();
      return;
    }

    setFormState("orggvc");
  }, [isCreatedPaymentMethod]);

  async function executeDisabledFlow() {
    setIsLoading(true);
    notification.info({ message: "Redirecting, please wait." });
    PromptContext.setIsDisabled(true);
    await BillingContext.setAccount(accountId);
    ConsoleContext.setRedirect(`/billing/account/${accountId}`);
  }

  React.useEffect(() => {
    const nameToUse = accountNameRef.current.value || fullNameRef.current.value;
    if (!nameToUse) {
      return;
    }
    const generatedOrgName = `${slug(nameToUse)}-${uuidv4().slice(0, 6)}`;
    orgNameRef.current.setValue(generatedOrgName);
  }, [accountNameRef.current.value, fullNameRef.current.value]);

  React.useEffect(() => {
    const stateIsRequired = accountAddressCountryRef.current.value === "United States";
    accountAddressStateRef.current.setIsRequired(stateIsRequired);
  }, [accountAddressCountryRef.current.value]);

  React.useEffect(() => {
    let res = true;
    if (!acceptedTerms) res = false;
    if (!fullNameRef.current.isValid) res = false;
    if (!companyRef.current.isValid) res = false;
    if (!jobTitleRef.current.isValid) res = false;
    if (!phoneRef.current.isValid) res = false;
    setIsGeneralValid(res);
  }, [
    acceptedTerms,
    fullNameRef.current.value,
    companyRef.current.isValid,
    jobTitleRef.current.isValid,
    phoneRef.current.isValid,
  ]);

  React.useEffect(() => {
    let res = true;
    if (!isGeneralValid) res = false;
    if (!accountAddressCountryRef.current.isValid) res = false;
    if (!accountAddressStateRef.current.isValid) res = false;
    if (!accountAddressCityRef.current.isValid) res = false;
    if (!accountAddressPostalCodeRef.current.isValid) res = false;
    if (!accountAddressLine1Ref.current.isValid) res = false;
    if (!accountAddressLine2Ref.current.isValid) res = false;
    setIsAccountAddressValid(res);
  }, [
    isGeneralValid,
    accountAddressCountryRef.current.isValid,
    accountAddressStateRef.current.isValid,
    accountAddressCityRef.current.isValid,
    accountAddressPostalCodeRef.current.isValid,
    accountAddressLine1Ref.current.isValid,
    accountAddressLine2Ref.current.isValid,
  ]);

  React.useEffect(() => {
    let res = true;
    if (!isGeneralValid) res = false;
    if (!isAccountAddressValid) res = false;
    setIsValidAccount(res);
  }, [isGeneralValid, isAccountAddressValid]);

  React.useEffect(() => {
    let res = isValidAccount;
    if (formState === "paymentmethod") {
      res = canCreatePaymentMethod;
    } else if (formState === "orggvc") {
      res = orgNameRef.current.isValid && gvcNameRef.current.isValid;
    }
    setCanCreate(res);
  }, [isValidAccount, formState, canCreatePaymentMethod, orgNameRef.current.isValid, gvcNameRef.current.isValid]);

  async function informHubspot() {
    if (isInformingHubspot || isInformHubspotUnnecessary) {
      return;
    }
    let preventHubspot = false;
    // TODO move these checks to helper functions
    if (!User.isLoggedIn || User.email.includes("@controlplane.com") || UserData.hasAccount || UserData.hasOrg) {
      preventHubspot = true;
    }
    if (IS_HUBSPOT_INTEGRATION_ENABLED && !preventHubspot) {
      try {
        setIsInformingHubspot(true);

        // TODO make this a function
        let hubspotutk = "";
        try {
          let _hubspotutk = document.cookie
            .split(" ")
            .find((c) => c.startsWith("hubspotutk"))!
            .split("=")[1];
          if (_hubspotutk.endsWith(";")) {
            _hubspotutk = _hubspotutk.split(";")[0];
          }
          hubspotutk = _hubspotutk;
        } catch (e) {}

        const account = getAccountObject();

        await request({
          service: "self",
          method: "post",
          url: `${HUBSPOT_SERVICE_URL}/firebasesignin`,
          body: {
            account,
            hubspotutk,
            environment: IS_DEPLOYMENT_ENV_TEST ? "test" : "production",
          },
        });

        setIsInformingHubspot(false);
      } catch (e) {
        setIsInformingHubspot(false);
        // TODO keep this in sentry
        console.error("Failed to inform hubspot");
      }
    }
  }

  function getAccountObject() {
    return {
      email: User.email,
      fullName: fullNameRef.current.value,
      accountName: accountNameRef.current.value || fullNameRef.current.value,
      extraInfo: {
        company: companyRef.current.value,
        jobTitle: jobTitleRef.current.value,
        linkedin: linkedinRef.current.value,
      },
      phone: phoneRef.current.value,
      address: {
        address1: accountAddressLine1Ref.current.value,
        address2: accountAddressLine2Ref.current.value,
        postalCode: accountAddressPostalCodeRef.current.value,
        city: accountAddressCityRef.current.value,
        state: accountAddressStateRef.current.value,
        country: accountAddressCountryRef.current.value,
      },
    };
  }

  async function onConfirmAccount() {
    if (!acceptedTerms) {
      return;
    }
    setInformHubspotUnnecessary(true);
    try {
      setIsLoading(true);
      const account = getAccountObject();
      const { data: newAccount } = await request({
        method: "post",
        service: "billing-ng",
        url: `/account`,
        body: { account },
      });

      // api for accept tou
      try {
        await request({
          method: "post",
          service: "billing-ng",
          url: `/account/${newAccount.id}/tou`,
          body: {
            tou: {
              customerName: newAccount.fullName,
              accepted: acceptedTerms,
            },
          },
        });
      } catch (e) {
        let errorMessage = e?.response?.data?.error;
        if (!errorMessage) errorMessage = e.message;
        console.error("Failed to succeed on tou", errorMessage);
        notification.warning({
          message: "Failed to accept terms of service",
          description: errorMessage,
        });
      }

      const _accountId = newAccount.id;
      setAccountId(_accountId);
      const _isDisabled = newAccount.enabled === false;
      setIsDisabled(_isDisabled);

      // inform about new account creation for hubspot
      let preventHubspot = false;
      if (!User.isLoggedIn || User.email.includes("@controlplane.com")) {
        preventHubspot = true;
      }
      if ((IS_HUBSPOT_INTEGRATION_ENABLED || IS_HUBSPOT_ACCOUNT_CREATED_FLOW_ENABLED) && !preventHubspot) {
        try {
          let hubspotutk = "";
          try {
            let _hubspotutk = document.cookie
              .split(" ")
              .find((c) => c.startsWith("hubspotutk"))!
              .split("=")[1];
            if (_hubspotutk.endsWith(";")) {
              _hubspotutk = _hubspotutk.split(";")[0];
            }
            hubspotutk = _hubspotutk;
          } catch (e) {}

          await request({
            service: "self",
            method: "post",
            url: `${HUBSPOT_SERVICE_URL}/accountcreated`,
            body: {
              account: { ...account, id: newAccount.id },
              hubspotutk,
              environment: IS_DEPLOYMENT_ENV_TEST ? "test" : "production",
            },
          });
        } catch (e) {
          // TODO keep this in sentry with all the info
          console.error("Failed to inform hubspot");
        }
      }

      setIsLoading(false);
      informExternal(newAccount.id);
      setFormState("paymentmethod");
    } catch (e) {
      let errorMessage = e?.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function informExternal(accountId: string) {
    if (externalId) {
      informExternalForm(accountId);
      return;
    }
    tryInformExternalCookie(accountId);
  }

  async function informExternalForm(accountId: string) {
    try {
      await request({
        method: "patch",
        url: `${REFERRAL_SERVICE_PARTNER_URL}/referral/${externalId}`,
        service: "self",
        body: { status: "created", accountId },
      });
    } catch (e) {
      console.error("Failed to inform external for form");
    }
  }

  async function tryInformExternalCookie(accountId: string) {
    try {
      const cookies = getReferralCookies();
      await request({
        method: "post",
        url: `${REFERRAL_SERVICE_PARTNER_URL}/cookieReferral`,
        service: "self",
        body: { accountId, email: User.email, ...cookies },
      });
    } catch (e) {
      console.error("Failed to inform external for cookie");
    }
  }

  async function ensureCookieFromWebsite() {
    if (!IS_DEPLOYMENT_ENV_PROD) {
      return;
    }

    const alreadyChecked = (localStorage.getItem("COOKIE_CHECKED") || "false") === "true";
    if (!alreadyChecked) {
      localStorage.setItem("COOKIE_CHECKED", "true");
      // @ts-ignore
      window.location = `https://signup.controlplane.com/check`;
    }
  }

  async function onConfirmPaymentMethod() {
    setInformHubspotUnnecessary(true);
    setIsConfirmedPaymentMethod(true);
  }

  async function onConfirmOrgGVC() {
    setInformHubspotUnnecessary(true);
    setIsLoading(true);
    const { status, message } = await createOrgGVC(
      accountId,
      orgNameRef.current.value,
      gvcNameRef.current.value,
      locations
    );
    await UserData.requestAccountSummaries();
    await BillingContext.setAccount(accountId);
    setIsLoading(false);
    switch (status) {
      case "none":
        notification.warning({
          message: "Failed",
          description: message || "There is a failure. Please try again later.",
        });
        break;
      case "orgCreated":
        notification.success({
          message: "Success",
          description: "Created org, failed to create the GVC",
        });
        PromptContext.setIsDisabled(true);
        ConsoleContext.clearRedirect();
        ConsoleContext.setRedirect("/");
        break;
      case "gvcCreated":
        notification.success({
          message: "Success",
          description: "Created org and GVC",
        });
        PromptContext.setIsDisabled(true);
        ConsoleContext.clearRedirect();
        ConsoleContext.setRedirect("/");
        break;
    }
  }

  let links: (CreateFormLink & { isCompleted: boolean })[] = [
    {
      name: "Account Info",
      value: `-accountinfo`,
      isActive: formState === "account" ? true : false,
      isCompleted: isGeneralValid,
    },
    {
      name: "Address",
      value: `-address`,
      isActive: formState === "account" ? isGeneralValid : false,
      isCompleted: isAccountAddressValid,
    },
    {
      name: "Payment Method",
      value: `-paymentmethod`,
      isActive: formState === "paymentmethod" ? true : false,
      pushTop: true,
      isCompleted: isConfirmedPaymentMethod && canCreatePaymentMethod && isCreatedPaymentMethod,
    },
  ];
  if (!isDisabled) {
    links.push({
      name: "Org & GVC",
      value: `-orggvc`,
      isActive: formState === "orggvc" ? true : false,
      pushTop: true,
      isCompleted:
        isConfirmedPaymentMethod &&
        canCreatePaymentMethod &&
        isCreatedPaymentMethod &&
        orgNameRef.current.isValid &&
        gvcNameRef.current.isValid,
    });
  }

  let next = {
    isActive: isGeneralValid,
    label: "Next (Address)",
    url: "-address",
  };
  if (location.pathname.includes("-address")) {
    next = {
      isActive: false,
      label: "Next",
      url: "",
    };
  } else if (!location.pathname.includes("-accountinfo")) {
    next = {
      isActive: false,
      label: "Next",
      url: "",
    };
  }

  let onConfirm = onConfirmAccount;
  if (formState === "paymentmethod") {
    onConfirm = onConfirmPaymentMethod;
  } else if (formState === "orggvc") {
    onConfirm = onConfirmOrgGVC;
  }

  if (externalId === null || !externalHandled) {
    return null;
  }

  return (
    <BasePathContext.Provider value={basePath}>
      <NGFormContext.Provider value={formDataRef.current}>
        <CreateAccountLayout
          next={next}
          title={
            formState === "account"
              ? `Create Account`
              : formState === "paymentmethod"
              ? "Add a Payment Method"
              : "Create Org & GVC"
          }
          create={{ isActive: canCreate, onConfirm }}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-accountinfo`} />} />
            <Route
              path={`-accountinfo`}
              element={
                <AccountCreateInfo
                  fullName={fullNameRef.current}
                  accountName={accountNameRef.current}
                  company={companyRef.current}
                  jobTitle={jobTitleRef.current}
                  linkedin={linkedinRef.current}
                  phone={phoneRef.current}
                  acceptedTerms={acceptedTerms}
                  setAcceptedTerms={setAcceptedTerms}
                />
              }
            />
            <Route
              path={`-address`}
              element={
                <AccountCreateAddress
                  country={accountAddressCountryRef.current}
                  state={accountAddressStateRef.current}
                  city={accountAddressCityRef.current}
                  postalCode={accountAddressPostalCodeRef.current}
                  line1={accountAddressLine1Ref.current}
                  line2={accountAddressLine2Ref.current}
                />
              }
            />
            <Route
              path={`-paymentmethod`}
              element={
                <Elements stripe={stripePromise}>
                  <AccountCreatePaymentMethod
                    accountId={accountId}
                    isConfirmed={isConfirmedPaymentMethod}
                    setCanCreatePaymentMethod={setCanCreatePaymentMethod}
                    setIsLoading={setIsLoading}
                    onDone={() => setIsCreatedPaymentMethod(true)}
                    onError={() => setIsConfirmedPaymentMethod(false)}
                  />
                </Elements>
              }
            />
            <Route
              path={`-orggvc`}
              element={
                <AccountCreateOrgGVC
                  gvcName={gvcNameRef.current}
                  orgName={orgNameRef.current}
                  locations={locations}
                  setLocations={setLocations}
                />
              }
            />
          </Routes>
        </CreateAccountLayout>
      </NGFormContext.Provider>
    </BasePathContext.Provider>
  );
};

export const BillingAccountCreate = observer(BillingAccountCreateRaw);
