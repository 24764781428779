import { types } from "mobx-state-tree";
import { AutoscalerModel } from "./autoscaler";
import { TaintModel, UnmanagedPoolModel } from "./common";
import { NetworkingModel } from "./networking";
import { PreInstallScriptProperty } from "./preInstallScript";

// const LabelName = Joi.string().custom((value: string) => {
//   const parts = value.split('/');
//   if (parts.length != 1 && parts.length != 2) {
//     throw new Error('label name invalid format');
//   }

//   if (parts.length == 2) {
//     const domain = parts[0];

//     if (!domain.match(/^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/)) {
//       throw new Error('invalid domain part for label name');
//     }
//   }

//   let name = parts[0];
//   if (parts.length == 2) {
//     name = parts[1];
//   }

//   if (!name.match(/^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/)) {
//     throw new Error(`invalid label name`);
//   }
//   return value;
// });

// const LabelValue = Joi.string()
//   .allow('')
//   .custom((value: string) => {
//     //https://github.com/kubernetes/apimachinery/blob/v0.27.1/pkg/util/validation/validation.go#L167

//     if (value.length > LabelValueMaxLength) {
//       throw new Error(`label value must be max ${LabelValueMaxLength} long`);
//     }

//     if (!value.match(/^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/)) {
//       throw new Error('label value is invalid');
//     }

//     return value;
//   });

const HetznerPoolModel = types.model("Hetzner Node Pool", {
  name: types.string, // regex /^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/
  labels: types.maybe(types.frozen()), // ask for examples
  taints: types.array(TaintModel),
  serverType: types.string, // one of server types
  minSize: types.number, // min 0
  maxSize: types.number, // >=  minSize
});

export const HetznerProviderModel = types.model("Hetzner Provider", {
  region: types.string, // 'fsn1', 'nbg1', 'hel1', 'ash', 'hil'
  hetznerLabels: types.optional(types.frozen(), {}),
  tokenSecretLink: types.string,
  networkId: types.string,
  firewallId: types.maybe(types.string),
  nodePools: types.array(HetznerPoolModel),
  dedicatedServerNodePools: types.array(UnmanagedPoolModel),
  image: types.optional(types.string, ""),
  sshKey: types.maybe(types.string),
  autoscaler: types.optional(AutoscalerModel, () => AutoscalerModel.create()),
  networking: types.optional(NetworkingModel, () => NetworkingModel.create()),
  preInstallScript: PreInstallScriptProperty,
  floatingIPSelector: types.optional(types.frozen(), {}), // If supplied, nodes will get assigned a random floating ip matching the selector
});
