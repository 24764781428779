import * as React from "react";
import { ExternalLink } from "react-feather";
import { DOCS_URL } from "../../envVariables";

export const AutoScalingDoc: React.FC = () => {
  return (
    <a
      href={`${DOCS_URL}/reference/volumeset#autoscaling`}
      referrerPolicy={"no-referrer"}
      target={"_blank"}
      className={`text-sm color-link flex my-2`}
    >
      Learn More
      <ExternalLink className="ml-1" style={{ width: 18, height: 18 }} />
    </a>
  );
};
