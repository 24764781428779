import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { mk8sMobx, mk8sModel } from "../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftManagerModel } from "../../mst/stores/mk8s.draft.manager";
import { Mk8sDetail } from "./detail";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";

type RouteParams = "mk8s";
const Mk8sDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());
  const { mk8s: mk8sName } = useParams<RouteParams>();
  const [mk8s, setMk8s] = React.useState<mk8sMobx>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);
  const draftManagerRef = React.useRef(Mk8sDraftManagerModel.create());
  const draftManager = draftManagerRef.current;

  React.useEffect(() => {
    fetchItem();
  }, [mk8sName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data: workloadRes } = await request({ url: resourceLink("mk8s", mk8sName) });
      const mk8sInstance = mk8sModel.create(workloadRes);
      setMk8s(mk8sInstance);
      await draftManager.startDraftDetail(mk8sInstance);
      setIsLoading(false);
    } catch (e) {
      console.log(e.message);
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching mk8s to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"mk8s"} canCreate={true} />;
  }

  if (!draftManager.draft.edit) return null;

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {mk8s.name} - Kubernetes - From many clouds, one™
        </title>
      </Helmet>
      <NGFormContext.Provider value={formDataRef.current}>
        <DetailContext.Provider value={{ item: mk8s, fetchItem }}>
          <Mk8sDetail mk8s={mk8s} mk8sDraft={draftManager.draft.edit} startDraft={draftManager.startDraftDetail} />
        </DetailContext.Provider>
      </NGFormContext.Provider>
    </>
  );
};

export const Mk8sDetailRoute = observer(Mk8sDetailRouteRaw);
