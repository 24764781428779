import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../mobxDataModels/stringModel";
import { FormButtons } from "../../components/forms/formButtons";
import { FormElement } from "../../components/forms/formElement";
import { VolumeSetMobx } from "../../mst/kinds/volumeset";
import { useDetailContext } from "../../components/detail/detailContext";
import { Link } from "react-router-dom";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import { NGLabel } from "../../newcomponents/text/label";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  volumeSet: VolumeSetMobx;
}
const InfoRaw: React.FC<Props> = ({ volumeSet }) => {
  const { fetchItem } = useDetailContext();

  // info
  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const descriptionRef = React.useRef(
    StringModel.create({ label: "Description", initialValue: volumeSet.description }),
  );

  React.useEffect(() => {
    PromptContext.setWhen(isDirty || isLoading);
  }, [isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    let res = false;
    if (descriptionRef.current.isDirty) res = true;
    setIsDirty(res);
  }, [descriptionRef.current.isDirty]);

  function reset() {
    descriptionRef.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      const description = descriptionRef.current.value || null;
      await volumeSet.patch({
        description,
      });
      descriptionRef.current.confirm();
      if (!descriptionRef.current.value) {
        descriptionRef.current.setInitialValue(volumeSet.name);
      }
      notification.success({
        message: "Success",
        description: "Updated Volume Set",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        reset();
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  let hasVolumes = false;
  for (const location of volumeSet.status.locations) {
    if (location.volumes.length > 0) {
      hasVolumes = true;
    }
  }

  return (
    <>
      <div>
        <NGInputAdapter className="mb-4" style={{ width: 450 }} data={descriptionRef.current} />
        {volumeSet.status.usedByWorkload ? (
          <div className="flex flex-col mb-4">
            <NGLabel>Used By Workload</NGLabel>
            <Link className="ngfocus color-link" to={`/console${volumeSet.status.usedByWorkload}`}>
              {volumeSet.status.usedByWorkload.split("/")[6]}
            </Link>
          </div>
        ) : null}
        <TagLinksTable
          tableId="volumeset-info-tag-links"
          tags={Object.entries(volumeSet.tags || {})
            .map(([tagKey, tagValue]) => ({ key: tagKey, value: (tagValue as string) || "" }))
            .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
          styles={{ header: { marginBottom: 0 } }}
        />
        <FormButtons
          onReset={reset}
          onSave={save}
          resetDisabled={isLoading || !isDirty}
          saveDisabled={isLoading || !isDirty}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export const Info = observer(InfoRaw);
