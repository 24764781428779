import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { DryRunAlert } from "../../dryRunAlert";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGSelect } from "../../../../newcomponents/select/ngselect";
import { NGButton } from "../../../../newcomponents/button/Button";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { useNGFormContext } from "../../../../reactContexts/ngFormContext";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";

type RouteParams = "index";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderEphemeralNodePoolRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const formData = useNGFormContext();

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const e = mk8sDraft.provider_ephemeral;
  const np = e.nodePools.find((np) => np.index === index);

  if (!np) {
    return <Navigate to={`${pathname.split("/-node-pools")[0]}/-node-pools`} />;
  }

  const pre = `ephemeral.nodePools[${index}].`;

  return (
    <>
      <div>
        <NGFormElement
          name={`${pre}name`}
          label={np.name.label}
          value={np.name.value}
          onChange={np.name.setValue}
          required
          error={!e.isNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
        />

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}count`}
            label={np.count.label}
            value={np.count.value}
            onChange={np.count.setValue}
            required={np.count.isRequired}
            error={np.count.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.count.setValue}
              path={`spec.provider.ephemeral.nodePools[${index}].count`}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}arch`}
            as={"select"}
            label={np.arch.label}
            options={np.arch.options}
            value={np.arch.value}
            required
            onChange={np.arch.setValue}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.arch.setValue}
              path={`spec.provider.ephemeral.nodePools[${index}].arch`}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}flavor`}
            as={"select"}
            label={np.flavor.label}
            options={np.flavor.options}
            value={np.flavor.value}
            required
            onChange={np.flavor.setValue}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.flavor.setValue}
              path={`spec.provider.ephemeral.nodePools[${index}].flavor`}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}cpu`}
            label={np.cpu.label}
            value={np.cpu.value}
            onChange={np.cpu.setValue}
            required={np.cpu.isRequired}
            error={np.cpu.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.cpu.setValue}
              path={`spec.provider.ephemeral.nodePools[${index}].cpu`}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}memory`}
            label={np.memory.label}
            value={np.memory.value}
            onChange={np.memory.setValue}
            required={np.memory.isRequired}
            error={np.memory.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.memory.setValue}
              path={`spec.provider.ephemeral.nodePools[${index}].memory`}
            />
          </div>
        </div>

        <NGInputListMst
          label="Labels"
          className="mb-8"
          data={np.labels}
          firstInput={(item) => (
            <NGInput
              required
              invalid={!item.firstValue}
              ignoreTouched
              placeholder="Key"
              value={item.firstValue}
              onChange={(e) => item.setFirstValue(e.target.value)}
            />
          )}
          secondInput={(item) => (
            <NGInput
              required
              invalid={!item.secondValue}
              ignoreTouched
              placeholder="Value"
              value={item.secondValue}
              onChange={(e) => item.setSecondValue(e.target.value)}
            />
          )}
        />
        <NGInputListMst
          label="Taints"
          data={np.taints}
          firstInput={(item) => (
            <NGInput
              required
              invalid={!item.firstValue}
              ignoreTouched
              placeholder="Key"
              value={item.firstValue}
              onChange={(e) => item.setFirstValue(e.target.value)}
            />
          )}
          secondInput={(item) => (
            <NGInput
              required
              invalid={!item.secondValue}
              ignoreTouched
              placeholder="Value"
              value={item.secondValue}
              onChange={(e) => item.setSecondValue(e.target.value)}
            />
          )}
          thirdInput={(item) => (
            <NGSelect
              placeholder="Effect"
              invalid={!item.thirdValue}
              ignoreTouched
              value={item.thirdValue}
              onChange={(value) => item.setThirdValue(value)}
              options={[
                { label: "NoSchedule", value: "NoSchedule" },
                { label: "PreferNoSchedule", value: "PreferNoSchedule" },
                { label: "NoExecute", value: "NoExecute" },
              ]}
            />
          )}
        />
        <NGButton
          style={{ width: 220 }}
          variant={"danger"}
          outlined
          onClick={() => {
            e.removeNodePoolAt(np.index);
            navigate(`${pathname.split("/-node-pools")[0]}/-node-pools`);
          }}
        >
          Delete Node Pool
        </NGButton>
      </div>
    </>
  );
};

export const ProviderEphemeralNodePool = observer(ProviderEphemeralNodePoolRaw);
