import * as React from "react";
import { observer } from "mobx-react-lite";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { ImageDetail } from "./imageDetail";
import { request } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { Helmet } from "react-helmet";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { useLocation } from "react-router-dom";
import { Image } from "./../../schema/types/image";

const ImageDetailRouteRaw: React.FC = () => {
  const { pathname } = useLocation();
  function getImageLink() {
    return pathname.split("/console")[1].split("/-")[0];
  }
  const [imageLink, setImageLink] = React.useState(getImageLink());
  React.useEffect(() => {
    setImageLink(getImageLink());
  }, [pathname]);

  const [image, setImage] = React.useState<Image>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
  }, [imageLink]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: imageLink });
      setImage(data);
      setIsLoading(false);
    } catch (e) {
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching image to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"image"} canCreate={false} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {image.name} - Image - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: image as any, fetchItem }}>
        <ImageDetail image={image} />
      </DetailContext.Provider>
    </>
  );
};

export const ImageDetailRoute = observer(ImageDetailRouteRaw);
