import * as React from "react";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../mobxDataModels/stringModel";
import { notification } from "antd";
import { useDetailContext } from "../../components/detail/detailContext";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { FormButtons } from "../../components/forms/formButtons";
import { IpSet } from "../../schema/types/ipset";
import { linksOf, request } from "../../services/cpln";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  ipset: IpSet;
}
const InfoRaw: React.FC<Props> = ({ ipset }) => {
  const descriptionRef = React.useRef(StringModel.create({ label: "Description", initialValue: ipset.description }));

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [promptWhen, setPromptWhen] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(promptWhen);
  }, [promptWhen]);

  useCleanPrompt();

  // General //
  const { fetchItem } = useDetailContext();

  React.useEffect(() => {
    let res = false;
    if (descriptionRef.current.isDirty) res = true;

    setIsDirty(res);
  }, [descriptionRef.current.isDirty]);

  React.useEffect(() => {
    setPromptWhen(isDirty);
  }, [isDirty]);

  function reset() {
    descriptionRef.current.reset();
  }

  async function save() {
    try {
      setIsLoading(true);
      const body: any = {
        description: descriptionRef.current.value || null,
      };

      await request({ method: "patch", body: body, url: linksOf(ipset).self! });
      descriptionRef.current.confirm();
      if (!descriptionRef.current.value) {
        descriptionRef.current.setInitialValue(ipset.name);
      }
      notification.success({
        message: "Success",
        description: "Updated ip set",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <div>
        <NGFormElement
          name="description"
          label={descriptionRef.current.label}
          value={descriptionRef.current.value}
          onChange={descriptionRef.current.setValue}
        />
        <TagLinksTable
          tableId="ipset-info-tag-links"
          tags={Object.entries(ipset.tags || {})
            .map(([tagKey, tagValue]) => ({ key: tagKey, value: (tagValue as string) || "" }))
            .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
          styles={{ header: { marginBottom: 0 } }}
        />
        <div style={{ width: 450 }} className="mt-8 flex items-center">
          <FormButtons
            onReset={reset}
            onSave={save}
            resetDisabled={isLoading || !isDirty}
            saveDisabled={isLoading || !isDirty}
            loading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export const Info = observer(InfoRaw);
