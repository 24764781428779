import * as React from "react";
import { observer } from "mobx-react-lite";
import { ListOfItemsMobx } from "../../../mobxDataModels/listOfItemsModel";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { NGInputListMst } from "../../../newcomponents/inputList/inputListMst";
import { NGTextArea } from "../../../newcomponents/textarea";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabelText } from "../../../newcomponents/text/labelText";

interface Props {
  type: "postStart" | "preStop";
  use: boolean;
  setUse: (value: boolean) => void;
  command: string;
  setCommand: (value: string) => void;
  args: ListOfItemsMobx;
}
const LifecycleHookRaw: React.FC<Props> = ({ type, use, setUse, command, setCommand, args }) => {
  return (
    <div className={`${use ? "mb-8" : "mb-4"}`}>
      <NGSwitch value={use} onChange={(value) => setUse(value)}>
        <NGLabelText>Use {type === "postStart" ? "Post Start" : type === "preStop" ? "Pre Stop" : ""} Hook</NGLabelText>
      </NGSwitch>
      {use ? (
        <>
          {/* <ReachTooltip
              contents={
                type === "postStart"
                  ? ["Command and arguments executed immediately after the container is created."]
                  : type === "preStop"
                  ? ["Command and arguments executed immediately before the container is stopped."]
                  : []
              }
              info
            /> */}
          <NGFormElement
            name={`${type}command`}
            label={"Command"}
            className="flex-grow"
            value={command}
            onChange={setCommand}
          />
          {/* TODO integrate into input list  */}
          {/* <ReachTooltip
          contents={["Command line command and arguments passed to the container at runtime.", "It is an ordered list."]}
          info
        /> */}
          <NGInputListMst
            label="Arg List"
            data={args}
            draggable
            styles={{ container: { width: 450 } }}
            firstInput={(item) => (
              <NGTextArea
                value={item.firstValue}
                onChange={(e) => item.setFirstValue(e.target.value)}
                placeholder="Multiline Input"
                invalid={!item.firstValue}
                ignoreTouched
              />
            )}
          />
        </>
      ) : null}
    </div>
  );
};

export const LifecycleHook = observer(LifecycleHookRaw);
