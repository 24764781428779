import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { UserData } from "../../../mobxStores/userData/userData";
import { DetailedRadioGroup, DetailedRadioOption } from "../../../newcomponents/detailedRadio/detailedRadioGroup";
import { HiddenSectionsMobx } from "../../../mobxStores/hiddenSections";
import { NGButton } from "../../../newcomponents/button/Button";
import NGAlert from "../../../newcomponents/alert";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { DOCS_URL } from "../../../envVariables";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  isExampleApplied: boolean;
  setIsExampleApplied: React.Dispatch<React.SetStateAction<boolean>>;
}
const WorkloadCreateGeneralRaw: React.FC<Props> = ({ workloadDraft, isExampleApplied, setIsExampleApplied }) => {
  function applyExampleWorkload() {
    setIsExampleApplied(true);
    workloadDraft.setGeneralName("httpbin-example");
    workloadDraft.setContainerImage(workloadDraft.container1, "kennethreitz/httpbin");
    workloadDraft.setServesTraffic("httpbin", true);
    if (workloadDraft.container1.ports.length < 1) {
      workloadDraft.container1.addPort();
    }
    workloadDraft.container1.ports[0].number.setValue("80");
    workloadDraft.firewall.external_setAllInboundAllowed(true);
    workloadDraft.firewall.external_setAllOutboundAllowed(true);
  }

  const options: DetailedRadioOption[] = [
    {
      value: "serverless",
      title: "Serverless",
      description: "Not to be confused with Lambda. Serverless means a workload can be scaled to zero replicas.",
    },
    {
      value: "standard",
      title: "Standard",
      description: "A standard Kubernetes workload whose minimum scale is one replica.",
    },
    {
      value: "stateful",
      title: "Stateful",
      description: "A workload whose replicas are uniquely addressable and can use persistent volume claims.",
    },
    { value: "cron", title: "Cron", description: "A scheduled workload." },
  ];

  return (
    <>
      {!UserData.hasWorkload && !isExampleApplied ? (
        <div className="flex items-center mb-4">
          <NGAlert
            style={{ width: 550 }}
            type={"info"}
            message={"Do you want to use an example workload spec to get started?"}
          />
          <NGButton className="ml-2" onClick={applyExampleWorkload} variant={"primary"}>
            Use Example
          </NGButton>
        </div>
      ) : null}
      <div className="flex items-center mb-2">
        <NGLabel>Workload Type</NGLabel>
        <InfoTooltip
          title={
            <div>
              You can find more information about workload types{" "}
              <a
                className="ngfocus color-link font-semibold"
                target="_blank"
                href={`${DOCS_URL}/reference/workload#types`}
              >
                here
              </a>
            </div>
          }
        />
      </div>
      <DetailedRadioGroup
        className="mb-4"
        groupName="createWorkloadType"
        selectedValue={workloadDraft.type.value}
        options={options}
        onChange={workloadDraft.setType}
      />
      <NGFormElement
        name={"name"}
        value={workloadDraft.name.value}
        onChange={workloadDraft.setGeneralName}
        required={workloadDraft.name.isRequired}
        error={workloadDraft.name.error}
        label={workloadDraft.name.label}
      />
      <NGFormElement
        name={"description"}
        value={workloadDraft.description.value}
        onChange={workloadDraft.description.setValue}
        label={workloadDraft.description.label}
      />
      <div className={`mb-4 flex items-center`}>
        <NGSwitch
          value={workloadDraft.supportDynamicTags}
          onChange={(value: boolean) => workloadDraft.setSupportDynamicTags(value)}
        >
          <NGLabelText>Support Dynamic Tags</NGLabelText>
        </NGSwitch>
        <InfoTooltip title="If this is enabled, Control Plane will automatically redeploy your workload when one of the container images is updated in the container registry." />
      </div>
    </>
  );
};

export const WorkloadCreateGeneral = observer(WorkloadCreateGeneralRaw);
