import * as React from "react";
import clsx from "clsx";
import "./input.scss";
import { X } from "react-feather";
import { useNGFormContext } from "../../reactContexts/ngFormContext";
import { Tooltip } from "../../components/Tooltip";

// Notes
// Three ways to give react components as props
// 1 - React.ReactElement | Given as c={<MyComponent />} | Used as {c}
// 2 - React.ComponentType | Given as C={MyComponent} | Used as {<C />}
// 3 - () => React.ReactElement | Given as r={() => <MyComponent />} | Used as {r()}

// export interface FinalFormAdapter {
//   active?: boolean;
//   dirty?: boolean;
//   invalid?: boolean;
//   visited?: boolean;
//   touched?: boolean;
// }

// export interface InputFocusHandlers {
//   onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
//   onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
// }

export interface InnerButtonProps {
  title: string;
  render: () => React.ReactElement;
  onClick: () => void;
}

export interface NGInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
  buttons?: InnerButtonProps[];
  showClear?: boolean;
  size?: "toRemoveLarge" | "normal" | "small";
  invalid?: boolean;
  ignoreTouched?: boolean;
  // emphasizeEmptyStringOnEnds?: boolean;
}

// visited - gained focus
// touched - gained and lost focus

export const NGInput: React.FC<NGInputProps> = ({
  // --
  buttons = [],
  showClear = false,
  size = "normal",
  invalid = false,
  ignoreTouched = false,
  // emphasizeEmptyStringOnEnds = false,
  disabled,
  style = {},
  className,
  onChange,
  name,
  onFocus,
  onBlur,
  ...inputProps
}) => {
  const formData = useNGFormContext();
  const [focused, setFocused] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [visited, setVisited] = React.useState(false);
  function localOnFocus(e: React.FocusEvent<HTMLInputElement>) {
    if (onFocus) {
      onFocus(e);
    }
    setFocused(true);
  }
  function localOnBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (onBlur) {
      onBlur(e);
    }
    setFocused(false);
  }
  React.useEffect(() => {
    if (focused) {
      if (name && formData) {
        formData.set(name, { focused: true });
      }
      if (!visited) {
        setVisited(true);
        if (name && formData) {
          formData.set(name, { visited: true });
        }
      }
      return;
    }

    if (name && formData) {
      formData.set(name, { focused: false });
    }
    if (visited && !touched) {
      setTouched(true);
      if (name && formData) {
        formData.set(name, { touched: true });
      }
    }
  }, [focused]);

  const { value: _value } = inputProps;
  const value: string = (_value as any) || "";

  /*
  Emphasize Whitespace
  */
  const buttonsWidth = buttons.length * 24;
  const baseFontSize = 16; // TODO connect this to css
  const charSize = baseFontSize * 0.25; // 0.25rem
  const inputRef = React.useRef<HTMLInputElement>(null);
  const widthCalculatorRef = React.useRef<HTMLDivElement>(null);
  let leadingSpace = value.length - value.trimLeft().length;
  let trailingSpace = value.trimLeft().length === 0 ? 0 : value.length - value.trimRight().length;

  const [widthCalClientWidth, setWidthCalClientWidth] = React.useState(0);
  const [clientWidth, setClientWidth] = React.useState(0);
  // const widthCalClientWidth = Math.min(_widthCalClientWidth, clientWidth);
  React.useEffect(() => {
    const widthCalClientWidth = widthCalculatorRef.current?.clientWidth || 0;
    setWidthCalClientWidth(widthCalClientWidth);

    setClientWidth(inputRef.current?.clientWidth || 0);
    setScrollWidth(inputRef.current!.scrollWidth || 0);
  }, [value]);
  // TODO fix usage of all "replaceAll"
  // @ts-ignore
  const spaceCount = value.trim().length - value.trim().replaceAll(" ", "").length;
  const spaceSize = spaceCount * charSize;
  const [scrollLeft, setScrollLeft] = React.useState(0);
  const [scrollWidth, setScrollWidth] = React.useState(0);
  const scrollableAmount = scrollWidth - clientWidth;
  const scrolled = scrollableAmount - scrollLeft < 1;
  const scrollable = widthCalClientWidth > clientWidth;
  const spacerSize = spaceSize + widthCalClientWidth - buttonsWidth;

  let startsWithEmphasizeWidth = 0;
  if (scrollable && scrollLeft > charSize * leadingSpace) {
    startsWithEmphasizeWidth = 0;
  } else if (scrollable) {
    startsWithEmphasizeWidth = charSize * leadingSpace - scrollLeft;
  } else {
    startsWithEmphasizeWidth = charSize * leadingSpace;
  }

  let endsWithEmphasizeWidth = 0;
  if (!scrollable || (scrollable && scrolled)) {
    endsWithEmphasizeWidth = charSize * trailingSpace;
  } else if (scrollable && scrollableAmount - scrollLeft < charSize * trailingSpace) {
    endsWithEmphasizeWidth = charSize * trailingSpace - (scrollableAmount - scrollLeft);
  }
  // TODO simplify the calculation, make it work with all whitespace too

  React.useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const onScroll = () => {
      setScrollLeft(inputRef.current!.scrollLeft);
    };
    inputRef.current.addEventListener("scroll", onScroll);
    return () => inputRef.current?.removeEventListener("scroll", onScroll);
  }, []);
  /*
  END Emphasize Whitespace
  */

  const shouldShowClearButton = !disabled && showClear && value.length > 0;

  return (
    <>
      <div
        className={clsx(
          "nginput ngfocus",
          {
            hoveringfocus: !disabled,
            large: size === "toRemoveLarge",
            normal: size === "normal",
            small: size === "small",
            disabled: disabled,
            invalid: invalid && !focused && (ignoreTouched || touched),
          },
          className
        )}
        style={style}
      >
        <input
          id={formData && name ? `${formData.id}${name}` : undefined}
          className={clsx({ withButtons: shouldShowClearButton || buttons?.length > 0 })}
          disabled={disabled}
          value={value}
          onChange={onChange}
          name={name}
          onFocus={localOnFocus}
          onBlur={localOnBlur}
          aria-invalid={invalid ? "true" : "false"}
          {...inputProps}
          ref={inputRef}
        />
        {/* <div ref={widthCalculatorRef} className={clsx("width-calculator")}>
        {value.trim().replaceAll(" ", "")}
      </div> */}
        {/* <div
        className={clsx("clone", {
          hidden: !(
            !!value &&
            value.length > 0 &&
            (value.startsWith(" ") || value?.endsWith(" ")) &&
            emphasizeEmptyStringOnEnds
          ),
        })}
        style={{ paddingRight: 1.2 * baseFontSize + buttonsWidth }}
      >
        <div className="startsWith">
          <div className="emphasizeIndicator" style={{ width: startsWithEmphasizeWidth }} />
        </div>
        <div className="spacer" style={{ width: spacerSize }} />
        <div className="endsWith">
          <div className="emphasizeIndicator" style={{ width: endsWithEmphasizeWidth }} />
        </div>
      </div> */}
        {buttons?.map(({ render, onClick, title }, index) => (
          <Tooltip key={title} title={title}>
            <button
              key={index}
              onClick={onClick}
              className={clsx("ngfocus nginnerbutton", `innerbutton-${title.toLowerCase()}`)}
              disabled={disabled}
            >
              {render()}
            </button>
          </Tooltip>
        ))}
        {shouldShowClearButton ? (
          <Tooltip title="Clear">
            <button
              // id={`${idPrefixRef.current}-custombutton`}
              disabled={disabled}
              tabIndex={-1}
              aria-label={"custombutton"}
              onClick={() => {
                if (onChange) {
                  onChange({ target: { value: "" } } as any);
                }
              }}
              className={clsx("ngfocus nginnerbutton innerbutton-clear")}
            >
              <X className="h-4" />
            </button>
          </Tooltip>
        ) : null}
      </div>
    </>
  );
};
