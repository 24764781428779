import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { notification, Modal } from "antd";
import { ReleaseInstance } from "../types/release";
import { request } from "../../../services/cpln";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { NGButton } from "../../../newcomponents/button/Button";

interface Props {
  release: ReleaseInstance;
  onClose: any;
  onDone: any;
}

const ReleaseUninstallModalRaw: React.FC<Props> = ({ release, onClose, onDone }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  async function handleUninstall() {
    try {
      setIsLoading(true);
      await request({
        service: "marketplace",
        method: "delete",
        url: `/org/${ConsoleContext.org}/release/${release.id}`,
      });
      notification.success({ message: "Success", description: "Uninstalled release" });
      setIsLoading(false);
      onDone();
      navigate(`/console/org/${ConsoleContext.org}/marketplace/release`);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed", description: errorMessage });
    }
  }

  return (
    <Modal
      title={
        <>
          Uninstall Release:{" "}
          <span className="font-bold" style={{ color: "var(--color-danger)" }}>
            {release.name}
          </span>
        </>
      }
      open={true}
      closable={false}
      maskClosable={!isLoading}
      onCancel={onClose}
      footer={
        <div className="modal-actions">
          <NGButton variant="secondary" onClick={onClose} disabled={isLoading}>
            Cancel
          </NGButton>
          <NGButton variant="danger" onClick={handleUninstall} loading={isLoading} disabled={isLoading}>
            Uninstall
          </NGButton>
        </div>
      }
      destroyOnClose
    >
      <span>Are you sure you want to uninstall this release? This action cannot be undone.</span>
    </Modal>
  );
};

export const ReleaseUninstallModal = observer(ReleaseUninstallModalRaw);
