import * as React from "react";
import jsYaml from "js-yaml";
import { ECRDataMobx, SecretMobx } from "../../mst/kinds/secret";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../mobxDataModels/stringModel";
import { inputValidations } from "../../mobxDataModels/validations";
import { Alert, notification } from "antd";
import { arraysAreEqual } from "../../services/cpln";
import { DictionaryProperty } from "./dictionaryProperty";
import { NoTag } from "../../components/tag/noTag";
import { RemovableValue } from "../../components/tag/removableValue";
import { FormLabel } from "../../components/forms/formLabel";
import { Eye, EyeOff, Download } from "react-feather";
import { useDetailContext } from "../../components/detail/detailContext";
import { MobxMultilineInput } from "../../components/inputs/mobxMultilineInput/mobxMultilineInput";
import { CodeEditorModal } from "../../components/modals/codeEditorModal";
import { ReadOnlyInput } from "../../components/inputs/readOnlyInput";
import { GenericInputs } from "./types";
import { CircleSpinner } from "../../components/generic/circle-spinner";
import { parseEnvFile } from "../../services/utils";
import { NGButton } from "../../newcomponents/button/Button";
import { NGRadioGroup } from "../../newcomponents/radioGroup";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { MobxInput } from "../../components/inputs/mobxInput";
import { NGCheckbox } from "../../newcomponents/checkbox";
import { FormButtons } from "../../components/forms/formButtons";
import { NGError } from "../../newcomponents/text/error";
import NGAlert from "../../newcomponents/alert";
import { InfoTooltip } from "../../components/InfoTooltip";

interface Props {
  secret: SecretMobx;
}
const EditRaw: React.FC<Props> = ({ secret }) => {
  const userpass_usernameRef = React.useRef(StringModel.create({ label: "Username", isRequired: true }));
  const userpass_passwordRef = React.useRef(
    StringModel.create({ label: "Password", isRequired: true, isSensitive: true })
  );

  const azureConnector_urlRef = React.useRef(
    StringModel.create({ label: "URL", isRequired: true, isSensitive: true, validationKey: "https" })
  );
  const azureConnector_codeRef = React.useRef(
    StringModel.create({ label: "Code", isSensitive: true, isRequired: true })
  );

  const dictionary_keyRef = React.useRef(StringModel.create({ label: "Key", isRequired: false }));
  const dictionary_valueRef = React.useRef(StringModel.create({ label: "Value", isRequired: false }));

  const aws_secretKeyRef = React.useRef(
    StringModel.create({ label: "Secret Key", isRequired: true, isSensitive: true })
  );
  const aws_accessKeyRef = React.useRef(
    StringModel.create({ label: "Access Key", isRequired: true, isSensitive: true, validationKey: "awsAccessKey" })
  );
  const aws_roleArnRef = React.useRef(
    StringModel.create({ label: "Role Arn", isSensitive: true, validationKey: "awsRoleArn" })
  );
  const aws_externalIdRef = React.useRef(StringModel.create({ label: "External Id" }));

  const ecr_secretKeyRef = React.useRef(
    StringModel.create({ label: "Secret Key", isRequired: true, isSensitive: true })
  );
  const ecr_accessKeyRef = React.useRef(
    StringModel.create({ label: "Access Key", isRequired: true, isSensitive: true, validationKey: "awsAccessKey" })
  );
  const ecr_roleArnRef = React.useRef(
    StringModel.create({ label: "Role Arn", isSensitive: true, validationKey: "awsRoleArn" })
  );
  const ecr_repoRef = React.useRef(StringModel.create({ label: "Repo", validationKey: "ecrRepo" }));
  const ecr_externalIdRef = React.useRef(StringModel.create({ label: "External Id" }));

  const nats_accountIdRef = React.useRef(
    StringModel.create({ label: "Account ID", isSensitive: true, validationKey: "natsAccountId", isRequired: true })
  );
  const nats_privateKeyRef = React.useRef(
    StringModel.create({ label: "Private Key", isSensitive: true, validationKey: "natsPrivateKey", isRequired: true })
  );

  const keypair_passphraseRef = React.useRef(StringModel.create({ label: "Passphrase", isSensitive: true }));

  // States //
  const [canReveal, setCanReveal] = React.useState<boolean | null>(null);
  const [revealedSecret, setRevealedSecret] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFetchingSecretData, setIsFetchSecretData] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [promptWhen, setPromptWhen] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(promptWhen);
  }, [promptWhen]);

  useCleanPrompt();

  const [ecrRepos, setEcrRepos] = React.useState<string[]>([]);
  const [dictionary, setDictionary] = React.useState<{ [_: string]: string }>({});
  const [editedDictProperty, setEditedDictProperty] = React.useState<string | null>(null);
  const [dictRevealedKeys, setDictRevealedKeys] = React.useState<string[]>([]);

  const [canBeBase64, setCanBeBase64] = React.useState(false);
  const [encoding, setEncoding] = React.useState<"plain" | "base64">("plain");

  const [isCodeEditorOpen, setIsCodeEditorOpen] = React.useState<boolean>(false);
  const [codeEditorTitle, setCodeEditorTitle] = React.useState<string>("");
  const [codeEditorInitialValue, setCodeEditorInitialValue] = React.useState<string>("");
  const [selectedFileLabelIndex, setSelectedFileLabelIndex] = React.useState<number>(0);

  const [input1Label, setInput1Label] = React.useState("");
  const [input1Text, setInput1Text] = React.useState("");
  const [input2Label, setInput2Label] = React.useState("");
  const [input2Text, setInput2Text] = React.useState("");
  const [input3Label, setInput3Label] = React.useState("");
  const [input3Text, setInput3Text] = React.useState("");

  // General //
  const { fetchItem } = useDetailContext();
  let dictBtnDisabled = false;
  let hasUncompletedAction = false;

  if (dictionary_keyRef.current.value.length < 1) {
    dictBtnDisabled = true;
  }
  if (Object.keys(dictionary).includes(dictionary_keyRef.current.value) && !editedDictProperty) {
    dictBtnDisabled = true;
  }
  if (
    dictionary_keyRef.current.value.length > 0 &&
    dictionary[dictionary_keyRef.current.value] === dictionary_valueRef.current.value
  ) {
    dictBtnDisabled = true;
  }

  if (secret.type === "ecr") {
    if (ecr_repoRef.current.value.length > 0) hasUncompletedAction = true;
  } else if (secret.type === "dictionary") {
    if (dictionary_keyRef.current.value.length > 0) hasUncompletedAction = true;
    if (dictionary_valueRef.current.value.length > 0) hasUncompletedAction = true;
  }

  // Effects //
  React.useEffect(() => {
    initFileLabels();
    revealSecret();
  }, []);

  React.useEffect(() => {
    let res = true;
    if (canReveal) {
      if (secret.type === "opaque") {
        if (!canBeBase64 && encoding === "base64") res = false;
      } else if (secret.type === "userpass") {
        if (!userpass_usernameRef.current.isValid) res = false;
        if (!userpass_passwordRef.current.isValid) res = false;
      } else if (secret.type === "azure-connector") {
        if (!azureConnector_urlRef.current.isValid) res = false;
        if (!azureConnector_codeRef.current.isValid) res = false;
      } else if (secret.type === "dictionary") {
        if (Object.keys(dictionary).length < 1) res = false;
      } else if (secret.type === "aws") {
        if (!aws_accessKeyRef.current.isValid) res = false;
        if (!aws_roleArnRef.current.isValid) res = false;
        if (!aws_secretKeyRef.current.isValid) res = false;
      } else if (secret.type === "ecr") {
        if (!ecr_accessKeyRef.current.isValid) res = false;
        if (!ecr_roleArnRef.current.isValid) res = false;
        if (!ecr_secretKeyRef.current.isValid) res = false;
        if (ecrRepos.length < 1) res = false;
      } else if (secret.type === "nats-account") {
        if (!nats_accountIdRef.current.isValid) res = false;
        if (!nats_privateKeyRef.current.isValid) res = false;
      } else if (secret.type === "tls") {
        if (input1Text.length < 1 || input2Text.length < 1) res = false;
      } else {
        if (input1Text.length < 1) res = false;
      }
    }
    setIsValid(res);
  }, [
    canReveal,
    userpass_usernameRef.current.isValid,
    userpass_passwordRef.current.isValid,
    azureConnector_urlRef.current.isValid,
    azureConnector_codeRef.current.isValid,
    aws_secretKeyRef.current.isValid,
    aws_roleArnRef.current.isValid,
    aws_accessKeyRef.current.isValid,
    ecr_secretKeyRef.current.isValid,
    ecr_roleArnRef.current.isValid,
    ecr_accessKeyRef.current.isValid,
    nats_accountIdRef.current.isValid,
    nats_privateKeyRef.current.isValid,
    canBeBase64,
    encoding,
    ecrRepos,
    input1Text,
    input2Text,
    input3Text,
    dictionary,
  ]);

  React.useEffect(() => {
    let res = false;
    // if user clicked on edit data and revealed the secret with success
    if (canReveal && revealedSecret) {
      const s: any = revealedSecret as any;
      if (secret.type === "userpass") {
        if (userpass_usernameRef.current.isDirty) res = true;
        if (userpass_passwordRef.current.isDirty) res = true;
        if ((revealedSecret as any).data.encoding !== encoding) res = true;
      } else if (secret.type === "azure-connector") {
        if (azureConnector_urlRef.current.isDirty) res = true;
        if (azureConnector_codeRef.current.isDirty) res = true;
      } else if (secret.type === "dictionary") {
        const oldDictArray = Object.entries((revealedSecret as any).data).map(([key, value]) => `${key}:${value}`);
        const newDictArray = Object.entries(dictionary).map(([key, value]) => `${key}:${value}`);
        if (!arraysAreEqual(oldDictArray, newDictArray)) res = true;
      } else if (secret.type === "aws") {
        if (aws_accessKeyRef.current.isDirty) res = true;
        if (aws_roleArnRef.current.isDirty) res = true;
        if (aws_secretKeyRef.current.isDirty) res = true;
        if (aws_externalIdRef.current.isDirty) res = true;
      } else if (secret.type === "ecr") {
        if (ecr_accessKeyRef.current.isDirty) res = true;
        if (ecr_roleArnRef.current.isDirty) res = true;
        if (ecr_secretKeyRef.current.isDirty) res = true;
        if (!arraysAreEqual(ecrRepos, ((revealedSecret as any).data as ECRDataMobx).repos)) res = true;
        if (ecr_externalIdRef.current.isDirty) res = true;
      } else if (secret.type === "nats-account") {
        if (nats_accountIdRef.current.isDirty) res = true;
        if (nats_privateKeyRef.current.isDirty) res = true;
      } else if (secret.type === "keypair") {
        if (s.data.secretKey !== input1Text) res = true;
        if (s.data.publicKey) {
          if (s.data.publicKey !== input2Text) res = true;
        } else {
          if (input2Text.length > 0) res = true;
        }
        if (keypair_passphraseRef.current.isDirty) res = true;
      } else if (secret.type === "tls") {
        if (s.data.key !== input1Text) res = true;
        if (s.data.cert !== input2Text) res = true;
        if (s.data.chain) {
          if (s.data.chain !== input3Text) res = true;
        } else {
          if (input3Text.length > 0) res = true;
        }
      } else if (secret.type === "opaque") {
        if (s.data.payload !== input1Text) res = true;
        if (s.data.encoding !== encoding) res = true;
      } else {
        if (s.data !== input1Text) res = true;
      }
    }
    setIsDirty(res);
  }, [
    encoding,
    revealedSecret,
    canReveal,
    userpass_usernameRef.current.isDirty,
    userpass_passwordRef.current.isDirty,
    azureConnector_urlRef.current.isDirty,
    azureConnector_codeRef.current.isDirty,
    keypair_passphraseRef.current.isDirty,
    aws_secretKeyRef.current.isDirty,
    aws_roleArnRef.current.isDirty,
    aws_accessKeyRef.current.isDirty,
    aws_externalIdRef.current.isDirty,
    ecr_secretKeyRef.current.isDirty,
    ecr_roleArnRef.current.isDirty,
    ecr_accessKeyRef.current.isDirty,
    ecrRepos,
    ecr_externalIdRef.current.isDirty,
    nats_accountIdRef.current.isDirty,
    nats_privateKeyRef.current.isDirty,
    input1Text,
    input2Text,
    input3Text,
    dictionary,
  ]);

  React.useEffect(() => {
    if (secret.type === "opaque") {
      setCanBeBase64(inputValidations.base64[0]("", input1Text) === true);
    }
  }, [input1Text]);

  React.useEffect(() => {
    setPromptWhen(isDirty);
  }, [isDirty]);

  React.useEffect(() => {
    const url = azureConnector_urlRef.current.value;
    if (!url.includes("?code=")) {
      return;
    }
    const [newUrl, newCode] = url.split("?code=");
    if (newUrl && newCode) {
      azureConnector_urlRef.current.setValue(newUrl);
      azureConnector_codeRef.current.setValue(newCode);
    }
  }, [azureConnector_urlRef.current.value]);

  React.useEffect(() => {
    if (canReveal === false) return;
    if (!revealedSecret) return;
    const s: any = revealedSecret as any;
    if (secret.type === "userpass") {
      userpass_usernameRef.current.setInitialValue(s.data.username);
      userpass_passwordRef.current.setInitialValue(s.data.password);
      setEncoding(s.data.encoding);
    } else if (secret.type === "azure-connector") {
      azureConnector_urlRef.current.setInitialValue(s.data.url);
      azureConnector_codeRef.current.setInitialValue(s.data.code);
    } else if (secret.type === "dictionary") {
      setDictionary((revealedSecret as any).data);
    } else if (secret.type === "aws") {
      aws_accessKeyRef.current.setInitialValue(s.data.accessKey);
      aws_roleArnRef.current.setInitialValue(s.data.roleArn);
      aws_secretKeyRef.current.setInitialValue(s.data.secretKey);
      aws_externalIdRef.current.setInitialValue(s.data.externalId || "");
    } else if (secret.type === "ecr") {
      ecr_accessKeyRef.current.setInitialValue(s.data.accessKey);
      ecr_roleArnRef.current.setInitialValue(s.data.roleArn);
      ecr_secretKeyRef.current.setInitialValue(s.data.secretKey);
      setEcrRepos(s.data.repos);
      ecr_externalIdRef.current.setInitialValue(s.data.externalId || "");
    } else if (secret.type === "nats-account") {
      nats_accountIdRef.current.setInitialValue(s.data.accountId);
      nats_privateKeyRef.current.setInitialValue(s.data.privateKey);
    } else if (secret.type === "keypair") {
      setInput1Text(s.data.secretKey);
      if (s.data.publicKey) {
        setInput2Text(s.data.publicKey);
      }
      if (s.data.passphrase) {
        keypair_passphraseRef.current.setInitialValue(s.data.passphrase);
      }
    } else if (secret.type === "tls") {
      setInput1Text(s.data.key);
      setInput2Text(s.data.cert);
      if (s.data.chain) {
        setInput3Text(s.data.chain);
      }
    } else if (secret.type === "opaque") {
      setInput1Text(s.data.payload);
      setTimeout(() => setEncoding(s.data.encoding), 200);
    } else {
      setInput1Text(s.data);
    }
  }, [canReveal]);

  React.useEffect(() => {
    const val1 = userpass_usernameRef.current.value;
    const val2 = userpass_passwordRef.current.value;
    const isBase64 = inputValidations.base64[0]("", val1) === true && inputValidations.base64[0]("", val2) === true;
    // if (!isBase64) {
    //   setEncoding("plain");
    // }
    setCanBeBase64(isBase64);
  }, [userpass_usernameRef.current.value, userpass_passwordRef.current.value]);

  React.useEffect(() => {
    if (ecrRepos.includes(ecr_repoRef.current.value)) {
      ecr_repoRef.current.setOverrideError("This repo is already added");
    } else {
      ecr_repoRef.current.clearOverrideError();
    }
  }, [ecr_repoRef.current.value, ecrRepos]);

  // Functions //
  async function revealSecret() {
    try {
      setIsFetchSecretData(true);
      const res = await secret.reveal();
      setRevealedSecret(res as any);
      setCanReveal(true);
      setIsFetchSecretData(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;

      if (e?.response?.status == 403) {
        errorMessage = "You don't have permissions to Reveal or Edit this secret.";
      }

      notification.warning({
        message: "Failed",
        description: errorMessage,
      });

      setRevealedSecret(null);
      setCanReveal(false);
      setIsFetchSecretData(false);
    }
  }

  function initFileLabels() {
    let label1 = "";
    let label2 = "";
    let label3 = "";
    switch (secret.type) {
      case "opaque":
        label1 = "Opaque Secret";
        break;
      case "tls":
        label1 = "TLS Key";
        label2 = "TLS Cert";
        label3 = "TLS Chain";
        break;
      case "gcp":
        label1 = "GCP Secret";
        break;
      case "docker":
        label1 = "Docker Secret";
        break;
      case "keypair":
        label1 = "Secret Key";
        label2 = "Public Key";
        break;
      case "azure-sdk":
        label1 = "Azure Secret";
        break;
      default:
        break;
    }
    setInput1Label(label1);
    setInput2Label(label2);
    setInput3Label(label3);
  }

  function reset() {
    if (canReveal === false) return;
    if (!revealedSecret) return;
    const s: any = revealedSecret as any;
    if (secret.type === "userpass") {
      userpass_usernameRef.current.reset();
      userpass_passwordRef.current.reset();
      setEncoding(s.data.encoding);
    } else if (secret.type === "azure-connector") {
      azureConnector_urlRef.current.reset();
      azureConnector_codeRef.current.reset();
    } else if (secret.type === "dictionary") {
      dictionary_keyRef.current.reset();
      dictionary_valueRef.current.reset();
      setEditedDictProperty(null);
      setDictionary((revealedSecret as any).data);
      setDictRevealedKeys([]);
    } else if (secret.type === "aws") {
      aws_accessKeyRef.current.reset();
      aws_roleArnRef.current.reset();
      aws_secretKeyRef.current.reset();
      aws_externalIdRef.current.reset();
    } else if (secret.type === "ecr") {
      ecr_accessKeyRef.current.reset();
      ecr_roleArnRef.current.reset();
      ecr_secretKeyRef.current.reset();
      setEcrRepos((revealedSecret as any).data.repos);
      ecr_repoRef.current.reset();
      ecr_externalIdRef.current.reset();
    } else if (secret.type === "nats-account") {
      nats_accountIdRef.current.reset();
      nats_privateKeyRef.current.reset();
    } else if (secret.type === "keypair") {
      setInput1Text(s.data.secretKey);
      setInput2Text(s.data.publicKey || "");
      keypair_passphraseRef.current.reset();
    } else if (secret.type === "tls") {
      setInput1Text(s.data.key);
      setInput2Text(s.data.cert);
      setInput3Text(s.data.chain || "");
    } else if (secret.type === "opaque") {
      setInput1Text(s.data.payload);
      setTimeout(() => setEncoding(s.data.encoding), 200);
    } else {
      setInput1Text(s.data);
    }
  }

  async function save() {
    try {
      setIsLoading(true);
      const body: any = {};

      if (canReveal && revealedSecret) {
        if (secret.type === "aws") {
          body.data = {
            accessKey: aws_accessKeyRef.current.value,
            secretKey: aws_secretKeyRef.current.value,
          };
          if (aws_roleArnRef.current.value) {
            body.data.roleArn = aws_roleArnRef.current.value;
          } else if ((revealedSecret as any).data.roleArn) {
            body.data.roleArn = null;
          }
          if (aws_externalIdRef.current.value) {
            body.data.externalId = aws_externalIdRef.current.value;
          } else if ((revealedSecret as any).data.externalId) {
            body.data.externalId = null;
          }
        } else if (secret.type === "ecr") {
          body.data = {
            accessKey: ecr_accessKeyRef.current.value,
            secretKey: ecr_secretKeyRef.current.value,
            repos: ecrRepos,
          };
          if (ecr_roleArnRef.current.value) {
            body.data.roleArn = ecr_roleArnRef.current.value;
          } else if ((revealedSecret as any).data.roleArn) {
            body.data.roleArn = null;
          }
          if (ecr_externalIdRef.current.value) {
            body.data.externalId = ecr_externalIdRef.current.value;
          } else if ((revealedSecret as any).data.externalId) {
            body.data.externalId = null;
          }
        } else if (secret.type === "dictionary") {
          body["$replace/data"] = dictionary;
        } else if (secret.type === "nats-account") {
          body["$replace/data"] = {
            accountId: nats_accountIdRef.current.value,
            privateKey: nats_privateKeyRef.current.value,
          };
        } else if (secret.type === "userpass") {
          body.data = {
            username: userpass_usernameRef.current.value,
            password: userpass_passwordRef.current.value,
            encoding,
          };
        } else if (secret.type === "azure-connector") {
          body.data = {
            url: azureConnector_urlRef.current.value,
            code: azureConnector_codeRef.current.value,
          };
        } else if (secret.type === "tls") {
          body.data = {
            key: input1Text,
            cert: input2Text,
          };
          if (input3Text.length > 0) {
            body.data.chain = input3Text;
          } else if ((revealedSecret as any).data.chain) {
            body.data.chain = null;
          }
        } else if (secret.type === "keypair") {
          body.data = {
            secretKey: input1Text,
          };
          if (input2Text) {
            body.data.publicKey = input2Text;
          } else if ((revealedSecret as any).data.publicKey) {
            body.data.publicKey = null;
          }
          if (keypair_passphraseRef.current.value) {
            body.data.passphrase = keypair_passphraseRef.current.value;
          } else if ((revealedSecret as any).data.passphrase) {
            body.data.passphrase = null;
          }
        } else if (secret.type === "opaque") {
          body.data = {
            payload: input1Text,
            encoding,
          };
        } else {
          body.data = input1Text;
        }
      }
      await secret.patch(body);
      userpass_usernameRef.current.setIsHidden(true);
      userpass_passwordRef.current.setIsHidden(true);
      azureConnector_urlRef.current.setIsHidden(true);
      azureConnector_codeRef.current.setIsHidden(true);
      aws_secretKeyRef.current.setIsHidden(true);
      aws_accessKeyRef.current.setIsHidden(true);
      ecr_secretKeyRef.current.setIsHidden(true);
      ecr_accessKeyRef.current.setIsHidden(true);
      nats_accountIdRef.current.setIsHidden(true);
      nats_privateKeyRef.current.setIsHidden(true);
      keypair_passphraseRef.current.setIsHidden(true);
      setRevealedSecret(null);
      setDictRevealedKeys([]);
      notification.success({
        message: "Success",
        description: "Updated secret",
      });
      setCanReveal(false);
      setIsLoading(false);
      await revealSecret();
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  function onDictionaryPropertyRemove(key: string) {
    const _dict = { ...dictionary };
    delete _dict[key];
    setDictionary(_dict);

    // Remove from revealed dictionary on deletion
    setDictRevealedKeys(dictRevealedKeys.filter((i) => i !== key));
  }

  function onDictionaryPropertyEdit(key: string) {
    dictionary_keyRef.current.setValue(key);
    dictionary_valueRef.current.setValue(dictionary[key]);
    setEditedDictProperty(key);
  }

  function cancelDictionaryPropertyEdit() {
    dictionary_keyRef.current.reset();
    dictionary_valueRef.current.reset();
    setEditedDictProperty(null);
  }

  function dictionaryPropertyAddConfirm() {
    const _dict = { ...dictionary };
    const key = dictionary_keyRef.current.value;
    const value = dictionary_valueRef.current.value;
    const isRevealAll = dictRevealedKeys.length == Object.keys(_dict).length;
    _dict[key] = value;
    setDictionary(_dict);
    dictionary_keyRef.current.reset();
    dictionary_valueRef.current.reset();

    // Reveal key if all keys are already revealed
    if (isRevealAll) {
      setDictRevealedKeys([...dictRevealedKeys, key]);
    }
  }

  function dictionaryPropertyEditConfirm() {
    if (!editedDictProperty) return;
    const _dict = { ...dictionary };
    delete _dict[editedDictProperty];
    const key = dictionary_keyRef.current.value;
    const value = dictionary_valueRef.current.value;
    _dict[key] = value;
    setDictionary(_dict);
    setEditedDictProperty(null);
    dictionary_keyRef.current.reset();
    dictionary_valueRef.current.reset();

    // Remove key from dict revealed keys and reveal the new key
    if (key != editedDictProperty && dictRevealedKeys.includes(editedDictProperty)) {
      setDictRevealedKeys(dictRevealedKeys.filter((i) => i !== editedDictProperty));
      setDictRevealedKeys([...dictRevealedKeys, key]);
    }
  }

  function dictionaryToggleValuesVisibility() {
    if (Object.keys(dictionary).length === dictRevealedKeys.length) {
      // all revealed
      setDictRevealedKeys([]);
    } else {
      setDictRevealedKeys(Object.keys(dictionary).map((key) => key));
    }
  }

  function onECRRepoRemove(repo: string) {
    setEcrRepos((r) => r.filter((i) => i !== repo));
  }

  function onECRRepoAdd() {
    const value = ecr_repoRef.current.value;
    setEcrRepos((r) => [...r, value]);
    ecr_repoRef.current.reset();
  }

  function onUploadDictFile(e: any) {
    const input: HTMLInputElement = e.target;
    if (!input.value || !input.files || input.files.length < 1) {
      input.value = "";
      return;
    }

    const file = input.files[0];
    if (!file) {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
      return;
    }

    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = function (evt) {
      // Try env file
      const overriddenKeys: string[] = [];
      const dictionaryKeys = Object.keys(dictionary);
      let atLeastOneSucceeded = false;

      const _dict = { ...dictionary };

      // Try env file
      try {
        const content: string = evt.target?.result as string;
        const rawPairs = Object.entries(parseEnvFile(content)).map(([name, value]) => ({ name, value }));
        for (const pair of rawPairs) {
          if (dictionaryKeys.includes(pair.name)) {
            overriddenKeys.push(pair.name);
          }
          _dict[pair.name] = pair.value;
        }
        atLeastOneSucceeded = true;
      } catch (e) {
        console.error("env: " + e.message);
      }

      if (!atLeastOneSucceeded) {
        // Try yaml
        try {
          const content: string = evt.target?.result as string;
          const rawYamls: { [_: string]: string }[] = jsYaml.loadAll(content) as any;

          // Try k8s secret
          for (const rawYaml of rawYamls) {
            if (!!rawYaml.data && typeof rawYaml.data === "object" && Object.keys(rawYaml.data).length > 0) {
              const rawPairs = Object.entries(rawYaml.data as { [_: string]: string }).map(([name, value]) => ({
                name,
                value,
              }));
              for (const pair of rawPairs) {
                if (dictionaryKeys.includes(pair.name)) {
                  overriddenKeys.push(pair.name);
                }
                _dict[pair.name] = pair.value;
              }
              atLeastOneSucceeded = true;
            } else {
              const rawPairs = Object.entries(rawYaml).map(([name, value]) => ({ name, value }));
              for (const pair of rawPairs) {
                if (dictionaryKeys.includes(pair.name)) {
                  overriddenKeys.push(pair.name);
                }
                _dict[pair.name] = pair.value;
              }
              atLeastOneSucceeded = true;
            }
          }
        } catch (e) {
          console.error("yaml: " + e.message);
        }
      }

      if (!atLeastOneSucceeded) {
        // Try json
        try {
          const content: string = evt.target?.result as string;
          const rawPairs = Object.entries(JSON.parse(content) as { [_: string]: string }).map(([name, value]) => ({
            name,
            value,
          }));
          for (const pair of rawPairs) {
            if (dictionaryKeys.includes(pair.name)) {
              overriddenKeys.push(pair.name);
            }
            _dict[pair.name] = pair.value;
          }
          atLeastOneSucceeded = true;
        } catch (e) {
          console.error("json: " + e.message);
        }
      }
      if (atLeastOneSucceeded && overriddenKeys.length > 0) {
        notification.info({
          duration: null,
          message: "Some Keys Were Overridden",
          description: (
            <>
              <div>Overridden Keys Are:</div>
              <ul>
                {overriddenKeys.map((envVarKey, index) => (
                  <li key={envVarKey + String(index)} className="block my-1">
                    {envVarKey}
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      }
      if (!atLeastOneSucceeded) {
        notification.warning({
          message: "Failed",
          description: "File is invalid",
        });
      } else {
        setDictionary(_dict);
      }
    };
    reader.onerror = function () {
      input.value = "";
      notification.warning({
        message: "Failed",
        description: "File is invalid",
      });
    };
    input.value = "";
  }

  function handleCodeEditorOpen(fileLabel: string, fileLabelIndex: number): void {
    setCodeEditorTitle(fileLabel);
    setSelectedFileLabelIndex(fileLabelIndex);

    switch (fileLabelIndex) {
      case GenericInputs.Input1:
        setCodeEditorInitialValue(input1Text);
        break;
      case GenericInputs.Input2:
        setCodeEditorInitialValue(input2Text);
        break;
      case GenericInputs.Input3:
        setCodeEditorInitialValue(input3Text);
        break;
    }

    setIsCodeEditorOpen(true);
  }

  function handleCodeEditorOk(value: string): void {
    switch (selectedFileLabelIndex) {
      case GenericInputs.Input1:
        setInput1Text(value);
        break;
      case GenericInputs.Input2:
        setInput2Text(value);
        break;
      case GenericInputs.Input3:
        setInput3Text(value);
        break;
    }

    setIsCodeEditorOpen(false);
  }

  if (canReveal === null) {
    return <CircleSpinner />;
  }

  if (canReveal === false) {
    return <NGAlert type={"error"} message="You do not have the permissions to reveal and edit this secret." />;
  }

  return (
    <>
      <div>
        <div>
          {isCodeEditorOpen ? (
            <CodeEditorModal
              title={codeEditorTitle}
              initialValue={codeEditorInitialValue}
              open={isCodeEditorOpen}
              onOk={handleCodeEditorOk}
              onClose={() => setIsCodeEditorOpen(false)}
            />
          ) : null}
          {isFetchingSecretData ? <CircleSpinner /> : null}
          {secret.type === "dictionary" && (
            <div className="flex flex-col">
              <div className="mb-2 flex items-center gap-2">
                <span>Dictionary Key-Value Pairs</span>
                <label
                  tabIndex={0}
                  className="cursor-pointer text-sm ngfocus color-link flex items-center"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("envFile")?.click();
                    }
                  }}
                >
                  Import <Download className="feather-icon inline-block ml-1" />
                  <input
                    className="invisible"
                    style={{ width: 0 }}
                    onChange={onUploadDictFile}
                    type={"file"}
                    multiple={false}
                  />
                </label>
              </div>
              <div className="mb-3">
                {Object.entries(dictionary).length === 0 ? (
                  <NoTag override={"No dictionary properties"} />
                ) : (
                  <div
                    className="flex table-labels px-4 py-1 border"
                    style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                  >
                    <div className="w-5/12">Key</div>
                    <div className="w-7/12 flex justify-between">
                      <span>Value</span>
                      <button
                        className="ngfocus color-link font-medium flex items-center gap-x-1"
                        onClick={dictionaryToggleValuesVisibility}
                      >
                        {Object.keys(dictionary).length === dictRevealedKeys.length ? (
                          <>
                            <span>Hide Values</span>
                            <EyeOff className="feather-icon" />
                          </>
                        ) : (
                          <>
                            <span>Reveal Values</span>
                            <Eye className="feather-icon" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                )}
                {Object.entries(dictionary)
                  .sort()
                  .map(([key, value]) => (
                    <DictionaryProperty
                      key={key}
                      _key={key}
                      _value={value}
                      onEdit={onDictionaryPropertyEdit}
                      onRemove={onDictionaryPropertyRemove}
                      isHidden={!dictRevealedKeys.includes(key)}
                      toggleIsHidden={() => {
                        if (dictRevealedKeys.includes(key)) {
                          setDictRevealedKeys(dictRevealedKeys.filter((i) => i !== key));
                        } else {
                          setDictRevealedKeys([...dictRevealedKeys, key]);
                        }
                      }}
                    />
                  ))}
              </div>
              {!editedDictProperty && Object.keys(dictionary).includes(dictionary_keyRef.current.value) ? (
                <NGAlert
                  className="mb-3"
                  type={"warning"}
                  message={`A property with key "${dictionary_keyRef.current.value}" is already defined.`}
                />
              ) : null}
              {editedDictProperty ? (
                <NGAlert
                  className="mb-3"
                  type={"info"}
                  render={() => (
                    <p>
                      You are now editing an item with the key <span className="font-bold">{editedDictProperty}</span>
                    </p>
                  )}
                />
              ) : null}
              <div className="flex gap-x-2 mb-2">
                <MobxMultilineInput className="w-full" data={dictionary_keyRef.current} />
                <MobxMultilineInput className="w-full" data={dictionary_valueRef.current} />
              </div>
              <div className="self-end flex items-center">
                {editedDictProperty ? (
                  <NGButton
                    className="mr-2"
                    variant={"danger"}
                    outlined
                    style={{ width: 72 }}
                    onClick={cancelDictionaryPropertyEdit}
                  >
                    Cancel
                  </NGButton>
                ) : null}
                <NGButton
                  variant={"action"}
                  style={{ width: 72 }}
                  disabled={dictBtnDisabled}
                  onClick={editedDictProperty ? dictionaryPropertyEditConfirm : dictionaryPropertyAddConfirm}
                >
                  {editedDictProperty ? "Update" : "Add"}
                </NGButton>
              </div>
            </div>
          )}
          {secret.type === "userpass" && (
            <>
              <NGRadioGroup
                label="Encoding"
                className="mb-4"
                value={encoding}
                onChange={(value) => setEncoding(value as any)}
                options={[
                  { value: "base64", label: "Base64", isDisabled: !canBeBase64 && encoding !== "base64" },
                  { value: "plain", label: "Plain" },
                ]}
              />
              <MobxMultilineInput
                style={{ width: 450 }}
                className="mb-4"
                dataTestId="userpass_username"
                data={userpass_usernameRef.current}
              />
              <MobxMultilineInput
                style={{ width: 450 }}
                className="mb-4"
                dataTestId="userpass_password"
                data={userpass_passwordRef.current}
              />
            </>
          )}
          {secret.type === "azure-connector" && (
            <>
              <MobxMultilineInput style={{ width: 450 }} className="mb-4" data={azureConnector_urlRef.current} />
              <MobxMultilineInput style={{ width: 450 }} className="mb-4" data={azureConnector_codeRef.current} />
            </>
          )}
          {secret.type === "aws" && (
            <>
              <MobxMultilineInput style={{ width: 450 }} className="mb-4" data={aws_accessKeyRef.current} />
              <MobxMultilineInput style={{ width: 450 }} className="mb-4" data={aws_secretKeyRef.current} />
              <MobxMultilineInput style={{ width: 450 }} className="mb-4" data={aws_roleArnRef.current} />
              <MobxInput style={{ width: 450 }} className="mb-4" data={aws_externalIdRef.current} />
            </>
          )}
          {secret.type === "ecr" && (
            <div style={{ width: 450 }} className="flex flex-col">
              <MobxMultilineInput className="mb-4" data={ecr_accessKeyRef.current} />
              <MobxMultilineInput className="mb-4" data={ecr_secretKeyRef.current} />
              <MobxMultilineInput className="mb-4" data={ecr_roleArnRef.current} />
              <div className="mb-2">
                <FormLabel>ECR Repositories</FormLabel>
              </div>
              {ecrRepos.length === 0 ? (
                <span>
                  <NoTag override={"No repos"} />
                </span>
              ) : null}
              {ecrRepos.map((repo) => (
                <RemovableValue
                  style={{ marginRight: 0 }}
                  key={repo}
                  value={repo}
                  onRemove={() => onECRRepoRemove(repo)}
                />
              ))}
              <MobxMultilineInput className="my-2" data={ecr_repoRef.current} />
              <NGButton
                variant={"action"}
                className="self-end"
                style={{ width: 72 }}
                disabled={ecr_repoRef.current.value.length < 1 || !ecr_repoRef.current.isValid}
                onClick={onECRRepoAdd}
              >
                Add
              </NGButton>
              <MobxInput style={{ width: 450 }} className="my-4" data={ecr_externalIdRef.current} />
            </div>
          )}
          {secret.type === "nats-account" && (
            <>
              <MobxMultilineInput style={{ width: 450 }} className="mb-4" data={nats_accountIdRef.current} />
              <MobxMultilineInput style={{ width: 450 }} className="mb-4" data={nats_privateKeyRef.current} />
            </>
          )}
          {input1Label ? (
            <div className="mb-4" style={{ width: 450 }}>
              {input1Text ? (
                <div className="flex items-center gap-x-2 mt-2 w-full">
                  <ReadOnlyInput label={input1Label} value={input1Text} type="password" />
                  <NGButton
                    style={{ minWidth: 150, height: 46 }}
                    variant={"secondary"}
                    onClick={() => {
                      handleCodeEditorOpen(input1Label, GenericInputs.Input1);
                    }}
                  >
                    Edit
                  </NGButton>
                </div>
              ) : (
                <div>
                  <FormLabel size="base">
                    {input1Label} <span className="color-danger">*</span>
                  </FormLabel>
                  <NGButton
                    className="mt-2"
                    variant="primary"
                    onClick={() => {
                      handleCodeEditorOpen(input1Label, GenericInputs.Input1);
                    }}
                  >
                    Set Data
                  </NGButton>
                </div>
              )}
              {secret.type === "opaque" ? (
                <>
                  <div className="mt-2 flex items-center">
                    <NGCheckbox
                      id={"base64"}
                      aria-label="Base64 Decode at Runtime"
                      checked={encoding === "base64"}
                      onChange={(value) => setEncoding(value ? "base64" : "plain")}
                    />
                    <label htmlFor={"base64"} className="ml-1 cursor-pointer">
                      Base64 Decode at Runtime
                    </label>
                    <InfoTooltip
                      title="Checking this box will perform a base64 decode at runtime of the secret content."
                      style={{ marginLeft: 5 }}
                    />
                  </div>
                  {encoding === "base64" && !canBeBase64 ? (
                    <NGAlert type="error" className="mt-2" message={"The provided value is not a valid Base64"} />
                  ) : null}
                </>
              ) : null}
            </div>
          ) : null}
          {input2Label ? (
            <div className="mb-4" style={{ width: 450 }}>
              {input2Text ? (
                <div className="flex items-center gap-x-2 mt-2 w-full">
                  <ReadOnlyInput label={input2Label} value={input2Text} type="password" />
                  <NGButton
                    style={{ minWidth: 150, height: 46 }}
                    variant={"secondary"}
                    onClick={() => {
                      handleCodeEditorOpen(input2Label, GenericInputs.Input2);
                    }}
                  >
                    Edit
                  </NGButton>
                </div>
              ) : (
                <div>
                  <FormLabel size="base">
                    {input2Label} {secret.type === "tls" ? <span className="color-danger">*</span> : null}
                  </FormLabel>
                  <NGButton
                    className="mt-2"
                    variant="primary"
                    onClick={() => {
                      handleCodeEditorOpen(input2Label, GenericInputs.Input2);
                    }}
                  >
                    Set Data
                  </NGButton>
                </div>
              )}
            </div>
          ) : null}
          {input3Label ? (
            <div className="mb-4" style={{ width: 450 }}>
              {input3Text ? (
                <div className="flex items-center gap-x-2 mt-2 w-full">
                  <ReadOnlyInput label={input3Label} value={input3Text} type="password" />
                  <NGButton
                    style={{ minWidth: 150, height: 46 }}
                    variant={"secondary"}
                    onClick={() => {
                      handleCodeEditorOpen(input3Label, GenericInputs.Input3);
                    }}
                  >
                    Edit
                  </NGButton>
                </div>
              ) : (
                <div>
                  <FormLabel size="base">{input3Label}</FormLabel>
                  <NGButton
                    className="mt-2"
                    variant="primary"
                    onClick={() => {
                      handleCodeEditorOpen(input3Label, GenericInputs.Input3);
                    }}
                  >
                    Set Data
                  </NGButton>
                </div>
              )}
            </div>
          ) : null}
          {secret.type === "keypair" ? (
            <div style={{ width: 450 }}>
              <MobxMultilineInput style={{ width: 450 }} className="mt-4" data={keypair_passphraseRef.current} />
              {!keypair_passphraseRef.current.value ? (
                <NGAlert className="mt-2" message={"Passphrase is required if provided secret key is encrypted."} />
              ) : null}
            </div>
          ) : null}
        </div>
        <div style={{ width: 450 }} className="mt-8 flex items-center">
          <FormButtons
            onReset={reset}
            onSave={save}
            resetDisabled={isLoading || !isDirty}
            saveDisabled={isLoading || !isDirty || !isValid}
            loading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export const Edit = observer(EditRaw);
