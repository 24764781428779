import clsx from "clsx";
import { UIData } from "../../../../mobxStores/uiData/uiData";
import { observer } from "mobx-react-lite";
import { NavLink, useLocation } from "react-router-dom";
import { ExternalLink } from "react-feather";
import { Tooltip } from "../../../Tooltip";

export interface MenuLinkProps {
  url: string;
  prefix?: string;
  isAdd?: boolean;
  showArrow?: boolean;
  isExternal?: boolean;
  Icon?: React.ElementType;
  testId?: string;
  tooltip?: {
    content: string;
    disable: boolean;
    placement: "top" | "right";
  };
  style?: React.CSSProperties;
  customIsActive?: (pathname: string) => boolean;
  onClick?: (e: React.MouseEvent) => void;
  label: string;
}

const MenuLinkRaw: React.FC<MenuLinkProps> = ({
  url,
  prefix,
  isAdd = false,
  showArrow = false,
  isExternal = false,
  Icon,
  tooltip,
  customIsActive,
  style,
  onClick,
  label,
}) => {
  const { pathname } = useLocation();
  const isActive = !customIsActive ? pathname.startsWith(url.split("?")[0]) : customIsActive(pathname);

  return (
    <NavLink
      to={url}
      className={"ngfocus"}
      tabIndex={isActive ? -1 : 0}
      style={{ width: "100%", ...style }}
      onClick={onClick}
    >
      <Tooltip
        title={prefix ? `${UIData.isNavCollapsed ? `${prefix}: ` : ""}${tooltip?.content}` : label}
        mouseEnterDelay={!UIData.isNavCollapsed && (!tooltip || tooltip.disable) ? 9999 : 0}
        placement={tooltip?.placement || "right"}
        trigger={["hover"]}
      >
        <div
          data-testid={`${prefix}-menu-change`}
          className={clsx("menu-link", {
            action: isAdd,
            selected: isActive,
            "with-arrow": showArrow,
          })}
        >
          {Icon ? <Icon className="menu-link-icon" /> : null}
          {!UIData.isNavCollapsed && (
            <>
              <span data-testid={label.toLowerCase()}>
                {prefix ? `${prefix}: ` : null}
                {label}
              </span>
              {isExternal ? <ExternalLink size={21} /> : null}
            </>
          )}
        </div>
      </Tooltip>
    </NavLink>
  );
};

export const MenuLink = observer(MenuLinkRaw);
