import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { notification } from "antd";
import { CreateLayout } from "../../components/create/layout";
import { CreateFormLink } from "../org/create/create";
import { NameModel } from "../../mobxDataModels/nameModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { homeLink, linksOf, request, resourceLink } from "../../services/cpln";
import { TagsNew } from "../../components/create/tags";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { NGInputListMst } from "../../newcomponents/inputList/inputListMst";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import clsx from "clsx";
import { Workload } from "../../schema/types/workload/workload";

const IpSetCreateRaw: React.FC = () => {
  const basePath = useBasePath("/ipset/-create/*");
  const formDataRef = React.useRef(new NGFormData());

  const nameRef = React.useRef(NameModel.create());
  const descriptionRef = React.useRef(StringModel.create({ label: "Description" }));
  const [link, setLink] = React.useState("");
  const locationsRef = React.useRef(ListOfItemsModel.create());
  const tagsRef = React.useRef(TagsNewModel.create());

  const [isLoading, setIsLoading] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  let isSpecValid = true;
  if (!nameRef.current.isValid) {
    isSpecValid = false;
  }
  for (const location of locationsRef.current.items) {
    // TODO keep this error
    if (!location.firstValue) {
      isSpecValid = false;
    }
  }

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const links: CreateFormLink[] = [
    { name: "General", value: `-general`, isActive: true },
    { name: "Spec", value: `-spec`, isActive: nameRef.current.isValid },
    { name: "Tags", value: `-tags`, isActive: isSpecValid },
  ];

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };

  if (pathname.includes("-general")) {
    if (nameRef.current.isValid) {
      next.isActive = true;
    }
    next.label = "Next (Spec)";
    next.url = `-spec`;
  } else if (pathname.includes("-spec")) {
    if (isSpecValid) {
      next.isActive = true;
    }
    next.label = "Next (Tags)";
    next.url = `-tags`;
  }

  React.useEffect(() => {
    for (const item of locationsRef.current.items) {
      if (!item.secondValue) {
        item.setSecondValue("keep");
      }
    }
  }, [locationsRef.current.items.length]);

  React.useEffect(() => {
    navigate(`${basePath}/-general`, { replace: true });
  }, []);

  React.useEffect(() => {
    let res = true;
    if (!nameRef.current.isValid) res = false;
    if (!descriptionRef.current.isValid) res = false;
    if (!isSpecValid) res = false;
    if (!tagsRef.current.isValid) res = false;
    setIsValid(res);
  }, [nameRef.current.isValid, descriptionRef.current.isValid, isSpecValid, tagsRef.current.isValid]);

  function getIpSetObject() {
    const object = {
      name: nameRef.current.value,
      description: descriptionRef.current.value || nameRef.current.value,
      spec: {
        link: link,
        locations: locationsRef.current.items.map((i) => {
          const { absolute } = ngParseLink(i.firstValue, { kind: "location" });
          return { name: absolute, retentionPolicy: i.secondValue };
        }),
      },
      tags: tagsRef.current.asObject,
    };
    if (link) {
      object.spec.link = link;
    } else {
      // @ts-ignore
      delete object.spec.link;
    }
    return object;
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      await request({ method: "post", url: homeLink("ipset"), body: getIpSetObject() });
      const itemLink = resourceLink("ipset", getIpSetObject().name);
      notification.success({
        message: "Success",
        description: "Ip Set is created",
      });
      setIsLoading(false);
      PromptContext.setIsDisabled(true);
      navigate(`/console${itemLink}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/-create");
  }

  return (
    <BasePathContext.Provider value={basePath}>
      <NGFormContext.Provider value={formDataRef.current}>
        <CreateLayout
          getPreview={getIpSetObject}
          next={next}
          shouldBlockNavigation={shouldBlockNavigation}
          title={"Create Ip Set"}
          name={nameRef.current.value}
          canCreate={isValid}
          onConfirm={onConfirm}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-general`} />} />
            <Route
              path={`-general`}
              element={
                <div>
                  <NGFormElement
                    name={"name"}
                    value={nameRef.current.value}
                    onChange={nameRef.current.setValue}
                    label={nameRef.current.label}
                    error={nameRef.current.error}
                    required={nameRef.current.isRequired}
                  />
                  <NGFormElement
                    name={"description"}
                    value={descriptionRef.current.value}
                    onChange={descriptionRef.current.setValue}
                    label={descriptionRef.current.label}
                  />
                </div>
              }
            />
            <Route
              path={`-spec`}
              element={
                <div>
                  <NGKindSelect<Workload>
                    kind="workload"
                    className="mb-4"
                    style={{ width: 650 }}
                    renderOption={({ getOptionValue, getOptionLabel, option, isHovered, isSelected, props }) => {
                      const { isValid, name: gvcName } = ngParseLink(linksOf(option).gvc || "", { useInputCtx: true });

                      return (
                        <li
                          key={getOptionValue(option)}
                          {...props}
                          className={clsx(`flex items-center justify-between`, {
                            "option-hover": isHovered,
                            "option-selected": isSelected,
                          })}
                        >
                          <span>{getOptionLabel(option)}</span>
                          {isValid ? <span>{gvcName}</span> : null}
                        </li>
                      );
                    }}
                    getOptionValue={(option) => {
                      const link = linksOf(option).self;
                      if (!link) {
                        return `invalid item - ${option.name}`;
                      }

                      const { isValid, gvcRelative } = ngParseLink(link, { useInputCtx: true });
                      if (!isValid) {
                        return `invalid item - ${option.name}`;
                      }
                      return gvcRelative;
                    }}
                    value={link}
                    onChange={(value) => setLink(value)}
                  />
                  <NGInputListMst
                    label="Locations"
                    styles={{
                      header: { width: 650 },
                      container: { width: 650 },
                      secondInputContainer: { minWidth: 100, flexGrow: 0 },
                    }}
                    data={locationsRef.current}
                    firstInput={(i) => (
                      <NGKindSelect kind="location" value={i.firstValue} onChange={(value) => i.setFirstValue(value)} />
                    )}
                    secondInput={(i) => (
                      <NGSelect
                        options={[
                          { label: "Free", value: "free" },
                          { label: "Keep", value: "keep" },
                        ]}
                        value={i.secondValue}
                        onChange={(value) => i.setSecondValue(value)}
                      />
                    )}
                  />
                </div>
              }
            />
            <Route path={`-tags`} element={<TagsNew tags={tagsRef.current} />} />
          </Routes>
        </CreateLayout>
      </NGFormContext.Provider>
    </BasePathContext.Provider>
  );
};

export const IpSetCreate = observer(IpSetCreateRaw);
