import { applySnapshot, flow, getParent, Instance, types } from "mobx-state-tree";
import { request } from "../../services/cpln";
import { BaseModel } from "../base";

export const VolumeSetPerformanceClassModel = types.model({
  name: types.string,
  minCapacity: types.maybe(types.number),
  maxCapacity: types.number,
  // featuresSupported: types.array(types.string), // 'automatic-expansion', 'snapshots'
});
export interface VolumeSetPerformanceClassMobx extends Instance<typeof VolumeSetPerformanceClassModel> {}
export interface VolumeSetPerformanceClass {
  name: string;
  minCapacity?: number;
  maxCapacity: number;
  // featuresSupported: VolumeSetPerformanceClassFeature[];
  // addition
  readableName: string;
}

export const PerformanceClasses: VolumeSetPerformanceClass[] = [
  {
    name: "general-purpose-ssd",
    minCapacity: 10,
    maxCapacity: 16384,
    // featuresSupported: [
    //   "automatic-expansion", //
    //   "snapshots",
    // ],
    readableName: "General Purpose SSD",
  },
  {
    name: "high-throughput-ssd",
    minCapacity: 1000,
    maxCapacity: 16384,
    // featuresSupported: [
    //   "automatic-expansion", //
    //   "snapshots",
    // ],
    readableName: "High Throughput SSD",
  },
];

export const VolumeSetSnapshotModel = types.model({
  name: types.string,
  id: types.string,
  created: types.string,
  expires: types.maybe(types.string),
  size: types.number, // in gb
  tags: types.array(types.frozen()),
});
export interface VolumeSetSnapshotMobx extends Instance<typeof VolumeSetSnapshotModel> {}
export interface VolumeSetSnapshot {
  name: string;
  id: string;
  created: string;
  expires?: string;
  size: number;
  tags: { [_: string]: string };
}

export const VolumeSetStatusVolumeModel = types
  .model({
    lifecycle: types.maybe(types.string), // 'creating', 'unused', 'unbound', 'bound', 'deleted', 'repairing'
    storageDeviceId: types.maybe(types.string),
    index: types.number,
    currentSize: types.number, // in gb
    driver: types.string,
    volumeSnapshots: types.array(VolumeSetSnapshotModel),
  })
  .views((self) => ({
    get volumeSet() {
      const parent: VolumeSetMobx = getParent(self, 5);
      return parent;
    },
  }));
export interface VolumeSetStatusVolumeMobx extends Instance<typeof VolumeSetStatusVolumeModel> {}
export interface VolumeSetStatusVolume {
  lifecycle?: "creating" | "unused" | "unbound" | "bound" | "deleted" | "repairing";
  storageDeviceId?: string;
  index: number;
  currentSize: number;
  driver: string;
  location: string;
  volumeSnapshots: VolumeSetSnapshot[];
}

export const VolumeSetStatusLocationModel = types.model({
  name: types.optional(types.string, ""),
  volumes: types.array(VolumeSetStatusVolumeModel),
  desiredVolumeCount: types.optional(types.number, 0),
});

export const VolumeSetStatusModel = types.model({
  parentId: types.maybe(types.string),
  usedByWorkload: types.maybe(types.string),
  locations: types.array(VolumeSetStatusLocationModel),
});
export interface VolumeSetStatusMobx extends Instance<typeof VolumeSetStatusModel> {}
export interface VolumeSetStatus {
  parentId?: string;
  replicaCount?: number;
  volumes: VolumeSetStatusVolume[];
}

const VolumeSetSpecSnapshotsModel = types.model({
  // What are examples, can it be empty? if not, what is the default?
  retentionDuration: types.optional(types.string, ""),
  // .regex(
  //   /^([0-9]+(\.[0-9]+)?d)?([0-9]+(\.[0-9]+)?h)?([0-9]+(\.[0-9]+)?m)?([0-9]+(\.[0-9]+)?s)?([0-9]+(\.[0-9]+)?ms)?([0-9]+(\.[0-9]+)?us)?([0-9]+(\.[0-9]+)?ns)?$/
  // )
  schedule: types.optional(types.string, ""),
});
export interface VolumeSetSpecSnapshotsMobx extends Instance<typeof VolumeSetSpecSnapshotsModel> {}
export interface VolumeSetSpecSnapshots {
  retentionDuration: string;
  schedule: string;
}

const VolumeSetSpecScalingModel = types.model({
  maxCapacity: types.optional(types.number, 10), // min value is 10
  minFreePercentage: types.optional(types.number, 1), // between 1 and 100
  scalingFactor: types.optional(types.number, 1.1), // min value is 1.1
});
export interface VolumeSetSpecScalingMobx extends Instance<typeof VolumeSetSpecScalingModel> {}
export interface VolumeSetSpecScaling {
  maxCapacity: number;
  minFreePercentage: number;
  scalingFactor: number;
}

const VolumeSetSpecModel = types.model({
  initialCapacity: types.number, // min is 10
  performanceClass: types.string, // "general-purpose-ssd", "high-throughput-ssd"
  fileSystemType: types.string, // 'xfs', 'ext4', ext4 is default
  snapshots: types.maybe(VolumeSetSpecSnapshotsModel),
  storageClassSuffix: types.optional(types.string, ""),
  autoscaling: types.maybe(VolumeSetSpecScalingModel),
});
export interface VolumeSetSpecMobx extends Instance<typeof VolumeSetSpecModel> {}
export interface VolumeSetSpec {
  initialCapacity: number;
  performanceClass: "general-purpose-ssd" | "high-throughput-ssd";
  fileSystemType: "xfs" | "ext4";
  snapshots?: VolumeSetSpecSnapshots;
  storageClassSuffix?: string;
  autoscaling?: VolumeSetSpecScaling;
}

// TODO implement below for volumeSetSpecModel validity
//Validate initialCapacity
// if (value.initialCapacity > performanceClass.maxCapacity) {
//   throw new Error(`initialCapacity cannot be greater than the maxCapacity of its performance class (${performanceClass.maxCapacity} GB)`);
// }
// if (performanceClass.minCapacity && value.initialCapacity < performanceClass.minCapacity) {
//   throw new Error(`initialCapacity cannot be less than the minCapacity of its performance class (${performanceClass.minCapacity} GB)`);
// }
// if (value.autoscaling?.maxCapacity && value.initialCapacity > value.autoscaling.maxCapacity) {
//   throw new Error('initialCapacity cannot be greater than autoscaling.maxCapacity');
// }

// //Validate autoscaling.maxCapacity
// if (value.autoscaling?.maxCapacity && value.autoscaling?.maxCapacity > performanceClass.maxCapacity) {
//   throw new Error(
//     `autoscaling.maxCapacity cannot be greater than the maxCapacity of its performance class (${performanceClass.maxCapacity} GB)`
//   );
// }
// if (value.autoscaling?.maxCapacity && performanceClass.minCapacity && value.autoscaling?.maxCapacity < performanceClass.minCapacity) {
//   throw new Error(
//     `autoscaling.maxCapacity cannot be less than the minCapacity of its performance class (${performanceClass.maxCapacity} GB)`
//   );
// }
// return value;

export const VolumeSetModel = types
  .compose(
    "VolumeSet",
    BaseModel,
    types.model({
      spec: VolumeSetSpecModel,
      status: types.optional(VolumeSetStatusModel, () => VolumeSetStatusModel.create()),
      isFetching: types.optional(types.boolean, false),
    })
  )
  .views((self) => ({
    get gvc() {
      return self.selfLink.split("/")[4];
    },
  }))
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      self.isFetching = true;
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
      self.isFetching = false;
    });
    return { fetch };
  })
  .actions((self) => {
    const patch: (body: any) => Promise<void> = flow(function* (body: any) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      delete body.events;
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  });

export interface VolumeSetMobx extends Instance<typeof VolumeSetModel> {}
