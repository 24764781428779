import * as React from "react";
import { Kind } from "../../../mst/base";
import { homeLink, linksOf, request } from "../../../services/cpln";
import { UseTableItemDataOptions, useTableItemData } from "./useTableItemData";
import { List } from "../../../schema/types/base";
import { Query } from "../../../schema/types/query";

export function useTableItemQueryData<T>(kind: Kind, opts?: UseTableItemDataOptions) {
  const itemData = useTableItemData<T>(kind, opts);

  const [query, setQuery] = React.useState<Query | null>(null);
  const [isLoadingQueried, setIsLoadingQueried] = React.useState(false);
  const [queriedItems, setQueriedItems] = React.useState<T[]>([]);

  async function fetchQueriedItems(automated: boolean = false) {
    try {
      if (!query) {
        return;
      }

      // TODO can merge a query and scope to gvc here
      if (!automated) {
        setIsLoadingQueried(true);
        setQueriedItems([]);
      }
      const { data } = await request<List<T>>({ method: "post", url: homeLink(kind) + "/-query", body: query });
      let allItems: T[] = data.items;
      let nextLink = linksOf(data).next;
      while (nextLink) {
        const { data } = await request({ url: nextLink });
        nextLink = linksOf(data).next;
        allItems.splice(allItems.length, 0, ...data.items);
      }

      if (!query?.spec?.sort) {
        allItems.sort((a: any, b: any) => {
          if (a.name < b.name) return -1;
          if (b.name < a.name) return 1;
          return 0;
        });
      }
      setQueriedItems(allItems);
      setIsLoadingQueried(false);
    } catch (e) {
      setIsLoadingQueried(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      console.error(`Failed to fetch queried items of ${kind}`, errorMessage);
    }
  }

  React.useEffect(() => {
    fetchQueriedItems();
  }, [kind, query]);

  const visibleItems = query ? queriedItems : itemData.items;

  return { ...itemData, isLoadingQueried, queriedItems, fetchQueriedItems, query, setQuery, visibleItems };
}
