import * as React from "react";
import { Navigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { GVCMobx, GVCModel } from "../../mst/kinds/gvc";
import { GvcDetail } from "./detail";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { request, resourceLink } from "../../services/cpln";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { NGFormData } from "../../mobxStores/ngFormData";
import { NGFormContext } from "../../reactContexts/ngFormContext";
import { OrgMobx, OrgModel } from "../../mst/kinds/org";

type RouteParams = "gvc";
const GVCDetailRouteRaw: React.FC = () => {
  const formDataRef = React.useRef(new NGFormData());

  const { gvc: gvcName } = useParams<RouteParams>();
  const [gvc, setGVC] = React.useState<GVCMobx>(undefined as any);
  const [org, setOrg] = React.useState<OrgMobx>(undefined as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
    fetchOrg();
  }, [gvcName]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("gvc", gvcName) });
      setGVC(GVCModel.create(data));
      setIsLoading(false);
    } catch (e) {
      if (ConsoleContext.hasGVC && ConsoleContext.gvc === gvcName) {
        ConsoleContext.clearGVC();
      }
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  async function fetchOrg() {
    try {
      const { data } = await request({ url: resourceLink("org", ConsoleContext.org!) });
      setOrg(OrgModel.create(data));
    } catch (e) {}
  }

  if (isLoading) {
    return <Loader fullScreen reason={"Fetching gvc for detail page"} />;
  }

  if (isIncorrect) {
    return <Navigate to={"/console"} />;
  }

  return (
    <NGFormContext.Provider value={formDataRef.current}>
      <DetailContext.Provider value={{ item: gvc, fetchItem }}>
        <GvcDetail org={org} gvc={gvc} />
      </DetailContext.Provider>
    </NGFormContext.Provider>
  );
};

export const GVCDetailRoute = observer(GVCDetailRouteRaw);
