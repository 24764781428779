import { types, Instance, getParent } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { defaultValues } from "../base";
import { Mk8sDraftMobx } from "./mk8s.draft";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";

export const Mk8sDraftAutoscalerModel = types
  .model({
    _unneededTime: types.optional(types.string, defaultValues.mk8s.provider.common.autoscaler.unneededTime),
    _unreadyTime: types.optional(types.string, defaultValues.mk8s.provider.common.autoscaler.unreadyTime),
    _utilizationThreshold: types.optional(
      types.number,
      defaultValues.mk8s.provider.common.autoscaler.utilizationThreshold
    ),

    expanders: types.optional(ListOfItemsModel, () =>
      ListOfItemsModel.create({
        _items: defaultValues.mk8s.provider.common.autoscaler.expander.map((i) => ({ firstValue: i })),
      })
    ),
    unneededTime: types.optional(StringModel, () =>
      StringModel.create({
        label: "Unneeded Time",
      })
    ),
    unreadyTime: types.optional(StringModel, () =>
      StringModel.create({
        label: "Unready Time",
      })
    ),
    utilizationThreshold: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Utilization Threshold",
        allowDot: true,
        min: 0.1,
        max: 1,
      })
    ),
  })
  .actions((self) => ({
    reset() {
      self.expanders.reset();
      self.unneededTime.setInitialValue(self._unneededTime);
      self.unreadyTime.setInitialValue(self._unreadyTime);
      self.utilizationThreshold.setInitialValue(String(self._utilizationThreshold));
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self.expanders.confirm();
      self._unneededTime = self.unneededTime.value;
      self._unreadyTime = self.unreadyTime.value;
      self._utilizationThreshold = Number(self.utilizationThreshold.value);
      self.reset();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.expanders.isDirty) res = true;
      if (self.unneededTime.isDirty) res = true;
      if (self.unreadyTime.isDirty) res = true;
      if (self.utilizationThreshold.isDirty) res = true;

      return res;
    },
    get dirtyReason() {
      let reason = "";

      if (self.expanders.isDirty) reason = "expander dirty";
      if (self.unneededTime.isDirty) reason = self.unneededTime.isDirtyReason;
      if (self.unreadyTime.isDirty) reason = self.unreadyTime.isDirtyReason;
      if (self.utilizationThreshold.isDirty) reason = self.utilizationThreshold.isDirtyReason;

      return reason;
    },
    get isValid() {
      let res = true;
      if (self.expanders.items.length > 3) res = false;
      if (!self.unneededTime.isValid) res = false;
      if (!self.unreadyTime.isValid) res = false;
      if (!self.utilizationThreshold.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.expanders.items.length > 3) reason = "expander length is above 3";
      if (!self.unneededTime.isValid) reason = self.unneededTime.invalidReason;
      if (!self.unreadyTime.isValid) reason = self.unreadyTime.invalidReason;
      if (!self.utilizationThreshold.isValid) reason = self.utilizationThreshold.invalidReason;
      return reason;
    },
    get asObject() {
      let obj: any = {};

      const rootParent = getParent(self, 2) as Mk8sDraftMobx;
      if (self.expanders.items.length > 0) {
        obj.expander = self.expanders.items.map((i) => i.firstValue);
      } else {
        obj.expander = defaultValues.mk8s.provider.common.autoscaler.expander;
      }

      if (!rootParent._new && self.unneededTime.value.length < 1 && self.unneededTime.isDirty) {
        obj.unneededTime = null;
      } else {
        obj.unneededTime = self.unneededTime.value || defaultValues.mk8s.provider.common.autoscaler.unneededTime;
      }

      if (!rootParent._new && self.unreadyTime.value.length < 1 && self.unreadyTime.isDirty) {
        obj.unreadyTime = null;
      } else {
        obj.unreadyTime = self.unreadyTime.value || defaultValues.mk8s.provider.common.autoscaler.unreadyTime;
      }

      if (!rootParent._new && self.utilizationThreshold.value.length < 1 && self.utilizationThreshold.isDirty) {
        obj.utilizationThreshold = null;
      } else {
        obj.utilizationThreshold =
          self.utilizationThreshold.value.length > 0
            ? Number(self.utilizationThreshold.value)
            : defaultValues.mk8s.provider.common.autoscaler.utilizationThreshold;
      }

      return obj;
    },
  }));
export interface Mk8sDraftAutoscalerMobx extends Instance<typeof Mk8sDraftAutoscalerModel> {}
