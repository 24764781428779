import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { DomainDraftPort, DomainDraftPortModel } from "./domain.draft.port";
import { DomainMobx } from "../kinds/domain";
import { isApex } from "../../services/utils";
import { TagNewModel, TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { defaultValues } from "../base";
import { convertCiphers, defaultCiphers } from "../kinds/domain/ciphers";
import { DomainPortRoute } from "./domain.draft.port.route";
import { ngParseLink } from "../../utils/linkParser/linkParser";

export const DomainDraftModel = types
  .model({
    _name: types.optional(types.string, ""),
    _description: types.optional(types.string, ""),
    _dnsMode: types.optional(types.enumeration(["cname", "ns"]), "cname"),
    _routingMode: types.optional(types.enumeration(["none", "subdomainBased", "pathBased"]), "none"),
    _gvcName: types.optional(types.string, ""),
    _acceptAllHosts: types.optional(types.boolean, false),
    _ports: types.optional(types.array(types.frozen()), [
      {
        number: 443,
        protocol: "http2",
        tls: {
          cipherSuites: defaultCiphers,
          minProtocolVersion: "TLSV1_2",
        },
        routes: [],
      },
    ]),
    name: types.optional(StringModel, () =>
      StringModel.create({
        label: "Fully Qualified Domain Name",
        isRequired: true,
        examples: ["bar.com", "foo.bar.com"],
        validationKey: "domain",
        transformKey: "lowerCase",
      })
    ),
    description: types.optional(StringModel, () =>
      StringModel.create({
        label: "Description",
      })
    ),
    dnsModeInput: types.optional(types.enumeration(["ns", "cname"]), "ns"),
    routingMode: types.optional(types.enumeration(["none", "subdomainBased", "pathBased"]), "none"),
    gvcName: types.optional(types.string, ""),
    acceptAllHosts: types.optional(types.boolean, false),
    ports: types.array(DomainDraftPortModel),
    _tags: types.array(TagNewModel),
    tags: types.optional(TagsNewModel, () => TagsNewModel.create()),
    // for path based
    routeGvcName: types.optional(types.string, ""),
    routeGvcHasDedicatedLoadBalancerRequestId: types.optional(types.string, ""),
    routeGvcHasDedicatedLoadBalancer: types.optional(types.boolean, true),
    subdomainGvcHasDedicatedLoadBalancerRequestId: types.optional(types.string, ""),
    subdomainGvcHasDedicatedLoadBalancer: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    setGvcName(value: string) {
      self.gvcName = value;
    },
    setRouteGvcName(value: string) {
      self.routeGvcName = value;
    },
  }))
  .views((self) => ({
    get gvcLink() {
      const { absolute } = ngParseLink(self.gvcName, { kind: "gvc" });
      return absolute;
    },
    get routeGvcLink() {
      const { absolute } = ngParseLink(self.routeGvcName, { kind: "gvc" });
      return absolute;
    },
  }))
  .actions((self) => ({
    setAcceptAllHosts(value: boolean) {
      self.acceptAllHosts = value;
    },
    setRouteGVCHasDedicatedLoadBalancerRequestId(id: string) {
      self.routeGvcHasDedicatedLoadBalancerRequestId = id;
    },
    setRouteGVCHasDedicatedLoadBalancer(value: boolean) {
      self.routeGvcHasDedicatedLoadBalancer = value;
    },
    setSubdomainGVCHasDedicatedLoadBalancerRequestId(id: string) {
      self.subdomainGvcHasDedicatedLoadBalancerRequestId = id;
    },
    setSubdomainGVCHasDedicatedLoadBalancer(value: boolean) {
      self.subdomainGvcHasDedicatedLoadBalancer = value;
    },
  }))
  .views((self) => ({
    get isApexDomain() {
      return isApex(self.name.value);
    },
    get canAddPort() {
      return self.ports.length < defaultValues.domain.port.number.options.length;
    },
  }))
  .views((self) => ({
    get dnsMode() {
      return self.isApexDomain ? "cname" : self.dnsModeInput;
    },
  }))
  .actions((self) => ({
    generalReset() {
      self.name.setInitialValue(self._name);
      self.description.setInitialValue(self._description);
    },
    specReset() {
      self.dnsModeInput = self._dnsMode;
      self.gvcName = self._gvcName;
      self.routingMode = self._routingMode;
      self.acceptAllHosts = self._acceptAllHosts;
      const tabs = self.ports.map((p) => {
        if (self._routingMode !== "pathBased" && p._tab === "routes") {
          return "info";
        }
        return p._tab;
      });
      self.ports.clear();
      for (let portIndex in self._ports as DomainDraftPort[]) {
        const port = self._ports[portIndex];
        let _tls: any = undefined;
        if (port.tls) {
          _tls = JSON.parse(JSON.stringify(port.tls));
        }
        if (_tls && _tls.cipherSuites && Array.isArray(_tls.cipherSuites) && _tls.cipherSuites.length > 0) {
          _tls.cipherSuites = convertCiphers(_tls.cipherSuites);
        }
        const portInstance = DomainDraftPortModel.create({
          _tab: tabs[portIndex],
          _number: port.number,
          _protocol: port.protocol,
          _routes: port.routes || [],
          _cors: port.cors,
          _tls,
        });
        self.ports.push(portInstance);
      }

      if (self.ports.length > 0) {
        if (self.ports[0]._routes.length > 0) {
          const firstRoute = self.ports[0]._routes[0] as DomainPortRoute;
          self.routeGvcName = firstRoute.workloadLink.split("/")[4];
        } else {
          self.routeGvcName = "";
        }
      }
    },
  }))
  .actions((self) => ({
    reset() {
      self.generalReset();
      self.specReset();
      self.tags.reset();
      // @ts-ignore
      self.limitPortNumbers();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._name = self.name.value;
      self.name.confirm();
      self._description = self.description.value;
      self.description.confirm();
      self.dnsModeInput = self.dnsMode;
      self._dnsMode = self.dnsModeInput;
      self._gvcName = self.gvcName;
      self._acceptAllHosts = self.acceptAllHosts;
      self._ports.clear();
      for (let newPort of self.ports.map((p) => p.asObject)) {
        self._ports.push(newPort);
      }
      for (let port of self.ports) {
        port.confirm();
      }
      self._routingMode = self.routingMode;
      self.tags.confirm();
    },
  }))
  .views((self) => ({
    get isPortsDirty() {
      let res = false;
      if (self._ports.length !== self.ports.length) {
        res = true;
      }
      for (let port of self.ports) {
        if (port.isDirty) {
          res = true;
        }
      }
      return res;
    },
  }))
  .views((self) => ({
    get isGeneralDirty() {
      let res = false;
      if (self.name.isDirty) res = true;
      if (self.description.isDirty) res = true;
      return res;
    },
    get isGeneralValid() {
      let res = true;
      if (!self.name.isValid) res = false;
      return res;
    },
    get generalInvalidReason(): InvalidReason {
      if (!self.name.value) return { code: 1, type: "error", message: "Domain value is required" };
      if (!self.name.isValid) return { code: 1, type: "error", message: "Domain is invalid" };
      return { code: 0, type: "info", message: "" };
    },
    get isSpecDirty() {
      let res = false;
      if (self._dnsMode !== self.dnsMode) {
        res = true;
      }
      if (self._acceptAllHosts !== self.acceptAllHosts) {
        res = true;
      }
      if (self._routingMode !== self.routingMode) {
        res = true;
      }
      if (self.routingMode === "subdomainBased") {
        if (self._gvcName !== self.gvcName) {
          res = true;
        }
      }
      if (self.isPortsDirty) {
        res = true;
      }
      return res;
    },
    get specDirtyReason() {
      let res = "";
      if (self._dnsMode !== self.dnsMode) {
        return "dnsMode";
      }
      if (self._acceptAllHosts !== self.acceptAllHosts) {
        return "accept all hosts";
      }
      if (self._routingMode !== self.routingMode) {
        return "routing mode";
      }
      if (self.routingMode === "subdomainBased") {
        if (self._gvcName !== self.gvcName) {
          return "subdomain gvclink";
        }
      }
      if (self._ports.length !== self.ports.length) {
        return `port count - was: ${self._ports.length} | is: ${self.ports.length}`;
      }
      for (let port of self.ports) {
        if (port.isDirty) {
          return "port - " + port.dirtyReason;
        }
      }
      return res;
    },
    get isSpecValid() {
      let res = true;

      if (self.dnsMode === "cname") {
        // cname requires each port to set up a serverCertificate, only if routingMode is not none
        if (self.routingMode === "subdomainBased") {
          if (self.ports.length < 1) {
            res = false;
          }
          for (let port of self.ports) {
            if (!port.tls?.serverCertificateSecretName) {
              res = false;
            }
          }
        }
      }

      for (let port of self.ports) {
        if (!port.isValid) {
          res = false;
        }
      }

      // routing mode subdomain based requires a gvclink to be set
      if (self.routingMode === "subdomainBased" && !self.gvcName) {
        res = false;
      }

      return res;
    },
    get specInvalidReason(): InvalidReason {
      if (self.dnsMode === "cname") {
        // cname requires each port to set up a serverCertificate, only if routingMode is subdomain
        if (self.routingMode === "subdomainBased") {
          if (self.ports.length < 1) {
            return {
              code: 2,
              type: "error",
              message: "CNAME and Subdomain Based Routing option requires at least 1 port.",
            };
          }
          for (let port of self.ports) {
            if (!port.tls?.serverCertificateSecretName) {
              return {
                code: 3,
                type: "error",
                message: "CNAME and Subdomain Based Routing option requires ports to set a Server Certificate Secret.",
              };
            }
          }
        }
      }

      for (let port of self.ports) {
        if (!port.isValid) {
          return port.invalidReason;
        }
      }

      // routing mode subdomain based requires a gvclink to be set
      if (self.routingMode === "subdomainBased" && !self.gvcName) {
        return { code: 1, type: "error", message: "Subdomain Based Routing requires a GVC link" };
      }

      return { code: 0, type: "info", message: "" };
    },
  }))
  .actions((self) => ({
    addPort() {
      let port = defaultValues.domain.port.number.options[0];
      if (self.ports.length > 0) {
        const currentPorts = self.ports.map((p) => p.number.value);
        for (const availablePort of defaultValues.domain.port.number.options) {
          if (!currentPorts.includes(String(availablePort))) {
            port = Number(availablePort);
          }
        }
      }
      const portItem = DomainDraftPortModel.create({ _number: port, _tls: defaultValues.domain.tls });
      self.ports.push(portItem);
      // @ts-ignore
      self.limitPortNumbers();
      return portItem.id;
    },
    removePort(id: string) {
      const portItem = self.ports.find((p) => p.id === id);
      if (portItem) {
        self.ports.remove(portItem);
      }
      // @ts-ignore
      self.limitPortNumbers();
    },
  }))
  .actions((self) => ({
    // add-remove port, change port number
    limitPortNumbers() {
      // limit port number options
      for (const port of self.ports) {
        const otherPorts = self.ports.filter((p) => p.id !== port.id).map((p) => Number(p.number.value));
        const portOptions: number[] = [];
        for (const portOption of defaultValues.domain.port.number.options) {
          if (otherPorts.includes(portOption)) {
            continue;
          }
          if (portOptions.includes(portOption)) {
            continue;
          }
          portOptions.push(portOption);
        }

        port.number.setOptions(portOptions.map((p) => ({ label: String(p), value: String(p) })));
      }
    },
  }))
  .actions((self) => ({
    setPortNumber(portId: string, value: string) {
      const port = self.ports.find((p) => p.id === portId);
      if (!port) {
        return;
      }
      port.number.setValue(value);
      self.limitPortNumbers();
    },
    setDNSMode(value: "ns" | "cname") {
      self.dnsModeInput = value;
    },
    setRoutingMode(value: "none" | "subdomainBased" | "pathBased") {
      self.routingMode = value;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.isGeneralDirty) {
        res = true;
      }
      if (self.isSpecDirty) {
        res = true;
      }
      if (self.tags.isDirty) {
        res = true;
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.isGeneralValid) {
        res = false;
      }
      if (!self.isSpecValid) {
        res = false;
      }
      if (!self.tags.isValid) {
        res = false;
      }
      return res;
    },
    get invalidReason(): InvalidReason {
      // TODO update
      if (self.generalInvalidReason.code !== 0) {
        return self.generalInvalidReason;
      }
      if (self.specInvalidReason.code !== 0) {
        return self.specInvalidReason;
      }
      if (self.tags.invalidReason.code !== 0) {
        return self.tags.invalidReason;
      }
      return { code: 0, type: "info", message: "" };
    },
    get asObject() {
      const body: any = {
        name: self.name.value,
        description: self.description.value || self.name.value,
        tags: self.tags.asObject,
      };
      body.spec = {
        dnsMode: self.dnsMode,
        acceptAllHosts: self.acceptAllHosts,
      };
      if (self.routingMode === "subdomainBased") {
        body.spec.gvcLink = self.gvcLink;
      }
      if (self.ports.length > 0) {
        body.spec.ports = self.ports.map((p) => p.asObject);
        if (self.routingMode !== "pathBased") {
          for (let port of body.spec.ports) {
            delete port.routes;
          }
        }
      }
      return body;
    },
  }))
  .views((self) => ({
    get asObjectForPatch() {
      const body = self.asObject;
      body["$replace/spec"] = body.spec;
      delete body.spec;
      return body;
    },
  }));

export interface DomainDraftMobx extends Instance<typeof DomainDraftModel> {}

export const DomainDraftStoreModel = types
  .model({
    edit: types.maybe(DomainDraftModel),
  })
  .actions((self) => ({
    new() {
      self.edit = DomainDraftModel.create();
    },
    start(domain: DomainMobx) {
      let initialRoutingMode: "none" | "subdomainBased" | "pathBased" = "none";
      if (domain.spec?.gvcLink) {
        initialRoutingMode = "subdomainBased";
      } else if (domain.spec?.ports && domain.spec?.ports.length > 0) {
        if (domain.spec?.ports.some((p) => p.routes && p.routes.length > 0)) {
          initialRoutingMode = "pathBased";
        }
      }
      const { name: gvcName } = ngParseLink(domain.spec?.gvcLink || "", { kind: "gvc", useInputCtx: true });
      self.edit = DomainDraftModel.create({
        _name: domain.name,
        _description: domain.description,
        _dnsMode: domain.spec?.dnsMode as any,
        _gvcName: gvcName,
        _ports: domain.spec?.ports ? JSON.parse(JSON.stringify(domain.spec.ports)) : [],
        _routingMode: initialRoutingMode,
        _acceptAllHosts: domain.spec?.acceptAllHosts,
      });
    },
    reset() {
      self.edit?.reset();
    },
  }));
export interface DomainDraftStoreMobx extends Instance<typeof DomainDraftStoreModel> {}

/**
 *
 * spec" failed custom validation because When dnsMode is set to cname the domain must not route to a GVC or any Workloads or
 * must have a tls.serverCertificate must be configured for all ports.
 */

export interface InvalidReason {
  code: number;
  type: "info" | "warning" | "error";
  message: string;
}
