import stringify from "json-stable-stringify";
import randomBytes from "randombytes";
import base32Encode from "base32-encode";
import { WorkloadMobx, WorkloadModel } from "../mst/kinds/workload";
import { UserModel } from "../mst/kinds/user";
import { ServiceaccountModel } from "../mst/kinds/serviceaccount";
import { SecretModel } from "../mst/kinds/secret";
import { QuotaModel } from "../mst/kinds/quota";
import { PolicyModel } from "../mst/kinds/policy";
import { OrgModel } from "../mst/kinds/org";
import { LocationModel } from "../mst/kinds/location";
import { IdentityModel } from "../mst/kinds/identity";
import { GVCModel } from "../mst/kinds/gvc";
import { GroupModel } from "../mst/kinds/group";
import { DomainModel, DomainStatus } from "../mst/kinds/domain";
import { CloudaccountModel } from "../mst/kinds/cloudaccount";
import { AgentModel } from "../mst/kinds/agent";
import { DEPLOYMENT_ENV, HUBSPOT_SERVICE_URL, IS_HUBSPOT_INTEGRATION_ENABLED } from "../envVariables";
import { request, resourceLink } from "./cpln";
import { AuditContextModel } from "../mst/kinds/auditContext";
import { parse_host as parseDomain } from "tld-extract";
import { User } from "../mobxStores/user/user";
import { ConsoleContext } from "../mobxStores/consoleContext/consoleContext";
import { VolumeSetModel } from "../mst/kinds/volumeset";
import { LogEntry } from "../pages/logs/types";
import { Audit } from "../pages/auditTrail/types";
import { DetailContextItem } from "../components/detail/detailContext";
import { ContainerStatus } from "../schema/types/containerstatus";

export function objectToArray(obj: any): string[][] {
  if (!obj) return [];
  const entries = Object.entries(obj);
  for (let entry of entries) {
    if (entry[1] === null || entry[1] === undefined) {
      entry[1] = "";
    } else {
      entry[1] = (entry[1] as any).toString();
    }
  }
  return entries as string[][];
}

interface TagEntry {
  value: string[];
  width: number;
}

export function itemTagsForTable(tagsArray: string[][], columnWidth: number) {
  let tagEntries: TagEntry[] = tagsArray.map((value) => ({
    value,
    width: Math.min(40 + (value[0].length + value[1].length) * 7, 170),
  }));

  let tagEntriesToShow: TagEntry[] = [];

  let defaultWidth = 0;
  function getTagsWidth() {
    let w = 0;
    for (let entry of tagEntriesToShow) {
      w += entry.width;
    }
    return w + defaultWidth;
  }

  let tagIndex = 0;
  while (tagEntriesToShow.length < tagsArray.length && getTagsWidth() < columnWidth) {
    const tagEntry = tagEntries[tagIndex];
    tagEntriesToShow.push(tagEntry);
    tagIndex++;
  }

  if (tagEntriesToShow.length < tagsArray.length) {
    defaultWidth = 25;
  }

  while (getTagsWidth() >= columnWidth - 50) {
    tagEntriesToShow.pop();
  }

  return tagEntriesToShow.length;
}

export function randomName() {
  let length = 8;
  const first = "xbgjkmqacpez";
  let prefix = first[Math.floor(Math.random() * Math.floor(first.length))];
  length -= 1;
  const buf = randomBytes(length);
  return prefix + base32Encode(buf, "Crockford").toLowerCase().substring(0, length);
}

export function getMobxModel(kind: string) {
  switch (kind) {
    case "agent":
      return AgentModel;
    case "auditctx":
      return AuditContextModel;
    case "cloudaccount":
      return CloudaccountModel;
    case "domain":
      return DomainModel;
    case "group":
      return GroupModel;
    case "gvc":
      return GVCModel;
    case "identity":
      return IdentityModel;
    case "location":
      return LocationModel;
    case "org":
      return OrgModel;
    case "policy":
      return PolicyModel;
    case "quota":
      return QuotaModel;
    case "secret":
      return SecretModel;
    case "serviceaccount":
      return ServiceaccountModel;
    case "user":
      return UserModel;
    case "volumeset":
      return VolumeSetModel;
    case "workload":
    default:
      return WorkloadModel;
  }
}

export function getMetricsUrl(
  _type: "sider" | "org" | "workload" | "gvc" | "mk8s",
  metadata: {
    workloadName?: string;
    clusterName?: string;
    clusterAlias?: string;
  } = {},
) {
  const { gvc } = ConsoleContext;
  const { workloadName } = metadata;

  let queryParams = "";
  switch (_type) {
    case "gvc":
      queryParams = encodeURI(`var-gvc=${gvc}`);
      return `/d/cpln-metrics-overview/metrics-overview?${queryParams}`;
    case "workload":
      queryParams = encodeURI(`var-gvc=${gvc}&var-workload=${workloadName}`);
      return `/d/cpln-metrics-overview/metrics-overview?${queryParams}`;
    case "mk8s":
      return `/explore?cplnDatasource=${encodeURIComponent(
        `mk8s: ${metadata.clusterName} (${metadata.clusterAlias})`,
      )}`;
    case "sider":
    case "org":
    default:
      return "/d/cpln-metrics-overview/metrics-overview-cloud";
  }
}

export function getTracesUrl(
  _type: "org" | "gvc" | "workload",
  metadata: { workloadName?: string; gvcName?: string } = {},
) {
  const left: any = {
    datasource: "placeholder",
    queries: [
      {
        refId: "A",
        datasource: {
          type: "tempo",
          uid: "placeholder",
        },
        queryType: "nativeSearch",
        limit: 20,
        minDuration: "10ms",
      },
    ],
    range: { from: "now-1h", to: "now" },
  };

  if (["gvc", "workload"].includes(_type)) {
    left.queries[0].query = `{.gvc="${metadata.gvcName}"}`;
    left.queries[0].search = `gvc="${metadata.gvcName}"`;
  }
  if (_type === "workload") {
    left.queries[0].serviceName = metadata.workloadName;
  }

  const leftStr = JSON.stringify(left);

  switch (_type) {
    case "org":
    default:
      return `/explore?cplnDatasource=tracing&left=${leftStr}`;
  }
}

export interface HistoryState {
  from: string;
}

/* istanbul ignore next */
export function execOnEnterKey(e: React.KeyboardEvent) {
  if (e.keyCode !== 13) return () => {};
  e.stopPropagation();
  return function (fn: any) {
    fn(e);
  };
}

/* istanbul ignore next */
export function execOnEnterSpaceKeys(e: React.KeyboardEvent) {
  // enter / space key
  if (e.keyCode !== 13 && e.keyCode !== 32) return () => {};
  e.stopPropagation();
  return function (fn: any) {
    fn(e);
  };
}

export function deploymentContainerSort(workload: WorkloadMobx) {
  return (a: ContainerStatus, b: ContainerStatus) => {
    const specContainers = workload.spec.containers.map((c) => c.name);
    const first = a.name!;
    const second = b.name!;
    if (!specContainers.includes(first) && !specContainers.includes(second)) {
      return first > second ? 1 : -1;
    }
    if (!specContainers.includes(first)) {
      return 1;
    }
    if (!specContainers.includes(second)) {
      return -1;
    }

    const firstIndex = specContainers.indexOf(first);
    const secondIndex = specContainers.indexOf(second);
    return firstIndex - secondIndex;
  };
}

export function k8sKeySort(a: string, b: string): number {
  const firstKeys = [
    //
    "kind",
    "itemKind",
    "name",
    "description",
    "tags",
    "origin",
    "type",
    "provider",
    "data",
  ];

  const lastKeys = [
    //
    "version",
    "id",
    "spec",
    "status",
    "created",
    "lastModified",
    "manifest",
    "links",
  ];

  if (a == b) {
    return 0;
  }

  const fa = firstKeys.indexOf(a);
  const fb = firstKeys.indexOf(b);

  // 2: both at the top
  if (fa >= 0 && fb >= 0) {
    return fa - fb;
  }

  const la = lastKeys.indexOf(a);
  const lb = lastKeys.indexOf(b);

  // 1: both at the bottom
  if (la >= 0 && lb >= 0) {
    return la - lb;
  }

  // 4: none is first
  if (fa < 0 && fb < 0 && la < 0 && lb < 0) {
    // natural compare in the middle
    return a < b ? -1 : 1;
  }

  // 6: one shoud go to top
  if (fb >= 0) {
    // b is first
    return 1;
  }
  if (fa >= 0) {
    // b is first
    return -1;
  }

  // 5: at the end
  if (lb >= 0) {
    return -1;
  }
  if (la >= 0) {
    return 1;
  }

  return 0;
}

export function toSortedJSONString(obj: any): string {
  return stringify(obj, {
    cmp: (a: any, b: any) => k8sKeySort(a.key, b.key),
  });
}

export function toSortedJSON(obj: any): any {
  return JSON.parse(toSortedJSONString(obj));
}

export async function updateLastDeploymentTimeOnHubspot() {
  if (!IS_HUBSPOT_INTEGRATION_ENABLED) {
    return;
  }
  if (!User.isLoggedIn || User.email.includes("@controlplane.com")) {
    return;
  }
  try {
    const { org } = ConsoleContext;
    const time = new Date().toISOString().slice(0, 10);
    await request({
      service: "self",
      method: "post",
      url: `${HUBSPOT_SERVICE_URL}/lastdeployment`,
      body: { environment: DEPLOYMENT_ENV, time, org },
    });
  } catch (e) {
    console.error("Failed to update last deployment date");
  }
}

export function isApex(domain: string) {
  if (!domain) {
    return false;
  }
  try {
    const res = parseDomain(domain);
    if (res.sub === "") {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

export function parsedDomain(domain: string): { tld: string; domain: string; sub: string } {
  const defaultObj = { tld: "", domain: "", sub: "" };
  if (!domain) {
    return defaultObj;
  }
  try {
    const res = parseDomain(domain);
    return res;
  } catch (e) {
    return defaultObj;
  }
}

interface cplnlog {
  id: number;
  message?: string;
  messages?: string[];
}
interface cplnlogs {
  items: cplnlog[];
}

const cpln_logs: cplnlogs = {
  items: [],
};
// @ts-ignore
window.cpln_logs = cpln_logs;

export function cplnLog(msg: string | string[]) {
  const item: cplnlog = { id: cpln_logs.items.length };
  if (Array.isArray(msg)) {
    item.messages = msg;
  } else {
    item.message = msg;
  }
  cpln_logs.items.push(item);
  if (cpln_logs.items.length > 200) {
    cpln_logs.items = cpln_logs.items.slice(1);
  }
}

export function clearItem(item: any) {
  delete item.alias;
  delete item.version;
  delete item.id;
  delete item.links;
  delete item.created;
  delete item.lastModified;
  delete item.status;
  delete item.origin;

  if (item.tags) {
    delete item.tags["cpln/deployTimestamp"];
  }
}

// Copied from nodelibs/schema/src/workload.ts
export function convertCPUStringToNumber(cpuString: string | undefined): number {
  let cpu = 0;

  if (cpuString) {
    let m = cpuString.split("m");
    if (m.length == 2) {
      cpu = cpu + parseInt(m[0]);
    }
    if (m.length == 1) {
      cpu = cpu + parseFloat(m[0]) * 1000;
    }
  }

  return cpu;
}

// Copied from nodelibs/schema/src/workload.ts
export function convertMemoryStringToNumber(memory: string | undefined): number {
  // returns values in Mib
  let mem = 0;
  if (!memory) {
    return mem;
  }

  let memArray = memory.split(/(?=[GMKgmk])/);
  if (memArray.length == 2) {
    switch (memArray[1].toLowerCase()) {
      case "ki":
        mem = mem + Math.round(parseInt(memArray[0]) / 1024);
        break;
      case "k":
        mem = mem + Math.round(parseInt(memArray[0]) / 1048.576);
        break;
      case "mi":
        mem = mem + parseInt(memArray[0]);
        break;
      case "m":
        mem = mem + Math.round(parseInt(memArray[0]) / 1.048576);
        break;
      case "gi":
        mem = mem + parseFloat(memArray[0]) * 1024.0;
        break;
      case "g":
        mem = mem + Math.round(parseFloat(memArray[0]) * 954);
        break;
    }
  } else {
    mem = Math.round(parseInt(memArray[0]) / 1048576);
  }
  return mem;
}

export function nameOfDomainStatus(status: DomainStatus) {
  const s = status?.status;
  if (!s) {
    return "Not Available";
  }
  switch (s) {
    case "initializing":
      return "Initializing";
    case "ready":
      return "Ready";
    case "pendingDnsConfig":
      return "Pending DNS Config";
    case "pendingCertificate":
      return "Pending Certificate";
    case "usedByGvc":
      return "Used By GVC";
    case "warning":
      return "Warning";
    default:
      return s;
  }
}

export function getErrorMessage(e: any): string {
  let errorMessage = e?.response?.data?.message;
  if (!errorMessage) errorMessage = e?.message || "Error";
  return errorMessage;
}

export function getBillingErrorMessage(e: any): string {
  let errorMessage = getErrorMessage(e);
  const previousErrorMessage = errorMessage;
  if (errorMessage) {
    try {
      const asObject = JSON.parse(errorMessage);
      if (asObject.message) {
        errorMessage = asObject.message;
      }
    } catch (e) {
      errorMessage = previousErrorMessage;
    }
  }
  return errorMessage;
}

export function getReferralCookies(): { pId: string; pVisitorId: string; visitorId: string } {
  return {
    pId: getReferralCookie("pId"),
    pVisitorId: getReferralCookie("pVisitorId"),
    visitorId: getReferralCookie("visitorId"),
  };
}

export function getReferralCookie(cookieName: string) {
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) == " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) == 0) {
      return decodeURIComponent(cookie.slice(cookieName.length + 1));
    }
  }
  return "";
}

export const queryProperties = {
  identity: ["name", "id", "version", "description", "created", "lastModified"],
  volumeset: ["name", "id", "version", "description", "created", "lastModified"],
  workload: ["name", "id", "version", "description", "created", "lastModified"],
  agent: ["name", "id", "version", "description", "created", "lastModified"],
  cloudaccount: ["provider", "name", "id", "version", "description", "created", "lastModified"],
  domain: ["name", "id", "version", "description", "created", "lastModified"],
  image: ["name", "id", "digest", "repository", "tag"],
  location: ["origin", "provider", "region", "name", "id", "version", "description", "created", "lastModified"],
  secret: ["type", "name", "id", "version", "description", "created", "lastModified"],
  auditctx: ["name", "id", "version", "description", "created", "lastModified"],
  group: ["origin", "name", "id", "version", "description", "created", "lastModified"],
  policy: ["name", "id", "version", "description", "origin", "created", "lastModified"],
  serviceaccount: ["name", "id", "version", "description", "created", "lastModified"],
  user: ["idp", "email", "name", "id", "version", "created", "lastModified"],
};

export function getUniqueLabels(logEntry: LogEntry, commonLabels: string[]) {
  return logEntry.labels
    .filter((l) => {
      const ll = `${l[0]}=${l[1]}`;
      if (commonLabels.includes(ll)) {
        return false;
      }
      return true;
    })
    .sort((a, b) => {
      if (a[0] > b[0]) {
        return 1;
      }
      if (a[0] < b[0]) {
        return -1;
      }
      return 0;
    });
}

export function getLabelsWidth(logEntries: LogEntry[]) {
  let labelsWidth = 0;

  const LOG_CHARACTER_WIDTH = 8;

  for (const log of logEntries) {
    let logLabelsWidth = 0;

    for (const label of log.labels) {
      let labelWidth = `${label[0]}=${label[1]}`.length * LOG_CHARACTER_WIDTH;
      logLabelsWidth += labelWidth;
    }

    let COLUMN_WIDTH_LABELS_MAX = 400;

    logLabelsWidth = Math.min(logLabelsWidth, COLUMN_WIDTH_LABELS_MAX);

    if (logLabelsWidth > labelsWidth) {
      labelsWidth = logLabelsWidth;
    }
  }

  return labelsWidth;
}

export function getLetters() {
  return [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "y",
    "z",
  ];
}

export function getNumbers() {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
}

export async function checkCanCreateTrialAccount(
  email: string,
): Promise<{ isWorkEmail: boolean; canCreateAccount: boolean }> {
  const defaultReturn = { isWorkEmail: false, canCreateAccount: false };
  try {
    // TODO test new billing-ng
    const { data } = await request({
      service: "billing-ng",
      url: "/trial_accounts/validate",
      body: { email },
      method: "post",
    });
    return data;
  } catch (e) {
    console.error("checkIsUserEligibleForTrialAccount", e.message);
    return defaultReturn;
  }
}

export interface DryRunSchemaError {
  id: string;
  message: string;
  status: number;
  details: {
    context?: { key?: string; label?: string; valids?: string[]; value?: string };
    message?: string;
    path?: string[];
    type?: string;
  }[];
}

export interface DryRunResponse {
  code: string;
  id: string;
  message: string;
  status: number;
  details: {
    issues: DryRunIssue[];
  };
}

export interface DryRunIssue {
  message: string;
  recommendation?: string | string[];
  path: string;
  severity: string;
}

export function stringPairsToObject(arr: string[], separator = "=") {
  let obj: { [_: string]: string } = {};
  for (const item of arr) {
    const key = item.split(separator)[0];
    const value = item.split(separator)[1];
    obj[key] = value;
  }
  return obj;
}

export const OPERATORS_REQUIRING_TWO_VALUES = ["=", "<", ">", "<=", ">=", "!=", "~"];

export function isAuditDiffable(audit: Audit) {
  const actionTypesToPreventDiff = ["create", "edit"]; // shell exec reveal
  return audit.action?.type && actionTypesToPreventDiff.includes(audit.action.type);
}

export const tagLinkUrlPrefixes = ["https://", "http://", "ws://", "wss://"];

export function parseEnvFile(src: string) {
  const LINE = /(?:^|^)\s*(?:export\s+)?([\w.-]+)(?:\s*=\s*?|:\s+?)(\s*'(?:\\'|[^'])*'|\s*"(?:\\"|[^"])*"|\s*`(?:\\`|[^`])*`|[^#\r\n]+)?\s*(?:#.*)?(?:$|$)/gm;
  const obj: { [_: string]: any } = {};

  // Convert buffer to string
  let lines = src.toString();

  // Convert line breaks to same format
  lines = lines.replace(/\r\n?/gm, "\n");

  let match;
  while ((match = LINE.exec(lines)) != null) {
    const key = match[1];

    // Default undefined or null to empty string
    let value = match[2] || "";

    // Remove whitespace
    value = value.trim();

    // Check if double quoted
    const maybeQuote = value[0];

    // Remove surrounding quotes
    value = value.replace(/^(['"`])([\s\S]*)\1$/gm, "$2");

    // Expand newlines if double quoted
    if (maybeQuote === '"') {
      value = value.replace(/\\n/g, "\n");
      value = value.replace(/\\r/g, "\r");
    }

    // Add to object
    obj[key] = value;
  }

  return obj;
}

// TODO make a utils folder and separate functions/types
export interface CronSchedule {
  minuteSegments: CronSegment[];
  hourSegments: CronSegment[];
  dayOfMonthSegments: CronSegment[];
  monthSegments: CronSegment[];
  dayOfWeekSegments: CronSegment[];
}
export interface CronSegment {
  absoluteRangeMin: number;
  absoluteRangeMax: number;
  stepValue: number;
  wildcard: boolean;
}

const volumesetScheduleRegex = /^(\*|(\d+(-\d+)?))(\/\d+)?(,(\*|(\d+(-\d+)?))(\/\d+)?)*(\s(\*|(\d+(-\d+)?))(\/\d+)?(,(\*|(\d+(-\d+)?))(\/\d+)?)*){4}$/;
export function parseCronSchedule(cronSchedule: string): CronSchedule {
  if (!cronSchedule.match(volumesetScheduleRegex)) {
    throw new Error("The given schedule does not match the standard cron format.");
  }

  const pieces = cronSchedule.split(/\s/);

  if (pieces.length != 5) {
    throw new Error("The given schedule does not match the standard cron format.");
  }

  let parsedSchedule: CronSchedule = {
    minuteSegments: [],
    hourSegments: [],
    dayOfMonthSegments: [],
    monthSegments: [],
    dayOfWeekSegments: [],
  };
  parsedSchedule.minuteSegments = parseCronScheduleSegment(pieces[0]);
  parsedSchedule.hourSegments = parseCronScheduleSegment(pieces[1]);
  parsedSchedule.dayOfMonthSegments = parseCronScheduleSegment(pieces[2]);
  parsedSchedule.monthSegments = parseCronScheduleSegment(pieces[3]);
  parsedSchedule.dayOfWeekSegments = parseCronScheduleSegment(pieces[4]);
  return parsedSchedule;
}

function parseCronScheduleSegment(segments: string): CronSegment[] {
  let parsedSegments: CronSegment[] = [];
  for (let s of segments.split(",")) {
    let segment: CronSegment = {
      absoluteRangeMin: 0,
      absoluteRangeMax: 0,
      stepValue: 0,
      wildcard: false,
    };
    let pieces = s.split("/");
    let range = pieces[0].split("-");
    if (pieces[0] == "*") {
      segment.wildcard = true;
    } else {
      segment.absoluteRangeMin = parseInt(range[0]);
      segment.absoluteRangeMax = segment.absoluteRangeMin;
      if (range.length > 1) {
        segment.absoluteRangeMax = parseInt(range[1]);
      }
    }

    if (pieces.length > 1) {
      segment.stepValue = parseInt(pieces[1]);
    }
    parsedSegments.push(segment);
  }
  return parsedSegments;
}

export function stringToHash(value: string) {
  var hash = 0;

  if (value.length == 0) return hash;

  for (let i = 0; i < value.length; i++) {
    let char = value.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
}

export async function sleep(seconds: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, seconds * 1000);
  });
}

export async function getExportData(item: DetailContextItem, slim: boolean) {
  if (item.kind === "account") {
    return;
  }

  const kind = item.kind as any;
  let { data } = await request({ url: resourceLink(kind, item.name) });

  if (item.kind === "secret") {
    try {
      const { data: dataSecret } = await request({ url: resourceLink(item.kind, item.name) + "/-reveal" });
      data = dataSecret;
    } catch (e) {
      // throw new Error("Secret could not be revealed.");
    }
  }

  if (item.kind === "workload") {
    try {
      // TODOTABLE fix
      // const { data: deployments } = await request({ url: item.selfLink + "/deployment" });
      // const health = getWorkloadHealth(deployments.items as any, item as any);
      // data.status.ready = health.isReady;
      // data.status.readyLatest = health.isLatestReady;
      // data.status.readyCheckTimestamp = new Date().toISOString();
    } catch (e) {
      data.status.ready = "unknown";
      data.status.readyLatest = "unknown";
    }
  }

  if (slim) {
    clearItem(data);
  }
  return data;
}

export function hasDuplicatedValue(items: string[]) {
  const seen = new Set<string>();
  for (const item of items) {
    if (seen.has(item)) {
      return true;
    }
    seen.add(item);
  }
  return false;
}

export function getIsMarketplaceItem(item: any): boolean {
  return !!item.tags["console/marketplace"];
}

export function getIsHelmReleaseItem(item: any): boolean {
  return !!item.tags["cpln/release"];
}
