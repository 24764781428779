import * as React from "react";
import { observer } from "mobx-react-lite";
import { StringModel, StringMobx } from "../../mobxDataModels/stringModel";
import { NameModel } from "../../mobxDataModels/nameModel";
import { notification, Modal, Alert } from "antd";
import { nameOfKind, parentLink, request, resourceLink } from "../../services/cpln";
import { clearItem, updateLastDeploymentTimeOnHubspot } from "../../services/utils";
import { useGVCEmitter } from "../../emitters/gvc";
import { GVCMobx } from "../../mst/kinds/gvc";
import { WorkloadMobx, WorkloadModel } from "../../mst/kinds/workload";
import { IdentityMobx, IdentityModel } from "../../mst/kinds/identity";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { VolumeSetMobx, VolumeSetModel } from "../../mst/kinds/volumeset";
import { NGButton } from "../../newcomponents/button/Button";
import { NGCheckbox } from "../../newcomponents/checkbox";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { ExportWithItemsColumn } from "./exportWithItemsModal/column";
import { LoaderSmall } from "../../components/layout/loader/small";
import NGAlert from "../../newcomponents/alert";

interface Props {
  gvc: GVCMobx;
  onClose: () => void;
  clone: (item: any) => Promise<void>;
  changeGVCContextAfterClone: (gvcName: string) => Promise<void>;
}

enum ActiveModal {
  CLONE,
  SWITCH_TO_GVC,
}

const GVCCloneModalRaw: React.FC<Props> = ({ gvc, clone, onClose, changeGVCContextAfterClone }) => {
  const gvcEmitter = useGVCEmitter();
  const { org, gvc: contextGVC } = ConsoleContext;
  const nameRef = React.useRef<StringMobx>(NameModel.create());
  const descriptionRef = React.useRef<StringMobx>(StringModel.create({ label: "Description" }));
  const [isLoading, setIsLoading] = React.useState(false);

  const [copyLocations, setCopyLocations] = React.useState(false);
  const [activeModal, setActiveModal] = React.useState<ActiveModal>(ActiveModal.CLONE);
  const [workloads, setWorkloads] = React.useState<WorkloadMobx[]>([]);
  const [identities, setIdentities] = React.useState<IdentityMobx[]>([]);
  const [volumesets, setVolumesets] = React.useState<VolumeSetMobx[]>([]);
  const [workloadsFetched, setWorkloadsFetched] = React.useState<boolean>(false);
  const [identitiesFetched, setIdentitiesFetched] = React.useState<boolean>(false);
  const [volumesetsFetched, setVolumesetsFetched] = React.useState<boolean>(false);
  const [workloadResponses, setWorkloadResponses] = React.useState<any[]>([]);
  const [identityResponses, setIdentityResponses] = React.useState<any[]>([]);
  const [volumesetResponses, setVolumesetResponses] = React.useState<any[]>([]);
  const [selectedWorkloadLinks, setSelectedWorkloadLinks] = React.useState<string[]>([]);
  const [selectedIdentityLinks, setSelectedIdentityLinks] = React.useState<string[]>([]);
  const [selectedVolumesetLinks, setSelectedVolumesetLinks] = React.useState<string[]>([]);
  const [requiredIdentityLinks, setRequiredIdentityLinks] = React.useState<string[]>([]);
  const [requiredVolumesetLinks, setRequiredVolumesetLinks] = React.useState<string[]>([]);

  const selectedAndRequiredIdentityLinks = [...selectedIdentityLinks];
  for (let requiredIdentityLink of requiredIdentityLinks) {
    if (!selectedAndRequiredIdentityLinks.includes(requiredIdentityLink)) {
      selectedAndRequiredIdentityLinks.push(requiredIdentityLink);
    }
  }

  const selectedAndRequiredVolumesetLinks = [...selectedVolumesetLinks];
  for (let requiredVolumesetLink of requiredVolumesetLinks) {
    if (!selectedAndRequiredVolumesetLinks.includes(requiredVolumesetLink)) {
      selectedAndRequiredVolumesetLinks.push(requiredVolumesetLink);
    }
  }

  const VOLUMESET_DEFAULT_QUOTA = 10;
  const IDENTITY_DEFAULT_QUOTA = 10;

  React.useEffect(() => {
    Promise.all([getAllWorkloads(), getAllIdentities(), getAllVolumesets()]);
  }, []);

  React.useEffect(() => {
    const _requiredIdentityLinks: string[] = [];
    for (let workloadLink of selectedWorkloadLinks) {
      const workload = workloads.find((w) => w.selfLink === workloadLink);
      if (!workload) {
        continue;
      }
      if (workload.spec.identityLink && identities.some((i) => i.selfLink === workload.spec.identityLink)) {
        _requiredIdentityLinks.push(workload.spec.identityLink);
      }
    }

    setRequiredIdentityLinks(_requiredIdentityLinks);
  }, [selectedWorkloadLinks]);

  React.useEffect(() => {
    const _requiredVolumesetLinks: string[] = [];
    for (let workloadLink of selectedWorkloadLinks) {
      const workload = workloads.find((w) => w.selfLink === workloadLink);
      if (!workload) {
        continue;
      }
      for (const container of workload.spec.containers) {
        for (const volume of container.volumes) {
          if (volume.uri.includes("cpln://volumeset/")) {
            let volumeName = "";
            try {
              volumeName = volume.uri.split("cpln://volumeset/")[1];
            } catch (e) {}
            if (volumeName) {
              _requiredVolumesetLinks.push(resourceLink("volumeset", volumeName));
            }
          }
        }
      }
    }

    setRequiredVolumesetLinks(_requiredVolumesetLinks);
  }, [selectedWorkloadLinks]);

  function toggleWorkloadAll() {
    if (selectedWorkloadLinks.length !== workloads.length) {
      setSelectedWorkloadLinks(workloads.map((w) => w.selfLink));
    } else {
      setSelectedWorkloadLinks([]);
    }
  }

  function toggleWorkloadLink(link: string) {
    const isChecked = selectedWorkloadLinks.includes(link);
    if (isChecked) {
      setSelectedWorkloadLinks((links) => links.filter((_link) => _link !== link));
    } else {
      setSelectedWorkloadLinks((links) => [...links, link]);
    }
  }

  function toggleIdentityAll() {
    if (selectedAndRequiredIdentityLinks.length !== identities.length) {
      setSelectedIdentityLinks(identities.map((w) => w.selfLink));
    } else {
      setSelectedIdentityLinks([...requiredIdentityLinks]);
    }
  }

  function toggleIdentityLink(link: string) {
    const isChecked = selectedIdentityLinks.includes(link);
    if (isChecked) {
      setSelectedIdentityLinks((links) => links.filter((_link) => _link !== link));
    } else {
      setSelectedIdentityLinks((links) => [...links, link]);
    }
  }

  function toggleVolumesetAll() {
    if (selectedAndRequiredVolumesetLinks.length !== volumesets.length) {
      setSelectedVolumesetLinks(volumesets.map((w) => w.selfLink));
    } else {
      setSelectedVolumesetLinks([...requiredVolumesetLinks]);
    }
  }

  function toggleVolumesetLink(link: string) {
    const isChecked = selectedVolumesetLinks.includes(link);
    if (isChecked) {
      setSelectedVolumesetLinks((links) => links.filter((_link) => _link !== link));
    } else {
      setSelectedVolumesetLinks((links) => [...links, link]);
    }
  }

  async function getAllWorkloads() {
    let nextLink = parentLink("workload");
    const _workloads: WorkloadMobx[] = [];
    const _workloadResponses: any[] = [];
    while (nextLink) {
      const { data: workloadsRes } = await request({ url: nextLink });
      for (let item of workloadsRes.items) {
        _workloads.push(WorkloadModel.create(item));
        _workloadResponses.push(item);
      }
      nextLink = workloadsRes.links.find((link: any) => link.rel === "next")?.href;
    }
    setWorkloads(_workloads);
    setWorkloadResponses(_workloadResponses);
    setWorkloadsFetched(true);
  }

  async function getAllIdentities() {
    let nextLink = parentLink("identity");
    const _identities: IdentityMobx[] = [];
    const _identityResponses: any[] = [];
    while (nextLink) {
      const { data: identitiesRes } = await request({ url: nextLink });
      for (let item of identitiesRes.items) {
        _identities.push(IdentityModel.create(item));
        _identityResponses.push(item);
      }
      nextLink = identitiesRes.links.find((link: any) => link.rel === "next")?.href;
    }
    setIdentities(_identities);
    setIdentityResponses(_identityResponses);
    setIdentitiesFetched(true);
  }

  async function getAllVolumesets() {
    let nextLink = parentLink("volumeset");
    const _volumesets: VolumeSetMobx[] = [];
    const _volumesetResponses: any[] = [];
    while (nextLink) {
      const { data: volumesetsRes } = await request({ url: nextLink });
      for (let item of volumesetsRes.items) {
        _volumesets.push(VolumeSetModel.create(item));
        _volumesetResponses.push(item);
      }
      nextLink = volumesetsRes.links.find((link: any) => link.rel === "next")?.href;
    }
    setVolumesets(_volumesets);
    setVolumesetResponses(_volumesetResponses);
    setVolumesetsFetched(true);
  }

  async function confirmClone() {
    try {
      if (!nameRef.current.isValid) return;
      setIsLoading(true);

      let { data: gvcToClone } = await request({ url: gvc.selfLink });

      // override description
      if (descriptionRef.current.value) {
        gvcToClone.description = descriptionRef.current.value;
      } else {
        gvcToClone.description = nameRef.current.value;
      }

      // override name
      gvcToClone.name = nameRef.current.value;

      delete gvcToClone.spec.domain;
      delete gvcToClone.spec.tracing;

      if (!copyLocations) {
        gvcToClone.spec.staticPlacement = { locationLinks: [] };
      }

      await clone(gvcToClone);

      const gvcName = gvcToClone.name;

      const succeededWorkloads: { name: string; reason: string }[] = [];
      const succeededIdentities: { name: string; reason: string }[] = [];
      const succeededVolumesets: { name: string; reason: string }[] = [];
      const failedWorkloads: { name: string; reason: string }[] = [];
      const failedIdentities: { name: string; reason: string }[] = [];
      const failedVolumesets: { name: string; reason: string }[] = [];

      for (let identityLink of selectedAndRequiredIdentityLinks) {
        const identityMobx = identities.find((w) => w.selfLink === identityLink);
        const identity = identityResponses.find((i: any) => i.id === identityMobx?.id);
        if (!identity || !identityMobx) {
          continue;
        }
        const newIdentityLink = `/org/${org}/gvc/${gvcName}/identity`;
        try {
          const body = JSON.parse(JSON.stringify(identity));

          // Make slim
          clearItem(body);

          await request({ url: newIdentityLink, body: identity, method: "post" });
          succeededIdentities.push({ name: identity.name, reason: "" });
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) {
            errorMessage = e.message;
          }
          failedIdentities.push({ name: identity.name, reason: errorMessage });
        }
      }
      for (let volumesetLink of selectedAndRequiredVolumesetLinks) {
        const volumesetMobx = volumesets.find((w) => w.selfLink === volumesetLink);
        const volumeset = volumesetResponses.find((w: any) => w.id === volumesetMobx?.id);
        if (!volumeset || !volumesetMobx) {
          continue;
        }
        const newVolumesetLink = `/org/${org}/gvc/${gvcName}/volumeset`;
        try {
          const body = JSON.parse(JSON.stringify(volumeset));

          // Make slim
          clearItem(body);

          await request({ url: newVolumesetLink, body, method: "post" });
          succeededVolumesets.push({ name: volumeset.name, reason: "" });
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) {
            errorMessage = e.message;
          }
          failedVolumesets.push({ name: volumeset.name, reason: errorMessage });
        }
      }
      for (let workloadLink of selectedWorkloadLinks) {
        const workloadMobx = workloads.find((w) => w.selfLink === workloadLink);
        const workload = workloadResponses.find((w: any) => w.id === workloadMobx?.id);
        if (!workload || !workloadMobx) {
          continue;
        }
        const newWorkloadLink = `/org/${org}/gvc/${gvcName}/workload`;
        try {
          const body = JSON.parse(JSON.stringify(workload));
          if (body.spec.identityLink) {
            body.spec.identityLink = body.spec.identityLink.replace(`/gvc/${contextGVC}/`, `/gvc/${gvcName}/`);
          }

          // Make slim
          clearItem(body);

          await request({ url: newWorkloadLink, body, method: "post" });
          succeededWorkloads.push({ name: workload.name, reason: "" });
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) {
            errorMessage = e.message;
          }
          failedWorkloads.push({ name: workload.name, reason: errorMessage });
        }
      }

      notification.success({
        message: "Success",
        description: `Cloned ${nameOfKind(gvc.kind)}`,
      });
      if (succeededWorkloads.length > 0) {
        notification.success({
          message: "Succeeded Workloads",
          description: (
            <ul>
              {succeededWorkloads.map(({ name }) => (
                <li key={name} className="my-2">
                  <span className="block">{name}</span>
                </li>
              ))}
            </ul>
          ),
        });
        updateLastDeploymentTimeOnHubspot();
      }
      if (succeededIdentities.length > 0) {
        notification.success({
          message: "Succeeded Identities",
          description: (
            <ul>
              {succeededIdentities.map(({ name }) => (
                <li key={name} className="my-2">
                  <span className="block">{name}</span>
                </li>
              ))}
            </ul>
          ),
        });
      }
      if (succeededVolumesets.length > 0) {
        notification.success({
          message: "Succeeded Volume Sets",
          description: (
            <ul>
              {succeededVolumesets.map(({ name }) => (
                <li key={name} className="my-2">
                  <span className="block">{name}</span>
                </li>
              ))}
            </ul>
          ),
        });
      }
      if (failedWorkloads.length > 0) {
        notification.warning({
          duration: 0,
          message: "Failed Workloads",
          description: (
            <ul>
              {failedWorkloads.map(({ name, reason }) => (
                <li key={name} className="my-2">
                  <span className="block">{name}</span>
                  <span className="block">{reason}</span>
                </li>
              ))}
            </ul>
          ),
        });
      }
      if (failedIdentities.length > 0) {
        notification.warning({
          duration: 0,
          message: "Failed Identities",
          description: (
            <ul>
              {failedIdentities.map(({ name, reason }) => (
                <li key={name} className="my-2">
                  <span className="block">{name}</span>
                  <span className="block">{reason}</span>
                </li>
              ))}
            </ul>
          ),
        });
      }
      if (failedVolumesets.length > 0) {
        notification.warning({
          duration: 0,
          message: "Failed Volume Sets",
          description: (
            <ul>
              {failedVolumesets.map(({ name, reason }) => (
                <li key={name} className="my-2">
                  <span className="block">{name}</span>
                  <span className="block">{reason}</span>
                </li>
              ))}
            </ul>
          ),
        });
      }

      setIsLoading(false);
      setActiveModal(ActiveModal.SWITCH_TO_GVC);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  return (
    <>
      {activeModal == ActiveModal.CLONE ? (
        <Modal
          title={"Clone GVC"}
          open={true}
          closable={false}
          maskClosable={!isLoading}
          destroyOnClose
          width={900}
          onCancel={onClose}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" disabled={isLoading} onClick={onClose}>
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                onClick={confirmClone}
                loading={isLoading || !workloadsFetched || !identitiesFetched || !volumesetsFetched}
                data-testid="clone-modal"
                disabled={
                  isLoading ||
                  !nameRef.current.isValid ||
                  selectedAndRequiredIdentityLinks.length > IDENTITY_DEFAULT_QUOTA ||
                  requiredVolumesetLinks.length > VOLUMESET_DEFAULT_QUOTA ||
                  !workloadsFetched ||
                  !identitiesFetched ||
                  !volumesetsFetched
                }
              >
                Clone
              </NGButton>
            </div>
          }
        >
          {workloadsFetched && identitiesFetched && volumesetsFetched ? (
            <div className="flex flex-col">
              <NGFormElement
                name="name"
                label={nameRef.current.label}
                value={nameRef.current.value}
                onChange={nameRef.current.setValue}
              />
              <NGFormElement
                name="description"
                label={descriptionRef.current.label}
                value={descriptionRef.current.value}
                onChange={descriptionRef.current.setValue}
              />
              <NGCheckbox checked={copyLocations} onChange={(e) => setCopyLocations(e)}>
                <NGLabelText>Copy with Same GVC Locations</NGLabelText>
              </NGCheckbox>
              <NGLabelText style={{ margin: "0.25rem 0" }}>Select GVC items to export.</NGLabelText>
              <div className="export-with-items-modal-columns">
                <ExportWithItemsColumn
                  items={workloads.map((workload) => ({
                    value: workload.name,
                    checked: selectedWorkloadLinks.includes(workload.selfLink),
                    onChange: () => toggleWorkloadLink(workload.selfLink),
                  }))}
                  kind="workload"
                  selectedItemsLength={selectedWorkloadLinks.length}
                  toggleAllItems={toggleWorkloadAll}
                />
                <ExportWithItemsColumn
                  items={identities.map((identity) => {
                    const disabled = requiredIdentityLinks.includes(identity.selfLink);
                    const onChange = disabled ? undefined : () => toggleIdentityLink(identity.selfLink);
                    return {
                      value: identity.name,
                      disabled: disabled,
                      checked: selectedAndRequiredIdentityLinks.includes(identity.selfLink),
                      onChange: onChange,
                    };
                  })}
                  kind="identity"
                  selectedItemsLength={selectedAndRequiredIdentityLinks.length}
                  toggleAllItems={toggleIdentityAll}
                />
                <ExportWithItemsColumn
                  items={volumesets.map((volumeset) => {
                    const disabled = requiredVolumesetLinks.includes(volumeset.selfLink);
                    const onChange = disabled ? undefined : () => toggleVolumesetLink(volumeset.selfLink);
                    return {
                      value: volumeset.name,
                      disabled: disabled,
                      checked: selectedAndRequiredVolumesetLinks.includes(volumeset.selfLink),
                      onChange: onChange,
                    };
                  })}
                  kind="volumeset"
                  selectedItemsLength={selectedAndRequiredVolumesetLinks.length}
                  toggleAllItems={toggleVolumesetAll}
                />
              </div>
              <div className="flex justify-end mt-8">
                {selectedAndRequiredIdentityLinks.length > IDENTITY_DEFAULT_QUOTA ? (
                  <NGAlert type={"error"} message={`At most ${IDENTITY_DEFAULT_QUOTA} identity is allowed.`} />
                ) : requiredVolumesetLinks.length > VOLUMESET_DEFAULT_QUOTA ? (
                  <NGAlert type={"error"} message={`At most ${VOLUMESET_DEFAULT_QUOTA} volume set is allowed.`} />
                ) : null}
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "360px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoaderSmall />
            </div>
          )}
        </Modal>
      ) : null}
      {activeModal == ActiveModal.SWITCH_TO_GVC ? (
        <Modal
          title="Do you want to change context?"
          open={true}
          maskClosable={!isLoading}
          destroyOnClose
          onCancel={() => {
            onClose();
            gvcEmitter.emit("GVC_CLONED");
          }}
          footer={
            <div className="modal-actions">
              <NGButton
                variant="secondary"
                onClick={() => {
                  onClose();
                  gvcEmitter.emit("GVC_CLONED");
                }}
              >
                Cancel
              </NGButton>
              <NGButton
                variant="primary"
                loading={isLoading}
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  try {
                    await changeGVCContextAfterClone(nameRef.current.value);
                  } catch (e) {}
                  setIsLoading(false);
                  onClose();
                }}
              >
                Confirm
              </NGButton>
            </div>
          }
        >
          This will change your current context and set it to newly cloned GVC item.
        </Modal>
      ) : null}
    </>
  );
};

export const GVCCloneModal = observer(GVCCloneModalRaw);
