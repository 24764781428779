import * as React from "react";
import { observer } from "mobx-react-lite";
import { StringModel } from "../../mobxDataModels/stringModel";
import { notification } from "antd";
import { useDetailContext } from "../../components/detail/detailContext";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { FormButtons } from "../../components/forms/formButtons";
import { IpSet } from "../../schema/types/ipset";
import { linksOf, request } from "../../services/cpln";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { NGKindSelect } from "../../newcomponents/select/ngkindselect";
import { NGInputListMst } from "../../newcomponents/inputList/inputListMst";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { Workload } from "../../schema/types/workload/workload";
import clsx from "clsx";
import { isEqual } from "lodash";
import { NGLabel } from "../../newcomponents/text/label";

interface Props {
  ipset: IpSet;
}
const SpecRaw: React.FC<Props> = ({ ipset }) => {
  function getDefaultLink() {
    if (!ipset.spec.link) {
      return "";
    }
    const { gvcRelative } = ngParseLink(ipset.spec.link, { useInputCtx: true });
    return gvcRelative;
  }
  const [link, setLink] = React.useState(getDefaultLink());

  function getDefaultLocations() {
    return ipset.spec.locations.map((l) => ({ firstValue: l.name, secondValue: l.retentionPolicy }));
  }
  const locationsRef = React.useRef(ListOfItemsModel.create({ _items: getDefaultLocations() }));

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [promptWhen, setPromptWhen] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(promptWhen);
  }, [promptWhen]);

  useCleanPrompt();

  const { fetchItem } = useDetailContext();

  function getSpec() {
    const res: any = {
      locations: locationsRef.current.items.map((i) => {
        const { absolute } = ngParseLink(i.firstValue, { kind: "location" });
        return { name: absolute, retentionPolicy: i.secondValue };
      }),
    };
    if (link) {
      res.link = link;
    }
    return res;
  }

  React.useEffect(() => {
    for (const item of locationsRef.current.items) {
      if (!item.secondValue) {
        item.setSecondValue("keep");
      }
    }
  }, [locationsRef.current.items.length]);

  React.useEffect(() => {
    let res = false;
    if (link !== getDefaultLink()) res = true;
    if (
      !isEqual(
        getDefaultLocations(),
        locationsRef.current.items.map((i) => ({ firstValue: i.firstValue, secondValue: i.secondValue })),
      )
    )
      res = true;
    setIsDirty(res);
  }, [ipset.version, link, locationsRef.current.isDirty]);

  React.useEffect(() => {
    setPromptWhen(isDirty);
  }, [isDirty]);

  function handleReset() {
    setLink(getDefaultLink());
    locationsRef.current.reset();
  }

  async function handleSave() {
    try {
      setIsLoading(true);

      await request({ method: "patch", body: { "$replace/spec": getSpec() }, url: linksOf(ipset).self! });

      if (fetchItem) {
        await fetchItem();
      }

      notification.success({
        message: "Success",
        description: "Updated ip set",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <div>
        <NGLabel>Link</NGLabel>
        <NGKindSelect<Workload>
          kind="workload"
          className="mb-4"
          style={{ width: 650 }}
          renderOption={({ getOptionValue, getOptionLabel, option, isHovered, isSelected, props }) => {
            const { isValid, name: gvcName } = ngParseLink(linksOf(option).gvc || "", { useInputCtx: true });

            return (
              <li
                key={getOptionValue(option)}
                {...props}
                className={clsx(`flex items-center justify-between`, {
                  "option-hover": isHovered,
                  "option-selected": isSelected,
                })}
              >
                <span>{getOptionLabel(option)}</span>
                {isValid ? <span>{gvcName}</span> : null}
              </li>
            );
          }}
          getOptionValue={(option) => {
            const link = linksOf(option).self;
            if (!link) {
              return `invalid item - ${option.name}`;
            }

            const { isValid, gvcRelative } = ngParseLink(link, { useInputCtx: true });
            if (!isValid) {
              return `invalid item - ${option.name}`;
            }
            return gvcRelative;
          }}
          value={link}
          onChange={(value) => setLink(value)}
        />
        <NGInputListMst
          label="Locations"
          styles={{
            header: { width: 650 },
            container: { width: 650 },
            secondInputContainer: { minWidth: 100, flexGrow: 0 },
          }}
          data={locationsRef.current}
          firstInput={(i) => (
            <NGKindSelect kind="location" value={i.firstValue} onChange={(value) => i.setFirstValue(value)} />
          )}
          secondInput={(i) => (
            <NGSelect
              options={[
                { label: "Free", value: "free" },
                { label: "Keep", value: "keep" },
              ]}
              value={i.secondValue}
              onChange={(value) => i.setSecondValue(value)}
            />
          )}
        />
      </div>
      <div style={{ width: 450 }} className="mt-8 flex items-center">
        <FormButtons
          onReset={handleReset}
          onSave={handleSave}
          resetDisabled={isLoading || !isDirty}
          saveDisabled={isLoading || !isDirty}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export const Spec = observer(SpecRaw);
