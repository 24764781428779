import { getParent, types } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { Mk8sDraftProviderHetznerMobx } from "./mk8s.draft.provider.hetzner";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";

export const Mk8sDraftProviderHetznerNodePoolReadonlyModel = types.model({
  name: types.optional(types.string, ""),
  overrideImage: types.optional(types.string, ""),
  labels: types.array(
    types.model({
      key: types.string,
      value: types.string,
    })
  ),
  taints: types.array(
    types.model({
      key: types.string,
      value: types.string,
      effect: types.string,
    })
  ),
  serverType: types.optional(types.string, ""),
  minSize: types.optional(types.number, 0),
  maxSize: types.optional(types.number, 0),
});

export const Mk8sDraftProviderHetznerNodePoolModel = types
  .model({
    index: types.number,
    status: types.optional(types.enumeration(["default", "added"]), "default"),
    _pool: types.optional(Mk8sDraftProviderHetznerNodePoolReadonlyModel, () =>
      Mk8sDraftProviderHetznerNodePoolReadonlyModel.create()
    ),
    _name: types.optional(types.string, ""),
    name: types.optional(StringModel, () =>
      StringModel.create({ label: "Name", validationKey: "nodePoolName", isRequired: true })
    ),
    _overrideImage: types.optional(types.string, ""),
    overrideImage: types.optional(StringModel, () => StringModel.create({ label: "Override Image" })),
    labels: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    taints: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _serverType: types.optional(types.string, ""),
    serverType: types.optional(StringModel, () => StringModel.create({ label: "Server Type", isRequired: true })),
    _minSize: types.optional(types.string, ""),
    minSize: types.optional(StringModel, () => NumberModel.create({ label: "Min Size", isRequired: true, min: 0 })),
    _maxSize: types.optional(types.string, ""),
    maxSize: types.optional(StringModel, () => NumberModel.create({ label: "Max Size", isRequired: true, min: 0 })),
  })
  .actions((self) => ({
    setStatusAdded() {
      self.status = "added";
    },
    reset() {
      self.name.setInitialValue(self._pool.name);
      self.overrideImage.setInitialValue(self._pool.overrideImage);
      self.labels = ListOfItemsModel.create({
        _items: self._pool.labels.map((l) => ({ firstValue: l.key, secondValue: l.value })),
      });
      self.taints = ListOfItemsModel.create({
        _items: self._pool.taints.map((l) => ({ firstValue: l.key, secondValue: l.value, thirdValue: l.effect })),
      });
      self.serverType.setInitialValue(self._pool.serverType);
      self.minSize.setInitialValue(String(self._pool.minSize).length > 0 ? String(self._pool.minSize) : "");
      self.maxSize.setInitialValue(String(self._pool.maxSize).length > 0 ? String(self._pool.maxSize) : "");
    },
    confirm() {
      self.name.confirm();
      self.overrideImage.confirm();
      self.labels.confirm();
      self.taints.confirm();
      self.serverType.confirm();
      self.minSize.confirm();
      self.maxSize.confirm();

      const _pool: any = {
        name: self.name.value, //
        overrideImage: self.overrideImage.value,
        // labels: self.labels.map((l) => ({ key: l.key.value, value: l.value.value })),
        // taints: self.taints.map((t) => ({ key: t.key.value, value: t.value.value, effect: t.effect.value })),
        serverType: self.serverType.value,
        minSize: String(self.minSize.value).length > 0 ? Number(self.minSize.value) : undefined,
        maxSize: String(self.maxSize.value).length > 0 ? Number(self.maxSize.value) : undefined,
      };

      self._pool = Mk8sDraftProviderHetznerNodePoolReadonlyModel.create(_pool);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isLabelsValid() {
      let res = true;
      if (self.labels.items.some((l) => !l.firstValue || !l.secondValue)) res = false;
      return res;
    },
    get isTaintsValid() {
      let res = true;
      if (self.taints.items.some((t) => !t.firstValue || !t.secondValue || !t.thirdValue)) res = false;
      return res;
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.name.isValid) res = false;
      if (!self.isLabelsValid) res = false;
      if (!self.isTaintsValid) res = false;
      if (!self.serverType.isValid) res = false;
      if (!self.minSize.isValid) res = false;
      if (!self.maxSize.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.name.isValid) reason = "name is invalid";
      if (!self.isLabelsValid) reason = "labels not valid";
      if (!self.isTaintsValid) reason = "taints not valid";
      if (!self.serverType.isValid) reason = "serverType is invalid";
      if (!self.minSize.isValid) reason = self.minSize.invalidReason;
      if (!self.maxSize.isValid) reason = self.maxSize.invalidReason;
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.name.isDirty) res = true;
      if (self.overrideImage.isDirty) res = true;
      if (self.labels.isDirty) res = true;
      if (self.taints.isDirty) res = true;
      if (self.serverType.isDirty) res = true;
      if (self.minSize.isDirty) res = true;
      if (self.maxSize.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.name.isDirty) reason = self.name.isDirtyReason;
      if (self.overrideImage.isDirty) reason = self.overrideImage.isDirtyReason;
      if (self.labels.isDirty) reason = "labels are dirty";
      if (self.taints.isDirty) reason = "taints are dirty";
      if (self.serverType.isDirty) reason = self.serverType.isDirtyReason;
      if (self.minSize.isDirty) reason = self.minSize.isDirtyReason;
      if (self.maxSize.isDirty) reason = self.maxSize.isDirtyReason;
      return reason;
    },
    get asObject() {
      const labels: { [_: string]: string } = {};
      self.labels.items.forEach((l) => {
        labels[l.firstValue] = l.secondValue;
      });

      const obj: any = {
        name: self.name.value,
        labels: labels,
        taints: self.taints.items.map((t) => ({ key: t.firstValue, value: t.secondValue, effect: t.thirdValue })),
        serverType: self.serverType.value,
        minSize: Number(self.minSize.value),
        maxSize: Number(self.maxSize.value),
      };
      if (self.overrideImage.value) {
        obj.overrideImage = self.overrideImage.value;
      }
      return obj;
    },
  }));
