import * as React from "react";
import { Tooltip as AntTooltip, notification } from "antd";
import { CreateLayout } from "../../../components/create/layout";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { StringMobx } from "../../../mobxDataModels/stringModel";
import { observer } from "mobx-react-lite";
import { Mk8sCreateGeneral } from "./general";
import { homeLink, request } from "../../../services/cpln";
import { TagsNewMobx } from "../../../mobxDataModels/tagsNewModel";
import { TagsNew } from "../../../components/create/tags";
import { SelectMobx } from "../../../mobxDataModels/selectModel";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { mk8sDryRun } from "../../../mobxStores/dryRun/mk8s";
import { IS_DEPLOYMENT_ENV_TEST } from "../../../envVariables";
import { HiddenSectionsMobx } from "../../../mobxStores/hiddenSections";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { InnerNavLink } from "../../../components/detail/ngNav";
import { AlertCircle, Plus } from "react-feather";
import { Mk8sCreateProvider } from "./provider/provider";
import { Mk8sCreateAddonsRoute } from "./addons/route";
import { Mk8sCreateFirewall } from "./firewall";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGFormData } from "../../../mobxStores/ngFormData";

interface HetznerNodePoolTaintObjectInputGroup {
  key: StringMobx;
  value: StringMobx;
  effect: SelectMobx;
}
export interface HetznerNodePoolInputGroup {
  name: StringMobx;
  labels: TagsNewMobx;
  taints: HetznerNodePoolTaintObjectInputGroup[];
  serverType: SelectMobx;
  minSize: StringMobx;
  maxSize: StringMobx;
}

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const basePath = useBasePath("/mk8s/-create/*");

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    mk8sDryRun.run(mk8sDraft.asObject);
  }, [mk8sDraft.hash]);

  React.useEffect(() => {
    // TODO make params always an object?
    mk8sDryRun.reset(false);

    navigate(`${basePath}/-general`, { replace: true });
  }, []);

  React.useEffect(() => {
    fetchSchemaData();
  }, []);
  async function fetchSchemaData() {
    // Versions
    try {
      const { data: schemaRes } = await request({ url: "/catalog/schema/mk8s" });
      const versions: string[] = schemaRes.keys.spec.keys.version.allow;
      mk8sDraft.version.setOptions(versions.map((v) => ({ label: v, value: v })));
      mk8sDraft.setDefaultVersion(versions[versions.length - 1]);
    } catch (e) {
      console.error("Failed to get versions from mk8s catalog schema");
    }
  }

  const [previousPathname, setPreviousPathname] = React.useState("");
  const [visitedPages, setVisitedPages] = React.useState<string[]>([]);
  React.useEffect(() => {
    if (!visitedPages.includes(previousPathname)) {
      setVisitedPages((_visitedPages) => [..._visitedPages, previousPathname]);
    }
    setPreviousPathname(location.pathname);
  }, [location.pathname]);
  function visited(pathSection: string) {
    return visitedPages.some((visitedPath) => visitedPath.includes(pathSection));
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      const url = homeLink("mk8s");
      await request({ method: "post", url, body: mk8sDraft.asObject });
      setIsLoading(false);
      notification.success({
        message: "Success",
        description: "Created Kubernetes Cluster",
      });
      PromptContext.setIsDisabled(true);
      navigate(`/console${url}/${mk8sDraft.name.value}`);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.error({
        message: "Failed",
        description: errorMessage,
      });
    }
  }

  function shouldBlockNavigation(nextLocation: any, currentLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/mk8s/-create");
  }

  const formDataRef = React.useRef(new NGFormData());
  const formData = formDataRef.current;

  const ngLinks: (InnerNavLink & { isActive: boolean })[] = [
    // TODO change "isActive" property name to "disabled"
    {
      label: "General",
      url: `-general`,
      isActive: true,
      renderIcon:
        !mk8sDraft.name.value && (visited("/-general") || formData.get("name").touched)
          ? () => (
              <AntTooltip mouseEnterDelay={0} title={"Name is required"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    },
    {
      label: "Provider",
      url: `-provider`,
      disableFold: true,
      isActive: mk8sDraft.isGeneralValid,
      links: [
        {
          label: mk8sDraft.provider.options.find((opt) => opt.value === mk8sDraft.provider.value)!.label,
          url: mk8sDraft.provider.value,
          visible: !!mk8sDraft.provider.value,
          isActive: mk8sDraft.isGeneralValid,
          renderIcon:
            mk8sDraft.provider.value === "generic"
              ? !mk8sDraft.provider_generic.location.value &&
                (visited("/-provider/generic") || formData.get("generic.location").touched)
                ? () => (
                    <AntTooltip title={"Location is required"}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "aws"
              ? !mk8sDraft.provider_aws.isValid &&
                // TODO check touched any from the list of items
                visited("/-provider/aws")
                ? () => (
                    <AntTooltip title={"AWS is not configured"}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "hetzner"
              ? !mk8sDraft.provider_hetzner.isValid &&
                // TODO check touched any from the list of items
                visited("/-provider/hetzner")
                ? () => (
                    <AntTooltip title={"Hetzner is not configured"}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "oblivus"
              ? !mk8sDraft.provider_oblivus.isValid &&
                // TODO check touched any from the list of items
                visited("/-provider/oblivus")
                ? () => (
                    <AntTooltip title={"Oblivus is not configured"}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "lambdalabs"
              ? !mk8sDraft.provider_lambdalabs.isValid &&
                // TODO check touched any from the list of items
                visited("/-provider/lambdalabs")
                ? () => (
                    <AntTooltip title={"Lambda Labs is not configured"}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "linode"
              ? !mk8sDraft.provider_linode.isValid &&
                // TODO check touched any from the list of items
                visited("/-provider/linode")
                ? () => (
                    <AntTooltip title={"Linode is not configured"}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : mk8sDraft.provider.value === "ephemeral"
              ? !mk8sDraft.provider_ephemeral.isValid && visited("/-provider/ephemeral")
                ? () => (
                    <AntTooltip title={"Ephemeral is not configured"}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined
              : undefined,
          links:
            mk8sDraft.provider.value === "generic"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      mk8sDraft.provider_generic.nodePools.some((np) => !np.isValid) &&
                      visited("/-provider/generic/-node-pools")
                        ? () => (
                            <AntTooltip title={"Some node pools are not configured."}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                    links: [
                      ...mk8sDraft.provider_generic.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          (!np.isValid || !mk8sDraft.provider_generic.isNodePoolNameValid(np.index)) &&
                          (visited(`/-provider/generic/-node-pools/${npIndex}`) ||
                            formData.get(`generic.nodePools[${npIndex}].name`).touched)
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !mk8sDraft.provider_generic.networking.isValid && visited(`/-provider/generic/-advanced`)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "aws"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      mk8sDraft.provider_aws.nodePools.some((np) => !np.isValid) &&
                      visited("/-provider/aws/-node-pools")
                        ? () => (
                            <AntTooltip title={"Some node pools are not configured."}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                    links: [
                      ...mk8sDraft.provider_aws.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          (!np.isValid || !mk8sDraft.provider_aws.isNodePoolNameValid(np.index)) &&
                          (visited(`/-provider/aws/-node-pools/${npIndex}`) ||
                            formData.get(`aws.nodePools[${npIndex}].name`).touched)
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      (!mk8sDraft.provider_aws.networking.isValid || !mk8sDraft.provider_aws.autoscaler.isValid) &&
                      visited(`/-provider/aws/-advanced`)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "hetzner"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      mk8sDraft.provider_hetzner.nodePools.some((np) => !np.isValid) &&
                      visited("/-provider/hetzner/-node-pools")
                        ? () => (
                            <AntTooltip title={"Some node pools are not configured."}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                    links: [
                      ...mk8sDraft.provider_hetzner.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          (!np.isValid || !mk8sDraft.provider_hetzner.isNodePoolNameValid(np.index)) &&
                          visited(`/-provider/hetzner/-node-pools/${npIndex}`)
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Dedicated Server Node Pools",
                    url: "-dedicated-server-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      mk8sDraft.provider_hetzner.dedicatedServerNodePools.some((np) => !np.isValid) &&
                      visited("/-provider/hetzner/-dedicated-server-node-pools")
                        ? () => (
                            <AntTooltip title={"Some dedicated server node pools are not configured."}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                    links: [
                      ...mk8sDraft.provider_hetzner.dedicatedServerNodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          (!np.isValid || !mk8sDraft.provider_hetzner.isDedicatedServerNodePoolNameValid(np.index)) &&
                          visited(`/-provider/hetzner/-dedicated-server-node-pools/${npIndex}`)
                            ? () => (
                                <AntTooltip title={"Dedicated Server Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      (!mk8sDraft.provider_hetzner.networking.isValid ||
                        !mk8sDraft.provider_hetzner.autoscaler.isValid) &&
                      visited(`/-provider/hetzner/-advanced`)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "oblivus"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      mk8sDraft.provider_oblivus.nodePools.some((np) => !np.isValid) &&
                      visited("/-provider/oblivus/-node-pools")
                        ? () => (
                            <AntTooltip title={"Some node pools are not configured."}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                    links: [
                      ...mk8sDraft.provider_oblivus.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          (!np.isValid || !mk8sDraft.provider_oblivus.isNodePoolNameValid(np.index)) &&
                          visited(`/-provider/oblivus/-node-pools/${npIndex}`)
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Unmanaged Node Pools",
                    url: "-unmanaged-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      mk8sDraft.provider_oblivus.unmanagedNodePools.some((np) => !np.isValid) &&
                      visited("/-provider/oblivus/-unmanaged-node-pools")
                        ? () => (
                            <AntTooltip title={"Some unmanaged node pools are not configured."}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                    links: [
                      ...mk8sDraft.provider_oblivus.unmanagedNodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          (!np.isValid || !mk8sDraft.provider_oblivus.isUnmanagedNodePoolNameValid(np.index)) &&
                          visited(`/-provider/oblivus/-unmanaged-node-pools/${npIndex}`)
                            ? () => (
                                <AntTooltip title={"Unmanaged Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !mk8sDraft.provider_oblivus.autoscaler.isValid && visited(`/-provider/oblivus/-advanced`)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "lambdalabs"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      mk8sDraft.provider_lambdalabs.nodePools.some((np) => !np.isValid) &&
                      visited("/-provider/lambdalabs/-node-pools")
                        ? () => (
                            <AntTooltip title={"Some node pools are not configured."}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                    links: [
                      ...mk8sDraft.provider_lambdalabs.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          (!np.isValid || !mk8sDraft.provider_lambdalabs.isNodePoolNameValid(np.index)) &&
                          visited(`/-provider/lambdalabs/-node-pools/${npIndex}`)
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Unmanaged Node Pools",
                    url: "-unmanaged-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      mk8sDraft.provider_lambdalabs.unmanagedNodePools.some((np) => !np.isValid) &&
                      visited("/-provider/lambdalabs/-unmanaged-node-pools")
                        ? () => (
                            <AntTooltip title={"Some unmanaged node pools are not configured."}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                    links: [
                      ...mk8sDraft.provider_lambdalabs.unmanagedNodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          (!np.isValid || !mk8sDraft.provider_lambdalabs.isUnmanagedNodePoolNameValid(np.index)) &&
                          visited(`/-provider/lambdalabs/-unmanaged-node-pools/${npIndex}`)
                            ? () => (
                                <AntTooltip title={"Unmanaged Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !mk8sDraft.provider_lambdalabs.autoscaler.isValid && visited(`/-provider/lambdalabs/-advanced`)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "linode"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      mk8sDraft.provider_linode.nodePools.some((np) => !np.isValid) &&
                      visited("/-provider/linode/-node-pools")
                        ? () => (
                            <AntTooltip title={"Some node pools are not configured."}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                    links: [
                      ...mk8sDraft.provider_linode.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          (!np.isValid || !mk8sDraft.provider_linode.isNodePoolNameValid(np.index)) &&
                          visited(`/-provider/linode/-node-pools/${npIndex}`)
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                  {
                    label: "Advanced",
                    url: "-advanced",
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      !mk8sDraft.provider_linode.autoscaler.isValid && visited(`/-provider/linode/-advanced`)
                        ? () => (
                            <AntTooltip title={"Advanced options are not valid"}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                  },
                ]
              : mk8sDraft.provider.value === "ephemeral"
              ? [
                  {
                    label: "Node Pools",
                    url: "-node-pools",
                    disableFold: true,
                    // @ts-ignore
                    isActive: mk8sDraft.isGeneralValid,
                    renderIcon:
                      mk8sDraft.provider_ephemeral.nodePools.some((np) => !np.isValid) &&
                      visited("/-provider/ephemeral/-node-pools")
                        ? () => (
                            <AntTooltip title={"Some node pools are not configured."}>
                              <AlertCircle className="ml-1 h-4 color-danger" />
                            </AntTooltip>
                          )
                        : undefined,
                    links: [
                      ...mk8sDraft.provider_ephemeral.nodePools.map((np, npIndex) => ({
                        label: np.name.value || "New Node Pool",
                        url: String(np.index),
                        isDirty: np.isDirty,
                        isActive: mk8sDraft.isGeneralValid,
                        renderIcon:
                          (!np.isValid || !mk8sDraft.provider_ephemeral.isNodePoolNameValid(np.index)) &&
                          visited(`/-provider/ephemeral/-node-pools/${npIndex}`)
                            ? () => (
                                <AntTooltip title={"Node Pool is not valid"}>
                                  <AlertCircle className="ml-1 h-4 color-danger" />
                                </AntTooltip>
                              )
                            : undefined,
                      })),
                      {
                        label: "Add",
                        url: "-add",
                        type: "action",
                        renderIcon: () => <Plus />,
                        // @ts-ignore
                        isActive: mk8sDraft.isGeneralValid,
                      },
                    ],
                  },
                ]
              : [],
        },
      ],
    },
    {
      label: "Add-ons",
      url: `-addons`,
      isActive: mk8sDraft.isProviderValid,
      links: [
        mk8sDraft.addon_awsEcr.isEnabled
          ? {
              label: "AWS ECR",
              url: "-aws-ecr",
              isActive: true,
              renderIcon: !mk8sDraft.addon_awsEcr.isValid
                ? //  && (visited(`/-addons/-aws-ecr`) || formData.get(`awsEcr`).touched)
                  () => (
                    <AntTooltip title={"Addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.provider.value === "aws" && mk8sDraft.addon_awsEfs.isEnabled
          ? {
              label: "AWS EFS",
              url: "-aws-efs",
              isActive: true,
              renderIcon: !mk8sDraft.addon_awsEfs.isValid
                ? //  && (visited(`/-aws-efs`) || formData.get(`awsEfs`).touched)
                  () => (
                    <AntTooltip title={"Addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_awsElb.isEnabled
          ? {
              label: "AWS ELB",
              url: "-aws-elb",
              isActive: true,
              renderIcon: !mk8sDraft.addon_awsElb.isValid
                ? //  && (visited(`/-addons/-aws-elb`) || formData.get(`awsElb`).touched)
                  () => (
                    <AntTooltip title={"Addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_azureAcr.isEnabled
          ? {
              label: "Azure Container Registry",
              url: "-azure-acr",
              isActive: true,
              renderIcon: !mk8sDraft.addon_azureAcr.isValid
                ? // && (visited(`/-addons/-azure-acr`) || formData.get(`azureAcr`).touched)
                  () => (
                    <AntTooltip title={"Addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_azureWorkloadIdentity.isEnabled
          ? {
              label: "Azure Workload Identity",
              url: "-azure-workload-identity",
              isActive: true,
              renderIcon: !mk8sDraft.addon_azureWorkloadIdentity.isValid
                ? // && (visited(`/-addons/-azure-workload-identity`) || formData.get(`azureTenantId`).touched)
                  () => (
                    <AntTooltip title={"Addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_logs.isEnabled
          ? {
              label: "Logs",
              url: "-logs",
              isActive: true,
              renderIcon: !mk8sDraft.addon_logs.isValid
                ? // && (visited(`/-addons/-logs`) ||
                  //   formData.get(`logs.retentionDays`).touched ||
                  //   formData.get(`logs.includeNamespaces`).touched ||
                  //   formData.get(`logs.excludeNamespaces`).touched)
                  () => (
                    <AntTooltip title={"Addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_metrics.isEnabled
          ? {
              label: "Metrics",
              url: "-metrics",
              isActive: true,
              renderIcon: !mk8sDraft.addon_metrics.isValid
                ? // TODO ability to check any item under metrics is touched
                  // && (visited(`/-addons/-metrics`) ||
                  //   formData.get(`metrics.retentionDays`).touched ||
                  //   formData.get(`metrics.intervalSeconds`).touched ||
                  //   formData.get(`metrics.includeNamespaces`).touched ||
                  //   formData.get(`metrics.excludeNamespaces`).touched ||
                  //   formData.get(`metrics.retainLabels`).touched)
                  () => (
                    <AntTooltip title={"Addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
            }
          : { label: "filterOut", url: "filterOut" },
        mk8sDraft.addon_nvidia.isEnabled
          ? {
              label: "NVIDIA",
              url: "-nvidia",
              isActive: true,
            }
          : { label: "filterOut", url: "filterOut" },
      ].filter((l) => l.label !== "filterOut"),
    },
    {
      label: "Firewall",
      url: `-firewall`,
      isActive: mk8sDraft.isAddonsValid,
      renderIcon: !mk8sDraft.firewall.isValid
        ? () => (
            <AntTooltip title={"Firewall is not valid."}>
              <AlertCircle className="ml-1 h-4 color-danger" />
            </AntTooltip>
          )
        : undefined,
    },
    {
      label: "Tags",
      url: `-tags`,
      isActive: mk8sDraft.isFirewallValid,
      renderIcon: !mk8sDraft.tags.isValid
        ? () => (
            <AntTooltip title={"Tags are not valid."}>
              <AlertCircle className="ml-1 h-4 color-danger" />
            </AntTooltip>
          )
        : undefined,
    },
  ];

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };

  if (location.pathname.includes("-general")) {
    if (mk8sDraft.isGeneralValid) {
      next.isActive = true;
    }
    next.label = "Next (Provider)";
    next.url = `-provider`;
  } else if (location.pathname.endsWith("-provider")) {
    next.isActive = true;
    next.label = `Next (${mk8sDraft.provider.options.find((opt) => opt.value === mk8sDraft.provider.value)!.label})`;
    next.url = `-provider/${mk8sDraft.provider.value}`;
  } else if (location.pathname.endsWith(`-provider/${mk8sDraft.provider.value}`)) {
    if (mk8sDraft.isProviderValid) {
      next.isActive = true;
    }
    next.label = `Add Node Pool`;
    next.url = `-provider/${mk8sDraft.provider.value}/-node-pools/-add`;
  } else if (location.pathname.includes("-provider")) {
    if (mk8sDraft.isProviderValid) {
      next.isActive = true;
    }
    next.label = "Next (Add-ons)";
    next.url = `-addons`;
  } else if (location.pathname.includes("-addons")) {
    next.isActive = true;

    if (
      mk8sDraft.addon_awsEcr.isEnabled &&
      !location.pathname.includes("-aws-ecr") &&
      !mk8sDraft.addon_awsEcr.isValid
    ) {
      next.label = "Configure AWS ECR";
      next.url = `-addons/-aws-ecr`;
    } else if (
      mk8sDraft.provider.value === "aws" &&
      mk8sDraft.addon_awsEfs.isEnabled &&
      !location.pathname.includes("-aws-efs") &&
      !mk8sDraft.addon_awsEfs.isValid
    ) {
      next.label = "Configure AWS EFS";
      next.url = `-addons/-aws-efs`;
    } else if (
      mk8sDraft.addon_awsElb.isEnabled &&
      !location.pathname.includes("-aws-elb") &&
      !mk8sDraft.addon_awsElb.isValid
    ) {
      next.label = "Configure AWS ELB";
      next.url = `-addons/-aws-elb`;
    } else if (
      mk8sDraft.addon_azureAcr.isEnabled &&
      !location.pathname.includes("-azure-acr") &&
      !mk8sDraft.addon_azureAcr.isValid
    ) {
      next.label = "Configure Azure ACR";
      next.url = `-addons/-azure-acr`;
    } else if (
      mk8sDraft.addon_azureWorkloadIdentity.isEnabled &&
      !location.pathname.includes("-azure-workload-identity") &&
      !mk8sDraft.addon_azureWorkloadIdentity.isValid
    ) {
      next.label = "Configure Azure";
      next.url = `-addons/-azure-workload-identity`;
    } else if (
      mk8sDraft.addon_logs.isEnabled &&
      !location.pathname.includes("-logs") &&
      !mk8sDraft.addon_logs.isValid
    ) {
      next.label = "Configure Logs";
      next.url = `-addons/-logs`;
    } else if (
      mk8sDraft.addon_metrics.isEnabled &&
      !location.pathname.includes("-metrics") &&
      !mk8sDraft.addon_metrics.isValid
    ) {
      next.label = "Configure Metrics";
      next.url = `-addons/-metrics`;
    } else if (
      mk8sDraft.addon_nvidia.isEnabled &&
      !location.pathname.includes("-nvidia") &&
      !mk8sDraft.addon_nvidia.isValid
    ) {
      next.label = "Configure NVIDIA";
      next.url = `-addons/-nvidia`;
    } else {
      next.label = "Next (Firewall)";
      next.url = `-firewall`;
      next.isActive = mk8sDraft.isAddonsValid;
    }
  } else if (location.pathname.includes("-firewall")) {
    if (mk8sDraft.isFirewallValid) {
      next.isActive = true;
    }
    next.label = "Next (Tags)";
    next.url = `-tags`;
  }

  return (
    <div style={{ maxWidth: 1600 }}>
      <BasePathContext.Provider value={basePath}>
        <NGFormContext.Provider value={formData}>
          <CreateLayout
            getPreview={() => ({ kind: "mk8s", ...mk8sDraft.asObject })}
            next={next}
            shouldBlockNavigation={shouldBlockNavigation}
            title={"Create Kubernetes Cluster"}
            name={mk8sDraft.name.value}
            canCreate={mk8sDraft.isValid && !mk8sDryRun.hasError}
            onConfirm={onConfirm}
            isLoading={isLoading}
            links={[]}
            useNewNav
            ngLinks={ngLinks as any}
            hideButtons={location.search.includes("?nodePool=")}
          >
            <Routes>
              <Route index element={<Navigate to={`-general`} />} />
              <Route path={`-general`} element={<Mk8sCreateGeneral mk8sDraft={mk8sDraft} />} />
              <Route path={`-provider/*`} element={<Mk8sCreateProvider mk8sDraft={mk8sDraft} />} />
              <Route path={`-addons/*`} element={<Mk8sCreateAddonsRoute mk8sDraft={mk8sDraft} />} />
              <Route path={`-firewall`} element={<Mk8sCreateFirewall mk8sDraft={mk8sDraft} />} />
              <Route path={`-tags`} element={<TagsNew tags={mk8sDraft.tags} />} />
            </Routes>
          </CreateLayout>
        </NGFormContext.Provider>
      </BasePathContext.Provider>
      {IS_DEPLOYMENT_ENV_TEST && HiddenSectionsMobx.isRevealed("debug") ? (
        <div>
          <div>Invalid Reason</div>
          <div>{mk8sDraft.invalidReason || "none"}</div>
          <div>Dry run error</div>
          <div>Has error?: {String(mk8sDryRun.hasError)}</div>
          {mk8sDryRun.hasError ? <pre className="text-xs">{JSON.stringify(mk8sDryRun.response, null, 2)}</pre> : null}
        </div>
      ) : null}
    </div>
  );
};

export const Mk8sCreate = observer(Mk8sCreateRaw);
