import { Instance, types } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { StringModel } from "../../mobxDataModels/stringModel";

export const WorkloadDraftSecurityOptionsModel = types
  .model({
    _configure: types.optional(types.boolean, false),
    _filesystemGroupId: types.maybe(types.number),
    configure: types.optional(types.boolean, false),
    filesystemGroupId: types.optional(StringModel, () =>
      NumberModel.create({ label: "File System Group ID", isRequired: true, min: 1, max: 65534, allowDot: false })
    ),
  })
  .actions((self) => ({
    reset() {
      self.configure = self._configure;
      self.filesystemGroupId.setInitialValue(String(self._filesystemGroupId || ""));
    },
    setConfigure(value: boolean) {
      self.configure = value;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    confirm() {
      self._configure = self.configure;
      self._filesystemGroupId = Number(self.filesystemGroupId.value);
      self.filesystemGroupId.confirm();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self._configure !== self.configure) res = true;
      if (self.configure) {
        if (self.filesystemGroupId.isDirty) res = true;
      }
      return res;
    },
    get isDirtyReason() {
      let reason = "";
      if (self._configure !== self.configure) reason = "configure changed";
      if (self.configure) {
        if (self.filesystemGroupId.isDirty) reason = "filesystemGroupId";
      }
      return reason;
    },
    get isValid() {
      let res = true;
      if (self.configure) {
        if (!self.filesystemGroupId.isValid) res = false;
      }
      return res;
    },
    get asObject() {
      return {
        filesystemGroupId: Number(self.filesystemGroupId.value),
      };
    },
  }));
export interface WorkloadDraftSecurityOptionsMobx extends Instance<typeof WorkloadDraftSecurityOptionsModel> {}
