import { observer } from "mobx-react-lite";
import * as React from "react";
import { mk8sMobx } from "../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabelText } from "../../../newcomponents/text/labelText";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const AddonsNvidiaRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <div>
      <div className="mb-2">Nvidia</div>
      <NGSwitch
        value={mk8sDraft.addon_nvidia.taintGPUNodes}
        onChange={(value) => mk8sDraft.addon_nvidia.setTaintGPUNodes(value)}
      >
        <NGLabelText>Taint GPU Nodes</NGLabelText>
      </NGSwitch>
    </div>
  );
};

export const AddonsNvidia = observer(AddonsNvidiaRaw);
