import clsx from "clsx";
import { UIData } from "../../../../mobxStores/uiData/uiData";
import { PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";
import { ChevronDown, ChevronUp } from "react-feather";
import { NGTooltip } from "../../../../newcomponents/ngtooltip";

interface MenuListProps extends PropsWithChildren {
  isOpen?: boolean;
  isExpandable?: boolean;
  title?: string;
  className?: string;
  onClick?: any;
}

const MenuListRaw: React.FC<MenuListProps> = ({ isOpen = true, isExpandable = false, title, onClick, children }) => {
  const { isNavCollapsed } = UIData;

  const CollapseIcon = () => {
    if (isOpen) return <ChevronUp color="var(--color-gray-800)" size={20} />;
    else return <ChevronDown color="var(--color-gray-800)" size={20} />;
  };

  const CollapseToggle = () => (
    <NGTooltip delayMs={0} placement="right" trigger={["hover"]} title={title} disable={!UIData.isNavCollapsed}>
      <button className={`menu-link ngfocus`} onClick={(e) => onClick(e)}>
        <CollapseIcon />
      </button>
    </NGTooltip>
  );

  const CollapseTitle = () => (
    <div className="menu-list-collapse-title" onClick={(e) => onClick(e)}>
      <h2>{title}</h2>
      <CollapseIcon />
    </div>
  );

  const renderExpandButton = () => (isNavCollapsed ? <CollapseToggle /> : <CollapseTitle />);

  return (
    <div className={clsx("menu-list", { collapsed: isNavCollapsed })}>
      {isExpandable ? renderExpandButton() : <h2>{title} </h2>}
      {isOpen ? <ul>{children}</ul> : null}
    </div>
  );
};

export const MenuList = observer(MenuListRaw);
