import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderNetworking } from "../../providerNetworking";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderGenericAdvancedRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const g = mk8sDraft.provider_generic!;

  return (
    //
    <div>
      <div className="mb-4">
        <ProviderNetworking clusterDraft={mk8sDraft} networking={g.networking} />
      </div>
    </div>
  );
};

export const ProviderGenericAdvanced = observer(ProviderGenericAdvancedRaw);
