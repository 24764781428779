import * as React from "react";
import { observer } from "mobx-react-lite";
import { Modal } from "antd";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { MobxInput } from "../../../components/inputs/mobxInput";
import { WorkloadDraftContainerMobx } from "../../../mst/stores/workload.draft.container";
import { StringWithImageRef } from "../../../components/inputs/stringWithImageRef/stringWithImageRef";
import { FormLabel } from "../../../components/forms/formLabel";
import { Select } from "../../../components/inputs/select";
import { useLocation } from "react-router-dom";
import { ImageIcon } from "../../../components/Icons";
import { Theme } from "../../../mobxStores/uiData/theme";
import { NGButton } from "../../../newcomponents/button/Button";
import NGAlert from "../../../newcomponents/alert";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { InfoTooltip } from "../../../components/InfoTooltip";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  containerDraft: WorkloadDraftContainerMobx;
}
const GeneralRaw: React.FC<Props> = ({ containerDraft, workloadDraft }) => {
  const { theme } = Theme;
  const { pathname } = useLocation();
  const [containerImageInitialValue, setContainerImageInitialValue] = React.useState("");
  const isCreateFormOrNewContainer = pathname.includes("/-create") || pathname.includes("/-new");
  const [isImageTouched, setIsImageTouched] = React.useState(!isCreateFormOrNewContainer);
  const isCplnRegistry = containerDraft.image.value.startsWith("/org/");

  React.useEffect(() => {
    setContainerImageInitialValue(containerDraft.image.value);
  }, []);

  function onServesTraffic() {
    let shouldWarn = workloadDraft.simulateServesTraffic(containerDraft.name.value, !containerDraft.servesTraffic);
    if (shouldWarn) {
      const m = Modal.confirm({
        title: "Enable 'Serves Traffic'",
        content: (
          <div>
            <div>
              Currently another container serves traffic. Serverless workloads allow only a single container to serve
              traffic. Do you want to disable this feature for the other container and enable for this container?
            </div>
          </div>
        ),
        footer: (
          <div className="modal-actions">
            <NGButton variant="secondary" onClick={() => m.destroy()}>
              Close
            </NGButton>
            <NGButton
              variant="primary"
              onClick={() => {
                workloadDraft.setServesTraffic(containerDraft.name.value, !containerDraft.servesTraffic);
                m.destroy();
              }}
            >
              Confirm
            </NGButton>
          </div>
        ),
      });
    } else {
      workloadDraft.setServesTraffic(containerDraft.name.value, !containerDraft.servesTraffic);
    }
  }

  return (
    <>
      {(isImageTouched && !!containerDraft.image.value.length) || !!containerImageInitialValue ? (
        <div className="flex items-center gap-1 text-sm mb-2 pl-4">
          {isCplnRegistry ? (
            <img
              className="object-contain"
              style={{ width: 16, height: 16 }}
              src={`/resources/logos/controlPlaneLogoOnly.svg`}
            />
          ) : (
            <ImageIcon style={{ width: 16, height: 16, fill: theme === "dark" ? "white" : "" }} />
          )}
          <span>
            {isCplnRegistry ? "CPLN" : "External"}
            {` Registry`}
          </span>
        </div>
      ) : (
        // This is to prevent jumping
        <div style={{ height: 28 }} />
      )}
      <div className="flex items-center mb-4">
        <StringWithImageRef
          style={{ width: 800 }}
          data={containerDraft.image}
          setValueOverride={(value: string) => workloadDraft.setContainerImage(containerDraft, value)}
          onBlur={() => setIsImageTouched(true)}
        />
        <InfoTooltip
          title={[
            "The full image and tag path for a public or private docker registry.",
            `Private container images must have valid credentials configured in 'pullSecretLinks' on the GVC (Global Virtual Cloud).`,
          ]}
        />
      </div>
      <div className="flex mb-6">
        <MobxInput
          style={{ width: 800 }}
          data={containerDraft.name}
          setValue={(value: string) => workloadDraft.setContainerName(containerDraft, value)}
        />
        <InfoTooltip className="mt-4" title="The name of the container." />
      </div>
      {workloadDraft.isServerless ? (
        <>
          <div className="mb-2">
            <NGSwitch
              data-testid={`switch-serves-traffic`}
              value={containerDraft.servesTraffic}
              onChange={() => onServesTraffic()}
            >
              <NGLabelText>Serves Traffic</NGLabelText>
            </NGSwitch>
          </div>
          {!workloadDraft.isServerlessServesTraffic && (
            <NGAlert
              className="mb-4"
              message="A serverless workload must serve traffic from at least one of its containers"
            />
          )}
          {containerDraft.servesTraffic && (
            <div className="flex">
              <Select
                style={{ width: 220, marginRight: 10 }}
                label={containerDraft.ports[0].procotol.label}
                onChange={containerDraft.setServerlessProtocolValue}
                options={containerDraft.ports[0].procotol.options}
                value={containerDraft.ports[0].procotol.value}
              />
              <MobxInput style={{ width: 220 }} data={containerDraft.ports[0].number} />
              <InfoTooltip
                className="mt-4"
                title={[
                  "The port in the container which receives external traffic or traffic from other workloads.",
                  "Only one container is allowed to specify a port.",
                ]}
              />
            </div>
          )}
        </>
      ) : workloadDraft.isStandard || workloadDraft.isStateful ? (
        <div style={{ width: 450 }} className="flex flex-col gap-2">
          <FormLabel parent>Ports</FormLabel>
          {containerDraft.ports.map((port, index) => {
            return (
              <div key={String(index)} className="flex gap-4">
                <Select
                  style={{ width: 115 }}
                  label={port.procotol.label}
                  onChange={port.procotol.setValue}
                  options={port.procotol.options}
                  value={port.procotol.value}
                />
                <MobxInput className="flex-grow" data={port.number} idSuffix={index.toString()} hideErrorMessage />
                <NGButton
                  className="my-1"
                  style={{ width: 110 }}
                  variant={"danger"}
                  outlined
                  onClick={() => containerDraft.removePort(index)}
                >
                  Remove
                </NGButton>
              </div>
            );
          })}
          <div className="flex flex-col gap-4 items-end">
            {containerDraft.ports.length === 0 ? (
              <NGAlert className="w-full" message={"Add ports to receive internal and external traffic."} />
            ) : (
              <span className="flex-grow" />
            )}
            <NGButton style={{ width: 110 }} variant={"secondary"} onClick={containerDraft.addPort}>
              Add Port
            </NGButton>
          </div>
        </div>
      ) : null}
    </>
  );
};

export const General = observer(GeneralRaw);
