import React from "react";
import { observer } from "mobx-react-lite";
import {
  chargeableItemUnitProcessorMap,
  ChartData,
  featureLabelMap,
  formatPrice,
  formatXLabel,
  getBody,
  getUsageDayPreset,
  getUsageHourPresets,
  getUsageMonthPreset,
  getUsageWeekPreset,
  GraphData,
  GroupBy,
  PageView,
  Period,
  PeriodGroup,
  TChargeableItemUnit,
  TimeStep,
} from "./utils";
import { Select, Dropdown, notification, Menu } from "antd";
import { ComposedChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { Loader } from "../../components/layout/loader";
import { CustomTooltip } from "./tooltip";
import { Check, ChevronDown, ChevronUp } from "react-feather";
import { arraysAreEqual, request } from "../../services/cpln";
import { darkThemeColors, lightThemeColors } from "./colors";
import { Theme } from "../../mobxStores/uiData/theme";
import { v4 as uuidv4 } from "uuid";
import { CustomLegend } from "./legend";
import { LegendPayloadSaver } from "./legendPayloadSaver";
import { useDebounce } from "../../components/table/useDebounce";
import { BillingContext } from "../../mobxStores/billingContext/billingContext";
import clsx from "clsx";
import { ViewModal } from "../../components/modals/viewModal";
import { UIData } from "../../mobxStores/uiData/uiData";
import { NGButton } from "../../newcomponents/button/Button";
import { Divider } from "../../newcomponents/divider";
import NGAlert from "../../newcomponents/alert";
import { FormLabel } from "../../components/forms/formLabel";
import moment from "moment-timezone";
import { RangePicker } from "../../components/antd/RangePicker";
import { Timezone } from "../../mobxStores/userData/timezone";

const BillingUsageNGRaw: React.FC = () => {
  const cItems = BillingContext.ChargeableItems;
  const features = BillingContext.ChargeableItemsFeatures;

  const { AccountUUID, OrgNames } = BillingContext;

  const requestIdRef = React.useRef(uuidv4());

  const palette = Theme.theme === "light" ? lightThemeColors : darkThemeColors;

  const [isDisplayingTooltip, setIsDisplayingTooltip] = React.useState(false);
  const [isViewing, setIsViewing] = React.useState(false);
  const [isLoadingViewExport, setIsLoadingViewExport] = React.useState(false);

  const [isMiB, setIsMiB] = React.useState(false);
  const [isFiltersVisible, setIsFiltersVisible] = React.useState(false);
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const [legendPayload, _setLegendPayload] = React.useState<any[] | undefined>([]);
  const [legendFilters, setLegendFilters] = React.useState<string[]>([]);
  function toggleLegendFilter(value: string, reverse: boolean) {
    if (legendFilters.includes(value)) {
      setLegendFilters(legendFilters.filter((legendFilter) => legendFilter !== value));
    } else {
      setLegendFilters([...legendFilters, value]);
    }
  }

  const [timeStep, setTimeStep] = React.useState<TimeStep>("day");
  const [pageView, setPageView] = React.useState<PageView>("cost");
  const [groupBy, setGroupBy] = React.useState<GroupBy>("account");
  const [queryResponseKey, setQueryResponseKey] = React.useState<string>(uuidv4());
  const [queryResponse, setQueryResponse] = React.useState<any>(null);
  const [queryResponseCache, setQueryResponseCache] = React.useState<any>(null);

  const [orgFilter, setOrgFilter] = React.useState<string>("");
  const [allGVCs, setAllGVCs] = React.useState<string[]>([]);
  const [gvcFilters, setGVCFilters] = React.useState<string[]>([]);
  const [allLocationss, setAllLocations] = React.useState<string[]>([]);
  const [locationFilters, setLocationFilters] = React.useState<string[]>([]);
  const [allTags, setAllTags] = React.useState<{ [tag: string]: string[] }>({});
  const [tagFilters, setTagFilters] = React.useState<{ [tag: string]: string[] }>({});

  const [chargeableItemFilters, setChargeableItemFilters] = React.useState<string[]>([]); // use id as value
  const [featureFilters, setFeatureFilters] = React.useState<string[]>([]);

  // TODO support without null
  const [startISO, setStartISO] = React.useState<string | null>(
    moment().startOf("day").subtract(31, "day").toISOString(),
  );
  const [endISO, setEndISO] = React.useState<string | null>(moment().startOf("day").toISOString());

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Check if the clicked element is outside the entire component
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsFiltersVisible(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleOutsideClick);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleDropdownOpenChange = (flag: boolean) => {
    setIsFiltersVisible(flag);
  };

  React.useEffect(() => {
    const propertyName = "--page-min-width";
    try {
      (document.querySelector(".n-page") as HTMLDivElement).style.setProperty(propertyName, "1650px");
    } catch (e) {
      console.error("Failed to update min width");
    }

    return () => {
      try {
        (document.querySelector(".n-page") as HTMLDivElement).style.setProperty(propertyName, "1400px");
      } catch (e) {
        console.error("Failed to reset min width");
      }
    };
  }, []);

  React.useEffect(() => {
    const onClick = (e: any) => setIsFiltersVisible(false);

    // TODONOW fix
    const elList = [".n-main", ".n-main-content", ".n-header"].map((className) => document.querySelector(className));
    elList.forEach((el) => el?.addEventListener("click", onClick));

    return () => elList.forEach((el) => el?.removeEventListener("click", onClick));
  }, []);

  React.useEffect(() => {
    if (pageView === "metric") {
      if (groupBy === "metric" || groupBy === "feature") {
        setGroupBy("org");
      }
    }
  }, [pageView, groupBy]);

  const [tagValuesTriggererDebounced, setTagValuesTriggererDebounced] = useDebounce(uuidv4(), 400);
  function triggerTagValues() {
    setTagValuesTriggererDebounced(uuidv4());
  }
  React.useEffect(() => {
    fetchAllTags();
  }, [tagValuesTriggererDebounced]);

  React.useEffect(() => {
    triggerTagValues();
  }, [orgFilter, startISO, endISO]);

  async function fetchAllTags() {
    let _orgFilters = !!orgFilter ? [orgFilter] : OrgNames;

    const _tags: { [tag: string]: string[] } = {};
    const _allGVCs: string[] = [];
    const _allLocations: string[] = [];
    for (const org of _orgFilters) {
      const gvcTagsPromise = fetchAllItemsOfKind(org, "gvc");
      const locationTagsPromise = fetchAllItemsOfKind(org, "location");
      const featureTagsPromises = features.map((f) => fetchAllItemsOfKind(org, "name", { feature: f }));
      const responses = await Promise.all([gvcTagsPromise, locationTagsPromise, ...featureTagsPromises]);
      for (const _gvc of responses[0]) {
        if (!!_gvc && !_allGVCs.includes(_gvc)) {
          _allGVCs.push(_gvc);
        }
      }
      for (const _location of responses[1]) {
        if (!!_location && !_allLocations.includes(_location)) {
          _allLocations.push(_location);
        }
      }
      for (const featureIndexStr in features) {
        const featureIndex = Number(featureIndexStr);
        const feature = features[featureIndex];
        const tagValues = responses[featureIndex + 2];
        if (!_tags[feature]) {
          _tags[feature] = [];
        }
        for (const tagValue of tagValues) {
          if (!!tagValue && !_tags[feature].includes(tagValue)) {
            _tags[feature].push(tagValue);
          }
        }
      }
    }
    setAllLocations(_allLocations);
    setAllGVCs(_allGVCs);
    setAllTags(_tags);
  }

  async function fetchAllItemsOfKind(org: string, tag: string, filterBy?: any) {
    const body: any = {
      startTime: moment(startISO).startOf("day").toISOString(),
      endTime: moment(endISO).add(1, "day").startOf("day").toISOString(),
      tag: tag,
    };
    if (filterBy) {
      body.filterBy = filterBy;
    }
    const { data } = await request({
      service: "metering",
      method: "post",
      url: `/org/${org}/tags/values/query`,
      body: body,
    });
    return data.tagValues || [];
  }

  const [queryTriggererDebounced, setQueryTriggererDebounced] = useDebounce(uuidv4(), 400);
  function triggerQuery() {
    setQueryResponse({});
    setQueryTriggererDebounced(uuidv4());
  }

  React.useEffect(() => {
    queryUsage();
  }, [queryTriggererDebounced]);

  React.useEffect(() => {
    triggerQuery();
    setLegendFilters([]);
  }, [
    timeStep,
    pageView,
    groupBy,
    chargeableItemFilters,
    gvcFilters,
    locationFilters,
    featureFilters,
    tagFilters,
    orgFilter,
    startISO,
    endISO,
  ]);

  function generateQueryBody() {
    let bodyStartTime = startISO!;
    let bodyEndTime = endISO!;
    if (timeStep !== "hour") {
      bodyStartTime = moment(startISO).startOf("day").toISOString();
      bodyEndTime = moment(endISO).startOf("day").toISOString();
    }

    const body = getBody(bodyStartTime, bodyEndTime, timeStep, groupBy, pageView, cItems, features);

    for (const query of body.consumptionQueries) {
      if (gvcFilters.length > 0) {
        (query.filterBy as any).gvc = `/${gvcFilters.map((i) => `^${i}$`).join("|")}/`;
      }
      if (locationFilters.length > 0) {
        (query.filterBy as any).location = `/${locationFilters.map((i) => `^${i}$`).join("|")}/`;
      }

      // TODO filter again by the currently available tag values
      for (const [tag, values] of Object.entries(tagFilters)) {
        if (values.length < 1) {
          continue;
        }

        if ((query.filterBy as any).feature !== tag) {
          continue;
        }
        (query.filterBy as any).name = `/${values.map((i) => `^${i}$`).join("|")}/`;
      }
    }

    if (features.includes(groupBy)) {
      body.consumptionQueries = body.consumptionQueries.filter((q) => q.filterBy.feature === groupBy);
    }

    if (chargeableItemFilters.length > 0) {
      body.consumptionQueries = body.consumptionQueries.filter((q) => chargeableItemFilters.includes(q.id));
    }

    if (featureFilters.length > 0) {
      body.consumptionQueries = body.consumptionQueries.filter((q) => featureFilters.includes(q.filterBy.feature));
    }

    for (const query of body.consumptionQueries) {
      delete (query as any).id;
    }
    return body;
  }

  async function queryUsage() {
    const requestId = uuidv4();
    requestIdRef.current = requestId;
    setIsLoading(true);
    setQueryResponse({});
    setError("");

    const body = generateQueryBody();

    try {
      const url = !!orgFilter ? `/org/${orgFilter}/charges/-query` : `/account/${AccountUUID}/charges/-query`;
      const { data } = await request({
        service: "billing-ng",
        method: "post",
        url: url,
        body: body,
      });
      if (requestIdRef.current !== requestId) {
        return;
      }
      setQueryResponse(data);
      setQueryResponseCache(data);
      setQueryResponseKey(uuidv4());
    } catch (e) {
      setError(e.response?.data?.error || e.message);
    }

    setIsLoading(false);
  }

  function setLegendPayload(newPayload?: any) {
    if (!newPayload || !Array.isArray(newPayload)) {
      _setLegendPayload(undefined);
      return;
    }

    const oldDataKeys = legendPayload?.map((p) => p.dataKey) || [];
    const newDataKeys = newPayload.map((p) => p.dataKey);

    if (arraysAreEqual(oldDataKeys, newDataKeys)) {
      return;
    }

    _setLegendPayload(newPayload);
  }

  async function exportJSON(detailed: boolean) {
    try {
      setIsLoadingViewExport(true);
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = `usage-${AccountUUID}-${endISO}-${timeStep}${detailed ? "-detailed" : ""}.json`;
      let blob = "";
      const queryBody = generateQueryBody();
      if (detailed) {
        queryBody.detailed = true;
      }
      const url = `/account/${AccountUUID}/charges/-query`;
      const { data } = await request({ service: "billing-ng", method: "post", url: url, body: queryBody });
      blob = JSON.stringify(data, null, 2);
      const file = new Blob([blob], { type: "text/json" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
      setIsLoadingViewExport(false);
    } catch (e) {
      setIsLoadingViewExport(false);
      notification.warning({ message: "Failed", description: "Try again" });
    }
  }

  // units are for each x axis group of graph items (bars in a single day)
  // graphData is the corresponding real data for the unit, by dataKey
  let chartData: ChartData = { units: [], graphData: [] };

  let totalSum = 0;
  let projectedTotalSum = 0;

  const periods: Period[] =
    (queryResponse as any)?.results?.periods || (queryResponseCache as any)?.results?.periods || [];

  try {
    // Collect datakeys
    for (const period of periods) {
      for (const group of period.groups || []) {
        const groupCloneForDataKey: PeriodGroup = JSON.parse(JSON.stringify(group));
        if (pageView === "cost" && groupBy !== "metric") {
          // will add up money, no need to separate datakeys by metric
          delete groupCloneForDataKey.key.metric;
        }
        delete groupCloneForDataKey.key.replicas;
        const dataKey = Object.values(groupCloneForDataKey.key).join("-");

        // label without metric from group keys
        let label = `${Object.values(group.key)
          .filter((v) => v !== group.key.metric)
          .join(" ")}`;

        // Add metric to label for this datakey
        if (pageView !== "cost" || groupBy === "metric") {
          label += ` ${group.key.metric}`;
        }

        if (chartData.units.some((k) => k.dataKey === dataKey)) {
          continue;
        }

        chartData.units.push({
          metric: group.key.metric,
          dataKey: dataKey,
          label: label,
          totalSum: 0,
          projectedTotalSum: 0,
        });
      }
    }

    for (const periodIndex in periods) {
      const period = periods[periodIndex];
      const graphData: GraphData = {
        periodTimes: `${period.startTime} ${period.endTime}`,
        valueMap: {} as any,
        totalMap: {} as any,
        projectedValueMap: {} as any,
        projectedTotalMap: {} as any,
      };

      // Sum total and values for the group from group consumptions, also update sums across the periods
      for (const group of period.groups || []) {
        const groupCloneForDataKey: PeriodGroup = JSON.parse(JSON.stringify(group));
        if (pageView === "cost" && groupBy !== "metric") {
          delete groupCloneForDataKey.key.metric;
        }
        delete groupCloneForDataKey.key.replicas;
        let dataKey = Object.values(groupCloneForDataKey.key).join("-");

        // Set up keys in the maps
        if (!graphData.totalMap[dataKey]) {
          graphData.totalMap[dataKey] = 0;
        }
        if (!graphData.projectedTotalMap[dataKey]) {
          graphData.projectedTotalMap[dataKey] = 0;
        }
        if (!graphData.valueMap[dataKey]) {
          graphData.valueMap[dataKey] = 0;
        }
        if (!graphData.projectedValueMap[dataKey]) {
          graphData.projectedValueMap[dataKey] = 0;
        }

        for (const consumption of group.consumptions) {
          // TODO use total instead of charge, only say charge in the UI
          const total = consumption.total || 0;
          graphData.totalMap[dataKey] += total;
          const projectedTotal = consumption.projectedTotal || 0;
          let projectedTotalDiff = projectedTotal;
          if (projectedTotal > 0) {
            projectedTotalDiff = projectedTotal - total;
          }
          // TODO might rename this to something with diff and keep projectedTotal separate
          graphData.projectedTotalMap[dataKey] += projectedTotalDiff;

          totalSum += consumption.total || 0;
          projectedTotalSum += consumption.projectedTotal || 0;

          const value = consumption.value || 0;
          graphData.valueMap[dataKey] += value;
          const projectedValue = consumption.projectedValue || 0;
          let projectedValueDiff = projectedValue;
          if (projectedValue > 0) {
            projectedValueDiff = projectedValue - value;
          }
          // TODO might rename this to something with diff and keep projectedValue separate
          graphData.projectedValueMap[dataKey] += projectedValueDiff;
        }
      }

      // set total to 2 precision numbers
      for (const dataKey of Object.keys(graphData.totalMap)) {
        graphData.totalMap[dataKey] = formatPrice(graphData.totalMap[dataKey]);
        graphData.projectedTotalMap[dataKey] = formatPrice(graphData.projectedTotalMap[dataKey]);
      }

      // average the data by chargeable item units
      // TODO currently this relies on that each chargeable item which has the same metric value, also has the same unit
      for (const dataKey of Object.keys(graphData.valueMap)) {
        const unit = chartData.units.find((unit) => unit.dataKey === dataKey);
        if (!unit || !unit.metric) {
          continue;
        }

        let newValue: number = graphData.valueMap[dataKey] as number;
        let newProjectedValue: number = graphData.projectedValueMap[dataKey] as number;

        const cItem = cItems.find((c) => c.consumptionTags.metric === unit.metric);
        if (!cItem) {
          continue;
        }
        const cItemUnits: TChargeableItemUnit[] = cItem?.unit.split(" * ") as any;
        for (const cItemUnit of cItemUnits) {
          const processors = chargeableItemUnitProcessorMap[cItemUnit];
          for (const processor of processors) {
            newValue = processor(newValue, period.elapsedSeconds || 0, period.totalSeconds || 0, isMiB);
            newProjectedValue = processor(
              newProjectedValue,
              period.elapsedSeconds || 0,
              period.totalSeconds || 0,
              isMiB,
            );
          }
        }

        graphData.valueMap[dataKey] = newValue;
        graphData.projectedValueMap[dataKey] = newProjectedValue;
      }

      // fill empty keys' totals
      for (const dataKey of Object.keys(graphData.valueMap)) {
        if (!graphData.valueMap[dataKey]) {
          graphData.valueMap[dataKey] = 0;
        }
        if (!graphData.projectedValueMap[dataKey]) {
          graphData.projectedValueMap[dataKey] = 0;
        }
        if (!graphData.totalMap[dataKey]) {
          graphData.totalMap[dataKey] = 0;
        }
        if (!graphData.projectedTotalMap[dataKey]) {
          graphData.projectedTotalMap[dataKey] = 0;
        }
      }
      chartData.graphData.push(graphData);
    }

    for (const unit of chartData.units) {
      let totalSum = 0;
      let projectedTotalSum = 0;
      for (const graphData of chartData.graphData) {
        totalSum += graphData.totalMap[unit.dataKey];
        projectedTotalSum += graphData.projectedTotalMap[unit.dataKey];
      }
      unit.totalSum = formatPrice(totalSum);
      unit.projectedTotalSum = formatPrice(projectedTotalSum);
    }
  } catch (e) {}

  const totalCost =
    "$" + new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalSum);
  const totalProjectedCost =
    "$" +
    new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
      totalSum + projectedTotalSum,
    );

  let hasAnyFilter =
    orgFilter ||
    gvcFilters.length > 0 ||
    featureFilters.length > 0 ||
    locationFilters.length > 0 ||
    chargeableItemFilters.length > 0;
  if (!hasAnyFilter) {
    for (const [_, value] of Object.entries(tagFilters)) {
      if (value.length > 0) {
        hasAnyFilter = true;
      }
    }
  }

  const chartDataUnits = chartData.units.filter((unit) => unit.metric !== "replicas");

  return (
    <div style={{ width: 1400 }}>
      <div className="flex items-center" style={{ width: 1370 }}>
        <div className="text-2xl mt-2" style={{ width: 136 }}>
          {pageView === "cost" ? "Cost" : "Usage"}
        </div>
        <div className="flex items-end gap-4 mb-4">
          <div className="flex flex-col">
            <span>
              <FormLabel>Page View</FormLabel>
            </span>
            <Select
              options={[
                { label: "Cost", value: "cost" },
                { label: "Usage", value: "metric" },
              ]}
              onChange={(value) => setPageView(value)}
              value={pageView}
            />
          </div>
          <div className="flex flex-col">
            <span>
              <FormLabel>Group By</FormLabel>
            </span>
            <Select
              style={{ width: 120 }}
              options={
                pageView === "cost"
                  ? [
                      { label: "None", value: "account" },
                      { label: "Org", value: "org" },
                      { label: "GVC", value: "gvc" },
                      { label: "Location", value: "location" },
                      { label: "Chargable Item", value: "metric" },
                      { label: "Feature", value: "feature" },
                      ...features.map((feature) => ({ label: featureLabelMap[feature] || feature, value: feature })),
                    ]
                  : [
                      { label: "None", value: "account" },
                      { label: "Org", value: "org" },
                      { label: "GVC", value: "gvc" },
                      { label: "Location", value: "location" },
                      ...features.map((feature) => ({ label: featureLabelMap[feature] || feature, value: feature })),
                    ]
              }
              onChange={(value) => setGroupBy(value)}
              value={groupBy}
            />
          </div>
          <div className="flex flex-col">
            <span>
              <FormLabel>Time Step</FormLabel>
            </span>
            <Select
              style={{ width: 120 }}
              value={timeStep}
              options={[
                { label: "Hour", value: "hour" },
                { label: "Day", value: "day" },
                { label: "Week", value: "week" },
                { label: "Month", value: "month" },
              ]}
              onChange={(value) => {
                let newEndTime = moment().add(1, "day").startOf("day");
                let newStartTime = newEndTime.clone();
                if (value === "hour") {
                  newStartTime.subtract(1, "day");
                }
                if (value === "day") {
                  newStartTime.subtract(31, "day");
                }
                if (value === "week") {
                  newStartTime.subtract(5, "week");
                }
                if (value === "month") {
                  newStartTime.subtract(1, "year");
                }
                setStartISO(newStartTime.toISOString());
                setEndISO(newEndTime.toISOString());
                setTimeStep(value as any);
              }}
            />
          </div>
          <div className="flex flex-col">
            <span>
              <FormLabel>Time Range (UTC)</FormLabel>
            </span>
            <RangePicker
              size="small"
              clearIcon={false}
              fromISO={startISO}
              setFromISO={setStartISO}
              toISO={endISO}
              setToISO={setEndISO}
              showTime={timeStep === "hour"}
              showHour={timeStep === "hour"}
              showMinute={false}
              showSecond={false}
              allowEmpty={[false, false]}
              presets={
                timeStep === "hour"
                  ? getUsageHourPresets()
                  : timeStep === "day"
                  ? getUsageDayPreset()
                  : timeStep === "week"
                  ? getUsageWeekPreset()
                  : getUsageMonthPreset()
              }
              timezoneValue={Timezone.value}
            />
          </div>
          <div ref={dropdownRef}>
            <Dropdown
              open={isFiltersVisible}
              onOpenChange={handleDropdownOpenChange}
              dropdownRender={() => (
                <div
                  id="usage-filter-dropdown"
                  className="dropdown overflow-auto"
                  style={{ maxHeight: "calc(90svh - 120px)" }}
                  onClick={(e: any) => {
                    // Prevent the click event from propagating up to the document
                    e.stopPropagation();
                  }}
                >
                  <div className="dropdown-item">
                    <label>Features</label>
                    <Select
                      mode="multiple"
                      value={featureFilters}
                      placeholder={"All"}
                      options={features.map((f) => ({ label: featureLabelMap[f], value: f }))}
                      onChange={setFeatureFilters}
                    />
                  </div>
                  <div className="dropdown-item">
                    <label>Chargable Items</label>
                    <Select
                      mode="multiple"
                      value={chargeableItemFilters}
                      placeholder={"All"}
                      options={cItems.map((c) => ({ label: c.name, value: c.id }))}
                      onChange={setChargeableItemFilters}
                    />
                  </div>
                  <div className="dropdown-item">
                    <label>Orgs</label>
                    <Select
                      value={orgFilter}
                      placeholder={"All"}
                      onChange={setOrgFilter}
                      options={[{ label: "All Orgs", value: "" }, ...OrgNames.map((g) => ({ label: g, value: g }))]}
                    />
                  </div>
                  <div className="dropdown-item">
                    <span>
                      <label>GVCs</label>
                    </span>
                    <Select
                      mode={"multiple"}
                      value={gvcFilters}
                      placeholder={"All"}
                      onChange={setGVCFilters}
                      options={allGVCs.map((g) => ({ label: g, value: g }))}
                    />
                  </div>
                  <div className="dropdown-item">
                    <label>Locations</label>
                    <Select
                      mode={"multiple"}
                      value={locationFilters}
                      placeholder={"All"}
                      onChange={setLocationFilters}
                      options={allLocationss.map((g) => ({ label: g, value: g }))}
                    />
                  </div>
                  {[...features].map((tag) => (
                    <div className="dropdown-item">
                      <label>{featureLabelMap[tag] || tag}</label>
                      <Select
                        mode={"multiple"}
                        value={tagFilters[tag] || []}
                        placeholder={"All"}
                        onChange={(filters) => {
                          const _tagFilters = JSON.parse(JSON.stringify(tagFilters));
                          _tagFilters[tag] = filters;
                          setTagFilters(_tagFilters);
                        }}
                        options={(allTags[tag] || []).map((t) => ({ label: t, value: t }))}
                      />
                    </div>
                  ))}
                  <Divider />
                  <div className="dropdown-actions">
                    {hasAnyFilter ? (
                      <NGButton
                        size={"normal"}
                        variant="secondary"
                        text
                        onClick={() => {
                          const _tagFilters = JSON.parse(JSON.stringify(tagFilters));
                          for (const key of Object.keys(_tagFilters)) {
                            _tagFilters[key] = [];
                          }
                          setTagFilters(_tagFilters);
                          setGVCFilters([]);
                          setLocationFilters([]);
                          setFeatureFilters([]);
                          setChargeableItemFilters([]);
                          setIsFiltersVisible(false);
                        }}
                      >
                        Clear Filters
                      </NGButton>
                    ) : null}
                    <NGButton size={"normal"} variant="primary" onClick={() => setIsFiltersVisible(false)}>
                      Ok
                    </NGButton>
                  </div>
                </div>
              )}
              trigger={[]}
            >
              <NGButton
                size={"normal"}
                style={{ marginTop: "auto" }}
                variant={hasAnyFilter ? "primary" : "secondary"}
                outlined={!hasAnyFilter}
                renderIcon={(hover, props) =>
                  hasAnyFilter ? (
                    <Check {...props} size={18} />
                  ) : isFiltersVisible ? (
                    <ChevronUp {...props} size={18} />
                  ) : (
                    <ChevronDown {...props} size={18} />
                  )
                }
                onClick={() => setIsFiltersVisible((x) => !x)}
              >
                Filters
              </NGButton>
            </Dropdown>
          </div>
        </div>
        <span className="flex-grow" />
        <Dropdown
          trigger={["click"]}
          dropdownRender={() => (
            <Menu
              onClick={async ({ key }: any) => {
                UIData.updateLastActivityTimestamp();
                switch (key) {
                  case "view":
                    setIsViewing(true);
                    break;
                  case "export-json":
                    await exportJSON(false);
                    break;
                  case "export-json-detailed":
                    await exportJSON(true);
                    break;
                }
              }}
            >
              <Menu.Item disabled={!queryResponse} key={"view"} data-testid="action-view">
                View
              </Menu.Item>
              <Menu.SubMenu title={"Export"} key={"ignore"} data-testid="action-export">
                <Menu.Item key={"export-json"} data-testid="action-export-json">
                  JSON
                </Menu.Item>
                <Menu.Item key={"export-json-detailed"} data-testid="action-export-json-detailed">
                  JSON Detailed
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          )}
        >
          <NGButton
            size={"normal"}
            variant="primary"
            outlined
            className="mt-auto mb-4"
            renderIcon={(hover, props) => <ChevronDown {...props} size={18} />}
            loading={isLoading || isLoadingViewExport}
            disabled={isLoading || isLoadingViewExport}
            data-testid="usage-actions"
          >
            Actions
          </NGButton>
        </Dropdown>
      </div>

      <div style={{ width: 1370 }} className="mb-24 relative">
        {error ? <NGAlert type={"error"} message={error} /> : null}
        {!error ? (
          <>
            <div className={`relative ${!!queryResponse && Object.keys(queryResponse).length < 1 ? "usage-blur" : ""}`}>
              {/* {pageView === "metric" ? (
                <div className="absolute z-50" style={{ top: 13, right: 30 }}>
                  <Switch
                    checkedChildren={"GiB"}
                    unCheckedChildren={"MiB"}
                    checked={!isMiB}
                    onChange={(checked) => setIsMiB(!checked)}
                  />
                </div>
              ) : null} */}
              <ComposedChart
                className={`${isLoading ? "pointer-events-none" : ""}`}
                key={queryResponseKey}
                width={1370}
                height={450}
                data={chartData.graphData}
                barCategoryGap={"15%"}
                margin={{ left: 0, top: 50 }}
                barGap={chartDataUnits.length < 5 ? undefined : 0}
                onClick={(obj: any) => {
                  if (timeStep === "hour") return;
                  const label = obj?.activeLabel;
                  if (!label) return;
                  if (timeStep === "month" || timeStep === "week") {
                    setTimeStep("day");
                  } else if (timeStep === "day") {
                    setTimeStep("hour");
                  }

                  const startTime = moment(label.split(" ")[0]);
                  const endTime = moment(label.split(" ")[1]);
                  setStartISO(startTime.toISOString());
                  setEndISO(endTime.toISOString());
                }}
              >
                <CartesianGrid
                  vertical={false}
                  strokeDasharray="3 6"
                  fill={Theme.theme === "dark" ? "#333" : "#eee"}
                  fillOpacity={0.6}
                  stroke={Theme.theme === "dark" ? "#ccc" : "#888"}
                />
                {pageView === "cost" && projectedTotalSum > 0 ? (
                  <XAxis
                    label={{
                      value: `Total Projected Cost: ${totalProjectedCost}`,
                      position: "top",
                    }}
                    axisLine={false}
                    tickLine={false}
                    type={"number"}
                    xAxisId={"totalProjectedCost"}
                    orientation={"top"}
                    tickFormatter={(_v: string) => ""}
                  />
                ) : null}
                {pageView === "cost" ? (
                  <XAxis
                    label={{
                      value: `Total Cost: ${totalCost}`,
                      position: "top",
                    }}
                    axisLine={false}
                    tickLine={false}
                    type={"number"}
                    xAxisId={"totalCost"}
                    orientation={"top"}
                    tickFormatter={(_v: string) => ""}
                  />
                ) : null}
                <XAxis
                  tickFormatter={(value) =>
                    formatXLabel({
                      value: value,
                      timeStep: timeStep,
                    })
                  }
                  dataKey={"periodTimes"}
                  tickLine={false}
                  orientation={"bottom"}
                />
                <YAxis
                  label={{
                    value: pageView === "cost" ? "Cost" : "Cores",
                    offset: 20,
                    position: "top",
                  }}
                  tickFormatter={(value: any) => `${pageView === "cost" ? "$" : ""}${value}`}
                  axisLine={true}
                  tickLine={false}
                  type={"number"}
                  yAxisId="left"
                />
                {/* TODO show replicas as a line */}
                {/* {pageView === "metric" && groupBy !== "volume-sets" ? (
                  <YAxis
                    label={{
                      value: "Replicas",
                      offset: 20,
                      position: "top",
                    }}
                    axisLine={true}
                    tickLine={false}
                    type={"number"}
                    yAxisId="replicas"
                    hide={true}
                  />
                ) : null} */}
                <Tooltip
                  content={
                    <CustomTooltip
                      pageView={pageView}
                      groupBy={groupBy}
                      timeStep={timeStep}
                      chartData={chartData}
                      isMiB={isMiB}
                      setIsDisplayingTooltip={setIsDisplayingTooltip}
                    />
                  }
                />
                {pageView === "metric" ? (
                  <YAxis
                    label={{
                      value: "",
                      offset: 30,
                      position: "top",
                    }}
                    tickFormatter={(value) => {
                      try {
                        return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                          .format(value)
                          .split(".")[0];
                      } catch (e) {
                        return value;
                      }
                    }}
                    axisLine={true}
                    tickLine={false}
                    type={"number"}
                    yAxisId="right"
                    orientation="right"
                  />
                ) : null}
                <Tooltip />
                {chartDataUnits.map((unit, index) => {
                  return (
                    <Bar
                      key={unit.dataKey}
                      unit={pageView === "cost" ? "$" : ""}
                      dataKey={`${pageView === "cost" ? "totalMap" : "valueMap"}.${unit.dataKey}`}
                      fill={palette[index & palette.length]}
                      yAxisId={pageView === "cost" || unit.metric === "cpu" ? "left" : "right"}
                      stackId={unit.dataKey}
                      hide={legendFilters.length > 0 && !legendFilters.includes(unit.dataKey)}
                    />
                  );
                })}
                {/* TODO show replicas */}
                {/* {pageView === "metric" && groupBy !== "volume-sets"
                  ? chartDataUnits.map((unit, index) => {
                      return (
                        <Line
                          key={unit.dataKey + "replicas"}
                          unit={""}
                          dataKey={`values.${unit.dataKey}`}
                          stroke={palette[index & palette.length]}
                          fill={palette[index & palette.length]}
                          yAxisId={"replicas"}
                        />
                      );
                    })
                  : null} */}
                {chartDataUnits.map((unit, index) => {
                  return (
                    <Bar
                      key={unit.dataKey + "projected"}
                      unit={pageView === "cost" ? "$" : ""}
                      dataKey={`${pageView === "cost" ? "projectedTotalMap" : "projectedValueMap"}.${unit.dataKey}`}
                      stroke={palette[index & palette.length]}
                      fill="transparent"
                      yAxisId={pageView === "cost" || unit.metric === "cpu" ? "left" : "right"}
                      stackId={unit.dataKey}
                      hide={legendFilters.length > 0 && !legendFilters.includes(unit.dataKey)}
                    />
                  );
                })}
                <Legend
                  content={<LegendPayloadSaver setPayload={setLegendPayload} />}
                  wrapperStyle={{
                    bottom: -10,
                    right: 0,
                  }}
                />
              </ComposedChart>
              <CustomLegend
                filters={legendFilters}
                groupBy={groupBy}
                onClick={toggleLegendFilter}
                payload={legendPayload}
              />
            </div>
          </>
        ) : null}
        {!error && isLoading ? (
          <div className="absolute w-full flex items-center justify-center top-0 mt-64">
            <Loader reason={"fetching billing details"} />
          </div>
        ) : null}
      </div>
      <NGAlert
        className={clsx({ invisible: isDisplayingTooltip })}
        style={{ width: 650, zIndex: 1 }}
        type={"info"}
        message={"All charges are estimated, the final bill is the authoritative accurate source."}
      />
      {isViewing ? (
        <ViewModal
          filename={`usage-${AccountUUID}-${endISO}-${timeStep}.json`}
          onClose={() => setIsViewing(false)}
          title={"Cost & Usage"}
          visible={isViewing}
          initialFormat={"json"}
          object={queryResponse}
        />
      ) : null}
    </div>
  );
};

export default observer(BillingUsageNGRaw);
