import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../../services/cpln";
import { DryRunAlert } from "../../../dryRunAlert";
import { NGButton } from "../../../../../newcomponents/button/Button";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { MoreHorizontal } from "react-feather";
import { NGProviderBrowser } from "../../../ngProviderBrowser";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../../newcomponents/input/input";
import { NGSelect } from "../../../../../newcomponents/select/ngselect";

type RouteParams = "index";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderLinodeNodePoolRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [browserKey, setBrowserKey] = React.useState("");
  const formData = useNGFormContext();

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const l = mk8sDraft.provider_linode;
  const np = l.nodePools.find((np) => np.index === index);

  if (!np) {
    return <Navigate to={`${pathname.split("/-node-pools")[0]}/-node-pools`} />;
  }

  const pre = `linode.nodePools[${index}].`;

  return (
    <>
      <div>
        <NGFormElement
          name={`${pre}name`}
          label={np.name.label}
          value={np.name.value}
          onChange={np.name.setValue}
          required
          error={!l.isNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
        />

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}serverType`}
            label={np.serverType.label}
            value={np.serverType.value}
            onChange={np.serverType.setValue}
            required={np.serverType.isRequired}
            error={np.serverType.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => setBrowserKey("server-types"),
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}serverType`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.serverType.setValue}
              path={`spec.provider.linode.nodePools[${index}].serverType`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}overrideImage`}
            label={np.overrideImage.label}
            value={np.overrideImage.value}
            onChange={np.overrideImage.setValue}
            required={np.overrideImage.isRequired}
            error={np.overrideImage.error}
            innerButtons={[
              {
                title: "Browse",
                onClick: () => setBrowserKey("images"),
                render: () => <MoreHorizontal />,
              },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}overrideImage`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.overrideImage.setValue}
              path={`spec.provider.linode.nodePools[${index}].overrideImage`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}subnetId`}
            label={np.subnetId.label}
            value={np.subnetId.value}
            onChange={np.subnetId.setValue}
            required={np.subnetId.isRequired}
            error={np.subnetId.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("subnets"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}subnetId`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.subnetId.setValue}
              path={`spec.provider.linode.nodePools[${index}].subnetId`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}minSize`}
            label={np.minSize.label}
            value={np.minSize.value}
            onChange={np.minSize.setValue}
            required={np.minSize.isRequired}
            error={np.minSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}minSize`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.minSize.setValue}
              path={`spec.provider.linode.nodePools[${index}].minSize`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}maxSize`}
            label={np.maxSize.label}
            value={np.maxSize.value}
            onChange={np.maxSize.setValue}
            required={np.maxSize.isRequired}
            error={np.maxSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}maxSize`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.maxSize.setValue}
              path={`spec.provider.linode.nodePools[${index}].maxSize`}
            />
          </div>
        </div>
        <NGInputListMst
          label="Labels"
          className="mb-8"
          data={np.labels}
          firstInput={(item) => (
            <NGInput
              required
              invalid={!item.firstValue}
              ignoreTouched
              placeholder="Key"
              value={item.firstValue}
              onChange={(e) => item.setFirstValue(e.target.value)}
            />
          )}
          secondInput={(item) => (
            <NGInput
              required
              invalid={!item.secondValue}
              ignoreTouched
              placeholder="Value"
              value={item.secondValue}
              onChange={(e) => item.setSecondValue(e.target.value)}
            />
          )}
        />
        <NGInputListMst
          label="Taints"
          data={np.taints}
          firstInput={(item) => (
            <NGInput
              required
              invalid={!item.firstValue}
              ignoreTouched
              placeholder="Key"
              value={item.firstValue}
              onChange={(e) => item.setFirstValue(e.target.value)}
            />
          )}
          secondInput={(item) => (
            <NGInput
              required
              invalid={!item.secondValue}
              ignoreTouched
              placeholder="Value"
              value={item.secondValue}
              onChange={(e) => item.setSecondValue(e.target.value)}
            />
          )}
          thirdInput={(item) => (
            <NGSelect
              placeholder="Effect"
              invalid={!item.thirdValue}
              ignoreTouched
              value={item.thirdValue}
              onChange={(value) => item.setThirdValue(value)}
              options={[
                { label: "NoSchedule", value: "NoSchedule" },
                { label: "PreferNoSchedule", value: "PreferNoSchedule" },
                { label: "NoExecute", value: "NoExecute" },
              ]}
            />
          )}
        />
        <NGButton
          style={{ width: 220 }}
          variant={"danger"}
          outlined
          onClick={() => {
            l.removeNodePoolAt(np.index);
            navigate(`${pathname.split("/-node-pools")[0]}/-node-pools`);
          }}
        >
          Delete Node Pool
        </NGButton>
      </div>
      {browserKey === "server-types" ? (
        <NGProviderBrowser
          // TODO pagination for all discovery endpoints with pages
          title="Browse Linode Server Types"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "server-types",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          sortable
          onOk={(value) => np.serverType.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Cores", "Memory", "Disk", "GPUs", "Cost per Month"]}
          rightAlign={["Cost per Month"]}
          distinctFilters={["Cores", "GPUs", "Memory"]}
          getData={(item: any) => {
            let priceObject = item.region_prices.find((rp) => rp.id === l.region.value);
            if (!priceObject) {
              priceObject = item.price;
            }
            const price = priceObject.monthly;
            const cost = price.toLocaleString("en-US", { style: "currency", currency: "USD" });

            let memoryAmount = item.memory;
            let memoryUnit = "MB";
            if (memoryAmount >= 1024 * 10) {
              memoryAmount /= 1024;
              memoryUnit = "GB";
            }

            let diskAmount = item.disk;
            let diskUnit = "MB";
            if (diskAmount >= 1024 * 10) {
              diskAmount /= 1024;
              diskUnit = "GB";
            }

            return [
              //
              item.id,
              item.label,
              item.vcpus,
              [item.memory, `${memoryAmount}${memoryUnit}`],
              [item.disk, `${diskAmount}${diskUnit}`],
              item.gpus,
              [price, cost],
            ];
          }}
        />
      ) : null}
      {browserKey === "images" ? (
        <NGProviderBrowser
          title="Browse Linode Images"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "images",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          onOk={(value) => np.overrideImage.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Description"]}
          getData={(item: any) => [item.id, item.id, item.label]}
        />
      ) : null}
      {browserKey === "subnets" ? (
        <NGProviderBrowser
          title="Browse Linode Subnets"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "subnets",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.data;
          }}
          onOk={(value) => np.subnetId.setValue(String(value as string))}
          onClose={() => setBrowserKey("")}
          labels={["Label", "Id", "IPv4"]}
          getData={(item: any) => [item.id, item.label, item.id, item.ipv4]}
        />
      ) : null}
    </>
  );
};

export const Mk8sCreateProviderLinodeNodePool = observer(Mk8sCreateProviderLinodeNodePoolRaw);
