import * as React from "react";
import { observer } from "mobx-react-lite";
import "./releaseList.css";
import { ReleaseInstance } from "../types/release";
import { request } from "../../../services/cpln";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { Table } from "../../../newcomponents/table/table";
import NGAlert from "../../../newcomponents/alert";
import { InternalLink } from "../../../newcomponents/table/components/InternalLink";

const MarketplaceReleaseListRaw: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [releases, setReleases] = React.useState<ReleaseInstance[]>([]);

  React.useEffect(() => {
    fetchReleases();
  }, []);

  async function fetchReleases() {
    try {
      setIsLoading(true);
      setError("");
      const { data } = await request({ service: "marketplace", url: `/org/${ConsoleContext.org}/release` });
      setReleases(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e.response?.data?.error;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      setError(errorMessage);
    }
  }

  if (error) {
    return <NGAlert type={"error"} title="Error" message={error} />;
  }

  return (
    <>
      <Table<ReleaseInstance>
        tableId="marketplace-apps"
        data={releases}
        enableFilter
        title={"Apps"}
        columns={[
          {
            id: "name",
            label: "Name",
            cell: (p) => {
              const item = p.row.original;
              return (
                <div className="flex items-center" style={{ height: 56 }}>
                  <InternalLink label={item.name} to={item.id} />
                </div>
              );
            },
          },
          { id: "gvc", label: "GVC" },
          // TODO
          // { id: "template", label: "Template" },
        ]}
        isLoading={isLoading}
      />
    </>
  );
};

export const MarketplaceReleaseList = observer(MarketplaceReleaseListRaw);
