import * as React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { DomainMobx, DomainModel, DomainStatus } from "../../mst/kinds/domain";
import { DomainDetail } from "./detail";
import { IncorrectItem } from "../../components/generic/IncorrectItem/incorrectItem";
import { automatedRequest, request, resourceLink } from "../../services/cpln";
import { Loader } from "../../components/layout/loader";
import { DetailContext } from "../../components/detail/detailContext";
import { DOMAIN_UPDATE_INTERVAL_MS, IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { Helmet } from "react-helmet";

type RouteParams = "domain";
const DomainDetailRouteRaw: React.FC = () => {
  const [domain, setDomain] = React.useState<DomainMobx>({ version: -1 } as any);
  const { domain: domainName } = useParams<RouteParams>();
  const [status, setStatus] = React.useState<DomainStatus>(null as any);
  const [isLoadingStatus, setIsLoadingStatus] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isIncorrect, setIsIncorrect] = React.useState(false);

  React.useEffect(() => {
    fetchItem();
  }, [domainName]);

  React.useEffect(() => {
    fetchDomainStatus(false);
    let intervalId = setInterval(() => {
      fetchDomainStatus(true);
    }, DOMAIN_UPDATE_INTERVAL_MS);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [domain]);

  async function fetchItem() {
    try {
      setIsLoading(true);
      const { data } = await request({ url: resourceLink("domain", domainName) });
      const domainInstance = DomainModel.create(data);
      domainInstance.setJson(data);
      setDomain(domainInstance);
      setIsLoading(false);
    } catch (e) {
      setIsIncorrect(true);
      setIsLoading(false);
    }
  }

  async function fetchDomainStatus(automated: boolean) {
    try {
      setIsLoadingStatus(true);
      const url = resourceLink("domain", domainName);
      const requestToAwait = automated ? automatedRequest({ url }) : request({ url });
      const { data } = await requestToAwait;
      setStatus(data.status || null);
      setIsLoadingStatus(false);
    } catch (e) {
      setIsLoadingStatus(false);
      setStatus(null as any);

      if (e.response?.status === 404) {
        setIsIncorrect(true);
        setDomain(null as any);
      }
    }
  }

  if (isLoading) {
    return <Loader fullScreen reason={"fetching domain to show detail"} />;
  }

  if (isIncorrect) {
    return <IncorrectItem kind={"domain"} canCreate={true} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_DEPLOYMENT_ENV_TEST ? `TEST | ` : ""}
          {domain.name} - Domain - From many clouds, one™
        </title>
      </Helmet>
      <DetailContext.Provider value={{ item: domain, fetchItem }}>
        <DomainDetail status={status} isLoadingStatus={isLoadingStatus} />
      </DetailContext.Provider>
    </>
  );
};

export const DomainDetailRoute = observer(DomainDetailRouteRaw);
