import * as React from "react";
import { ViewModal } from "../../../components/modals/viewModal";
import { WorkloadMobx } from "../../../mst/kinds/workload";
import { Switch as AntSwitch } from "antd";
import { request, resourceLink } from "../../../services/cpln";
import { Activity, ArrowLeft } from "react-feather";
import { observer } from "mobx-react-lite";
import { VolumeSetMobx, VolumeSetModel, VolumeSetStatusVolumeMobx } from "../../../mst/kinds/volumeset";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FormLabel } from "../../../components/forms/formLabel";
import clsx from "clsx";
import { Message } from "./message";
import { NGButton } from "../../../newcomponents/button/Button";
import { CommandModal_ConfirmReplicaTermination } from "../commands/commandModal_comfirmReplicaTermination";
import { useCommandsContext } from "../../../components/detail/commandsContext";
import { Deployment } from "../../../schema/types/workload/deployment";

interface ConfirmReplicaTerminationProps {
  location: string;
  replica: string;
}
interface Props {
  workload: WorkloadMobx;
  deployments: Deployment[];
}

const StatefulReplicaRaw: React.FC<Props> = ({ workload, deployments }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { deployment: deploymentParam, replica: replicaParamString, replicaIndex: replicaIndexString } = useParams<{
    deployment: string;
    replica: string;
    replicaIndex: string;
  }>();
  const replicaParam = decodeURI(replicaParamString || "");
  const replicaIndex = Number(replicaIndexString);

  const { commands, fetchCommands } = useCommandsContext();

  const updatedTimeoutIdRef = React.useRef<any>(null);
  const [isViewing, setIsViewing] = React.useState(false);
  const [terminateReplica, setTerminateReplica] = React.useState<ConfirmReplicaTerminationProps | undefined>(undefined);

  function getDefaultViewVolumes() {
    return String(localStorage.getItem("stateful-workload-view-volumes") || "false") === "true";
  }
  const [viewVolumes, _setViewVolumes] = React.useState<boolean>(getDefaultViewVolumes());

  const [isVolumeSetsReady, setIsVolumeSetsReady] = React.useState<boolean>(false);
  const [volumesets, setVolumesets] = React.useState<VolumeSetMobx[]>([]);

  function setViewVolumes(value: boolean) {
    _setViewVolumes(value);
    localStorage.setItem("stateful-workload-view-volumes", String(value));
  }

  const deployment = deployments.find((deployment) => deployment.name === deploymentParam);
  const replica = deployment?.status?.versions?.find((version) => version.name === replicaParam);

  React.useEffect(() => {
    return () => {
      if (updatedTimeoutIdRef.current) {
        clearTimeout(updatedTimeoutIdRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    // TODO do we update the page when a patch is applied?
    fetchVolumesets();
  }, []);

  if (!replica) {
    return (
      <div>
        Replica is not found, <Link to={pathname.split("/").slice(0, -5).join("/")}>go back</Link>
      </div>
    );
  }

  async function fetchVolumesets() {
    const links: string[] = [];
    for (const container of workload.spec.containers) {
      for (const volume of container.volumes.filter((v) => v.uri.startsWith("cpln://volumeset/"))) {
        const name = volume.uri.split("cpln://volumeset/")[1];
        const link = resourceLink("volumeset", name);
        if (!links.includes(link)) {
          links.push(link);
        }
      }
    }
    const _volumesets: VolumeSetMobx[] = [];
    for (const link of links) {
      try {
        const { data: volumeset } = await request({ url: link });
        _volumesets.push(VolumeSetModel.create(volumeset));
      } catch (e) {
        console.error("failed to fetch and create mobx instance for volumeset " + link);
      }
    }
    setVolumesets(_volumesets);
    setIsVolumeSetsReady(true);
  }

  const hasActiveStopCommand = commands.find(
    (command) =>
      command.type === "stopReplica" &&
      command.spec?.location === deploymentParam &&
      command.lifecycleStage === "running"
  );

  return (
    <>
      {isViewing ? (
        <ViewModal
          kind={"deployment"}
          link={workload.selfLink + "/deployment"}
          onClose={() => setIsViewing(false)}
          title={workload.name + " Deployments"}
          visible={isViewing}
          filename={workload.name + "-deployments"}
        />
      ) : null}
      {!!terminateReplica ? (
        <CommandModal_ConfirmReplicaTermination
          location={terminateReplica.location}
          replica={terminateReplica.replica}
          onClose={() => setTerminateReplica(undefined)}
          onDone={() => {
            setTerminateReplica(undefined);
            fetchCommands();
          }}
          visible={!!terminateReplica}
        />
      ) : null}
      <div>
        <div className={"mb-4"}>
          <div className="border-b mb-4">
            <div className="flex items-center mb-4">
              <button
                className="ngfocus color-link"
                onClick={() => navigate(pathname.split("/").slice(0, -5).join("/"))}
              >
                <ArrowLeft className="mr-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
                <span className="text-sm">Back to Deployments</span>
              </button>
            </div>
            <div className="flex items-start gap-4 mb-4">
              <div className="flex flex-col">
                <FormLabel>Replica</FormLabel>
                <div className={`font-medium flex items-center gap-1`}>
                  <span>{replica.name}</span>
                  <Activity className={`feather-icon ${replica.ready ? "color-action" : "color-danger"}`} />
                </div>
              </div>
              <div className="flex flex-col">
                <FormLabel>Location</FormLabel>
                <div className={`font-medium`}>{deploymentParam}</div>
              </div>
              <div className="mb-4 flex items-center">
                <div className="mr-2">
                  <FormLabel>View Volumes</FormLabel>
                </div>
                <AntSwitch
                  data-testid="switch-capacity-ai"
                  checked={viewVolumes}
                  onChange={setViewVolumes}
                  size={"small"}
                  disabled={!isVolumeSetsReady}
                />
              </div>
              <div className="flex-grow" />
              {replicaParamString &&
              deploymentParam &&
              deployments.find((deployment) => deployment.name === deploymentParam)?.status?.remote ? (
                <NGButton
                  size={"small"}
                  variant={"secondary"}
                  onClick={() => setTerminateReplica({ location: deploymentParam, replica: replicaParamString })}
                >
                  Restart Replica
                </NGButton>
              ) : null}
            </div>
          </div>
          <Message
            messages={[replica.message || "", ...(hasActiveStopCommand ? [`Replica is being restarted`] : [])]}
            // TODO remove logsLink and eventsLink requirement
            logsLink={pathname.replace("deployment", "-logs?trigger=true")}
            eventsLink={pathname.replace("deployment", "-events")}
          />
          <div className={`flex items-center px-4 py-1 font-semibold table-labels border text-sm`}>
            <div className="w-2/12">Container</div>
            <div className="w-3/12">Image</div>
            <div className="w-7/12 flex items-center">
              <div className="flex-1 flex-grow">Status</div>
              <div className="flex-1 flex-grow">Reserved CPU</div>
              <div className="flex-1 flex-grow">Reserved Memory</div>
              <div className="flex-1 flex-grow">Volume Size</div>
            </div>
          </div>
          {Object.values(replica.containers || {}).map((container, containerIndex) => {
            // storage size calculation
            const containerVolumeSets = volumesets.filter((vs) => {
              for (const c of workload.spec.containers) {
                if (c.name !== container.name) {
                  continue;
                }
                if (c.volumes.some((v) => v.uri.includes(`cpln://volumeset/${vs.name}`))) {
                  return true;
                }
              }
              return false;
            });

            const containerVolumes: VolumeSetStatusVolumeMobx[] = [];

            for (const volumeSet of containerVolumeSets) {
              for (const location of volumeSet.status.locations) {
                if (location.name !== deploymentParam) {
                  continue;
                }
                for (const volume of location.volumes) {
                  if (volume.index !== replicaIndex) {
                    continue;
                  }
                  containerVolumes.push(volume);
                }
              }
            }

            let containerStorageSize = 0;
            for (const volume of containerVolumes) {
              containerStorageSize += volume.currentSize;
            }

            return (
              <div
                key={container?.name || "" + containerIndex}
                className={clsx("py-2 flex flex-col border border-t-0")}
              >
                <div className="flex items-center px-4">
                  <div className="w-2/12 flex items-center gap-1">
                    <Link
                      className="color-link"
                      to={`${pathname.split("/").slice(0, -6).join("/") + "/container/" + container.name}`}
                    >
                      {container.name}
                    </Link>
                    <Activity className={`feather-icon ${container.ready ? "color-action" : "color-danger"}`} />
                  </div>
                  <div className="w-3/12 truncate pr-2">{container.image}</div>
                  <div className="w-7/12 flex items-center">
                    <div className={`flex-1 flex-grow ${container.ready ? "color-action" : "color-danger"}`}>
                      {container.ready ? "Ready" : "Not Ready"}
                    </div>
                    <div className="flex-1 flex-grow">{container.resources?.cpu}m</div>
                    <div className="flex-1 flex-grow">{container.resources?.memory}Mi</div>
                    <div className="flex-1 flex-grow">{containerStorageSize}GB</div>
                  </div>
                </div>
                <Message
                  messages={[container?.message || ""]}
                  isHealthy={container.ready}
                  logsLink={pathname.split("/deployment/")[0] + "/-logs?trigger=true"}
                  eventsLink={pathname.split("/deployment/")[0] + "/-events"}
                  className={`pl-4 mb-2`}
                />
                {isVolumeSetsReady && viewVolumes && containerVolumes.length < 1 ? (
                  <div className="text-sm pl-4">No volumes attached to this container</div>
                ) : null}
                <div className={`${containerVolumes.length > 0 && viewVolumes ? "block" : "hidden"} py-2 mx-4 `}>
                  <div
                    className={`flex items-center px-4 py-1 font-semibold bg-table-label-drop border border-b-0 text-sm`}
                  >
                    <div className="w-5/12">Volume Set</div>
                    <div className="w-7/12 flex items-center">
                      <div className="flex-1 flex-grow">Lifecycle</div>
                      <div className="flex-1 flex-grow" />
                      <div className="flex-1 flex-grow" />
                      <div className="flex-1 flex-grow">Volume Size</div>
                    </div>
                  </div>
                  {containerVolumes.map((containerVolume, containerVolumeIndex) => (
                    <div
                      key={String(containerVolume.index) + containerVolumeIndex}
                      className={`flex items-center px-4 border-r border-l ${
                        containerVolumeIndex === containerVolumes.length - 1 ? "border-b" : ""
                      }`}
                    >
                      <div className="w-5/12 truncate pr-2">
                        <Link className="color-link" to={`/console` + containerVolume.volumeSet.selfLink}>
                          {containerVolume.volumeSet.name}
                        </Link>
                      </div>
                      <div className="w-7/12 flex items-center">
                        <div
                          className={`gap-2 flex-1 flex-grow ${
                            containerVolume.lifecycle === "bound" ? "color-action" : ""
                          }`}
                        >
                          {containerVolume.lifecycle?.slice(0, 1).toUpperCase()}
                          {containerVolume.lifecycle?.slice(1)}
                        </div>
                        <div className="flex-1 flex-grow"></div>
                        <div className="flex-1 flex-grow"></div>
                        <div className="flex-1 flex-grow">{containerVolume.currentSize}GB</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export const StatefulReplica = observer(StatefulReplicaRaw);
