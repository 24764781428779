import * as React from "react";
import { StringModel } from "../../mobxDataModels/stringModel";
import { observer } from "mobx-react-lite";
import { notification } from "antd";
import { GroupMobx } from "../../mst/kinds/group";
import { FormButtons } from "../../components/forms/formButtons";
import { useDetailContext } from "../../components/detail/detailContext";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { TagLinksTable } from "../../components/detail/tagLinksTable";
import { tagLinkUrlPrefixes } from "../../services/utils";

interface Props {
  group: GroupMobx;
}
const InfoRaw: React.FC<Props> = ({ group }) => {
  const { fetchItem } = useDetailContext();
  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const descriptionRef = React.useRef(StringModel.create({ label: "Description", initialValue: group.description }));

  React.useEffect(() => {
    PromptContext.setWhen(descriptionRef.current.isDirty || isLoading);
  }, [descriptionRef.current.isDirty, isLoading]);

  useCleanPrompt();

  React.useEffect(() => {
    let res = false;
    if (descriptionRef.current.isDirty) res = true;
    setIsDirty(res);
  }, [descriptionRef.current.isDirty]);

  async function reset() {
    setIsLoading(true);
    descriptionRef.current.reset();
    setIsLoading(false);
  }

  async function save() {
    try {
      setIsLoading(true);
      const description = descriptionRef.current.value || null;
      const body: any = {
        description,
      };
      await group.patch(body);
      descriptionRef.current.confirm();
      if (!descriptionRef.current.value) {
        descriptionRef.current.setInitialValue(group.name);
      }
      notification.success({
        message: "Success",
        description: "Updated group",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <>
      <div>
        <NGFormElement
          name="description"
          label={descriptionRef.current.label}
          value={descriptionRef.current.value}
          onChange={descriptionRef.current.setValue}
        />
        <TagLinksTable
          tableId="group-info-tag-links"
          tags={Object.entries(group.tags || {})
            .map(([tagKey, tagValue]) => ({ key: tagKey, value: (tagValue as string) || "" }))
            .filter((t) => tagLinkUrlPrefixes.some((p) => t.value.startsWith(p)))}
          styles={{ header: { marginBottom: 0 } }}
        />
        <FormButtons
          onReset={reset}
          onSave={save}
          resetDisabled={isLoading || !isDirty}
          saveDisabled={isLoading || !isDirty}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export const Info = observer(InfoRaw);
