import { v4 as uuidv4 } from "uuid";
import { getParent, Instance, types } from "mobx-state-tree";
import { PortModel } from "../../mobxDataModels/portModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { DomainDraftMobx, InvalidReason } from "./domain.draft";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { SelectModel } from "../../mobxDataModels/selectModel";

export const DomainPortRouteModel = types
  .model({
    id: types.optional(types.string, () => uuidv4()),
    method: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Route Method",
        initialValue: "prefix",
        options: [
          { label: "Prefix", value: "prefix" },
          { label: "Regex", value: "regex" },
        ],
      })
    ),
    prefix: types.optional(StringModel, () =>
      StringModel.create({ label: "Prefix", isRequired: true, initialValue: "/", validationKey: "domainRoutePrefix" })
    ),
    regex: types.optional(StringModel, () => StringModel.create({ label: "Regex" })),
    replacePrefix: types.optional(StringModel, () =>
      StringModel.create({ label: "Replace Prefix", validationKey: "domainRoutePrefix" })
    ),
    hostPrefix: types.optional(StringModel, () =>
      StringModel.create({ label: "Host Prefix", validationKey: "domainRouteHostPrefix" })
    ),
    portInput: types.optional(StringModel, () => PortModel.create({ label: "Port" })),
    portOptions: types.array(types.string),
    workloadName: types.optional(types.string, ""),
    portType: types.optional(types.enumeration(["input", "select"]), "input"),
  })
  .views((self) => ({
    get workloadLink(): string {
      const parent: DomainDraftMobx = getParent(self, 4);
      if (!parent) {
        return "";
      }
      const { absolute } = ngParseLink(self.workloadName, { gvc: parent.routeGvcName, kind: "workload" });
      return absolute;
    },
  }))
  .actions((self) => ({
    setWorkloadName(value: string) {
      self.workloadName = value;
    },
    setPortOptions(options: string[]) {
      self.portOptions.replace(options);
    },
    setPortType(type: "input" | "select") {
      self.portType = type;
    },
  }))
  .actions((self) => ({
    apply(route: DomainPortRoute) {
      self.method.setInitialValue(route.regex ? "regex" : "prefix");
      self.prefix.setInitialValue(route.prefix || "");
      self.regex.setInitialValue(route.regex || "");
      self.replacePrefix.setInitialValue(route.replacePrefix || "");
      self.hostPrefix.setInitialValue(route.hostPrefix || "");
      self.portInput.setInitialValue(route.port ? String(route.port) : "");
      const options: string[] = [];
      if (route.port) {
        options.push(String(route.port));
        self.setPortOptions(options);
      }
      const { name: workloadName } = ngParseLink(route.workloadLink, { kind: "workload" });
      self.workloadName = workloadName;
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.workloadName) {
        res = false;
      }
      if (self.method.value === "prefix" && !self.prefix.isValid) {
        res = false;
      }
      if (!self.replacePrefix.isValid) {
        res = false;
      }
      if (!self.hostPrefix.isValid) {
        res = false;
      }
      if (self.portType === "input") {
        if (!self.portInput.isValid) {
          res = false;
        }
      }
      return res;
    },
    get invalidReason(): InvalidReason {
      if (!self.workloadName) {
        return { code: 1, type: "error", message: "A route requires a workload to be set." };
      }
      if (self.method.value === "prefix" && !self.prefix.isValid) {
        // TODO can be invalidReason from prefix itself
        return { code: 1, type: "error", message: "A route requires a prefix." };
      }
      if (!self.replacePrefix.isValid) {
        return { code: 1, type: "error", message: "A route requires a valid replace prefix." };
      }
      if (!self.hostPrefix.isValid) {
        return { code: 1, type: "error", message: "A route requires a valid host prefix" };
      }
      if (self.portType === "input") {
        if (!self.portInput.isValid) {
          // TODO can be invalidReason from prefix itself
          return { code: 1, type: "error", message: "Port is not valid for the PORT" };
        }
      }
      return { code: 0, type: "info", message: "" };
    },
    get asObject() {
      const obj: DomainPortRoute = {
        workloadLink: self.workloadLink,
      };
      if (self.method.value === "prefix") {
        obj.prefix = self.prefix.value;
      } else {
        obj.regex = self.regex.value;
      }
      if (self.replacePrefix.value) {
        obj.replacePrefix = self.replacePrefix.value;
      }
      if (self.hostPrefix.value) {
        obj.hostPrefix = self.hostPrefix.value;
      }
      if (self.portInput.value) {
        obj.port = Number(self.portInput.value);
      }
      return obj;
    },
  }));
export interface DomainPortRouteMobx extends Instance<typeof DomainPortRouteModel> {}
export interface DomainPortRoute {
  prefix?: string;
  regex?: string;
  workloadLink: string;
  replacePrefix?: string;
  hostPrefix?: string;
  port?: number;
}
