import * as React from "react";
import { useLocation } from "react-router-dom";
import { User } from "../mobxStores/user/user";
import { nameOfKind, pluralNameOfKind } from "../services/cpln";

// const validStates = [
//   "checkingLocalOrg",
//   "noSelectedOrg",
//   "noOrg",
//   "checkingLocalGVC",
//   "noSelectedGVC",
//   "noGVC",
//   "hasContext",
// ];
type BreadcrumbsContextType = [BreadcrumbItem[], (x: BreadcrumbItem[]) => void, (userEmail: string) => void];
export function useInitBreadcrumbs(): BreadcrumbsContextType {
  const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>([]);

  function setUserEmail(email: string) {
    if (breadcrumbs.length >= 3) {
      breadcrumbs[2].content = email;
      setBreadcrumbs(breadcrumbs);
    }
  }

  return [breadcrumbs, setBreadcrumbs, setUserEmail];
}

export const BreadcrumbsContext = React.createContext<BreadcrumbsContextType>(null as any);

export function useBreadcrumbs(): BreadcrumbsContextType {
  const { pathname } = useLocation();
  const [breadcrumbs, setBreadcrumbs, setUserEmail] = React.useContext(BreadcrumbsContext);

  React.useEffect(() => {
    if (!User.isLoggedIn) {
      setBreadcrumbs([]);
    }
    setBreadcrumbs(getBreadcrumbs(pathname));
  }, [pathname, User.isLoggedIn]);

  return [breadcrumbs, setBreadcrumbs, setUserEmail];
}

export interface BreadcrumbItem {
  content: string;
  url?: string;
  debug: {
    index: number;
  };
}

export function getBreadcrumbs(pathname: string): BreadcrumbItem[] {
  let res: { content: string; url?: string; debug: { index: number } }[] = [];
  if (pathname.startsWith("/console/org/")) {
    const sections = pathname
      .split("/-")[0]
      .split("/console")[1]
      .split("/")
      .filter(Boolean)
      .filter((s) => !s.includes("permissions"));
    if (sections.length < 2) return res;
    let org = "";
    let gvc = "";
    let isGvc = false;
    let isQuota = false;
    let isImage = false;
    const isWorkloadOptions = pathname.includes("/-options/");
    const isCreateForm = pathname.includes("/-create/");
    const isOrgExternalLogs = pathname.includes("/-externallogs/");
    for (let indexString in sections) {
      const index = Number(indexString);
      const section = sections[indexString];
      switch (index) {
        case 0:
          // org kind
          continue;
        case 1:
          // org name
          org = section;
          res.push({ content: section, debug: { index: index } });
          if (sections.length > 2) {
            res[0].url = `/org/${org}`;
          }
          continue;
        case 2:
          // kind name
          isGvc = section === "gvc";
          isQuota = section === "quota";
          isImage = section === "image" || section === "repository";

          let breadcrumb: any = { content: isGvc ? nameOfKind(section) : pluralNameOfKind(section) };
          // only add url if it is not gvc
          if (!isGvc && sections.length > 3) {
            breadcrumb.url = `/org/${org}/${section}`;
          }
          const isAuditTrailPage = section === "audittrail";
          const isLogsPage = section === "logs";
          const isUsagePage = section === "usage";
          if (isAuditTrailPage) {
            breadcrumb = { content: "Audit Trails" };
          }
          if (isLogsPage) {
            breadcrumb = { content: "Logs" };
          }
          if (isUsagePage) {
            breadcrumb = { content: "Usage" };
          }
          if (isOrgExternalLogs) {
            continue;
          }
          res.push({ ...breadcrumb, debug: { index: index } });
          continue;
        case 3:
          if (isGvc) {
            // gvc item name
            gvc = section;
            let breadcrumb: any = { content: section };
            if (sections.length > 4) {
              breadcrumb.url = `/org/${org}/gvc/${gvc}`;
            }
            res.push({ ...breadcrumb, debug: { index: index } });
            continue;
          }
          if (isQuota) {
            res.push({ content: section.split(":s:")[1], debug: { index: index } });
            continue;
          }
          // non-gvc item name
          res.push({ content: section, debug: { index: index } });
          continue;
        case 4:
          if (isGvc) {
            // workload, identity or volumeset kind
            let breadcrumb: any = { content: pluralNameOfKind(section) };
            if (sections.length > 5) {
              breadcrumb.url = `/org/${org}/gvc/${gvc}/${section}`;
            }
            res.push({ ...breadcrumb, debug: { index: index } });
          }
          if (isImage) {
            const imageBreadcrumb = res[res.length - 1];
            // imageBreadcrumb.url = `/org/${org}/repository/${imageBreadcrumb.content}`;
            imageBreadcrumb.url = "";
            res.push({ content: imageBreadcrumb.content + ":" + section, debug: { index: index } });
          }
          continue;
        case 5:
          if (isCreateForm) continue;
          if (isGvc) {
            // workload or identity item name
            let breadcrumb: any = { content: section };
            if (sections.length > 6 && !isWorkloadOptions) {
              breadcrumb.url = `/org/${org}/gvc/${gvc}/${sections[4]}/${section}`;
            }
            res.push({ ...breadcrumb, debug: { index: index } });
          }
          continue;
        case 6:
          if (isCreateForm) continue;
          if (isGvc) {
            // deployment kind or container
            let isContainer = section === "container";
            if (isContainer || isWorkloadOptions) continue;
            let breadcrumb: any = { content: pluralNameOfKind(section) };
            if (sections.length > 7) {
              breadcrumb.url = `/org/${org}/gvc/${gvc}/${sections[4]}/${sections[5]}/${section}`;
            }
            res.push({ ...breadcrumb, debug: { index: index } });
          }
          continue;
        case 7:
          if (isCreateForm) continue;
          // deployment or container item name
          res.push({ content: section, debug: { index: index } });
          continue;
        default:
          break;
      }
    }
  } else if (pathname.startsWith("/billing")) {
    res.push({ content: "Org Management & Billing", debug: { index: 0 } });
  } else if (pathname.includes("educational-videos")) {
    res.push({ content: "Educational Videos", debug: { index: 0 } });
  }

  for (const resItem of res) {
    if (resItem.content === "Images") {
      resItem.url = "";
    }
  }

  return res;
}
