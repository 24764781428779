import * as React from "react";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { request } from "../../../services/cpln";
import { RunReplicaTerminationReducerActionType, useRunReplicaTerminationReducer } from "./reducer_replicaTermination";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import NGAlert from "../../../newcomponents/alert";
import { useDetailContext } from "../../../components/detail/detailContext";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { WorkloadMobx } from "../../../mst/kinds/workload";
import { Loader } from "react-feather";
import { useDeploymentsReducer } from "../../../components/detail/deploymentsReducer";

interface Props {
  onClose: any;
  onDone: any;
  visible: boolean;
}

const CommandModal_ReplicaTerminationRaw: React.FC<Props> = ({ onClose, onDone, visible }) => {
  const { org, gvc } = ConsoleContext;
  const { item: workload } = useDetailContext() as { item: WorkloadMobx };
  const { state: deploymentsState, fetchDeployments } = useDeploymentsReducer();
  const deployments = deploymentsState.deploymentsMap[workload.name] || [];

  React.useEffect(() => {
    const unsubscribe = fetchDeployments([workload.name]);
    return () => {
      unsubscribe();
    };
  }, []);

  const formDataRef = React.useRef(new NGFormData());

  const [state, dispatch] = useRunReplicaTerminationReducer();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingReplicas, setIsLoadingReplicas] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const handleChangeLocation = async (_location: string) => {
    let _errorMessage: string = "";
    let _replicas: { label: string; value: string }[] = [];

    dispatch({ type: RunReplicaTerminationReducerActionType.LOCATION, payload: _location });

    setErrorMessage("");
    setIsLoadingReplicas(true);

    try {
      const deployment = deployments.find((deployment) => deployment.name === _location);
      if (!deployment || !deployment.status?.remote) {
        _errorMessage = `No${workload.isCron ? " active" : ""} replica was found in this location.`;
      } else {
        const url = `${deployment?.status.remote}/replicas/org/${org}/gvc/${gvc}/workload/${workload.name}`;
        const res = await request({ service: "self", url: url });
        if (workload.isCron) {
          for (let deployment of deployments) {
            if (deployment.name !== _location) continue;
            for (let jobExecution of deployment.status?.jobExecutions || []) {
              for (let replica of res.data.items) {
                if (replica.startsWith(jobExecution.name)) {
                  _replicas.push({ label: replica, value: replica });
                }
              }
            }
          }
        } else {
          _replicas = res.data.items.map((replica: string) => ({ label: replica, value: replica }));
        }
      }
    } catch (e) {
      notification.warning({ message: "Failed to fetch replica list." });
    }

    setIsLoadingReplicas(false);

    if (_replicas.length > 0) {
      console.log("_replicas", _replicas);
      const sortedReplicas = _replicas.slice().sort((a, b) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });
      dispatch({ type: RunReplicaTerminationReducerActionType.REPLICA, payload: sortedReplicas[0]?.value || "" });
      dispatch({ type: RunReplicaTerminationReducerActionType.REPLICAS, payload: sortedReplicas });
    } else {
      _errorMessage = `No${workload.isCron ? " active" : ""} replica was found in this location.`;
      dispatch({ type: RunReplicaTerminationReducerActionType.REPLICA, payload: "" });
      dispatch({ type: RunReplicaTerminationReducerActionType.REPLICAS, payload: [] });
    }

    if (_errorMessage) {
      setErrorMessage(_errorMessage);
    }
  };

  async function handleConfirm() {
    setIsLoading(true);
    try {
      await request({
        method: "post",
        url: workload.selfLink + "/-command",
        body: {
          type: "stopReplica",
          spec: { replica: state.replica, location: state.location },
        },
      });
      onDone();
      notification.success({
        message: "Success",
        description: `${workload.isStateful ? "Restarted" : "Stopped"} ${state.replica}`,
      });
    } catch (e) {
      notification.warning({
        message: `Failed to ${workload.isStateful ? "restart" : "stop"} ${state.replica}.`,
        description: e?.response?.data?.message,
      });
    }
    setIsLoading(false);
  }

  let isValid = true;
  if (!state.location) isValid = false;
  if (!state.replica) isValid = false;

  return (
    <NGFormContext.Provider value={formDataRef.current}>
      <Modal
        open={visible}
        closable={false}
        title={`${workload.isStateful ? "Restart" : "Stop"} Replica?`}
        footer={
          <div className="modal-actions">
            <NGButton disabled={isLoading} onClick={() => onClose()} variant={"danger"} outlined>
              Cancel
            </NGButton>
            <NGButton
              disabled={isLoading || isLoadingReplicas || !isValid}
              loading={isLoading || isLoadingReplicas}
              onClick={handleConfirm}
              variant={"primary"}
            >
              Confirm
            </NGButton>
          </div>
        }
        onCancel={onClose}
        maskClosable={!isLoading}
        destroyOnClose
      >
        <div>
          {!workload.isCron || errorMessage ? (
            <NGAlert
              style={{ width: "100%" }}
              className="mb-4"
              type={errorMessage ? "error" : "info"}
              message={
                errorMessage
                  ? errorMessage
                  : workload.isStateful
                  ? "A new replica will be started"
                  : "A new replica might be started depending on the workload scale options."
              }
            />
          ) : null}
          <NGFormLabel name={"location"} required invalid={!state.location}>
            Location
          </NGFormLabel>
          <NGSelect
            className="mb-4"
            name={"location"}
            value={state.location}
            onChange={(value) => handleChangeLocation(value)}
            options={deployments
              .slice()
              .sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
              })
              .map((d) => ({ label: d.name, value: d.name }))}
          />
          <NGFormLabel name={"replica"} required invalid={!state.replica}>
            Replica
          </NGFormLabel>
          <NGSelect
            className="mb-4"
            name={"replica"}
            value={state.replica}
            disabled={state.replica === "loading"}
            onChange={(value) => dispatch({ type: RunReplicaTerminationReducerActionType.REPLICA, payload: value })}
            options={state.replicas}
            buttons={
              isLoadingReplicas
                ? [
                    {
                      render() {
                        return <Loader className="h-4" />;
                      },
                      onClick() {},
                      title: "Loading",
                    },
                  ]
                : []
            }
          />
        </div>
      </Modal>
    </NGFormContext.Provider>
  );
};

export const CommandModal_ReplicaTermination = observer(CommandModal_ReplicaTerminationRaw);
