import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { AWSInstructions, ProviderAWSInstructions } from "../../provider_aws_instructions";
import { homeLink, request } from "../../../../services/cpln";
import { ConsoleContext } from "../../../../mobxStores/consoleContext/consoleContext";
import { DryRunAlert } from "../../dryRunAlert";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGSelect } from "../../../../newcomponents/select/ngselect";
import { NGRadioGroup } from "../../../../newcomponents/radioGroup";
import { mk8sMobx } from "./../../../../mst/kinds/mk8s/mk8s";
import { useNGFormContext } from "../../../../reactContexts/ngFormContext";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { ExternalLink, MoreHorizontal } from "react-feather";
import { NGFormLabel } from "../../../../newcomponents/text/formLabel";
import { NGError } from "../../../../newcomponents/text/error";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";
import { NGProviderBrowser } from "../../ngProviderBrowser";
import { dateString } from "../../../../components/dateStringFn";
import { formatStrings } from "../../../../utils/time";
import { AwsSsmIcon } from "../../awsSsmIcon";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderAWSRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const a = mk8sDraft.provider_aws!;
  const formData = useNGFormContext();

  const [browserKey, setBrowserKey] = React.useState("");

  const [awsDeployRoleArnInstructions, setAwsDeployRoleArnInstructions] = React.useState<AWSInstructions | null>(null);
  const [awsInstructionsError, setAwsInstructionsError] = React.useState<string>("");

  React.useEffect(() => {
    fetchAwsInstructions();
  }, []);

  React.useEffect(() => {
    mk8sDraft.provider_aws.fetchInstanceTypes();
  }, [a.region.value]);

  async function fetchAwsInstructions() {
    try {
      const { data } = await request({ url: `/org/${ConsoleContext.org}/mk8s/-instructions/aws` });
      setAwsDeployRoleArnInstructions(data);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setAwsInstructionsError(errorMessage);
    }
  }

  return (
    <>
      <div>
        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"aws.region"}
            label={a.region.label}
            value={a.region.value}
            onChange={a.region.setValue}
            required={a.region.isRequired}
            error={a.region.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("region"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={a.region.setValue}
              path={"spec.provider.aws.region"}
            />
          </div>
        </div>

        <NGFormLabel
          name={"aws.deployRoleArn"}
          required={a.deployRoleArn.isRequired}
          invalid={!a.deployRoleArn.isValid}
        >
          {a.deployRoleArn.label}
        </NGFormLabel>
        {awsInstructionsError || !awsDeployRoleArnInstructions ? null : (
          <ProviderAWSInstructions instructions={awsDeployRoleArnInstructions} draft={mk8sDraft} />
        )}
        <div className="flex items-start gap-2">
          <div className="mb-4">
            <NGInput
              name={"aws.deployRoleArn"}
              style={{ minWidth: 450 }}
              value={a.deployRoleArn.value}
              onChange={(e) => a.deployRoleArn.setValue(e.target.value)}
              invalid={!a.deployRoleArn.isValid}
            />
            {!a.deployRoleArn.value && formData.get("aws.deployRoleArn").touched ? (
              <NGError>{a.deployRoleArn.label} is required</NGError>
            ) : null}
          </div>
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            path={"spec.provider.aws.deployRoleArn"}
          />
        </div>
        <div className="flex items-center gap-1">
          <NGFormLabel
            name={a.image.type === "recommended" ? "aws.image.recommended" : "aws.image.exact"}
            required
            invalid={a.image.type === "recommended" ? !a.image.recommended : !a.image.exact.isValid}
          >
            Image
          </NGFormLabel>
          <AwsSsmIcon />
        </div>
        <NGRadioGroup
          options={[
            { label: "Recommended", value: "recommended" },
            { label: "Exact", value: "exact" },
          ]}
          value={a.image.type}
          onChange={(value) => a.image.setType(value as any)}
        />
        <div className="mb-4 flex items-start gap-2">
          {a.image.type === "recommended" ? (
            <NGSelect
              name={`aws.image.recommended`}
              style={{ width: 450, minWidth: 450 }}
              value={a.image.recommended}
              invalid={!a.image.recommended}
              onChange={a.image.setRecommended}
              options={a._recommendedAmis.map((ami) => ({ label: ami, value: ami }))}
            />
          ) : a.image.type === "exact" ? (
            <NGInput
              name={`aws.image.exact`}
              style={{ width: 450, minWidth: 450 }}
              value={a.image.exact.value}
              invalid={!a.image.exact.isValid}
              required
              onChange={(e) => a.image.exact.setValue(e.target.value)}
            />
          ) : null}

          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            path={`spec.provider.aws.image.${a.image.type}`}
            paths={[`spec.provider.aws.image`, `spec.provider.aws.image.recommended`, `spec.provider.aws.image.exact`]}
          />
        </div>

        <div className="flex gap-1 items-start">
          <NGFormElement
            name={"aws.vpcId"}
            label={a.vpcId.label}
            labelRender={() => <AwsSsmIcon />}
            value={a.vpcId.value}
            onChange={a.vpcId.setValue}
            error={a.vpcId.error}
            required={a.vpcId.isRequired}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("vpcId"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={a.vpcId.setValue}
              path={"spec.provider.aws.vpcId"}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={"aws.keyPair"}
            label={a.keyPair.label}
            value={a.keyPair.value}
            onChange={a.keyPair.setValue}
            error={a.keyPair.error}
            required={a.keyPair.isRequired}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("keyPair"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={a.keyPair.setValue}
              path={"spec.provider.aws.keyPair"}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <div className="mb-4">
            <div className="flex items-center gap-1">
              <NGFormLabel name={"aws.diskEncryptionKeyArn"} required={false} invalid={!a.diskEncryptionKeyArn.isValid}>
                {a.diskEncryptionKeyArn.label}
              </NGFormLabel>
              <AwsSsmIcon />
            </div>
            <NGInput
              name={"aws.diskEncryptionKeyArn"}
              style={{ width: 450 }}
              value={a.diskEncryptionKeyArn.value}
              onChange={(e) => a.diskEncryptionKeyArn.setValue(e.target.value)}
              invalid={!a.diskEncryptionKeyArn.isValid}
              buttons={[
                {
                  title: "Browse",
                  onClick: () => setBrowserKey("diskEncryptionKeyArn"),
                  render: () => <MoreHorizontal />,
                },
              ]}
            />
            {a.diskEncryptionKeyArn.error ? <NGError>{a.diskEncryptionKeyArn.error}</NGError> : null}
            <div style={{ width: 450 }}>
              This requires Autoscaler to use the
              <a
                href={
                  "https://docs.aws.amazon.com/autoscaling/ec2/userguide/key-policy-requirements-EBS-encryption.html"
                }
                target={"_blank"}
                className="color-link ml-1"
              >
                <span>KMS Key</span>
                <ExternalLink className="h-4 inline-block" style={{ transform: "translateY(1px)" }} />
              </a>
            </div>
          </div>
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={a.diskEncryptionKeyArn.setValue}
              path={"spec.provider.aws.diskEncryptionKeyArn"}
            />
          </div>
        </div>
        <div className="flex gap-2 items-start">
          <NGInputListMst
            label="Security Group IDs"
            labelRender={() => <AwsSsmIcon />}
            className="mb-8"
            styles={{ container: { width: 450, minWidth: 450 } }}
            data={a.securityGroupIds}
            firstInput={(item) => (
              <NGInput
                required
                invalid={!item.firstValue}
                ignoreTouched
                placeholder="Key"
                value={item.firstValue}
                onChange={(e) => item.setFirstValue(e.target.value)}
                buttons={[
                  {
                    title: "Browse",
                    onClick: () => setBrowserKey("securityGroupId"),
                    render: () => <MoreHorizontal />,
                  },
                ]}
              />
            )}
          />
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            path={"spec.provider.aws.securityGroupIds"}
          />
        </div>
      </div>
      {browserKey === "region" ? (
        <NGProviderBrowser
          title="Browse AWS Regions"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "regions",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.regions;
          }}
          onOk={(value) => a.region.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name"]}
          getData={(item: any) => [item, item]}
        />
      ) : null}
      {browserKey === "vpcId" ? (
        <NGProviderBrowser
          title="Browse AWS VPC IDs"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ec2-vpcs",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.Vpcs;
          }}
          onOk={(value) => a.vpcId.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "VPC ID", "CIDR Block", "Is Default"]}
          getData={(item: any) => {
            let name = "-";
            const nameTag = (item.Tags || []).find((t: any) => t.Key === "Name");
            if (nameTag) {
              name = nameTag.Value;
            }

            return [item.VpcId, name, item.VpcId, item.CidrBlock, String(item.IsDefault)];
          }}
        />
      ) : null}
      {browserKey === "keyPair" ? (
        <NGProviderBrowser
          title="Browse AWS EC2 Key Pairs"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ec2-keyPairs",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.KeyPairs;
          }}
          onOk={(value) => a.keyPair.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Type", "Create Time"]}
          getData={(item: any) => [
            item.KeyName,
            item.KeyName,
            item.KeyType,
            dateString(item.CreateTime, { format: formatStrings.log }),
          ]}
        />
      ) : null}
      {browserKey === "diskEncryptionKeyArn" ? (
        <NGProviderBrowser
          title="Browse AWS KMS Keys"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "kms-keys",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.Keys;
          }}
          onOk={(value) => a.diskEncryptionKeyArn.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Arn"]}
          getData={(item: any) => [item.KeyArn, item.KeyArn]}
        />
      ) : null}
      {browserKey === "securityGroupId" ? (
        <NGProviderBrowser
          title="Browse AWS Security Groups"
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ec2-securityGroups",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.SecurityGroups;
          }}
          multipleChoice
          onOk={(values) => a.securityGroupIds.setItems((values as string[]).map((v) => ({ firstValue: v })))}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Description", "Security Group ID"]}
          getData={(item: any) => [item.GroupId, item.GroupName, item.Description, item.GroupId]}
        />
      ) : null}
    </>
  );
};

export const ProviderAWS = observer(ProviderAWSRaw);
