import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route } from "react-router-dom";
import { DetailLayout } from "../../components/detail/layout";
import { Events } from "../../components/detail/events";
import { AccessReport } from "../../components/detail/accessReport";
import { TagsNew } from "../../components/detail/tagsNew";
import { VolumeSetMobx } from "../../mst/kinds/volumeset";
import { VolumeSetDraftMobx } from "../../mst/stores/volumeset.draft";
import { Info } from "./info";
import { Spec } from "./spec";
import { IS_DEPLOYMENT_ENV_TEST, VOLUMESET_UPDATE_INTERVAL_MS } from "../../envVariables";
import { AuditTrail } from "../../components/detail/auditTrail";
import { Volumes } from "./volumes";
import { DetailNavLink } from "../../components/detail/nav";
import { useVolumesetCommandsContext } from "../../reactContexts/volumesetCommandsContext";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { Commands } from "../../components/detail/commands";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { Tags } from "../../components/detail/tags";
interface Props {
  volumeSet: VolumeSetMobx;
  volumeSetDraft: VolumeSetDraftMobx;
}
const VolumeSetDetailRaw: React.FC<Props> = ({ volumeSet, volumeSetDraft: draft }) => {
  const basePath = useBasePath("/gvc/:gvc/volumeset/:volumeset/*");
  const { hashCommands } = useVolumesetCommandsContext();

  const eventlogHook = useEventlogs(volumeSet.selfLink);

  React.useEffect(() => {
    let intervalId = setInterval(() => {
      volumeSet.fetch();
    }, VOLUMESET_UPDATE_INTERVAL_MS);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [volumeSet.name]);

  const [links, setLinks] = React.useState<DetailNavLink[]>(getLinks());
  function getLinks(): DetailNavLink[] {
    let _links = [
      { label: "Info", url: "-info" },
      { label: "Volumes", url: "-volumes" },
      { label: "Spec", url: "-spec" },
      { label: "Tags", url: "-tags" },
      { label: "Events", url: "-events" },
      { label: "Audit Trail", url: `-audittrail` },
      { label: "Access Report", url: "-accessreport" },
    ];

    if (IS_DEPLOYMENT_ENV_TEST || localStorage.getItem("CPLN_DEBUG") === "true") {
      _links.push({ label: "Commands", url: "-commands" });
    }

    return _links;
  }

  React.useEffect(() => {
    setLinks(getLinks());
  }, [hashCommands]);

  return (
    <BasePathContext.Provider value={basePath}>
      <DetailLayout links={links as any}>
        <Route path={`-info`} element={<Info volumeSet={volumeSet} />} />
        <Route path={`-volumes`} element={<Volumes volumeSet={volumeSet} />} />
        <Route path={`-spec`} element={<Spec volumeSet={volumeSet} draft={draft} />} />
        <Route path={`-tags`} element={<Tags link={volumeSet.selfLink} tags={volumeSet.tags} />} />
        <Route
          path={`-events`}
          element={
            <Events
              kind={"volumeset"}
              eventlogs={eventlogHook.eventlogs}
              isLoading={eventlogHook.isLoading}
              fetchEventlogs={eventlogHook.fetchEventlogs}
            />
          }
        />
        <Route path={`-audittrail`} element={<AuditTrail kind="volumeset" resourceId={volumeSet.id} />} />
        <Route path={`-accessreport`} element={<AccessReport itemLink={volumeSet.selfLink} />} />
        {IS_DEPLOYMENT_ENV_TEST || localStorage.getItem("CPLN_DEBUG") === "true" ? (
          <Route path={`-commands`} element={<Commands selfLink={volumeSet.selfLink} />} />
        ) : null}
      </DetailLayout>
    </BasePathContext.Provider>
  );
};

export const VolumeSetDetail = observer(VolumeSetDetailRaw);
