import * as React from "react";
import { mk8sMobx } from "../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";
import { Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ProviderGenericRoute } from "./generic/route";
import { ProviderAWSRoute } from "./aws/route";
import { ProviderHetznerRoute } from "./hetzner/route";
import { ProviderEphemeralRoute } from "./ephemeral/route";
import { ProviderLambdalabsRoute } from "./lambdalabs/route";
import { ProviderOblivusRoute } from "./oblivus/route";
import { ProviderLinodeRoute } from "./linode/route";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  return (
    <div>
      <Routes>
        <Route index element={<Navigate to={`${mk8sDraft.provider.value}`} />} />
        <Route path={`aws/*`} element={<ProviderAWSRoute mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
        <Route path={`ephemeral/*`} element={<ProviderEphemeralRoute mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
        <Route path={`generic/*`} element={<ProviderGenericRoute mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
        <Route path={`hetzner/*`} element={<ProviderHetznerRoute mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
        <Route path={`lambdalabs/*`} element={<ProviderLambdalabsRoute mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
        <Route path={`linode/*`} element={<ProviderLinodeRoute mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
        <Route path={`oblivus/*`} element={<ProviderOblivusRoute mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
      </Routes>
    </div>
  );
};

export const Provider = observer(ProviderRaw);
