import clsx from "clsx";
import * as React from "react";
import { MinusCircle, Trash2 } from "react-feather";
import { NGButton } from "../button/Button";
import { NGCheckbox } from "../checkbox";
import { ReactSortable } from "react-sortablejs";
import "./inputList.css";
import { observer } from "mobx-react-lite";
import { ListItemMobx, ListOfItemsMobx } from "../../mobxDataModels/listOfItemsModel";
import { DragHandle } from "./inputListMobx";
import { NGFormLabel } from "../text/formLabel";
import { NGError } from "../text/error";
import NGAlert from "../alert";

export interface NGInputListMstProps {
  label: string;
  labelRender?: () => React.ReactElement;
  data: ListOfItemsMobx;
  firstInput: (item: ListItemMobx, index: number) => React.ReactElement;
  secondInput?: (item: ListItemMobx, index: number) => React.ReactElement;
  thirdInput?: (item: ListItemMobx, index: number) => React.ReactElement;
  itemExtra?: (item: ListItemMobx, index: number) => React.ReactElement | null;
  draggable?: boolean;
  noItemLabel?: string;
  required?: boolean;
  invalid?: boolean;
  error?: string;
  warning?: string;
  canAdd?: () => boolean;
  allowListSelection?: boolean;
  className?: string;
  removeFlexGrow?: boolean;
  styles?: {
    container?: React.CSSProperties;
    header?: React.CSSProperties;
    firstInputContainer?: React.CSSProperties;
    secondInputContainer?: React.CSSProperties;
    thirdInputContainer?: React.CSSProperties;
  };
}

function NGInputListMstRaw({
  label,
  labelRender,
  data,
  firstInput,
  secondInput,
  thirdInput,
  itemExtra,
  draggable = false,
  noItemLabel,
  required,
  invalid,
  error,
  warning,
  canAdd = () => true,
  allowListSelection = false,
  className = "",
  // TODO fix this better
  removeFlexGrow = false,
  styles = {
    container: {},
    header: {},
    firstInputContainer: {},
    secondInputContainer: {},
    thirdInputContainer: {},
  },
}: NGInputListMstProps) {
  return (
    <div className={clsx("flex flex-col mb-4", className)} style={styles.container}>
      <div className={clsx("flex items-center gap-1 mb-2")} style={{ width: 450, ...styles.header }}>
        {allowListSelection ? (
          <NGCheckbox
            aria-label={`input-list-selectall-${label}`}
            isDisabled={data.items.length < 1}
            checked={data.isAllSelected}
            indeterminate={!data.isAllSelected && data.selections.length > 0}
            onChange={data.toggleAllSelect}
          />
        ) : null}
        <NGFormLabel required={required} invalid={invalid}>
          {label}
        </NGFormLabel>
        {labelRender ? labelRender() : null}
        {removeFlexGrow ? null : <div className="flex-grow" />}
        {allowListSelection ? (
          <NGButton
            size={"small"}
            variant={"danger"}
            outlined
            renderIcon={() => <Trash2 className="h-4" />}
            disabled={data.selections.length < 1}
            onClick={() => data.removeSelections()}
          >
            Remove
          </NGButton>
        ) : null}
        <NGButton disabled={canAdd() === false} size={"small"} variant={"action"} onClick={() => data.add()}>
          Add
        </NGButton>
      </div>
      <div>
        {error ? null : data.items.length === 0 ? <div>{noItemLabel || `No ${label} Items`}</div> : null}
        <ReactSortable
          handle=".drag-handle"
          className="flex flex-col gap-2"
          list={JSON.parse(JSON.stringify(data.items))}
          setList={data.setItems}
        >
          {data.items.map((item, index) => {
            return (
              <div key={item.id}>
                <div className={clsx("flex items-center relative")}>
                  {draggable ? <DragHandle /> : null}
                  {allowListSelection ? (
                    <NGCheckbox
                      aria-label={`input-list-select-${item.id}`}
                      className="mr-1"
                      checked={data.selections.includes(item.id)}
                      onChange={(value) => data.toggleSelect(item.id)}
                    />
                  ) : null}
                  <div className="basis-0 flex-grow" style={styles.firstInputContainer}>
                    {firstInput(item, index)}
                  </div>
                  {secondInput ? (
                    <div className="basis-0 flex-grow ml-2" style={styles.secondInputContainer}>
                      {secondInput(item, index)}
                    </div>
                  ) : null}
                  {thirdInput ? (
                    <div className="basis-0 flex-grow ml-2" style={styles.thirdInputContainer}>
                      {thirdInput(item, index)}
                    </div>
                  ) : null}
                  {allowListSelection ? null : (
                    <NGButton
                      onClick={() => data.remove(item.id)}
                      variant="danger"
                      className="ml-1"
                      text
                      renderIcon={(_, props) => <MinusCircle {...props} />}
                    />
                  )}
                </div>
                {itemExtra ? (
                  <div className="mt-1" style={{ marginLeft: 27 }}>
                    {itemExtra(item, index)}
                  </div>
                ) : null}
              </div>
            );
          })}
        </ReactSortable>
        {error ? <NGAlert className="mt-2" type={"error"} message={error} /> : null}
        {warning ? <NGAlert className="mt-2" type={"warning"} message={warning} /> : null}
      </div>
    </div>
  );
}

export const NGInputListMst = observer(NGInputListMstRaw);
