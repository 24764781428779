import { Deployment } from "../../schema/types/workload/deployment";
import { HealthCheckSpec, Workload } from "../../schema/types/workload/workload";
import { OptionsMetric } from "../../schema/types/workload/workloadOptions";
import { request } from "../../services/cpln";
import { DefaultOptionsModel } from "./workload";

export interface WorkloadHealthObject {
  isReady: boolean;
  text: "Ready" | "Not Ready" | "Loading";
  expectedDeploymentVersion: number;
  currentReadyVersion: number;
  isLatestReady: boolean;
  hasIdentityIssue: boolean;
  hasSyncFailed: boolean;
}

export const workloadHelpers = {
  getContainerByName(workload: any, name: string) {
    return workload.containers.find((c) => c.name === name);
  },
  getType(workload: any) {
    return workload.spec.type;
  },
  getGvc(workload: any) {
    return workload.links
      .find((l) => l.rel === "gvc")!
      .href.split("/")
      .pop();
  },
  getDeploymentLink(workload: any) {
    return workload.links.find((l) => l.rel === "deployment")!.href;
  },
  getContainerNames(workload: any): string[] {
    return workload.spec.containers.map((c) => c.name);
  },
  getIsServerless(workload: any) {
    return workload.spec.type === "serverless";
  },
  getIsStandard(workload: any) {
    return workload.spec.type === "standard";
  },
  getIsCron(workload: any) {
    return workload.spec.type === "cron";
  },
  getIsStateful(workload: any) {
    return workload.spec.type === "stateful";
  },
  getHealth(deployments: Deployment[], workload: Workload): WorkloadHealthObject {
    if (!deployments || !Array.isArray(deployments) || deployments.length < 1) {
      return {
        isReady: false,
        text: "Not Ready",
        expectedDeploymentVersion: 0,
        currentReadyVersion: 0,
        isLatestReady: false,
        hasIdentityIssue: false,
        hasSyncFailed: false,
      };
    }
    let ready = 0;
    let latestReady = 0;
    let hasIdentityIssue = false;
    const identityErrors = ["running with no identity", "is not allowed to reveal the secret"];
    let hasSyncFailed = false;
    const expectedDeploymentVersion = deployments[0].status?.expectedDeploymentVersion || 0;
    let currentReadyVersion = 0;
    for (let deployment of deployments) {
      if (!deployment.status) {
        continue;
      }
      if (deployment.status.internal.syncFailed) {
        hasSyncFailed = true;
      }
      if (deployment.status.ready) {
        ready += 1;
        const deploymentLastProcessed = deployment.status.lastProcessedVersion || 0;
        if (deploymentLastProcessed < workload.version) {
          continue;
        }

        for (const version of deployment.status.versions || []) {
          if (!version.workload) {
            continue;
          }
          if (!version.ready) {
            continue;
          }
          if (version.workload > currentReadyVersion) {
            currentReadyVersion = version.workload;
          }
        }

        const latestVersion = (deployment.status.versions || []).find(
          (v) => v.workload === deployment.status!.expectedDeploymentVersion,
        );
        if (latestVersion) {
          if (latestVersion.ready) {
            latestReady += 1;
          }
          for (let errorMessage of identityErrors)
            if (latestVersion.message?.includes(errorMessage)) {
              hasIdentityIssue = true;
            }
        }
      }
    }
    let totalLocations = deployments.length;
    for (let localOption of workload.spec?.localOptions || []) {
      if (localOption.suspend || (localOption.autoscaling?.minScale === 0 && localOption.autoscaling?.maxScale === 0)) {
        totalLocations -= 1;
      }
    }

    if (ready > 0) {
      return {
        isReady: true,
        text: "Ready",
        isLatestReady: workload.spec?.type === "cron" ? true : latestReady >= totalLocations,
        expectedDeploymentVersion: expectedDeploymentVersion,
        currentReadyVersion: currentReadyVersion,
        hasIdentityIssue,
        hasSyncFailed,
      };
    }
    return {
      isReady: false,
      text: "Not Ready",
      expectedDeploymentVersion: expectedDeploymentVersion,
      currentReadyVersion: currentReadyVersion,
      isLatestReady: false,
      hasIdentityIssue,
      hasSyncFailed,
    };
  },
  getReadyLocations(deployments: Deployment[]): string {
    let ready = 0;
    for (let deployment of deployments) {
      if (deployment.status?.ready) {
        ready += 1;
      }
    }
    return `${ready}/${deployments.length}`;
  },
  getReadyLocationsOnly(deployments: Deployment[]): number {
    const readyLocationsText = this.getReadyLocations(deployments);
    return Number(readyLocationsText.split("/")[0]);
  },
  getReadyReplicas(deployments: Deployment[]): string {
    let ready = 0;
    let total = 0;
    for (let deployment of deployments) {
      for (let version of deployment.status?.versions || []) {
        for (let container of Object.values(version.containers || {})) {
          total += container.resources?.replicas || 0;
          ready += container.resources?.replicasReady || 0;
        }
      }
    }
    return `${ready}/${total}`;
  },
  getReadyReplicasOnly(deployments: Deployment[]): number {
    const readyReplicasText = this.getReadyReplicas(deployments);
    return Number(readyReplicasText.split("/")[0]);
  },
  getTotalReplicas(deployments: Deployment[]): number {
    const readyReplicasText = this.getReadyReplicas(deployments);
    return Number(readyReplicasText.split("/")[1]);
  },
  getContainers(workload: Workload) {
    return (workload.spec?.containers || []).map((c) => ({ name: c.name, image: c.image }));
  },
  getReadiness(deployments: Deployment[], workload: Workload) {
    const health = this.getHealth(deployments, workload);
    return health.text === "Loading" ? "Not Ready" : health.text;
  },
  getSuspendType(workload: any): "suspended" | "partiallySuspended" | "notSuspended" {
    let activeCount = 0;
    let suspendedCount = 0;

    if (workload.spec?.defaultOptions?.suspend) {
      suspendedCount += 1;
    } else {
      activeCount += 1;
    }

    for (let localOption of workload.spec?.localOptions || []) {
      if (localOption.suspend) {
        suspendedCount += 1;
      } else {
        activeCount += 1;
      }
    }

    if (suspendedCount > 0) {
      if (activeCount > 0) {
        return "partiallySuspended";
      } else {
        return "suspended";
      }
    } else {
      return "notSuspended";
    }
  },
  getCapacityAi(workload: Workload): boolean | undefined {
    if (!workload.spec?.defaultOptions) {
      return undefined;
    }
    return workload.spec.defaultOptions.capacityAI;
  },
  getMetric(workload: Workload): OptionsMetric | undefined {
    if (!workload.spec?.defaultOptions?.autoscaling) {
      return undefined;
    }
    return workload.spec.defaultOptions.autoscaling.metric;
  },
  getMinScale(workload: Workload): number | undefined {
    if (!workload.spec?.defaultOptions?.autoscaling) {
      return undefined;
    }
    return workload.spec.defaultOptions.autoscaling.minScale;
  },
  getMaxScale(workload: Workload): number | undefined {
    if (!workload.spec?.defaultOptions?.autoscaling) {
      return undefined;
    }
    return workload.spec.defaultOptions.autoscaling.maxScale;
  },
  async getAsyncIsSuspended(link: string) {
    try {
      const { data } = await request<Workload>({ url: link });
      let hasActiveInstance = false;
      if (!data.spec?.defaultOptions) {
        hasActiveInstance = true;
      }
      if (data.spec?.defaultOptions && data.spec.defaultOptions.suspend === false) {
        hasActiveInstance = true;
      }
      if (data.spec!.localOptions && data.spec!.localOptions.find((l) => l.suspend === false)) {
        hasActiveInstance = true;
      }
      return !hasActiveInstance;
    } catch (e) {
      return false;
    }
  },
  // ---
  healthCheck: {
    getHasMethod(healthCheck: HealthCheckSpec) {
      let res = false;
      if (healthCheck.exec) res = true;
      if (healthCheck.tcpSocket) res = true;
      if (healthCheck.httpGet) res = true;
      if (healthCheck.grpc) res = true;
      return res;
    },
    getMethod(healthCheck: HealthCheckSpec) {
      if (healthCheck.exec) return "command";
      if (healthCheck.tcpSocket) return "tcp";
      if (healthCheck.httpGet) return "http";
      if (healthCheck.grpc) return "grpc";
      return "command";
    },
  },
  firewall: {
    getInboundInternetReachable(firewall: any) {
      return firewall.inboundAllowCIDR.includes("0.0.0.0/0");
    },
    getOutboundInternetReachable(firewall: any) {
      return firewall.outboundAllowCIDR.includes("0.0.0.0/0");
    },
    getInboundAllowCIDRFiltered(firewall: any) {
      return firewall.inboundAllowCIDR.filter((i) => i !== "0.0.0.0/0");
    },
    getOutboundAllowCIDRFiltered(firewall: any) {
      return firewall.outboundAllowCIDR.filter((i) => i !== "0.0.0.0/0");
    },
  },
  async handleSuspend(links: string[]) {
    try {
      const promises: Promise<any>[] = [];
      for (const link of links) {
        const { data: workloadData } = await request<Workload>({
          url: link,
        });
        if (!workloadData.spec!.defaultOptions) {
          var opts = DefaultOptionsModel.create();
          workloadData.spec!.defaultOptions = JSON.parse(JSON.stringify(opts));
        }
        workloadData.spec!.defaultOptions!.suspend = true;
        for (let localOption of workloadData.spec!.localOptions || []) {
          localOption.suspend = true;
        }
        promises.push(
          request({
            method: "patch",
            url: link,
            body: workloadData,
          }),
        );
      }
      await Promise.all(promises);
      return true;
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      return errorMessage;
    }
  },
  async handleUnsuspend(links: string[]) {
    try {
      const promises: Promise<any>[] = [];
      for (const link of links) {
        const { data: workloadData } = await request<Workload>({
          url: link,
        });
        if (!workloadData.spec!.defaultOptions) {
          workloadData.spec!.defaultOptions = {} as any;
        }
        workloadData.spec!.defaultOptions!.suspend = false;
        for (let localOption of workloadData.spec!.localOptions || []) {
          localOption.suspend = false;
        }
        promises.push(
          request({
            method: "patch",
            url: link,
            body: workloadData,
          }),
        );
      }
      await Promise.all(promises);
      return true;
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      return errorMessage;
    }
  },
  async handleForceRedeployment(links: string[]) {
    try {
      const promises: Promise<any>[] = [];
      for (const link of links) {
        const body = { tags: { "cpln/deployTimestamp": new Date().toISOString() } };
        promises.push(
          request({
            method: "patch",
            url: link,
            body: body,
          }),
        );
      }
      await Promise.all(promises);
      return true;
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      return errorMessage;
    }
  },
};
