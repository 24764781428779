import { types, Instance } from "mobx-state-tree";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { arraysAreEqual } from "../../services/cpln";
import { Mk8sDraftUnmanagedNodePoolModel, Mk8sDraftUnmanagedNodePoolReadOnlyModel } from "./mk8s.draft.provider.common";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";

export const Mk8sDraftProviderPaperSpaceNodePoolsReadonlyModel = types.model({
  name: types.optional(types.string, ""),
  labels: types.array(
    types.model({
      key: types.string,
      value: types.string,
    })
  ),
  taints: types.array(
    types.model({
      key: types.string,
      value: types.string,
      effect: types.string,
    })
  ),
  minSize: types.maybe(types.number),
  maxSize: types.maybe(types.number),
  machineType: types.optional(types.string, ""),
});

export const Mk8sDraftProviderPaperSpaceNodePoolsModel = types
  .model({
    index: types.number,
    status: types.optional(types.enumeration(["default", "added"]), "default"),
    _pool: types.optional(Mk8sDraftProviderPaperSpaceNodePoolsReadonlyModel, () =>
      Mk8sDraftProviderPaperSpaceNodePoolsReadonlyModel.create()
    ),
    name: types.optional(StringModel, () =>
      StringModel.create({ label: "Name", validationKey: "nodePoolName", isRequired: true })
    ),
    labels: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    taints: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    minSize: types.optional(StringModel, () => NumberModel.create({ label: "Min Size" })),
    maxSize: types.optional(StringModel, () => NumberModel.create({ label: "Max Size" })),
    machineType: types.optional(StringModel, () => StringModel.create({ label: "Machine Type" })),
  })
  .actions((self) => ({
    setStatusAdded() {
      self.status = "added";
    },
    reset() {
      self.name.setInitialValue(self._pool.name);
      self.labels = ListOfItemsModel.create({
        _items: self._pool.labels.map((l) => ({ firstValue: l.key, secondValue: l.value })),
      });
      self.taints = ListOfItemsModel.create({
        _items: self._pool.taints.map((l) => ({ firstValue: l.key, secondValue: l.value, thirdValue: l.effect })),
      });

      self.minSize.setInitialValue(String(self._pool.minSize).length > 0 ? String(self._pool.minSize) : "");
      self.maxSize.setInitialValue(String(self._pool.maxSize).length > 0 ? String(self._pool.maxSize) : "");
      self.machineType.reset();
    },
    confirm() {
      self.name.confirm();

      self.labels.confirm();

      self.taints.confirm();
      self.minSize.confirm();
      self.maxSize.confirm();
      self.machineType.reset();

      const _pool: any = {
        name: self.name.value, //
        // labels: self.labels.map((l) => ({ key: l.key.value, value: l.value.value })),
        // taints: self.taints.map((t) => ({ key: t.key.value, value: t.value.value, effect: t.effect.value })),
        minSize: String(self.minSize.value).length > 0 ? Number(self.minSize.value) : undefined,
        maxSize: String(self.maxSize.value).length > 0 ? Number(self.maxSize.value) : undefined,
        machineType: self.machineType.value,
      };

      self._pool = Mk8sDraftProviderPaperSpaceNodePoolsReadonlyModel.create(_pool);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
  }))
  .views((self) => ({
    get isNameValid() {
      // TODO fix on upper level
      return true;
    },
  }))
  .views((self) => ({
    get isValid() {
      let res = true;
      if (!self.name.isValid) res = false;
      // if (self.labels.some((l) => !l.isValid)) res = false;
      // if (self.taints.some((t) => !t.isValid)) res = false;
      if (!self.minSize.isValid) res = false;
      if (!self.maxSize.isValid) res = false;
      if (!self.machineType.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.name.isValid) reason = "name is invalid";
      // if (self.labels.some((l) => !l.isValid)) reason = self.labels.find((l) => !l.isValid)?.invalidReason || "";
      // if (self.taints.some((l) => !l.isValid)) reason = self.taints.find((t) => !t.isValid)?.invalidReason || "";
      if (!self.minSize.isValid) reason = "minSize is invalid";
      if (!self.maxSize.isValid) reason = "maxSize is invalid";
      if (!self.machineType.isValid) reason = "machineType is invalid";
      return reason;
    },
    get isDirty() {
      let res = false;
      if (self.name.isDirty) res = true;
      // if (self.labels.some((l) => l.isDirty)) res = true;
      // if (self.taints.some((t) => t.isDirty)) res = true;
      if (self.minSize.isDirty) res = true;
      if (self.maxSize.isDirty) res = true;
      if (self.machineType.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.name.isDirtyReason) reason = self.name.isDirtyReason;
      // if (self.labels.some((l) => l.isDirty)) reason = self.labels.find((l) => l.isDirty)?.invalidReason || "";
      // if (self.taints.some((l) => l.isDirty)) reason = self.taints.find((t) => t.isDirty)?.invalidReason || "";
      if (self.minSize.isDirtyReason) reason = self.minSize.isDirtyReason;
      if (self.maxSize.isDirtyReason) reason = self.maxSize.isDirtyReason;
      if (self.machineType.isDirtyReason) reason = self.machineType.isDirtyReason;
      return reason;
    },
    get asObject() {
      // const labels: { [_: string]: string } = {};
      // self.labels.forEach((l) => {
      //   labels[l.key.value] = l.value.value;
      // });
      const obj: any = {
        name: self.name.value,
        // labels: labels,
        // taints: self.taints.map((t) => t.asObject),
        machineType: self.machineType.value,
      };
      if (self.minSize.value.length > 0) {
        obj.minSize = Number(self.minSize.value);
      }
      if (self.maxSize.value.length > 0) {
        obj.maxSize = Number(self.maxSize.value);
      }
      return obj;
    },
  }));

export const Mk8sDraftProviderPaperSpaceModel = types
  .model({
    _region: types.optional(types.string, ""),
    region: types.optional(StringModel, () => StringModel.create({ label: "Region", isRequired: true })),
    _nodePools: types.array(Mk8sDraftProviderPaperSpaceNodePoolsReadonlyModel),
    nodePools: types.array(Mk8sDraftProviderPaperSpaceNodePoolsModel),
    removedA: types.optional(types.boolean, false),
    _sharedDrives: types.array(types.string),
    sharedDrives: types.array(types.string),
    sharedDriveInput: types.optional(StringModel, () => StringModel.create({ label: "Shared Drive" })),
    _tokenSecretLink: types.optional(types.string, ""),
    // opaque link
    tokenSecretLink: types.optional(StringModel, () =>
      StringModel.create({
        label: "Token Secret Link",
        isRequired: true,
      })
    ),
    _unmanagedNodePools: types.array(Mk8sDraftUnmanagedNodePoolReadOnlyModel),
    unmanagedNodePools: types.array(Mk8sDraftUnmanagedNodePoolModel),
    removedUnmanaged: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    reset() {
      self.region.setInitialValue(self._region);
      self.tokenSecretLink.setInitialValue(self._tokenSecretLink);

      self.sharedDrives.clear();
      for (let _sharedDrive of self._sharedDrives) {
        self.sharedDrives.push(_sharedDrive);
      }

      self.nodePools.clear();
      for (let index in self._nodePools) {
        const _nodePool = self._nodePools[index];

        self.nodePools.push(
          Mk8sDraftProviderPaperSpaceNodePoolsModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          })
        );
      }
      self.unmanagedNodePools.clear();
      for (let index in self._unmanagedNodePools) {
        const _nodePool = self._unmanagedNodePools[index];

        self.unmanagedNodePools.push(
          Mk8sDraftUnmanagedNodePoolModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          })
        );
      }
      self.removedA = false;
      self.removedUnmanaged = false;
    },
    confirm() {
      self.region.confirm();
      self._region = self.region.value;
      self.tokenSecretLink.confirm();
      self._tokenSecretLink = self.tokenSecretLink.value;

      self._sharedDrives.clear();
      for (let sharedDrive of self.sharedDrives) {
        self._sharedDrives.push(sharedDrive);
      }
      self._nodePools.clear();
      for (let nodePool of self.nodePools) {
        nodePool.confirm();
        self._nodePools.push(
          Mk8sDraftProviderPaperSpaceNodePoolsReadonlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({
              key: key,
              value: value as string,
            })),
            taints: nodePool.asObject.taints,
          })
        );
      }
      self.removedA = false;
      self._unmanagedNodePools.clear();
      for (let nodePool of self.unmanagedNodePools) {
        nodePool.confirm();
        self._unmanagedNodePools.push(
          Mk8sDraftUnmanagedNodePoolReadOnlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({ key: key, value: value })),
            taints: nodePool.asObject.taints,
          })
        );
      }
      self.removedUnmanaged = false;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    addSharedDrive() {
      const newSharedDrive = self.sharedDriveInput.value;
      if (self.sharedDrives.includes(newSharedDrive)) {
        return;
      }
      self.sharedDrives.push(newSharedDrive);
      self.sharedDriveInput.reset();
    },
    removeSharedDrive(value: string) {
      if (!self.sharedDrives.includes(value)) {
        return;
      }
      self.sharedDrives.remove(value);
    },
    addNodePool() {
      let index = 0;
      if (self.nodePools.length > 0) {
        index = self.nodePools[self.nodePools.length - 1].index + 1;
      }

      self.nodePools.push(
        Mk8sDraftProviderPaperSpaceNodePoolsModel.create({
          index,
          status: "added",
        })
      );
    },
    removeNodePoolAt(index: number) {
      const node = self.nodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removedA = true;
      }
      self.nodePools.remove(node);
    },
    addUnmanagedNodePool() {
      let index = 0;
      if (self.unmanagedNodePools.length > 0) {
        index = self.unmanagedNodePools[self.unmanagedNodePools.length - 1].index + 1;
      }

      self.unmanagedNodePools.push(
        Mk8sDraftUnmanagedNodePoolModel.create({
          index,
          status: "added",
        })
      );
    },
    removeUnmanagedNodePoolAt(index: number) {
      const node = self.unmanagedNodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removedUnmanaged = true;
      }
      self.unmanagedNodePools.remove(node);
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.region.isDirty) res = true;
      if (self.tokenSecretLink.isDirty) res = true;
      if (!arraysAreEqual(self._sharedDrives, self.sharedDrives)) res = true;
      if (self._unmanagedNodePools.length !== self.unmanagedNodePools.length) res = true;
      if (self.removedA) res = true;
      if (self.removedUnmanaged) res = true;
      if (self.unmanagedNodePools.some((n) => n.isDirty)) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.region.isDirty) reason = self.region.isDirtyReason;
      if (self.tokenSecretLink.isDirty) reason = self.tokenSecretLink.isDirtyReason;
      if (!arraysAreEqual(self._sharedDrives, self.sharedDrives)) reason = "shared drives are different";
      if (self._nodePools.length !== self.nodePools.length) reason = "node pools length is different";
      if (self.removedA) reason = "removed a pool";
      if (self.nodePools.some((n) => n.isDirty)) reason = self.nodePools.find((n) => n.isDirty)?.dirtyReason || "";
      if (self._unmanagedNodePools.length !== self.unmanagedNodePools.length)
        reason = "unmanaged node pools length is different";
      if (self.removedUnmanaged) reason = "removed an unmanaged pool";
      if (self.unmanagedNodePools.some((n) => n.isDirty))
        reason = self.unmanagedNodePools.find((n) => n.isDirty)?.dirtyReason || "";
      return reason;
    },
    get isValid() {
      let res = true;
      if (!self.region.isValid) res = false;
      if (!self.tokenSecretLink.value) res = false;
      if (self.nodePools.some((n) => !n.isValid)) res = false;
      if (self.unmanagedNodePools.some((n) => !n.isValid)) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (!self.region.isValid) reason = self.region.invalidReason;
      if (!self.tokenSecretLink.value) reason = "no token secret link";
      if (self.nodePools.some((n) => !n.isValid)) reason = self.nodePools.find((n) => !n.isValid)?.invalidReason || "";
      if (self.unmanagedNodePools.some((n) => !n.isValid))
        reason = self.unmanagedNodePools.find((n) => !n.isValid)?.invalidReason || "";
      return reason;
    },
    get asObject() {
      return {
        region: self.region.value,
        tokenSecretLink: self.tokenSecretLink.value,
        sharedDrives: [...self.sharedDrives],
        nodePools: self.nodePools.map((n) => n.asObject),
        unmanagedNodePools: self.unmanagedNodePools.map((n) => n.asObject),
      };
    },
  }));
export interface Mk8sDraftProviderPaperSpaceMobx extends Instance<typeof Mk8sDraftProviderPaperSpaceModel> {}
