import * as React from "react";
import { notification } from "antd";
import { ChevronDown, ChevronUp, Copy, ExternalLink } from "react-feather";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../mst/stores/mk8s.draft";
import { NGInput } from "../../newcomponents/input/input";
import { NGButton } from "../../newcomponents/button/Button";
import { CodeEditor } from "../group/identityMatcher/codeEditor";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";

export interface AWSInstructions {
  message: string;
  data: {
    deployRole: string;
    trustPolicy: any;
    connectorPolicy: any;
    accessPolicies: string[];
    builtInPolicies: string[];
    cli: string[];
    // $ACCOUNT_ID
  };
}

// replace $ACCOUNT_ID in cli instructions

interface Props {
  instructions: AWSInstructions;
  draft: Mk8sDraftMobx;
}
const ProviderAWSInstructionsRaw: React.FC<Props> = ({ instructions, draft }) => {
  const [state, _setState] = React.useState<"none" | "cli" | "ui">("none");

  function setState(from: "cli" | "ui") {
    if (state === from) {
      _setState("none");
    } else {
      _setState(from);
    }
  }

  async function copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    notification.success({
      message: "Copied to clipboard",
    });
  }

  return (
    <>
      <div className="mt-1">
        <button className="block ngfocus color-link" onClick={() => setState("cli")}>
          How do I get my AWS Deploy Role ARN with AWS CLI?{" "}
          {state === "cli" ? (
            <ChevronUp style={{ transform: "translateY(2px)" }} className="feather-icon inline-block" />
          ) : (
            <ChevronDown style={{ transform: "translateY(2px)" }} className="feather-icon inline-block" />
          )}
        </button>

        {state === "cli" ? (
          <ul className="mt-1 mb-4 px-4 py-2 ml-4">
            <li className="my-2 list-decimal">
              Sign into the{" "}
              <a className="underline ngfocus color-link" href={"https://console.aws.amazon.com"} target={"_blank"}>
                AWS Console
                <ExternalLink className="ml-1 feather-icon inline-block" style={{ transform: "translateY(2px)" }} />
              </a>
              .
            </li>
            <li className="my-2 list-decimal">
              Click the dropdown next to your <span className="font-semibold">username</span>.
            </li>
            <li className="my-2 list-decimal">
              Copy the <span className="font-semibold">Account ID</span> next to "My Account".
            </li>
            <div className="flex items-center justify-center my-2 border" style={{ width: 421, height: 358 }}>
              <img
                className="object-container"
                style={{ width: 421, height: 358 }}
                src={"/resources/howto/aws_accountIdSteps.jpg"}
                alt={"how do I get my aws account id?"}
              />
            </div>
            <li className="mb-2 list-decimal">Paste it in the field below.</li>
            <NGInput
              style={{ width: 450 }}
              placeholder="AWS Account Id"
              value={draft.provider_aws._awsAccountId.value}
              onChange={(e) => draft.provider_aws._awsAccountId.setValue(e.target.value)}
            />
            <li className="list-decimal my-2">
              Install the{" "}
              <a
                className="underline hover:underline focus:underline ngfocus color-link"
                href={"https://aws.amazon.com/cli"}
                target={"_blank"}
              >
                AWS CLI
              </a>{" "}
              and{" "}
              <a
                className="underline hover:underline focus:underline ngfocus color-link"
                href={"https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-configure.html"}
                target={"_blank"}
              >
                configure authentication
              </a>
              .
            </li>
            <li className="my-2 list-decimal">
              Execute the following commands to create the role and set the policies.
            </li>
            {instructions.data.cli.map((_cliStep) => {
              let cliStep = _cliStep;
              if (draft.provider_aws._awsAccountId.value) {
                cliStep = _cliStep.replace("$ACCOUNT_ID", draft.provider_aws._awsAccountId.value);
              }
              return (
                <>
                  <div className="flex items-center my-2" key={cliStep}>
                    <input className="flex-grow px-4 py-2 border rounded" disabled={true} value={cliStep} />
                    <button className={`ml-2 focus`} onClick={() => copyToClipboard(cliStep)}>
                      <Copy className="ml-1 feather-icon color-link-hover" style={{ transform: "translateY(2px)" }} />
                    </button>
                  </div>
                </>
              );
            })}
            <li className="my-2 list-decimal">
              Execute the following command to get the ARN for the new role, and paste it in the field below.
            </li>
            <div className="flex items-center my-2">
              <input
                className="flex-grow px-4 py-2 border rounded"
                disabled={true}
                value={`aws iam get-role --role-name cpln-mk8s-${ConsoleContext.org} --query 'Role.[RoleName, Arn]' --output text`}
              />
              <button
                className={`ml-2 focus`}
                onClick={() =>
                  copyToClipboard(
                    `aws iam get-role --role-name cpln-mk8s-${ConsoleContext.org} --query 'Role.[RoleName, Arn]' --output text`
                  )
                }
              >
                <Copy className="ml-1 feather-icon color-link-hover" style={{ transform: "translateY(2px)" }} />
              </button>
            </div>
          </ul>
        ) : null}
        <button className="block ngfocus color-link" onClick={() => setState("ui")}>
          How do I get my AWS Deploy Role ARN from AWS UI?{" "}
          {state === "ui" ? (
            <ChevronUp style={{ transform: "translateY(2px)" }} className="feather-icon inline-block" />
          ) : (
            <ChevronDown style={{ transform: "translateY(2px)" }} className="feather-icon inline-block" />
          )}
        </button>
        {state === "ui" ? (
          <ul className="mt-1 mb-4 px-4 py-2 ml-4">
            <li className="list-decimal my-2">
              Navigate to the IAM Dashboard by typing <span className="font-semibold">IAM</span> in the search bar.
            </li>
            <li className="list-decimal my-2">
              In the navigation pane, choose <span className="font-semibold">Roles</span>.
            </li>
            <li className="list-decimal my-2">
              Click <span className="font-semibold">Create Role</span>.
            </li>
            <li className="list-decimal my-2">
              Select <span className="font-semibold">AWS Account</span> option.
            </li>
            <div className="flex items-center justify-center mb-2 border" style={{ width: 800 }}>
              <img
                className="object-container"
                style={{ width: 800 }}
                src={"/resources/howto/aws-new-aws-account.png"}
                alt={"select the option aws account"}
              />
            </div>
            <li className="list-decimal my-2">
              Keep <span className="font-semibold">This account</span> option selected.
            </li>
            <div className="flex items-center justify-center mb-2 border" style={{ width: 650 }}>
              <img
                className="object-container"
                style={{ width: 650 }}
                src={"/resources/howto/aws-new-this-account.png"}
                alt={"no changes at this step, just informing"}
              />
            </div>
            <li className="list-decimal my-2">
              Click <span className="font-semibold">Next</span> twice to go to{" "}
              <span className="font-semibold">Name, review, and create</span> step.
            </li>
            <li className="list-decimal my-2">
              Input role name as <span className="font-semibold">{instructions.data.deployRole}</span>.
              <button
                className={`ml-1 ngfocus color-link-hover`}
                onClick={() => copyToClipboard(instructions.data.deployRole)}
              >
                <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
              </button>
            </li>
            <li className="list-decimal my-2">
              Click <span className="font-semibold">Create role</span>.
            </li>
            {/* create connector policy */}
            <li className="list-decimal my-2">
              In the navigation pane, choose <span className="font-semibold">Policies</span>.
            </li>
            <li className="list-decimal my-2">
              Click <span className="font-semibold">Create Policy</span>.
            </li>
            <li className="list-decimal my-2">
              Switch to the <span className="font-semibold">JSON</span> tab.
            </li>
            <div className="flex items-center justify-center mb-2 border" style={{ width: 364 }}>
              <img
                className="object-container"
                style={{ width: 364 }}
                src={"/resources/howto/awsJSONTab.png"}
                alt={"switch to json tab"}
              />
            </div>
            <li className="list-decimal my-2">Copy and paste the following connector policy:</li>
            <NGButton
              className="my-2"
              variant="secondary"
              onClick={() => copyToClipboard(JSON.stringify(instructions.data.connectorPolicy, null, 2))}
            >
              Copy Connector Policy
            </NGButton>
            <CodeEditor
              value={JSON.stringify(instructions.data.connectorPolicy, null, 2)}
              setValue={() => {}}
              language={"json"}
              options={{
                style: {
                  fontSize: 14,
                },
                basicSetup: {
                  highlightActiveLine: false,
                  highlightActiveLineGutter: false,
                  lineNumbers: true,
                  autocompletion: false,
                  foldGutter: false,
                },
                readOnly: true,
              }}
            />
            <li className="list-decimal my-2">
              Click <span className="font-semibold">Next: Review</span>.
            </li>
            <li className="list-decimal my-2">
              Input policy name as <span className="font-semibold">{instructions.data.accessPolicies[0]}</span>.
              <button
                className={`ml-1 ngfocus color-link-hover`}
                onClick={() => copyToClipboard(instructions.data.accessPolicies[0])}
              >
                <Copy className="ml-1 feather-icon" style={{ transform: "translateY(2px)" }} />
              </button>
            </li>
            <li className="list-decimal my-2">
              Click <span className="font-semibold">Create policy</span>.
            </li>
            {/* Attach policies */}
            <li className="list-decimal my-2">
              In the navigation pane, choose <span className="font-semibold">Roles</span>.
            </li>
            <li className="list-decimal my-2">
              Search for the role <span className="font-semibold">{instructions.data.deployRole}</span> and click on the
              name.
            </li>
            <li className="list-decimal my-2">
              Click <span>Add permissions</span> and select <span>Attach policy</span> within the Permissions tab.
            </li>
            <div className="flex items-center justify-center mb-2 border" style={{ width: 700 }}>
              <img
                className="object-container"
                style={{ width: 700 }}
                src={"/resources/howto/aws-new-attach-policies.png"}
                alt={"click attach policies under permission tab"}
              />
            </div>
            <li className="list-decimal my-2">
              Search for the policy <span className="font-semibold">{instructions.data.accessPolicies[0]}</span> and
              click the checkbox.
            </li>
            {instructions.data.builtInPolicies.map((policy) => (
              <li className="list-decimal my-2">
                Search for the built in policy <span className="font-semibold">{policy}</span> and click the checkbox.
              </li>
            ))}
            <li className="list-decimal my-2">
              Click <span className="font-semibold">Add permissions</span>.
            </li>
            {/* Attach trust policy */}
            <li className="list-decimal my-2">
              Click the <span className="font-semibold">Trust Relationships</span> tab.
            </li>
            <li className="list-decimal my-2">
              Click <span className="font-semibold">Edit trust policy</span>.
            </li>
            <div className="flex items-center justify-center mb-2 border" style={{ width: 720 }}>
              <img
                className="object-container"
                style={{ width: 720 }}
                src={"/resources/howto/aws-new-trust-relationship-accent.png"}
                alt={"edit trust relationship of the role"}
              />
            </div>
            <li className="list-decimal my-2">Copy and paste the following trust policy</li>
            <NGButton
              className="my-2"
              variant="secondary"
              onClick={() => copyToClipboard(JSON.stringify(instructions.data.trustPolicy, null, 2))}
            >
              Copy Trust Policy
            </NGButton>
            <CodeEditor
              value={JSON.stringify(instructions.data.trustPolicy, null, 2)}
              setValue={() => {}}
              language={"json"}
              options={{
                style: {
                  fontSize: 14,
                },
                basicSetup: {
                  highlightActiveLine: false,
                  highlightActiveLineGutter: false,
                  lineNumbers: true,
                  autocompletion: false,
                  foldGutter: false,
                },
                readOnly: true,
              }}
            />
            <li className="list-decimal my-2">
              Click <span className="font-semibold">Update Policy</span>.
            </li>
            <li className="list-decimal my-2">
              Paste your Deploy Role ARN to the input are by copying it from the same page as shown in the image below.
            </li>
            <div className="flex items-center justify-center mb-2 border" style={{ width: 720 }}>
              <img
                className="object-container"
                style={{ width: 842 }}
                src={"/resources/howto/aws_deploy_role_arn.png"}
                alt={"copy deploy role arn"}
              />
            </div>
          </ul>
        ) : null}
      </div>
    </>
  );
};

export const ProviderAWSInstructions = observer(ProviderAWSInstructionsRaw);
